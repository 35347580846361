import Button from "@mui/material/Button";
import { CaretDown as ArrowDropDownIcon } from "@phosphor-icons/react/dist/ssr/CaretDown";
import { styled } from "@mui/material/styles";

const SelectButton = styled(Button)(({ theme }) => ({
	textTransform: "none",
	padding: theme.spacing(1.5, 2),
	justifyContent: "space-between",
	border: `1px solid ${theme.palette.divider}`,
	borderRadius: theme.shape.borderRadius,
	backgroundColor: theme.palette.background.paper,
	color: theme.palette.text.primary,
	"&:hover": {
		backgroundColor: theme.palette.action.hover,
		borderColor: `${theme.palette.divider}`,
	},
}));

interface FakeSelectButtonProps {
	onClick?: () => void;
}

export function FakeSelectButton(props: FakeSelectButtonProps) {
	const { onClick } = props;
	return (
		<SelectButton
			onClick={onClick}
			variant="outlined"
			endIcon={<ArrowDropDownIcon />}>
			Select an option
		</SelectButton>
	);
}
