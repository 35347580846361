import { Box } from "@mui/material";

import { PolygonCategoryConfigs } from "~ui-components/types/__generated/gql/graphql";

interface Props {
	style: PolygonCategoryConfigs;
}
export const PolygonSimple = ({ style }: Props) => {
	return (
		<Box>
			<Box
				width={20}
				height={20}
				bgcolor={style.symbolStyle?.fill?.color ?? "#fff"}
			/>
		</Box>
	);
};
