import { StackProps } from "@mui/material";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { Check as CheckIcon } from "@phosphor-icons/react/dist/ssr/Check";

import { TitleAndDescription } from "./tile-and-description";
import { Caculation } from "./calculation";
import { UserInput } from "./user-input";

import type { StepIconProps } from "@mui/material/StepIcon";

interface FormField {
	value: string;
	label: string;
}

export interface CalculatorPopupInput {
	title: string;
	description: string;
	buttonText: string;
	formFields: FormField[];
	calculatorFormatting: number;
	output: string;
}

type Props = StackProps & {
	popupType?: string;
	attributes: string[];
	form: ReturnType<typeof useFormik<CalculatorPopupInput>>;
};

function StepIcon({
	active,
	completed,
	icon,
}: StepIconProps): React.JSX.Element {
	const highlight = active ?? completed;

	return (
		<Avatar
			sx={{
				...(highlight && {
					bgcolor: "var(--mui-palette-primary-main)",
					color: "var(--mui-palette-primary-contrastText)",
				}),
			}}
			variant="rounded">
			{completed ? <CheckIcon /> : icon}
		</Avatar>
	);
}

export const Calculator = (props: Props) => {
	const { popupType, attributes, form } = props;
	const [activeStep, setActiveStep] = React.useState<number>(0);

	const handleNext = React.useCallback(() => {
		setActiveStep((prevState) => prevState + 1);
	}, []);

	const handleBack = React.useCallback(() => {
		setActiveStep((prevState) => prevState - 1);
	}, []);

	const handleComplete = React.useCallback(() => {
		form.handleSubmit();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const steps = React.useMemo(() => {
		return [
			{
				label: "TITLE AND DESCRIPTION",
				content: (
					<TitleAndDescription
						form={form}
						attributes={attributes}
						onBack={handleBack}
						onNext={handleNext}
					/>
				),
			},
			{
				label: "USER INPUT",
				content: (
					<UserInput
						form={form}
						attributes={attributes}
						onBack={handleBack}
						onNext={handleNext}
					/>
				),
			},
			{
				label: "CALCULATION",
				content: (
					<Caculation
						form={form}
						attributes={attributes}
						onBack={handleBack}
						onNext={handleComplete}
					/>
				),
			},
		];

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleBack, handleNext, handleComplete, form]);

	if (popupType === "calculator")
		return (
			<>
				<Stepper
					activeStep={activeStep}
					orientation="vertical"
					sx={{
						"& .MuiStepConnector-root": { ml: "19px" },
						"& .MuiStepConnector-line": {
							borderLeft: "2px solid var(--mui-palette-divider)",
						},
						"& .MuiStepLabel-iconContainer": { paddingRight: "16px" },
						"& .MuiStepContent-root": {
							borderLeft: "2px solid var(--mui-palette-divider)",
							ml: "19px",
						},
						"& .MuiStep-root:last-of-type .MuiStepContent-root": {
							borderColor: "transparent",
						},
					}}>
					{steps.map((step) => {
						return (
							<Step key={step.label}>
								<StepLabel StepIconComponent={StepIcon}>
									<Typography variant="overline">{step.label}</Typography>
								</StepLabel>
								<StepContent>
									<Box sx={{ px: 2, py: 3 }}>{step.content}</Box>
								</StepContent>
							</Step>
						);
					})}
				</Stepper>
			</>
		);
	return null;
};
