import { DefaultSeo as DefaultNextSeo } from "next-seo";

const { url, siteName, title, description, type } = {
	url: "mangomap.com",
	siteName: "Mango GIS",
	title: "Simple, professional online GIS maps",
	description:
		"The accessible and user-friendly alternative to Esri for smart spenders. Create and securely share maps your way.",
	type: "website",
};

export const DefaultSeo = () => {
	return (
		<DefaultNextSeo
			title={title}
			description={description}
			dangerouslySetAllPagesToNoFollow={process.env.ENABLE_SEO !== "true"}
			dangerouslySetAllPagesToNoIndex={process.env.ENABLE_SEO !== "true"}
			themeColor="#ff5722"
			canonical="https://mangomap.com"
			robotsProps={{
				maxSnippet: -1,
				maxImagePreview: "large",
				maxVideoPreview: -1,
			}}
			additionalMetaTags={[
				{
					name: "application-name",
					content: "Mango GIS",
				},
				{
					name: "apple-mobile-web-app-title",
					content: "Mango GIS",
				},
				{
					name: "format-detection",
					content: "telephone=no",
				},
				{
					name: "msapplication-TileColor",
					content: "#FF5621",
				},
				{
					name: "msapplication-tap-highlight",
					content: "no",
				},
			]}
			additionalLinkTags={[
				{
					rel: "icon",
					href: "/favicon.ico",
				},
				{
					rel: "apple-touch-icon",
					href: "/icons/apple-touch-icon.png",
					sizes: "180x180",
				},
				{
					rel: "icon",
					type: "image/png",
					sizes: "32x32",
					href: "/icons/favicon-32x32.png",
				},
				{
					rel: "icon",
					type: "image/png",
					sizes: "16x16",
					href: "/icons/favicon-16x16.png",
				},
				{
					rel: "manifest",
					href: "/manifest.json",
				},
				{
					rel: "preconnect",
					href: "https://fonts.googleapis.com",
				},
				{
					rel: "preconnect",
					href: "https://fonts.gstatic.com",
				},
				{
					rel: "preconnect",
					href: "https://dev.visualwebsiteoptimizer.com",
				},
			]}
			openGraph={{
				type,
				url,
				title,
				description:
					"Create and securely share maps your way. The accessible and user-friendly alternative to Esri for smart spenders.",
				siteName,
				locale: "en_GB",
				images: [
					{
						url: "/new-mangomap-assets/images/mangoGIS.png",
						width: 1182,
						height: 670,
						alt: "Mango GIS",
						type: "image/png",
					},
				],
			}}
		/>
	);
};
