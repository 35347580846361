import * as React from "react";

interface DialogController<T> {
	data?: T;
	handleClose: () => void;
	handleOpen: (data?: T) => void;
	open: boolean;
}

export function useDialog<TData = unknown>(): DialogController<TData> {
	const [state, setState] = React.useState<{ open: boolean; data?: TData }>({
		open: false,
		data: undefined,
	});

	const handleOpen = React.useCallback((data?: TData) => {
		setState({ open: true, data });
	}, []);

	const handleClose = React.useCallback(() => {
		setState({ open: false });
	}, []);

	return { ...state, handleClose, handleOpen };
}
