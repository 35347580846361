import {
	Alert,
	Box,
	Button,
	CardActions,
	Divider,
	Drawer,
	IconButton,
	Stack,
	Typography,
} from "@mui/material";
import {
	Question as HelpIcon,
	X as XIcon,
} from "@phosphor-icons/react/dist/ssr";
import { Editor } from "@tiptap/react";
import NextLink from "next/link";
import * as React from "react";

import { TextEditor } from "../../../text-editor";

export interface SettingsDrawerProps {
	editor: Editor;
	attributes?: string[];
	onClose?: () => void;
}

export const EquationDrawer: React.FC<SettingsDrawerProps> = ({
	editor: parentEditor,
	onClose,
	attributes,
}) => {
	const [content, setContent] = React.useState("");

	return (
		<Drawer
			open
			ModalProps={{ BackdropProps: { invisible: false }, sx: { zIndex: 1400 } }}
			PaperProps={{
				elevation: 24,
				sx: {
					display: "flex",
					flexDirection: "column",
					maxWidth: "100%",
					width: "440px",
				},
			}}
			anchor="right"
			disableScrollLock
			onClose={onClose}>
			<Stack
				direction="row"
				spacing={3}
				sx={{
					alignItems: "center",
					justifyContent: "space-between",
					px: 3,
					pt: 2,
				}}>
				<Typography variant="h6">Insert equation</Typography>

				<Stack
					direction="row"
					spacing={0.5}
					sx={{ alignItems: "center" }}>
					<IconButton onClick={onClose}>
						<XIcon />
					</IconButton>
				</Stack>
			</Stack>

			<Divider sx={{ mt: 2 }} />
			<Stack
				spacing={5}
				sx={{ overflowY: "auto", p: 3 }}>
				<TextEditor
					autofocus
					onUpdate={(e) => setContent(e.editor.getHTML())}
					toolsProps={{
						MathOperators: true,
						Typography: false,
						Bold: false,
						Italic: false,
						Strike: false,
						BulletList: false,
						OrderedList: false,
						AttributeSelect: { attributes },
					}}
				/>
				<Alert severity="info">
					<Stack
						direction="row"
						spacing={1}>
						<Typography variant="body2">
							Use attribute values for basic arithmetic and conditional
							statements.
						</Typography>
						<Box sx={{ width: 200 }}>
							<NextLink
								href="https://example.com"
								target="_blank">
								Learn more
								<HelpIcon />
							</NextLink>
						</Box>
					</Stack>
				</Alert>
				<CardActions sx={{ justifyContent: "flex-end", m: 0, p: 0, mt: 2 }}>
					<Button
						color="secondary"
						onClick={onClose}>
						Cancel
					</Button>
					<Button
						variant="contained"
						onClick={() => {
							parentEditor
								.chain()
								.focus()
								.insertContent(`{ ${content} }`)
								.run();
							onClose?.();
						}}>
						Save changes
					</Button>
				</CardActions>
			</Stack>
		</Drawer>
	);
};
