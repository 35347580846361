import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { ArrowRight as ArrowRightIcon } from "@phosphor-icons/react/dist/ssr/ArrowRight";
import Box from "@mui/material/Box";
import { Plus as AddIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import InputLabel from "@mui/material/InputLabel";
import Divider from "@mui/material/Divider";
import { ArrowLeft as ArrowLeftIcon } from "@phosphor-icons/react/dist/ssr/ArrowLeft";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import { getNanoId } from "utils";

import { SortableUserInput, UserInputProps } from "./sortable-user-input";

import { CalculatorPopupInput } from "./index";
export interface JobDetailsStepProps {
	form: ReturnType<typeof useFormik<CalculatorPopupInput>>;
	attributes: string[];
	onNext?: () => void;
	onBack?: () => void;
}

export function UserInput({
	form,
	onBack,
	onNext,
}: JobDetailsStepProps): React.JSX.Element {
	const [userInputs, setUserInputs] = React.useState<UserInputProps[]>([]);

	React.useEffect(() => {
		void form.setFieldValue("formFields", userInputs);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInputs]);

	const handleAddUserInput = () => {
		const input = [
			...userInputs,
			{
				id: getNanoId(),
				value: `{form_${userInputs.length + 1}}`,
				label: "Value",
			},
		];
		setUserInputs(input);
	};

	const handleChange = (key: string, value: string) => {
		setUserInputs(
			userInputs.map((item) => {
				if (item.id === key) {
					item.label = value;
				}
				return item;
			}),
		);
	};

	return (
		<Stack spacing={4}>
			<Stack
				spacing={2}
				direction="row">
				<Stack
					spacing={2}
					sx={{ width: "100%" }}>
					<Button
						onClick={handleAddUserInput}
						component="label"
						role={undefined}
						variant="contained"
						color="inherit"
						tabIndex={-1}
						startIcon={<AddIcon />}>
						User input field
					</Button>
					<SortableUserInput
						onUserInputChange={handleChange}
						userInputs={userInputs}
					/>
				</Stack>
				<Stack sx={{ ml: 3, width: "100%" }}>
					<InputLabel>Input preview</InputLabel>
					<Card
						sx={{
							display: "flex",
							flexDirection: "column",
							mt: 1,
							borderRadius: 1,
							flexGrow: 1,
						}}>
						<Stack
							sx={{
								p: 1,
								alignItems: "center",
								backgroundColor: "var(--mui-palette-background-dark)",
							}}
							direction="row"
							spacing={2}>
							{form.values.title || "Title"}
						</Stack>
						<Divider />
						<CardContent sx={{ flexGrow: 1 }}>
							<Stack
								sx={{ height: "100%" }}
								direction="column"
								spacing={2}>
								<Box sx={{ flexGrow: 1 }}>{form.values.description}</Box>
								{userInputs.map((item) => (
									<FormControl
										sx={{ mt: 1 }}
										key={item.id}>
										<InputLabel>{item.label}</InputLabel>
										<OutlinedInput />
									</FormControl>
								))}
								{form.values.buttonText && (
									<Button
										sx={{ width: "200px", alignSelf: "flex-end" }}
										variant="contained"
										color="secondary">
										{form.values.buttonText}
									</Button>
								)}
							</Stack>
						</CardContent>
					</Card>
				</Stack>
			</Stack>
			<Stack
				direction="row"
				spacing={2}
				sx={{ alignItems: "center", justifyContent: "flex-end" }}>
				<Button
					color="secondary"
					onClick={onBack}
					startIcon={<ArrowLeftIcon />}>
					Back
				</Button>
				<Button
					endIcon={<ArrowRightIcon />}
					onClick={onNext}
					variant="contained">
					Continue
				</Button>
			</Stack>
		</Stack>
	);
}
