import { FC, useMemo } from "react";
import {
	Box,
	Card,
	Divider,
	IconButton,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import {
	MapPinLine,
	PenNib,
	MapTrifold,
	TrashSimple,
	PencilSimple,
} from "@phosphor-icons/react/dist/ssr";

import {
	ColumnDef,
	DataTable,
} from "~ui-components/components/atoms/data-table";
import { Option } from "~ui-components/components/atoms/option";

import { BookmarksItem } from ".";

interface BookmarksListProps {
	bookmarksList: BookmarksItem[];
	onEditClick: (bookmark: BookmarksItem) => void;
	onDeleteClick: (bookmark: BookmarksItem) => void;
}

const BookmarkTypeIcon = {
	feature: <MapTrifold fontSize="var(--icon-fontSize-lg)" />,
	place: <MapPinLine fontSize="var(--icon-fontSize-lg)" />,
	sketch: <PenNib fontSize="var(--icon-fontSize-lg)" />,
};

export const BookmarksList: FC<BookmarksListProps> = ({
	bookmarksList,
	onDeleteClick,
	onEditClick,
}) => {
	const columns = useMemo(
		() =>
			[
				{
					name: "Type",
					formatter: (row): React.JSX.Element => (
						<Stack
							direction="row"
							alignItems={"center"}
							spacing={2}>
							{BookmarkTypeIcon[row.type]}
							<Stack>
								<Typography variant="body2">{row.title}</Typography>
								{row?.description && (
									<Typography
										color={"text.secondary"}
										variant="caption">
										{row.description}
									</Typography>
								)}
							</Stack>
						</Stack>
					),
				},
				{
					name: "Actions",
					formatter: (row: BookmarksItem): React.JSX.Element => (
						<Stack
							direction="row"
							spacing={1}>
							<IconButton onClick={() => onEditClick(row)}>
								<PencilSimple />
							</IconButton>
							<IconButton onClick={() => onDeleteClick(row)}>
								<TrashSimple />
							</IconButton>
						</Stack>
					),
					align: "right",
				},
			] satisfies ColumnDef<BookmarksItem>[],

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return (
		<>
			<Card sx={{ p: 2 }}>
				<BookmarksFilters />
				<Divider />
				<Box sx={{ overflowX: "auto" }}>
					<DataTable<BookmarksItem>
						hideHead
						columns={columns}
						rows={bookmarksList}
					/>
				</Box>
				<Divider />
			</Card>
		</>
	);
};

const BookmarksFilters = () => {
	return (
		<Stack
			direction="row-reverse"
			spacing={2}
			sx={{ alignItems: "center", flexWrap: "wrap", p: 2 }}>
			<Select
				name="sort"
				sx={{ maxWidth: "100%", width: "120px" }}
				value={"desc"}>
				<Option value="desc">Newest</Option>
				<Option value="asc">Oldest</Option>
			</Select>
		</Stack>
	);
};
