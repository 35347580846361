import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { Question as QuestionIcon } from "@phosphor-icons/react/dist/ssr/Question";
import { Ruler as RulerIcon } from "@phosphor-icons/react/dist/ssr/Ruler";
import RouterLink from "next/link";
import * as React from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

import { ToolsMeasureForm, ToolsMeasureFormProps } from "./tools-measure-form";

const href =
	"https://help.mangomap.com/hc/en-us/articles/360022375311-Measure-areas-and-distance";

interface ToolsMeasureProps {
	form: ToolsMeasureFormProps["form"];
}

export function ToolsMeasure(props: ToolsMeasureProps): React.JSX.Element {
	const { form } = props;

	return (
		<DashboardContentLayout pageTitle="Measure">
			<Card>
				<CardHeader
					action={
						<IconButton
							sx={{ borderRadius: 99 }}
							component={RouterLink}
							href={href}>
							<QuestionIcon fontSize="inherit" />
						</IconButton>
					}
					avatar={
						<Avatar>
							<RulerIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Allow users to measure distance and area"
				/>
				<ToolsMeasureForm form={form} />
			</Card>
		</DashboardContentLayout>
	);
}

export * from "./tools-measure-form";
