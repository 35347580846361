import { Button, Stack } from "@mui/material";
import { useState } from "react";

import { MapToolDrawer } from "../map-tool-drawer";

import { BookmarksList } from "./bookmarks-list";
import { EditBookmark } from "./edit-bookmark";
import { DeleteBookmarkDialog } from "./delete-bookmark-dialog";

export interface BookmarksItem {
	id: string;
	title: string;
	type: "feature" | "place" | "sketch";
	description?: string;
	isShared?: boolean;
}

type ViewProps = "list" | "edit";

export const BookmarksDrawer = ({ onClose, bookmarksList }) => {
	const [view, setView] = useState<ViewProps>("list");
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [selectedBookmark, setSelectedBookmark] =
		useState<BookmarksItem | null>(null);

	const handleDeleteClick = (bookmark) => {
		setSelectedBookmark(bookmark);
		setShowDeleteDialog(true);
	};

	const handleEditClick = (bookmark) => {
		setSelectedBookmark(bookmark);
		setView("edit");
	};

	return (
		<>
			<MapToolDrawer
				title="Bookmarks"
				onClose={onClose}
				Content={
					<>
						{view === "list" && (
							<BookmarksList
								bookmarksList={bookmarksList}
								onEditClick={handleEditClick}
								onDeleteClick={handleDeleteClick}
							/>
						)}
						{view === "edit" && <EditBookmark bookmark={selectedBookmark} />}
					</>
				}
				Action={
					view !== "list" && (
						<Stack
							direction={"row-reverse"}
							spacing={3}
							p={2}>
							<Button
								variant="contained"
								color="primary">
								Save
							</Button>
							<Button
								onClick={() => setView("list")}
								color="primary">
								Cancel
							</Button>
						</Stack>
					)
				}
			/>
			<DeleteBookmarkDialog
				open={showDeleteDialog}
				onClose={() => setShowDeleteDialog(false)}
			/>
		</>
	);
};
