import { useMutation } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { UpdatePasswordFormValues } from "ui-components";
import { apiInstance } from "utils";

export type UpdatePasswordBody = Record<string, unknown>;
export type UpdatePasswordResponse = Record<string, unknown>;

export const useUpdatePassword = () => {
	const {
		query: { "user-id": userId, token },
	} = useRouter();

	return useMutation({
		mutationKey: ["updatePassword"],
		mutationFn: async (values: UpdatePasswordFormValues) => {
			const postForm = new FormData();
			postForm.append("_method", "put");
			postForm.append("user[password]", values.password);
			postForm.append("user[password_confirmation]", values.retypedPassword);
			return apiInstance.post(
				`/users/${userId}/password?token=${token}`,
				postForm,
			);
		},
	});
};
