import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import { FC } from "react";

import { AccountBillingFormValues } from ".";

interface InputFieldProps {
	form: ReturnType<typeof useFormik<AccountBillingFormValues>>;
	name: string;
	label: string;
	md?: number;
	placeholder?: string;
}

export const InputField: FC<InputFieldProps> = ({
	form,
	name,
	label,
	md = 6,
	placeholder = "",
}) => {
	const { errors, getFieldProps } = form;

	return (
		<Grid
			md={md}
			xs={12}>
			<FormControl fullWidth>
				<TextField
					{...getFieldProps(name)}
					label={label}
					error={Boolean(errors[name])}
					helperText={errors[name]}
					placeholder={placeholder}
				/>
			</FormControl>
		</Grid>
	);
};
