import { useQuery } from "@tanstack/react-query";
import { graphierQLNextClient } from "utils";

import {
	GetCurrentUserQuery,
	GetCurrentUserQueryVariables,
} from "~auth/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetCurrentUser {
		getCurrentUser {
			id
			createdAt
			updatedAt
			name
			email
		}
	}
`;

export type GetCurrentUserVariables = GetCurrentUserQueryVariables;
export type GetCurrentUserResults = GetCurrentUserQuery["getCurrentUser"];

export const getCurrentUserQueryKey = () => ["getCurrentUser"];

const getCurrentUserQueryFn = async () => {
	return graphierQLNextClient<GetCurrentUserQuery, GetCurrentUserVariables>({
		query,
	}).then((res) => res.getCurrentUser);
};

export const useGetCurrentUser = (enabled?: boolean) => {
	return useQuery({
		enabled,
		queryKey: getCurrentUserQueryKey(),
		queryFn: () => getCurrentUserQueryFn(),
	});
};

export const useGetCurrentUserCache = () => {
	return useGetCurrentUser(false);
};

export const useGetCurrentUserAsserted = () => {
	const { data, ...rest } = useGetCurrentUserCache();
	if (!data) throw new Error("Data not available yet");

	return { ...rest, data };
};
