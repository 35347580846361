import { use } from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

// eslint-disable-next-line react-hooks/rules-of-hooks
export const i18n = use(Backend)
	.use(initReactI18next)
	.init({ lng: "en", fallbackLng: "en", interpolation: { escapeValue: false } })
	.catch((err) => {
		console.error("Failed to initialize i18n", err);
	});
