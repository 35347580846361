export const sortAscendingByCreatedAt = <T extends { createdAt: string }>(
	a: T,
	b: T,
) => {
	return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
};

export const sortMapNestedObject = (map) => {
	return {
		...map,
		layerGroupCollection: {
			...map.layerGroupCollection,
			items: map.layerGroupCollection.items
				.map((group) => ({
					...group,
					layerCollection: {
						...group.layerCollection,
						items: group.layerCollection.items.sort(sortAscendingByCreatedAt),
					},
				}))
				.sort(sortAscendingByCreatedAt),
		},
	};
};
