import { FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import { paths } from "config/paths";
import { useFormik } from "formik";
import RouterLink from "next/link";
import { FC } from "react";
import * as Yup from "yup";

import { MangoLogo } from "~ui-components/components/atoms/mango-logo";
import { CenteredLayout } from "~ui-components/components/organisms/centered-layout";

export const ForgotPasswordSchema = Yup.object({
	email: Yup.string().email("Invalid email").required("Required"),
});

export type ForgotPasswordFormValues = Yup.InferType<
	typeof ForgotPasswordSchema
>;

export interface ForgotPasswordProps {
	form: ReturnType<typeof useFormik<ForgotPasswordFormValues>>;
}

export const ForgotPassword: FC<ForgotPasswordProps> = ({ form }) => {
	return (
		<CenteredLayout>
			<Stack
				spacing={4}
				component="form"
				onSubmit={form.handleSubmit}>
				<div>
					<Box
						component={RouterLink}
						href={paths.home}
						sx={{ display: "inline-block", fontSize: 0 }}>
						<MangoLogo
							width={122}
							height={32}
						/>
					</Box>
				</div>
				<Card>
					<CardHeader title="Forgot password" />
					<CardContent>
						<Stack spacing={2}>
							<FormControl>
								<InputLabel>Email address</InputLabel>
								<OutlinedInput
									autoFocus
									type="email"
									{...form.getFieldProps("email")}
									error={!!form.touched.email && !!form.errors.email}
								/>
								{!!form.touched.email && !!form.errors.email && (
									<FormHelperText error>{form.errors.email}</FormHelperText>
								)}
							</FormControl>
							<Button
								type="submit"
								variant="contained"
								disabled={form.isSubmitting || !form.isValid}>
								Send reset link
							</Button>
						</Stack>
					</CardContent>
				</Card>
			</Stack>
		</CenteredLayout>
	);
};
