import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import {
	HandTap,
	Selection,
	CircleDashed,
	Polygon,
	SelectionAll,
} from "@phosphor-icons/react/dist/ssr";

type SelectionMode = "click" | "rectangle" | "radius" | "polygon" | "all";

const SELECTION_MODES = [
	{
		value: "click",
		tooltip: "Select one or more features by click",
		icon: <HandTap />,
	},
	{
		value: "rectangle",
		tooltip: "Select features within a rectangle",
		icon: <Selection />,
	},
	{
		value: "radius",
		tooltip: "Select features within a radius",
		icon: <CircleDashed />,
	},
	{
		value: "polygon",
		tooltip: "Select features within a freehand shape",
		icon: <Polygon />,
	},
	{ value: "all", tooltip: "Select all features", icon: <SelectionAll /> },
];

export const SelectionToggleInput = () => {
	const [selectionMode, setSelectionMode] = useState<SelectionMode>("click");

	return (
		<FormControl>
			<InputLabel>Selection</InputLabel>
			<ToggleButtonGroup
				color="primary"
				exclusive
				onChange={(_, value: SelectionMode) => {
					setSelectionMode(value);
				}}
				tabIndex={0}
				value={selectionMode}
				sx={{
					mt: 2,
					display: "flex",
					width: "188px",
				}}>
				{SELECTION_MODES.map(({ value, tooltip, icon }) => (
					<Tooltip
						key={value}
						title={tooltip}
						placement="bottom">
						<ToggleButton value={value}>{icon}</ToggleButton>
					</Tooltip>
				))}
			</ToggleButtonGroup>
			<FormHelperText sx={{ ml: 0 }}>
				Choose features or area from query layer.
			</FormHelperText>
		</FormControl>
	);
};
