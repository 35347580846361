import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Lock as LockIcon } from "@phosphor-icons/react/dist/ssr";
import { paths } from "config/paths";
import { useRouter } from "next/router";
import { FC } from "react";

interface AccountFormConfirmationCardProps {
	disabledCheckout?: boolean;
}

export const EditSubscriptionConfirmCard: FC<
	AccountFormConfirmationCardProps
> = ({ disabledCheckout }) => {
	const router = useRouter();

	return (
		<Card
			sx={{
				bgcolor: "background.level2",
			}}>
			<CardContent>
				<Stack spacing={2}>
					<Stack
						spacing={2}
						direction={"row"}
						alignItems={"center"}>
						<Button
							variant="text"
							color="inherit"
							sx={{
								minWidth: "120px",
								typography: "body2",
							}}
							href={paths.accountName.settings.root(
								router.query["account-name"],
							)}>
							Continue trial
						</Button>
						<Button
							fullWidth
							variant="contained"
							type="submit"
							disabled={disabledCheckout}>
							Checkout
						</Button>
					</Stack>
					<Stack
						padding={2}
						spacing={2}>
						<Stack
							direction="row"
							spacing={2}
							sx={{ alignItems: "center" }}>
							<Box
								sx={{
									alignItems: "center",
									color: "var(--mui-palette-success-main)",
									display: "flex",
									justifyContent: "center",
								}}>
								<LockIcon />
							</Box>
							<Typography variant="subtitle2">Secure checkout</Typography>
						</Stack>
						<Stack spacing={2}>
							<Typography
								variant="body2"
								color={"text.secondary"}>
								Your purchases are secured by an industry best encryption
								service - Recurly
							</Typography>
						</Stack>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	);
};
