import { Button, Chip, Link } from "@mui/material";
import * as React from "react";
import NextLink from "next/link";
import { railsServerUrls } from "config/rails-server-urls";

import {
	ColumnDef,
	DataTable,
} from "~ui-components/components/atoms/data-table";
import { dayjs } from "~ui-components/libs/dayjs";

export interface Invoice {
	id: string;
	date: Date;
	isPayNowAllowed: boolean;
	number: string;
	status: "PAID" | "PAST_DUE";
	amount: number;
}

const columns = [
	{
		formatter: (row) => dayjs(row.date).format("MMM D, YYYY"),
		field: "date",
		name: "Date",
		width: "250px",
	},
	{
		formatter: (row) =>
			row.isPayNowAllowed ? (
				<Button
					variant="contained"
					color="secondary"
					href={railsServerUrls.home}
					target="_blank">
					Pay now
				</Button>
			) : null,
		name: "",
		align: "right",
		width: "150px",
	},
	{
		formatter: (row) => (
			<Link
				component={NextLink}
				href={railsServerUrls.home}
				target="_blank">
				#{row.number}
			</Link>
		),

		name: "Invoice",
		width: "150px",
		align: "right",
	},
	{
		formatter: (row) => {
			if (row.status === "PAID")
				return (
					<Chip
						color="success"
						label="Paid"
						size="small"
						variant="soft"
					/>
				);
			if (row.status === "PAST_DUE")
				return (
					<Chip
						color="error"
						label="Past due"
						size="small"
						variant="soft"
					/>
				);
			return null;
		},
		name: "Status",
		width: "150px",
		align: "center",
	},
	{
		formatter: (row) =>
			new Intl.NumberFormat("en-US", {
				style: "currency",
				currency: "USD",
			}).format(row.amount),
		name: "Amount",
		width: "150px",
		align: "right",
	},
] satisfies ColumnDef<Invoice>[];

export interface InvoicesTableProps {
	rows: Invoice[];
}

export function InvoicesTable({ rows }: InvoicesTableProps): React.JSX.Element {
	return (
		<DataTable<Invoice>
			columns={columns}
			rows={rows}
		/>
	);
}
