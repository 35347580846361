import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { EyedropperSample as EyedropperSampleIcon } from "@phosphor-icons/react/dist/ssr/EyedropperSample";
import { map } from "lodash";
import { useState } from "react";

const BASE_MAP_ICONS = {
	1: "/new-mangomap-assets/images/basemap/basemap-1.png",
	2: "/new-mangomap-assets/images/basemap/basemap-2.png",
	3: "/new-mangomap-assets/images/basemap/basemap-3.png",
	4: "/new-mangomap-assets/images/basemap/basemap-4.png",
	5: "/new-mangomap-assets/images/basemap/basemap-5.png",
	6: "/new-mangomap-assets/images/basemap/basemap-6.png",
	7: "/new-mangomap-assets/images/basemap/basemap-7.png",
	8: "/new-mangomap-assets/images/basemap/basemap-8.png",
	9: <EyedropperSampleIcon size={20} />,
};

export const BaseMap = () => {
	const [currentBaseMap, setCurrentBaseMap] = useState("1");

	return (
		<Stack
			direction="row"
			spacing={2}
			alignItems="center">
			<Typography variant="subtitle2">BaseMap</Typography>
			{map(BASE_MAP_ICONS, (icon, key) => {
				const isSelected = currentBaseMap === key;

				let avatarProps = {};
				if (typeof icon === "string") {
					avatarProps = { src: icon };
				} else {
					avatarProps = {
						children: icon,
					};
				}

				return (
					<IconButton
						key={key}
						onClick={() => {
							setCurrentBaseMap(key);
						}}>
						<Avatar
							variant="rounded"
							{...avatarProps}
							sx={{
								border: isSelected
									? "2px solid var(--mui-palette-primary-main)"
									: "none",
							}}
						/>
					</IconButton>
				);
			})}
		</Stack>
	);
};
