import Link from "@mui/material/Link";
import { useFormik } from "formik";
import {
	SignInFormValues,
	SignInSchema,
	toast,
	SignIn as View,
} from "ui-components";
import { tryCatch } from "utils";

import { useSignIn } from "~auth/queries/useSignIn";

const INVALID_CREDS_ERROR_MESSAGE =
	"Something's not right. Check your email and password and try again.";

export const SignIn = () => {
	const signIn = useSignIn();
	const form = useFormik<SignInFormValues>({
		validateOnMount: true,
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: SignInSchema,
		onSubmit: (values, helpers) => {
			toast.promise(
				async () => {
					const [error, response] = await tryCatch(signIn.mutateAsync(values));
					if (error) {
						helpers.setSubmitting(false);
						throw error;
					}

					const responseBody = await response.json();

					if (response.status === 401) {
						toast.error(INVALID_CREDS_ERROR_MESSAGE);
						helpers.setSubmitting(false);
						return;
					}

					if (response.status === 200) {
						window.location.href = responseBody.redirect_url ?? "/maps";
						toast.loading("Redirecting...");
						return;
					}

					if (responseBody.redirect_url) {
						window.location.href = responseBody.redirect_url;
					}

					toast.error(responseBody.message ?? "Something went wrong!");
					helpers.setSubmitting(false);
				},
				{
					loading: "Signing you in...",
					error: () => {
						helpers.setSubmitting(false);
						return (
							<div
								style={{ fontWeight: 500, fontSize: "13px", lineHeight: 1.5 }}>
								Sign-in is temporarily unavailable. If this persists, please
								visit our&nbsp;
								<Link
									target="_blank"
									href="https://uptime.com/s/mangomap"
									variant="subtitle2">
									service status page
								</Link>
							</div>
						);
					},
				},
			);
		},
	});

	return <View form={form} />;
};
