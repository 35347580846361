import { paths } from "config/paths";
import { useRouter } from "next/router";
import { FC, ReactNode } from "react";

import { useAuthContext } from "~auth/contexts/auth";
import { MangoLogoLoadingBackdrop } from "~ui-components/index";

export const LoginRequired: FC<{ children: ReactNode }> = ({ children }) => {
	const router = useRouter();

	const { isLoading, isAuthenticated } = useAuthContext();

	if (isLoading) return <MangoLogoLoadingBackdrop />;

	if (!isAuthenticated) {
		if (router.pathname.includes("[account-name]")) {
			void router.replace(
				paths.accountName.signIn(String(router.query["account-name"])),
			);
		} else {
			void router.replace(paths.signInV2);
		}
		return <MangoLogoLoadingBackdrop />;
	}

	return children;
};
