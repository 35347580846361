import { StackProps } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { Copy as CopyIcon } from "@phosphor-icons/react/dist/ssr/Copy";
import { List as LayersIcon } from "@phosphor-icons/react/dist/ssr/List";
import { Question as QuestionIcon } from "@phosphor-icons/react/dist/ssr/Question";
import { useFormik } from "formik";
import _ from "lodash";
import Link from "next/link";
import { useClipboard } from "utils";

import { Option } from "~ui-components/components/atoms/option";

interface LayerDetailsInput {
	layerTitle: string;
	layerDescription: string;
	currentZoom: number;
	minZoom: number;
	maxZoom: number;
	featurePermalink: string;
}

type Props = StackProps & {
	form: ReturnType<typeof useFormik<LayerDetailsInput>>;
};

export const LayerDetails = (props: Props) => {
	const [, onCopyToClipboard] = useClipboard();
	const { form } = props;
	const { values, handleChange, handleBlur, errors, handleSubmit } = form;

	return (
		<Stack spacing={4}>
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<LayersIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Layer details"
					action={
						<Link
							href={
								"https://help.mangomap.com/hc/en-us/articles/360022122592-The-Layers-panel"
							}
							target="_blank">
							<IconButton aria-label="settings">
								<QuestionIcon />
							</IconButton>
						</Link>
					}
				/>
				<CardContent>
					<Stack spacing={3}>
						<FormControl>
							<InputLabel>Layer title</InputLabel>
							<OutlinedInput
								name="layerTitle"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.layerTitle}
								error={!!errors?.layerTitle}
							/>
							<FormHelperText>Source:</FormHelperText>
						</FormControl>
						<FormControl>
							<InputLabel>Description</InputLabel>
							<OutlinedInput
								name="layerDescription"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.layerDescription}
								error={!!errors?.layerDescription}
								multiline
							/>
						</FormControl>
					</Stack>
					<InputLabel sx={{ my: 3 }}>Scale range visibility</InputLabel>
					<Stack
						direction="row"
						spacing={3}>
						<FormControl>
							<InputLabel>Current zoom</InputLabel>
							<OutlinedInput
								name="currentZoom"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.currentZoom}
								error={!!errors?.currentZoom}
							/>
						</FormControl>
						<FormControl fullWidth>
							<InputLabel>Minimum zoom</InputLabel>
							<Select value={values.minZoom}>
								{_.range(1, 23).map((zoom) => (
									<Option
										key={zoom}
										value={zoom}>
										{zoom}
									</Option>
								))}
							</Select>
						</FormControl>
						<FormControl fullWidth>
							<InputLabel>Maximum zoom</InputLabel>
							<Select value={values.maxZoom}>
								{_.range(1, 23).map((zoom) => (
									<Option
										key={zoom}
										value={zoom}>
										{zoom}
									</Option>
								))}
							</Select>
						</FormControl>
					</Stack>
				</CardContent>
				<CardActions sx={{ justifyContent: "flex-end" }}>
					<Button color="secondary">Cancel</Button>
					<Button
						variant="contained"
						onClick={() => handleSubmit()}>
						Save changes
					</Button>
				</CardActions>
			</Card>
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<LayersIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Feature permalink"
					action={
						<Link
							href={
								"https://help.mangomap.com/hc/en-us/articles/360022122592-The-Layers-panel"
							}
							target="_blank">
							<IconButton aria-label="settings">
								<QuestionIcon />
							</IconButton>
						</Link>
					}
				/>
				<CardContent>
					<Stack>
						<FormControl>
							<OutlinedInput
								name="featurePermalink"
								id="outlined-adornment-password"
								value={values.featurePermalink}
								onBlur={handleBlur}
								onChange={handleChange}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											onClick={() => onCopyToClipboard(values.featurePermalink)}
											edge="end">
											<CopyIcon />
										</IconButton>
									</InputAdornment>
								}
								label="Password"
							/>
							<FormHelperText>
								Link directly to feature in this layer. Replace field-name and
								field-value with your values
							</FormHelperText>
						</FormControl>
					</Stack>
				</CardContent>
			</Card>
		</Stack>
	);
};
