import Clarity from "@microsoft/clarity";
import {
	GoogleAnalytics,
	GoogleTagManager,
	sendGAEvent,
	sendGTMEvent,
} from "@next/third-parties/google";
import { useRouter } from "next/router";
import * as React from "react";
import Insight from "react-linkedin-insight";
import { create } from "zustand";

interface AnalyticsStore {
	isPixelInit: boolean;
	isInsightInit: boolean;
	isClarityInit: boolean;
	isGaInit: boolean;
	isGtmInit: boolean;
	setIsPixelInit: (bool: boolean) => void;
	setIsInsightInit: (bool: boolean) => void;
	setIsClarityInit: (bool: boolean) => void;
	setIsGaInit: (bool: boolean) => void;
	setIsGtmInit: (bool: boolean) => void;
}

const _useAnalyticsStore = create<AnalyticsStore>((set) => ({
	isPixelInit: false,
	isInsightInit: false,
	isClarityInit: false,
	isGaInit: false,
	isGtmInit: false,
	setIsPixelInit: (bool: boolean) => set({ isPixelInit: bool }),
	setIsInsightInit: (bool: boolean) => set({ isInsightInit: bool }),
	setIsClarityInit: (bool: boolean) => set({ isClarityInit: bool }),
	setIsGaInit: (bool: boolean) => set({ isGaInit: bool }),
	setIsGtmInit: (bool: boolean) => set({ isGtmInit: bool }),
}));

export const useAnalyticsStore = () => _useAnalyticsStore((state) => state);

export const AnalyticsProvider = ({
	pixelId,
	insightId,
	clarityId,
	gaId,
	gtmId,
	children,
}: {
	pixelId?: string;
	insightId?: string;
	clarityId?: string;
	gaId?: string;
	gtmId?: string;
	children: React.ReactNode;
}) => {
	const analyticsStore = useAnalyticsStore();

	React.useEffect(() => {
		if (pixelId)
			import("react-facebook-pixel")
				.then(({ default: Pixel }) => {
					Pixel.init(pixelId);
					analyticsStore.setIsPixelInit(true);
				})
				.catch(console.error);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pixelId]);

	React.useEffect(() => {
		if (insightId) {
			Insight.init(insightId);
			analyticsStore.setIsInsightInit(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [insightId]);

	React.useEffect(() => {
		if (clarityId) {
			Clarity.init(clarityId);
			analyticsStore.setIsClarityInit(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clarityId]);

	React.useEffect(() => {
		if (gaId) {
			analyticsStore.setIsGaInit(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gaId]);

	React.useEffect(() => {
		if (gtmId) {
			analyticsStore.setIsGtmInit(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gtmId]);

	return (
		<>
			{!!gaId && <GoogleAnalytics gaId={gaId} />}
			{!!gtmId && <GoogleTagManager gtmId={gtmId} />}
			{children}
		</>
	);
};

export const useSendCurrentLandingPageViewEvent = () => {
	const { pathname } = useRouter();

	useSendAnalyticsEvent("viewLanding", { page: pathname });
};

export const useSendAnalyticsEvent = (
	event: string,
	object?: Record<string, any>,
) => {
	React.useEffect(() => {
		sendAnalyticsEvent(event, object);
	}, [event, object]);
};

export const sendAnalyticsEvent = (
	event: string,
	object: Record<string, any> = {},
) => {
	sendGTMEvent({ event, ...object });
	sendGAEvent("event", event, object);
};
