import { Dataset } from "~ui-components/types/__generated/gql/graphql";

export interface DataViewProps {
	isLoading: boolean;
	items: Dataset[];
	onItemDelete?: (itemId: string) => void;
}

export { DataPortalLargeView } from "./large-view";
export { DataPortalMediumView } from "./medium-view";
export { DataPortalRowView } from "./row-view";
