import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import { FC } from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import {
	AccountBillingForm,
	AccountBillingFormValues,
} from "~ui-components/components/organisms/user-accounts/billing-form";
import { AccountFormConfirmationCard } from "~ui-components/components/organisms/user-accounts/form-confirmation-card";

export interface EditBillingProps {
	form: ReturnType<typeof useFormik<AccountBillingFormValues>>;
}

export const EditBilling: FC<EditBillingProps> = ({ form }) => {
	return (
		<DashboardContentLayout pageTitle="Edit Billing">
			<Stack
				spacing={4}
				component="form"
				onSubmit={form.handleSubmit}>
				<Grid
					container
					spacing={4}>
					<Grid
						md={8}
						xs={12}>
						<AccountBillingForm form={form} />
					</Grid>
					<Grid
						md={4}
						xs={12}>
						<Stack spacing={4}>
							<AccountFormConfirmationCard
								disableSubmit={!form.isValid || form.isSubmitting}
							/>
						</Stack>
					</Grid>
				</Grid>
			</Stack>
		</DashboardContentLayout>
	);
};
