import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from "@mui/material";
import { FC } from "react";

export interface DeleteConfirmationModalProps {
	onClose: () => void;
	onConfirm: () => void;
}

export const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = ({
	onClose,
	onConfirm,
}) => {
	return (
		<Dialog
			open
			onClose={onClose}>
			<DialogTitle>Permanently delete this dataset?</DialogTitle>
			<DialogContent>
				<Typography variant="body2">
					Once deleted, this dataset will no longer be accesible.
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={onClose}
					color="inherit">
					Cancel
				</Button>
				<Button
					variant="contained"
					color="error"
					onClick={onConfirm}>
					Permanently delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};
