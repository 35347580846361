import {
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	IconButton,
	List,
	ListItem,
	Stack,
	Typography,
} from "@mui/material";
import { ArrowSquareOut } from "@phosphor-icons/react/dist/ssr/ArrowSquareOut";
import { paths } from "config/paths";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { FC, Fragment } from "react";

import { getDatasetMaps } from "~ui-components/components/molecules/data-portal-views/items/item-row";
import {
	Dataset,
	GEOMETRY_TYPE,
} from "~ui-components/types/__generated/gql/graphql";

export interface DatasetInfoProps {
	datasetInfo: Dataset;
	onDeleteDataset: () => void;
}

export const DatasetInfo: FC<DatasetInfoProps> = ({
	datasetInfo,
	onDeleteDataset,
}) => {
	const router = useRouter();

	const maps = getDatasetMaps(datasetInfo);

	return (
		<Card>
			<CardContent>
				<Stack spacing={2}>
					{datasetInfo.description && (
						<>
							<Stack spacing={2}>
								<Typography
									variant="body2"
									gutterBottom>
									{datasetInfo.description}
								</Typography>
							</Stack>
							<Divider />
						</>
					)}
					<List
						sx={{
							"& .MuiListItem-root": {
								justifyContent: "space-between",
								px: 0,
							},
						}}>
						<ListItem>
							<Typography variant="subtitle2">Type</Typography>
							<Typography
								color="text.secondary"
								variant="body2">
								{datasetInfo.type}
							</Typography>
						</ListItem>
						{![GEOMETRY_TYPE.WMS, GEOMETRY_TYPE.RASTER].includes(
							datasetInfo.type,
						) && (
							<ListItem>
								<Typography variant="subtitle2">Data sync</Typography>
								<Typography
									color="text.secondary"
									variant="body2">
									{datasetInfo.isSyncActive ? "Active" : "Inactive"}
								</Typography>
							</ListItem>
						)}
						<ListItem>
							<Typography variant="subtitle2">Created</Typography>
							<Typography
								color="text.secondary"
								variant="body2">
								{dayjs(datasetInfo.createdAt).format("YYYY-MM-DD HH:mm:ss")}
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant="subtitle2">Updated</Typography>
							<Typography
								color="text.secondary"
								variant="body2">
								{dayjs(datasetInfo.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
							</Typography>
						</ListItem>
					</List>
					{!!maps.length && (
						<Fragment>
							<Divider />
							<Typography variant="subtitle2">Maps using this data</Typography>
							<Stack
								component="ul"
								sx={{ listStyle: "none", m: 0, p: 0 }}>
								{maps.map((map) => (
									<ListItem
										key={map.id}
										sx={{ px: 0 }}>
										<Typography
											variant="subtitle2"
											sx={{ flex: 1 }}>
											{map.name}
										</Typography>
										<IconButton
											href={paths.accountName.maps.mapId.mapName.root(
												router.query["account-name"],
												map.id,
												map.name,
											)}
											target="_blank">
											<ArrowSquareOut />
										</IconButton>
									</ListItem>
								))}
							</Stack>
						</Fragment>
					)}
				</Stack>
			</CardContent>
			<CardActions>
				<Button
					size="small"
					variant="outlined"
					color="secondary"
					onClick={onDeleteDataset}>
					Delete dataset
				</Button>
			</CardActions>
		</Card>
	);
};
