import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { Info as InfoIcon } from "@phosphor-icons/react/dist/ssr/Info";
import { X as XIcon } from "@phosphor-icons/react/dist/ssr/X";
import { map } from "lodash";
import * as React from "react";
import { useState } from "react";

import { FileDropzone } from "~ui-components/components/atoms/file-dropzone";
import { BASIC_MARKERS } from "../basic-makers";

import { InvoicesTable, MarkerTableProps } from "./image-table";

export const PREDEFINED_MARKERS = {
	...BASIC_MARKERS,
};

export type MarkerValues = keyof typeof PREDEFINED_MARKERS;

export interface SettingsDrawerProps {
	onClose?: () => void;
	open?: boolean;
	onChange: (value: MarkerValues) => void;
	value: MarkerValues;
}

export function MarkerSettingsDrawer({
	onClose,
	open,
	onChange,
	value,
}: SettingsDrawerProps): React.JSX.Element {
	const [tab, setTab] = useState<string>("basic");

	return (
		<Drawer
			ModalProps={{ BackdropProps: { invisible: false }, sx: { zIndex: 1400 } }}
			PaperProps={{
				elevation: 24,
				sx: {
					display: "flex",
					flexDirection: "column",
					maxWidth: "100%",
					width: "440px",
				},
			}}
			anchor="right"
			disableScrollLock
			onClose={onClose}
			open={open}>
			<Stack
				direction="row"
				spacing={3}
				sx={{
					alignItems: "center",
					justifyContent: "space-between",
					px: 3,
					pt: 2,
				}}>
				<Typography variant="h6">Select marker</Typography>
				<Stack
					direction="row"
					spacing={0.5}
					sx={{ alignItems: "center" }}>
					<IconButton onClick={onClose}>
						<XIcon />
					</IconButton>
				</Stack>
			</Stack>
			<Stack
				spacing={5}
				sx={{ overflowY: "auto", p: 3 }}>
				<Tabs
					onChange={(_, value: string) => {
						setTab(value);
					}}
					value={tab}>
					<Tab
						label="Marker"
						value="basic"
					/>
					<Tab
						label="Custom"
						value="custom"
					/>
				</Tabs>
				<Box
					sx={{
						display: "flex",
					}}>
					<ToggleButtonGroup
						color="primary"
						exclusive
						tabIndex={0}
						value={value}
						sx={{ border: "none", boxShadow: "none" }}
						onChange={(_, value: keyof typeof PREDEFINED_MARKERS) => {
							onChange(value);
						}}>
						{tab === "basic"
							? map(BASIC_MARKERS, ({ Icon, ...rest }, key) => (
									<ToggleButton value={key}>
										<Icon
											fontSize={24}
											{...rest}
										/>
									</ToggleButton>
								))
							: null}
					</ToggleButtonGroup>
					{tab === "custom" ? (
						<Stack spacing={5}>
							<FileDropzone
								caption={
									<Stack direction="row">
										<InfoIcon fontSize={24} />
										<Box sx={{ pl: "5px" }}>Max. 50x50px, .png format.</Box>
									</Stack>
								}
							/>
							<Card>
								<InvoicesTable rows={INVOICES} />
							</Card>
						</Stack>
					) : null}
				</Box>
			</Stack>
		</Drawer>
	);
}

const INVOICES: MarkerTableProps["rows"] = [
	{
		id: "1",
		image: "some image",
		size: "32x32",
		action: "delete",
	},
	{
		id: "2",
		image: "some image",
		size: "32x32",
		action: "delete",
	},
	{
		id: "3",
		image: "some image",
		size: "32x32",
		action: "delete",
	},
];
