import Card from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { MagnifyingGlass as MagnifyingGlassIcon } from "@phosphor-icons/react/dist/ssr/MagnifyingGlass";
import { debounce } from "lodash";
import * as React from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { Option } from "~ui-components/components/atoms/option";

export interface Filters {
	query?: string;
}

type SortDir = "asc" | "desc";

export interface FiltersProps {
	filters: Filters;
	setFilters: (query: string) => void;
	sortDir: SortDir;
	setSort: (sort: SortDir) => void;
}

export const AddLayerPanelFilters: React.FC<FiltersProps> = ({
	sortDir = "desc",
	setSort,
	filters,
	setFilters,
}) => {
	const inputRef = React.useRef<HTMLInputElement>(null);

	useHotkeys(
		["meta+f", "ctrl+f"],
		() => {
			inputRef.current?.focus();
		},
		{ preventDefault: true },
	);

	return (
		<Card>
			<Stack
				direction="row"
				spacing={4}
				sx={{ alignItems: "center", flexWrap: "wrap", p: 2 }}>
				<OutlinedInput
					inputRef={inputRef}
					name="name"
					placeholder="Search (⌘/Ctl-F)"
					startAdornment={
						<InputAdornment position="start">
							<MagnifyingGlassIcon fontSize="var(--icon-fontSize-md)" />
						</InputAdornment>
					}
					sx={{ flex: "1 1 auto" }}
					autoFocus={!!filters.query}
					defaultValue={filters.query}
					onChange={debounce((e) => setFilters(e.target.value), 250)}
				/>
				<Select
					name="sort"
					sx={{ maxWidth: "100%", width: "120px" }}
					value={sortDir}
					onChange={(e) => setSort(e.target.value as SortDir)}>
					<Option value="desc">Newest</Option>
					<Option value="asc">Oldest</Option>
				</Select>
			</Stack>
		</Card>
	);
};
