import * as React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

import { Option } from "~ui-components/components/atoms/option";

export function BodyTextColorSelect(): React.JSX.Element {
	return (
		<FormControl fullWidth>
			<InputLabel>Body text color</InputLabel>
			<Select>
				<Option value="light">Light</Option>
				<Option value="dark">Dark</Option>
			</Select>
		</FormControl>
	);
}
