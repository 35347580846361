import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { paths } from "config/paths";
import RouterLink from "next/link";
import { useRouter } from "next/router";
import { FC } from "react";

import image from "./popper-cone-firecracker.avif";

export const SubscriptionConfirmation: FC = () => {
	const router = useRouter();

	return (
		<Box
			component="main"
			sx={{
				alignItems: "center",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				minHeight: "100%",
				py: "64px",
			}}>
			<Container maxWidth="lg">
				<Stack spacing={6}>
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<Box
							alt="Subscription Confirmation"
							component="img"
							src={image.src}
							sx={{ height: "auto", maxWidth: "100%", width: "200px" }}
						/>
					</Box>
					<Stack
						spacing={1}
						sx={{ textAlign: "center" }}>
						<Typography variant="h4">Thank You for Subscribing</Typography>
						<Typography color="text.secondary">
							Your subscription has been successfully activated. You now have
							full access to all features and benefits included in your plan. If
							you have any questions or need assistance, our support team is
							here to help.
						</Typography>
					</Stack>
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<Button
							component={RouterLink}
							href={paths.accountName.settings.root(
								router.query["account-name"],
							)}
							variant="contained">
							Go to my account
						</Button>
					</Box>
				</Stack>
			</Container>
		</Box>
	);
};
