import Box from "@mui/material/Box";
import * as React from "react";

export function ItemIcon({ src }): React.JSX.Element {
	return (
		<Box
			sx={[
				{
					overflow: "hidden",
					borderRadius: 1.5,
					alignItems: "center",
					display: "inline-flex",
					flex: "0 0 auto",
					justifyContent: "center",
					width: "100%",
					height: "100%",
					backgroundImage: `url("${src}")`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
					minHeight: "242px",
				},
			]}
		/>
	);
}
