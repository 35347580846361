import Box from "@mui/material/Box";
import * as React from "react";

const icons: Record<string, string> = {
	jpeg: "/new-mangomap-assets/icon-jpg.svg",
	jpg: "/new-mangomap-assets/icon-jpg.svg",
	mp4: "/new-mangomap-assets/icon-mp4.svg",
	pdf: "/new-mangomap-assets/icon-pdf.svg",
	png: "/new-mangomap-assets/icon-png.svg",
	svg: "/new-mangomap-assets/icon-svg.svg",
};

export interface FileIconProps {
	extension?: string;
}

export function FileIcon({ extension }: FileIconProps): React.JSX.Element {
	let icon: string;

	if (!extension) {
		icon = "/new-mangomap-assets/icon-other.svg";
	} else {
		icon = icons[extension] ?? "/new-mangomap-assets/icon-other.svg";
	}

	return (
		<Box
			sx={{
				alignItems: "center",
				display: "inline-flex",
				flex: "0 0 auto",
				justifyContent: "center",
				width: "48px",
				height: "48px",
			}}>
			<Box
				alt="File"
				component="img"
				src={icon}
				sx={{ height: "100%", width: "auto" }}
			/>
		</Box>
	);
}
