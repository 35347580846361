import {
	Dialog,
	DialogContent,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
} from "@mui/material";
import {
	Copy,
	FacebookLogo,
	LinkedinLogo,
	PaperPlaneTilt,
	X,
	TwitterLogo,
	RedditLogo,
	DiscordLogo,
} from "@phosphor-icons/react/dist/ssr";

const shareOptions = [
	{ icon: <Copy />, label: "Copy Link" },
	{ icon: <PaperPlaneTilt />, label: "Email" },
	{ icon: <LinkedinLogo />, label: "Share on Linkedin" },
	{ icon: <FacebookLogo />, label: "Share on Facebook" },
	{ icon: <TwitterLogo />, label: "Share on X" },
	{ icon: <RedditLogo />, label: "Share on Reddit" },
	{ icon: <DiscordLogo />, label: "Share on Discord" },
];

export const ShareFeatureDialog = ({ title, open, onClose }) => {
	return (
		<Dialog
			maxWidth="xs"
			onClose={onClose}
			open={open}
			sx={{
				"& .MuiDialog-container": { justifyContent: "center" },
				"& .MuiDialog-paper": { width: "100%" },
			}}>
			<DialogContent>
				<Stack
					direction="row"
					spacing={2}
					sx={{
						alignItems: "center",
						flex: "0 0 auto",
						justifyContent: "space-between",
						p: 2,
					}}>
					<Typography variant="h6">Share {title}</Typography>
					<IconButton onClick={onClose}>
						<X />
					</IconButton>
				</Stack>
				<Stack padding={2}>
					<List disablePadding>
						{shareOptions.map(({ icon, label }) => (
							<ListItem
								disablePadding
								key={label}>
								<ListItemButton>
									<ListItemIcon>{icon}</ListItemIcon>
									<ListItemText
										color="text.primary"
										primary={label}
									/>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};
