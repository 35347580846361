import {
	Avatar,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	InputLabel,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { SmileySad } from "@phosphor-icons/react/dist/ssr/SmileySad";
import { useFormik } from "formik";
import { FC } from "react";
import * as Yup from "yup";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import { Option } from "~ui-components/components/atoms/option";

export const CancelSubscriptionSchema = Yup.object({
	cancellingReason: Yup.string().required("Required"),
	comments: Yup.string(),
	acceptedAccessRevocation: Yup.boolean().oneOf([true]),
	acceptedDataDeletion: Yup.boolean().oneOf([true]),
});

export type CancelSubscriptionFormValues = Yup.InferType<
	typeof CancelSubscriptionSchema
>;

export interface CancelSubscriptionProps {
	form: ReturnType<typeof useFormik<CancelSubscriptionFormValues>>;
}

export const CancelSubscription: FC<CancelSubscriptionProps> = ({ form }) => {
	return (
		<DashboardContentLayout pageTitle="Cancel subscription">
			<Card
				component="form"
				onSubmit={form.handleSubmit}>
				<CardHeader
					avatar={
						<Avatar>
							<SmileySad fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Cancellation details"
				/>
				<CardContent>
					<Stack spacing={3}>
						<FormControl>
							<InputLabel>Why are you cancelling?</InputLabel>
							<Select
								{...form.getFieldProps("cancellingReason")}
								error={!!form.errors.cancellingReason}>
								<Option value="">Select a reason</Option>
								{[
									"Too expensive",
									"Too complicated",
									"Not enough time",
									"Missing features",
									"Performance issues",
									"Change in key account stakeholder",
									"Changes to business (e.g., business no longer in operation)",
									"Poor support experience",
									"Poor sales experience",
									"Other",
								].map((reason) => (
									<Option
										key={reason}
										value={reason}>
										{reason}
									</Option>
								))}
							</Select>
							{!!form.errors.cancellingReason && (
								<FormHelperText error>
									{form.errors.cancellingReason}
								</FormHelperText>
							)}
						</FormControl>
						<FormControl fullWidth>
							<InputLabel>Comments (optional)</InputLabel>
							<TextField
								minRows={3}
								multiline
								{...form.getFieldProps("comments")}
								error={!!form.errors.comments}
								helperText={form.errors.comments}
							/>
						</FormControl>
						<Typography
							variant="body2"
							fontWeight={600}>
							By cancelling your Mangomap account, you acknowledge the
							following:
						</Typography>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										{...form.getFieldProps("acceptedAccessRevocation")}
									/>
								}
								label="I understand that by cancelling my subscription all access to my account will be revoked with immediate effect."
							/>
							<FormControlLabel
								control={
									<Checkbox {...form.getFieldProps("acceptedDataDeletion")} />
								}
								label="I understand all account data, including maps, data, and associated content will be permanently deleted."
							/>
						</FormGroup>
					</Stack>
				</CardContent>
				<CardActions sx={{ justifyContent: "flex-end" }}>
					<Button
						variant="contained"
						color="error"
						type="submit"
						disabled={form.isSubmitting || !form.isValid}>
						Approve cancellation
					</Button>
				</CardActions>
			</Card>
		</DashboardContentLayout>
	);
};
