import { paths, routes } from "config/paths";
import { useRouter } from "next/router";
import * as React from "react";

import {
	SideNav,
	SideNavProps,
} from "~ui-components/components/molecules/side-nav";

const GetNavItems = (layerId: string | string[]): SideNavProps["navItems"] => {
	const router = useRouter();
	const accountName = router.query["account-name"] as string;
	const mapId = router.query["map-id"] as string;
	const mapName = router.query["map-name"] as string;
	return [
		{
			items: [
				{
					title: "Layer details",
					href: paths.accountName.maps.mapId.mapName.layers.layerId.details(
						accountName,
						mapId,
						mapName,
						layerId,
					),
					matcher: {
						type: "equals",
						href: routes.accountName.maps.mapId.mapName.layers.layerId,
					},
				},
				{
					title: "Layer style",
					href: paths.accountName.maps.mapId.mapName.layers.layerId.style(
						accountName,
						mapId,
						mapName,
						layerId,
					),
					matcher: {
						type: "equals",
						href: routes.accountName.maps.mapId.mapName.layers.layerId,
					},
				},
				{
					title: "Label",
					href: paths.accountName.maps.mapId.mapName.layers.layerId.label(
						accountName,
						mapId,
						mapName,
						layerId,
					),
					matcher: {
						type: "equals",
						href: routes.accountName.maps.mapId.mapName.layers.layerId,
					},
				},
				{
					title: "Popup",
					href: paths.accountName.maps.mapId.mapName.layers.layerId.popup(
						accountName,
						mapId,
						mapName,
						layerId,
					),
					matcher: {
						type: "equals",
						href: routes.accountName.maps.mapId.mapName.layers.layerId,
					},
				},
				{
					title: "Attribute format",
					href: paths.accountName.maps.mapId.mapName.layers.layerId.attributeFormatting(
						accountName,
						mapId,
						mapName,
						layerId,
					),
					matcher: {
						type: "equals",
						href: routes.accountName.maps.mapId.mapName.layers.layerId,
					},
				},
			],
		},
	];
};
export function LayerSettingsSideNav(): React.JSX.Element {
	const router = useRouter();
	if (router.query["layer-id"])
		return <SideNav navItems={GetNavItems(router.query["layer-id"] || "")} />;
	return <div></div>;
}
