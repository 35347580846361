import { FormControl, FormHelperText, InputLabel, Select } from "@mui/material";

import { Option } from "~ui-components/components/atoms/option";

export const ReferenceLayerInput = ({ layers }) => {
	return (
		<FormControl>
			<InputLabel>Reference Layer</InputLabel>
			<Select
				name="layer"
				value={layers[0].id}>
				{layers.map(({ id, name }) => (
					<Option
						key={id}
						value={id}>
						{name}
					</Option>
				))}
			</Select>
			<FormHelperText>
				Select the layer with the reference features and choose how to select
				the area or features you want to use.
			</FormHelperText>
		</FormControl>
	);
};
