import * as React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

import { Option } from "~ui-components/components/atoms/option";

export function TitleTextSizeSelect(): React.JSX.Element {
	return (
		<FormControl fullWidth>
			<InputLabel>Title text size</InputLabel>
			<Select>
				<Option value="default_v2">Small</Option>
				<Option value="blue_v2">Normal</Option>
				<Option value="darkBlue_v2">Large</Option>
				<Option value="Slate_v2">Huge</Option>
			</Select>
		</FormControl>
	);
}
