import { FC } from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import {
	PortalLayout,
	PortalLayoutProps,
} from "~ui-components/components/molecules/portal-layout";

export const AddLayerPanel: FC<PortalLayoutProps> = (props) => {
	return (
		<DashboardContentLayout pageTitle="Add layer to map">
			<PortalLayout {...props} />
		</DashboardContentLayout>
	);
};
