import { Divider } from "@mui/material";
import * as React from "react";

import {
	AnalysisLayerInput,
	AttributeFilterInputCollapse,
	SelectionToggleInput,
} from "~ui-components/components/molecules/map-view-tools";

export const QueryToolForm = ({ layers }) => {
	return (
		<React.Fragment>
			<AnalysisLayerInput
				layers={layers}
				filter={<AttributeFilterInputCollapse />}
			/>
			<SelectionToggleInput />
			<Divider />
		</React.Fragment>
	);
};
