import {
	FormControl,
	FormControlLabel,
	Stack,
	Switch,
	TextField,
	Typography,
} from "@mui/material";

export const EditBookmark = ({ bookmark }) => {
	const { title, description } = bookmark;

	return (
		<Stack
			p={2}
			spacing={2}>
			<FormControl>
				<TextField
					label="Title"
					value={title}
				/>
			</FormControl>
			<FormControl>
				<TextField
					multiline
					minRows={3}
					label="Description"
					value={description}
				/>
			</FormControl>
			<FormControl>
				<FormControlLabel
					control={<Switch />}
					label="Shared bookmark"
				/>
				<Typography
					color="text.secondary"
					variant="caption">
					Shared bookmarks are available to all map users.
				</Typography>
			</FormControl>
		</Stack>
	);
};
