import { StackProps } from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import { useFormik } from "formik";

import { TextEditor } from "~ui-components/components/atoms/text-editor/text-editor";

import type { EditorEvents } from "@tiptap/react";

interface SaveTermModalInput {
	id: string;
	active: boolean;
	content: string;
	index: number;
	label: string;
	width: number;
	key: string;
}

export type TermModalProps = StackProps & {
	form: ReturnType<typeof useFormik<SaveTermModalInput>>;
};

export function TermModal(props: TermModalProps): React.JSX.Element {
	const { form } = props;

	return (
		<form onSubmit={form.handleSubmit}>
			<Alert severity="info">
				Display a modal popup with rich content and checkbox for user
				acknowlegement. This modal appears when users visit map for the first
				time, and can be accessed from the menu
			</Alert>

			<FormControl>
				<InputLabel>Modal title</InputLabel>
				<OutlinedInput
					name="label"
					onChange={form.handleChange}
					onBlur={form.handleBlur}
					value={form.values.label}
					error={!!form.errors?.label}
				/>
			</FormControl>

			<FormControl fullWidth>
				<InputLabel>Description</InputLabel>
				<Box sx={{ mt: "8px", "& .tiptap-container": { height: "400px" } }}>
					<TextEditor
						placeholder="Write something"
						content={form.values.content}
						onUpdate={({ editor }: EditorEvents["update"]) => {
							void form.setFieldValue("content", editor.getText());
						}}
						toolsProps={{
							Code: true,
							CodeBlock: true,
							Blockquote: true,
							LinkInput: {},
							ImageInput: {},
							IFrameInput: {},
							Table: true,
							TextAlign: true,
							HorizontalRule: true,
						}}
					/>
				</Box>
			</FormControl>

			<CardActions sx={{ justifyContent: "flex-end" }}>
				<Button color="secondary">Cancel</Button>
				<Button
					type="submit"
					variant="contained">
					Save changes
				</Button>
			</CardActions>
		</form>
	);
}
