import * as React from "react";
import { Trash as RemoveIcon } from "@phosphor-icons/react/dist/ssr/Trash";
import { IconButton } from "@mui/material";

import {
	ColumnDef,
	DataTable,
} from "~ui-components/components/atoms/data-table";

export interface Marker {
	id: string;
	image: string;
	size: string;
	action: string;
}

const columns = [
	{
		formatter: (row) => <div>{row.image}</div>,
		field: "image",
		name: "Image",
	},
	{
		formatter: (row) => {
			return <div>{row.size}</div>;
		},
		name: "",
		align: "right",
	},
	{
		formatter: () => (
			<IconButton>
				<RemoveIcon />
			</IconButton>
		),
		name: "",
		align: "right",
	},
] satisfies ColumnDef<Marker>[];

export interface MarkerTableProps {
	rows: Marker[];
}

export function InvoicesTable({ rows }: MarkerTableProps): React.JSX.Element {
	return (
		<DataTable<Marker>
			columns={columns}
			rows={rows}
		/>
	);
}
