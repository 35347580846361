import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { BracketsCurly as AttributeIcon } from "@phosphor-icons/react/dist/ssr/BracketsCurly";
import { Editor } from "@tiptap/react";
import { FC } from "react";

import { usePopover } from "~ui-components/hooks/use-popover";

export interface AttributeSelectProps {
	editor: Editor;
	attributes?: string[];
}

export const AttributeSelect: FC<AttributeSelectProps> = ({
	editor,
	attributes,
}) => {
	const popover = usePopover<HTMLButtonElement>();

	return (
		<>
			<IconButton
				sx={{ width: 100 }}
				ref={popover.anchorRef}
				onClick={popover.handleOpen}>
				<Box
					display="flex"
					alignItems="center">
					<AttributeIcon />
					<Typography
						variant="body2"
						ml={1}>
						Attribute
					</Typography>
				</Box>
			</IconButton>
			{!!attributes?.length && (
				<Menu
					disablePortal
					anchorEl={popover.anchorRef.current}
					open={popover.open}
					onClose={popover.handleClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left",
					}}>
					{attributes.map((attribute) => (
						<MenuItem
							key={attribute}
							onClick={() => {
								editor.chain().focus().insertContent(`{${attribute}}`).run();
								popover.handleClose();
							}}>
							{attribute}
						</MenuItem>
					))}
				</Menu>
			)}
		</>
	);
};
