import { CacheProvider, EmotionCache } from "@emotion/react";
import { GlobalStyles } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { FC, ReactNode } from "react";

import { Rtl } from "~ui-components/components/atoms/rtl";
import {
	SettingsConsumer,
	SettingsProvider,
} from "~ui-components/contexts/settings";
import { LocalizationProvider } from "~ui-components/providers/localization-provider";
import { createTheme } from "~ui-components/theme/create-theme";

LicenseInfo.setLicenseKey(
	"54b15a57982fcfd357f997b69b2e34f7T1JERVI6Mzk0NzQsRVhQSVJZPTE2Nzg2MTEzNDAwMDAsS0VZVkVSU0lPTj0x",
);

export interface UiProviderProps {
	emotionCache: EmotionCache;
	children: ReactNode;
}
export const UiProvider: FC<UiProviderProps> = ({ emotionCache, children }) => (
	<CacheProvider value={emotionCache}>
		<LocalizationProvider>
			<SettingsProvider>
				<SettingsConsumer>
					{(settings) => {
						const theme = createTheme({
							primaryColor: settings.primaryColor,
							colorScheme: settings.colorScheme,
							direction: settings.direction,
						});

						return (
							<CssVarsProvider
								defaultColorScheme={settings.colorScheme}
								defaultMode={settings.colorScheme}
								theme={theme}>
								<CssBaseline />
								<Rtl direction={settings.direction}>
									<NProgressOverwriteGlobalStyles />
									{children}
								</Rtl>
							</CssVarsProvider>
						);
					}}
				</SettingsConsumer>
			</SettingsProvider>
		</LocalizationProvider>
	</CacheProvider>
);

const NProgressOverwriteGlobalStyles = () => (
	<GlobalStyles
		styles={(t) => `
			#nprogress {
				pointer-events: none;
			}

			#nprogress .bar {
				background: ${t.palette.primary.main};

				position: fixed;
				z-index: 1101;
				top: 0;
				left: 0;

				width: 100%;
				height: 2px;
			}

			/* Fancy blur effect */
			#nprogress .peg {
				display: block;
				position: absolute;
				right: 0px;
				width: 100px;
				height: 100%;
				box-shadow: 0 0 10px ${t.palette.primary.main}, 0 0 5px ${t.palette.primary.main};
				opacity: 1;

				-webkit-transform: rotate(3deg) translate(0px, -4px);
				-ms-transform: rotate(3deg) translate(0px, -4px);
				transform: rotate(3deg) translate(0px, -4px);
			}

			/* Remove these to get rid of the spinner */
			#nprogress .spinner {
				display: block;
				position: fixed;
				z-index: 1101;
				top: 15px;
				right: 15px;
			}

			#nprogress .spinner-icon {
				width: 18px;
				height: 18px;
				box-sizing: border-box;

				border: solid 2px transparent;
				border-top-color: ${t.palette.primary.main};
				border-left-color: ${t.palette.primary.main};
				border-radius: 50%;

				-webkit-animation: nprogress-spinner 400ms linear infinite;
				animation: nprogress-spinner 400ms linear infinite;
			}

			.nprogress-custom-parent {
				overflow: hidden;
				position: relative;
			}

			.nprogress-custom-parent #nprogress .spinner,
			.nprogress-custom-parent #nprogress .bar {
				position: absolute;
			}

			@-webkit-keyframes nprogress-spinner {
				0% {
					-webkit-transform: rotate(0deg);
				}
				100% {
					-webkit-transform: rotate(360deg);
				}
			}
			@keyframes nprogress-spinner {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		`}
	/>
);
