import { Airplane as AirplaneIcon } from "@phosphor-icons/react/dist/ssr/Airplane";
import { AirplaneTakeoff as AirplaneTakeoffIcon } from "@phosphor-icons/react/dist/ssr/AirplaneTakeoff";
import { Circle as CircleIcon } from "@phosphor-icons/react/dist/ssr/Circle";
import { FirstAid as FirstAidIcon } from "@phosphor-icons/react/dist/ssr/FirstAid";
import { Square as SquareIcon } from "@phosphor-icons/react/dist/ssr/Square";
import { Star as StarIcon } from "@phosphor-icons/react/dist/ssr/Star";
import { Triangle as TriangleIcon } from "@phosphor-icons/react/dist/ssr/Triangle";

export const BASIC_MARKERS = {
	circle: {
		Icon: (props) => (
			<CircleIcon
				weight="fill"
				{...props}
			/>
		),
	},
	square: {
		Icon: (props) => (
			<SquareIcon
				weight="fill"
				{...props}
			/>
		),
	},
	triangle: {
		Icon: (props) => (
			<TriangleIcon
				weight="fill"
				{...props}
			/>
		),
	},
	star: {
		Icon: (props) => (
			<StarIcon
				weight="fill"
				{...props}
			/>
		),
	},
	firstAid: {
		Icon: (props) => (
			<FirstAidIcon
				weight="fill"
				{...props}
			/>
		),
	},
	x: {
		Icon: (props) => (
			<FirstAidIcon
				weight="fill"
				{...props}
			/>
		),
		style: {
			transform: "rotate(45deg)",
		},
	},
	airplane: {
		Icon: (props) => (
			<AirplaneIcon
				weight="fill"
				{...props}
			/>
		),
	},
	airplaneTakeoff: {
		Icon: (props) => (
			<AirplaneTakeoffIcon
				weight="fill"
				{...props}
			/>
		),
	},
};
