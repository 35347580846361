import { Link } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Warning as WarningIcon } from "@phosphor-icons/react/dist/ssr/Warning";
import { paths } from "config/paths";
import { railsServerUrls } from "config/rails-server-urls";
import NextLink from "next/link";
import { useRouter } from "next/router";
import * as React from "react";

export function CancelSubscription(): React.JSX.Element {
	const router = useRouter();

	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<WarningIcon fontSize="var(--Icon-fontSize)" />
					</Avatar>
				}
				title="Cancel subscription"
			/>
			<CardContent>
				<Stack
					spacing={3}
					sx={{ alignItems: "flex-start" }}>
					<Typography variant="subtitle1">
						We know circumstances can sometimes change. But before you go, have
						you tried our&nbsp;
						<Link
							component={NextLink}
							href={railsServerUrls.helpCentre}
							target="_blank">
							Help Centre
						</Link>
						&nbsp;for techincal FAQs, or reach out to your&nbsp;
						<Link
							component={NextLink}
							href={railsServerUrls.submitHelpRequest}
							target="_blank">
							Customer Success
						</Link>
						&nbsp;manager.
					</Typography>
					<Button
						color="error"
						variant="outlined"
						component={NextLink}
						href={paths.accountName.settings.cancelSubscription(
							router.query["account-name"],
						)}>
						Cancel subscription
					</Button>
				</Stack>
			</CardContent>
		</Card>
	);
}
