import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import type { PrimaryColor } from "~ui-components/theme/types";

import { Option } from "./option";

const THEME_OPTIONS = [
	{ label: "Mango", value: "mango", color: "#635BFF" },
	{ label: "Green", value: "chateauGreen", color: "#16b364" },
	{ label: "Blue", value: "royalBlue", color: "#5265ff" },
	{ label: "Orange", value: "tomatoOrange", color: "#ff6c47" },
	{ label: "Red", value: "tomatoOrange", color: "#ff6c47" },
] satisfies { label: string; value: PrimaryColor; color: string }[];

interface OptionsThemeColorProps {
	onChange?: (value: PrimaryColor) => void;
	value?: PrimaryColor;
}

export const OptionsThemeColor = ({
	onChange,
	value,
}: OptionsThemeColorProps) => {
	return (
		<Stack spacing={1}>
			<Stack
				direction="row"
				spacing={2}
				sx={{ alignItems: "center", flexWrap: "wrap" }}>
				{THEME_OPTIONS.map((option) => (
					<Option
						icon={
							<Box
								sx={{
									bgcolor: option.color,
									borderRadius: "50%",
									flex: "0 0 auto",
									height: "24px",
									width: "24px",
								}}
							/>
						}
						key={option.value}
						label={option.label}
						onClick={() => {
							onChange?.(option.value);
						}}
						selected={option.value === value}
					/>
				))}
			</Stack>
		</Stack>
	);
};
