import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { displayBytes, getDatasetThumbnailUrl } from "utils";

import { Dataset } from "~ui-components/types/__generated/gql/graphql";
import { getDatasetMaps } from "~ui-components/components/molecules/data-portal-views/items/item-row";

import { ItemIcon } from "./item-icon";

export interface ItemCardProps {
	item: Dataset;
	onClick: (dataset: Dataset) => void;
}

export function ItemCard({ item, onClick }: ItemCardProps): React.JSX.Element {
	return (
		<React.Fragment>
			<Card
				key={item.id}
				sx={{
					transition: "box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
					"&:hover": { boxShadow: "var(--mui-shadows-16)" },
				}}>
				<Stack
					spacing={1}
					padding={2}
					sx={{
						cursor: "pointer",
					}}
					onClick={() => onClick(item)}>
					<Box display="flex">
						<ItemIcon src={getDatasetThumbnailUrl(item.id, item.updatedAt)} />
					</Box>
					<div>
						<Typography variant="subtitle2">{item.name}</Typography>
						<Stack
							direction="row"
							spacing={1}
							sx={{ alignItems: "center", justifyContent: "space-between" }}>
							<Typography
								color="text.secondary"
								variant="caption">
								{displayBytes(item.size)} • Used in{" "}
								{getDatasetMaps(item).length} maps
							</Typography>
						</Stack>
					</div>
				</Stack>
			</Card>
		</React.Fragment>
	);
}
