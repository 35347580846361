import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { paths } from "config/paths";
import dayjs from "dayjs";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC } from "react";

interface TrialRemainingCardProps {
	remainingDays: number;
	total: number;
	title: string;
	content: string;
	isPaidTrial?: boolean;
}

export const TrialRemainingCard: FC<TrialRemainingCardProps> = (props) => {
	const router = useRouter();

	const {
		remainingDays,
		total = 30,
		isPaidTrial = false,
		title,
		content,
	} = props;
	return (
		<Card
			sx={{
				bgcolor: "primary.main",
				color: "var(--mui-palette-common-white)",
			}}>
			<CardContent>
				<Stack
					spacing={3}
					gap={10}
					justifyContent={"space-between"}
					sx={{ paddingY: 2 }}>
					<Stack
						direction={{ xs: "column", md: "row" }}
						spacing={3}
						justifyContent={"space-between"}
						alignItems={"center"}>
						<Typography variant="h6">{title}</Typography>
						{!isPaidTrial && (
							<Button
								LinkComponent={Link}
								variant="contained"
								color="secondary"
								href={paths.accountName.settings.checkout(
									router.query["account-name"],
								)}>
								Upgrade to paid
							</Button>
						)}
					</Stack>
					<Stack spacing={2}>
						<Typography variant="subtitle2">{content}</Typography>
						<Stack
							spacing={2}
							direction={"row"}
							alignItems={"center"}>
							<LinearProgress
								sx={{ flex: "1 1 auto", height: "6px" }}
								value={(remainingDays / total) * 100}
								variant="determinate"
								color="secondary"
							/>
							<Typography variant="subtitle2">
								Expires{" "}
								{new Intl.DateTimeFormat("en-US", {
									dateStyle: "medium",
								}).format(dayjs().add(remainingDays, "day").toDate())}
							</Typography>
						</Stack>
						<Typography variant="subtitle2">
							{remainingDays} days remaining
						</Typography>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	);
};
