import { Checkbox } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
	DataGridPro,
	GridAlignment,
	GridRenderCellParams,
	GridRowModel,
	GridRowOrderChangeParams,
} from "@mui/x-data-grid-pro";
import { useFormik } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";

import { PREDEFINED_MARKERS } from "~ui-components/components/molecules/layer-style/marker-setting-drawer";
import { SymbolValue } from "~ui-components/types/__generated/gql/graphql";

import { CustomiseMarkerSettingsDrawer } from "./customiseMarker";

function updateRowPosition(
	initialIndex: number,
	newIndex: number,
	rows: GridRowModel[],
): Promise<GridRowModel[]> {
	return new Promise((resolve) => {
		setTimeout(
			() => {
				const rowsClone = [...rows];
				const [row] = rowsClone.splice(initialIndex, 1);
				if (row) rowsClone.splice(newIndex, 0, row);
				resolve(rowsClone);
			},
			Math.random() * 500 + 100,
		); // simulate network latency
	});
}

interface Props {
	form: ReturnType<
		typeof useFormik<{ symbolValues: SymbolValue[]; type: string }>
	>;
	onRowsChange: (rows: GridRowModel[]) => void;
}

export const PointSortable = React.memo(({ form, onRowsChange }: Props) => {
	const {
		values: { symbolValues },
	} = form;
	const [symbolValue, setSymbolValue] = useState<SymbolValue | null>(null);
	const [rows, setRows] = useState<GridRowModel[]>([]);
	const [selectedRowId, setSlectedRowId] = useState<number | null>(null);
	const [loading, setLoading] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);

	useEffect(() => {
		onRowsChange(rows);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rows]);

	useEffect(() => {
		const mappedRows = symbolValues.map((symbolValue, index) => ({
			id: index,
			symbology: symbolValue,
			"category-label": symbolValue.label ?? "",
			"category-value": symbolValue.value,
			"legend-visibility": symbolValue.legendVisibility,
		}));
		setRows(mappedRows);
	}, [symbolValues]);

	const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
		setLoading(true);
		const newRows = await updateRowPosition(
			params.oldIndex,
			params.targetIndex,
			rows,
		);
		setRows(newRows);
		setLoading(false);
	};

	const SymbologyComponent = (params: GridRenderCellParams) => {
		const { id, value } = params;
		const { Icon, ...iconProps } =
			PREDEFINED_MARKERS[value.symbol.basic.symbol];
		return (
			<IconButton
				onClick={() => {
					setSymbolValue(value);
					setSlectedRowId(Number(id));
					setOpenDrawer(true);
				}}>
				<Icon
					fontSize={24}
					{...iconProps}
					color={value.symbol.fill.color}
				/>
			</IconButton>
		);
	};

	const CategoryLabelComponent = (params: GridRenderCellParams) => {
		const [labelValue, setLabelValue] = useState(params.value || "");
		return (
			<OutlinedInput
				value={labelValue}
				onChange={(event) => {
					setLabelValue(event.target.value);
				}}
				onBlur={() => {
					setRows((prevRows) =>
						prevRows.map((row) =>
							row.id === params.id
								? { ...row, "category-label": labelValue }
								: row,
						),
					);
				}}
				onKeyDown={(event) => {
					// Prevent the space key from causing the cell to lose focus
					if (
						event.key === " " ||
						event.key === "ArrowRight" ||
						event.key === "ArrowLeft"
					) {
						event.stopPropagation();
					}
				}}
				sx={{ width: "100%" }}
			/>
		);
	};

	const LegendVisibilityComponent = (params: GridRenderCellParams) => {
		const [visibility, setVisibility] = useState(params.value);
		return (
			<Checkbox
				sx={{ mr: 4 }}
				checked={visibility}
				onChange={(event) => {
					setVisibility(event.target.checked);
					setRows((prevRows) =>
						prevRows.map((row) =>
							row.id === params.id
								? { ...row, "legend-visibility": event.target.checked }
								: row,
						),
					);
				}}
			/>
		);
	};

	const columns = React.useMemo(
		() => [
			{
				field: "symbology",
				headerName: "Symbology",
				width: 200,
				renderCell: SymbologyComponent,
			},
			{ field: "category-value", headerName: "Category value", width: 350 },
			{
				field: "category-label",
				width: 350,
				headerName: "Category label",
				renderCell: CategoryLabelComponent,
			},
			{
				field: "legend-visibility",
				headerName: "Legend visibility",
				align: "right" as GridAlignment,
				headerAlign: "right" as GridAlignment,
				flex: 1,
				renderCell: LegendVisibilityComponent,
			},
		],
		[],
	);

	return (
		<div style={{ height: 400, width: "100%" }}>
			<DataGridPro
				loading={loading}
				columns={columns}
				rows={rows}
				rowReordering
				onRowOrderChange={handleRowOrderChange}
				hideFooter
			/>
			<CustomiseMarkerSettingsDrawer
				onClose={() => setOpenDrawer(false)}
				open={openDrawer}
				onChange={(value) => {
					setRows((prevRows) =>
						prevRows.map((row) =>
							row.id === selectedRowId ? { ...row, symbology: value } : row,
						),
					);
				}}
				value={symbolValue ?? {}}
			/>
		</div>
	);
});
