export const populateTemplateString = (
	template: string,
	values?: Record<string, any>,
) => {
	if (!values) return template;

	return template.replace(
		/{\s*([^}]+)\s*}/g,
		(match, key) => values[key] ?? match,
	);
};
