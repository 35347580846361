import Stack from "@mui/material/Stack";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

interface Slots {
	CustomClassbreak: any;
}

const Empty = () => null;

const defaultSlots: Slots = {
	CustomClassbreak: Empty,
};

interface Props {
	slots?: Slots;
}

function CustomLegend(props: Props) {
	return (
		<DashboardContentLayout disableBackButton={true}>
			<Root {...props} />
		</DashboardContentLayout>
	);
}

function Root(props: Props) {
	const { slots = {} } = props;

	const Slots = {
		...defaultSlots,
		...slots,
	};

	return (
		<Stack
			direction={{ xs: "column", md: "row" }}
			spacing={4}
			sx={{ position: "relative", mt: "44px" }}>
			<Slots.CustomClassbreak />
		</Stack>
	);
}

export { CustomLegend };
