import {
	Dialog,
	DialogContent,
	Stack,
	Typography,
	Button,
} from "@mui/material";

export const DeleteFeatureDialog = ({ open, onClose }) => {
	return (
		<Dialog
			maxWidth="sm"
			onClose={onClose}
			open={open}
			sx={{
				"& .MuiDialog-container": { justifyContent: "center" },
				"& .MuiDialog-paper": { width: "100%" },
			}}>
			<DialogContent>
				<Stack
					direction="row"
					spacing={2}
					sx={{
						alignItems: "center",
						flex: "0 0 auto",
						justifyContent: "space-between",
						p: 2,
					}}>
					<Typography variant="h6">Permanently delete this feature?</Typography>
				</Stack>
				<Stack padding={2}>
					<Typography variant="body2">
						This will permanently erase the selected feature and it cannot be
						undone. This affects all layers using this data.
					</Typography>
				</Stack>
				<Stack
					direction={"row-reverse"}
					spacing={2}>
					<Button
						onClick={onClose}
						variant="contained">
						Permanently delete
					</Button>
					<Button onClick={onClose}>Cancel</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};
