import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { ArrowRight as ArrowRightIcon } from "@phosphor-icons/react/dist/ssr/ArrowRight";
import { useFormik } from "formik";
import * as React from "react";

import { AttributeInput } from "~ui-components/components/atoms/attribute-input";
import { TextEditor } from "~ui-components/components/atoms/text-editor/text-editor";

import { CalculatorPopupInput } from "./index";

interface Props {
	form: ReturnType<typeof useFormik<CalculatorPopupInput>>;
	attributes: string[];
	onNext?: () => void;
	onBack?: () => void;
}

export function TitleAndDescription({
	form,
	onNext,
	attributes,
}: Props): React.JSX.Element {
	return (
		<Stack spacing={3}>
			<Stack
				spacing={2}
				direction="row">
				<Stack
					spacing={2}
					sx={{ width: "100%" }}>
					<FormControl>
						<InputLabel>Map title</InputLabel>
						<AttributeInput
							onChange={(value) => {
								form.handleChange({
									target: { name: "title", value },
								});
							}}
							attributes={attributes}
						/>
					</FormControl>
					<FormControl>
						<InputLabel>Map description</InputLabel>
						<Box
							sx={{
								mt: "8px",
								"& .tiptap-container": { height: "200px" },
							}}>
							<TextEditor
								placeholder="Write something"
								content={form.values.description}
								toolsProps={{
									Strike: false,
									BulletList: false,
									OrderedList: false,
									HorizontalRule: true,
									AttributeSelect: { attributes },
								}}
								onUpdate={(e) =>
									form.setFieldValue("description", e.editor.getHTML())
								}
							/>
						</Box>
					</FormControl>
					<InputLabel>Button text</InputLabel>
					<AttributeInput
						onChange={(value) => {
							form.handleChange({
								target: { name: "buttonText", value },
							});
						}}
						attributes={attributes}
					/>
				</Stack>
				<Stack sx={{ ml: 3, width: "100%" }}>
					<InputLabel>Input preview</InputLabel>
					<Card
						sx={{
							display: "flex",
							flexDirection: "column",
							mt: 1,
							borderRadius: 1,
							flexGrow: 1,
						}}>
						<Stack
							sx={{
								p: 1,
								alignItems: "center",
								backgroundColor: "var(--mui-palette-background-dark)",
							}}
							direction="row"
							spacing={2}>
							{form.values.title || "Title"}
						</Stack>
						<Divider />
						<CardContent sx={{ flexGrow: 1 }}>
							<Stack
								sx={{ height: "100%" }}
								direction="column"
								spacing={2}>
								<Box sx={{ flexGrow: 1 }}>{form.values.description}</Box>
								{form.values.buttonText && (
									<Button
										sx={{ width: "200px", alignSelf: "flex-end" }}
										variant="contained"
										color="secondary">
										{form.values.buttonText}
									</Button>
								)}
							</Stack>
						</CardContent>
					</Card>
				</Stack>
			</Stack>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				<Button
					endIcon={<ArrowRightIcon />}
					onClick={onNext}
					variant="contained">
					Continue
				</Button>
			</Box>
		</Stack>
	);
}
