import { Box, Card, IconButton, Stack, Tab, Tabs } from "@mui/material";
import { CornersIn } from "@phosphor-icons/react/dist/ssr/CornersIn";
import { CornersOut } from "@phosphor-icons/react/dist/ssr/CornersOut";
import { FC, ReactNode } from "react";

import {
	Dataset,
	GEOMETRY_TYPE,
} from "~ui-components/types/__generated/gql/graphql";

export type TAB_VALUE = "MAP" | "DATA_TABLE";

export const TABS: { label: string; value: TAB_VALUE }[] = [
	{ label: "Map", value: "MAP" },
	{ label: "Data Table", value: "DATA_TABLE" },
] as const;

export interface DatasetPreviewProps {
	datasetInfo: Dataset;
	currentTab: TAB_VALUE;
	onTabChange: (value: TAB_VALUE) => void;
	isExpandedView: boolean;
	onExpandViewChange: (value: boolean) => void;
	slots: {
		Map: ReactNode;
		DataTable: ReactNode;
	};
}

export const DatasetPreview: FC<DatasetPreviewProps> = ({
	currentTab,
	onTabChange,
	isExpandedView,
	onExpandViewChange,
	slots: Slots,
	datasetInfo,
}) => {
	const filteredTabs = [GEOMETRY_TYPE.RASTER, GEOMETRY_TYPE.WMS].includes(
		datasetInfo?.type,
	)
		? TABS.filter((v) => v.value !== "DATA_TABLE")
		: TABS;

	return (
		<Card sx={{ height: "100%" }}>
			<Stack height="100%">
				<Stack
					direction="row"
					spacing={3}
					alignItems="center"
					sx={{ px: 3 }}>
					<Tabs
						sx={{ flex: 1 }}
						variant="scrollable"
						value={currentTab}
						onChange={(_, value) => onTabChange(value)}>
						{filteredTabs.map((tab) => (
							<Tab
								key={tab.value}
								label={tab.label}
								sx={{ minHeight: "auto" }}
								tabIndex={0}
								value={tab.value}
							/>
						))}
					</Tabs>
					<div>
						<IconButton onClick={() => onExpandViewChange(!isExpandedView)}>
							{isExpandedView ? <CornersIn /> : <CornersOut />}
						</IconButton>
					</div>
				</Stack>

				{currentTab === "MAP" && (
					<Box sx={{ display: "flex", flex: "1 1 0", minHeight: "600px" }}>
						<Box
							sx={{
								display: "flex",
								flex: "1 1 auto",
								flexDirection: "column",
								overflow: "hidden",
							}}>
							{Slots.Map}
						</Box>
					</Box>
				)}
				{currentTab === "DATA_TABLE" && Slots.DataTable}
			</Stack>
		</Card>
	);
};
