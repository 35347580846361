import { Backdrop, Box, Stack, Typography, styled } from "@mui/material";
import { keyframes } from "@mui/system";
import { FC } from "react";

import { MangoIcon } from "../mango-icon";

const pulse = keyframes`
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
`;

const PulseBox = styled(Box)({
	animation: `${pulse} 1s`,
	animationFillMode: "both",
	animationIterationCount: "infinite",
});

export const MangoLogoLoadingBackdrop: FC<{
	text?: string;
}> = ({ text }) => {
	return (
		<Backdrop
			open
			invisible
			data-testid="MapstackLoadingBackdrop">
			<Stack
				direction="column"
				gap={3}
				justifyContent="center"
				alignItems="center">
				<PulseBox>
					<MangoIcon />
				</PulseBox>
				{!!text && (
					<Typography
						variant="overline"
						sx={{
							userSelect: "none",
							textTransform: "uppercase",
						}}>
						{text}
					</Typography>
				)}
			</Stack>
		</Backdrop>
	);
};
