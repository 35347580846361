import Chip from "@mui/material/Chip";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { paths } from "config/paths";
import Link from "next/link";
import { useRouter } from "next/router";

function UsersTabs({ value, usersCount, accessGroupsCount }) {
	const router = useRouter();

	return (
		<Tabs
			value={value}
			sx={{ px: 3 }}
			variant="scrollable">
			<Tab
				icon={
					<Chip
						label={usersCount}
						size="small"
						variant="soft"
					/>
				}
				iconPosition="end"
				label="Users"
				sx={{ minHeight: "auto" }}
				tabIndex={0}
				value="users"
				component={Link}
				href={paths.accountName.users.root(router.query["account-name"])}
			/>

			<Tab
				icon={
					<Chip
						label={accessGroupsCount}
						size="small"
						variant="soft"
					/>
				}
				iconPosition="end"
				label="Access Groups"
				sx={{ minHeight: "auto" }}
				tabIndex={0}
				value="access-groups"
				component={Link}
				href={paths.accountName.groups.root(router.query["account-name"])}
			/>
		</Tabs>
	);
}

export { UsersTabs };
