import { Stack } from "@mui/material";

import { MapToolDrawer } from "~ui-components/components/molecules/map-tool-drawer";

interface Slots {
	Sketches?: any;
	SketchEditor?: any;
	SketchCreate?: any;
	SketchView?: any;
	LineEditor?: any;
	PolygonEditor?: any;
	RectangleEditor?: any;
	CircleEditor?: any;
	MarkerEditor?: any;
	TextAnnotationEditor?: any;
}

interface SlotProps {
	active?: string;
}

const Empty = () => null;

const defaultSlots: Slots = {
	Sketches: Empty,
	SketchEditor: Empty,
	SketchCreate: Empty,
	SketchView: Empty,
	LineEditor: Empty,
	PolygonEditor: Empty,
	RectangleEditor: Empty,
	CircleEditor: Empty,
	MarkerEditor: Empty,
	TextAnnotationEditor: Empty,
};

export interface TextEditorProps {
	onClose: () => void;
	title?: string;
	slots?: Slots;
	slotProps?: SlotProps;
}

export const SketchToolDrawer = ({ title, slots, slotProps, onClose }) => {
	const Slots = {
		...defaultSlots,
		...slots,
	};
	return (
		<MapToolDrawer
			title={title || "Sketch Tool"}
			onClose={onClose}
			Content={
				<Stack sx={{ height: "100%" }}>
					<Slots.Sketches active={slotProps.active} />
					<Slots.SketchEditor active={slotProps.active} />
					<Slots.SketchCreate active={slotProps.active} />
					<Slots.SketchView active={slotProps.active} />
					<Slots.LineEditor active={slotProps.active} />
					<Slots.PolygonEditor active={slotProps.active} />
					<Slots.RectangleEditor active={slotProps.active} />
					<Slots.CircleEditor active={slotProps.active} />
					<Slots.MarkerEditor active={slotProps.active} />
					<Slots.TextAnnotationEditor active={slotProps.active} />
				</Stack>
			}
		/>
	);
};
