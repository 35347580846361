import { Alert, Avatar, Box, Button, Stack, Typography } from "@mui/material";
import {
	CursorClick,
	HandGrabbing,
	LineSegment,
	Polygon,
} from "@phosphor-icons/react/dist/ssr";
import { useState } from "react";

import { PropertiesForm } from "./properties-form";

const CreateGeometry = ({ onClick }) => {
	return (
		<Box
			flex={1}
			pt={4}>
			<Stack
				alignItems={"center"}
				spacing={2}>
				<Avatar
					variant="rounded"
					onClick={onClick}>
					<CursorClick fontSize="var(--Icon-fontSize)" />
				</Avatar>
				<Typography
					variant="body2"
					color={"text.secondary"}>
					Click on the map to place a new marker
				</Typography>
				<Avatar variant="rounded">
					<LineSegment fontSize="var(--Icon-fontSize)" />
				</Avatar>
				<Typography
					variant="body2"
					color={"text.secondary"}>
					Click to start drawing line
				</Typography>
				<Avatar variant="rounded">
					<Polygon fontSize="var(--Icon-fontSize)" />
				</Avatar>
				<Typography
					variant="body2"
					color={"text.secondary"}>
					Click to start drawing shape
				</Typography>
				<Avatar variant="rounded">
					<HandGrabbing fontSize="var(--Icon-fontSize)" />
				</Avatar>
				<Typography
					variant="body2"
					color={"text.secondary"}>
					Drag nodes to modify the shape
				</Typography>
			</Stack>
		</Box>
	);
};

export const CreateFeature = ({ properties }) => {
	const [step, setStep] = useState<number>(0);
	return (
		<>
			<Box
				flex={1}
				p={2}>
				<Stack spacing={2}>
					<Alert severity="info">
						New features are added to source data and will be reflected in all
						maps using this data.
					</Alert>
				</Stack>
				{step === 0 && <CreateGeometry onClick={() => setStep(1)} />}
				{step === 1 && <PropertiesForm properties={properties} />}
			</Box>
			<Stack
				direction={"row-reverse"}
				spacing={3}
				p={2}>
				{step === 1 && (
					<Button
						color="primary"
						variant="contained">
						Create feature
					</Button>
				)}
				<Button color="primary">Cancel</Button>
			</Stack>
		</>
	);
};
