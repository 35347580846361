import { useState, useEffect } from "react";
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
} from "react-beautiful-dnd";
import { DotsSixVertical as DragIcon } from "@phosphor-icons/react/dist/ssr/DotsSixVertical";
import { Textbox as FormValueIcon } from "@phosphor-icons/react/dist/ssr/Textbox";
import { Trash as DeleteIcon } from "@phosphor-icons/react/dist/ssr/Trash";
import { Stack } from "@mui/material";
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";

export interface UserInputProps {
	id: string;
	value: string;
	label: string;
}

interface Props {
	userInputs: UserInputProps[];
	onUserInputChange: (key: string, value: string) => void;
}

export const SortableUserInput = (props: Props) => {
	const { userInputs, onUserInputChange } = props;
	const [items, setItems] = useState<UserInputProps[]>([]);

	useEffect(() => {
		setItems(userInputs);
	}, [userInputs]);

	const onDragEnd = (result: DropResult) => {
		const { destination, source } = result;

		if (!destination) return;

		const reorderedItems = Array.from(items);
		const [removed] = reorderedItems.splice(source.index, 1);
		if (removed) reorderedItems.splice(destination.index, 0, removed);

		setItems(reorderedItems);
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable-column">
				{(provided) => (
					<div
						{...provided.droppableProps}
						ref={provided.innerRef}>
						{items.map((item, index) => (
							<Draggable
								key={item.id}
								draggableId={item.id}
								index={index}>
								{(provided) => (
									<Stack
										spacing={2}
										direction="row"
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										sx={{ alignItems: "center" }}
										style={{
											userSelect: "none",
											padding: "16px",
											margin: "0 0 8px 0",
											backgroundColor: "var(--mui-palette-grey-100)",
											borderRadius: "8px",
											...provided.draggableProps.style,
										}}>
										<DragIcon fontSize={24} />
										<FormValue />
										<OutlinedInput
											name="layerTitle"
											value={item.label}
											onChange={(e) =>
												onUserInputChange(item.id, e.target.value)
											}
											// onBlur={handleBlur}
											// value={values.layerTitle}
											// error={!!errors?.layerTitle}
										/>
										<DeleteIcon fontSize={24} />
									</Stack>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};

const FormValue = () => {
	return (
		<Chip
			icon={<FormValueIcon />}
			label="Chip Filled"
			sx={{
				paddingLeft: "4px",
				backgroundColor: "var(--mui-palette-grey-300)",
				color: "var(--mui-palette-grey-800)",
			}}
		/>
	);
};
