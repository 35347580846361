import { FC } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

interface MapPrintPreviewProps {
	slots: {
		MapPrintDrawer: FC;
		MapPrintContent: FC;
	};
}

export const MapPrintPreview: FC<MapPrintPreviewProps> = ({ slots }) => {
	return (
		<Stack
			direction={{ xs: "column", md: "row" }}
			spacing={4}
			sx={{
				maxHeight: "100vh",
			}}>
			<Box>
				<slots.MapPrintDrawer />
			</Box>
			<Box
				width={"100%"}
				padding={6}
				justifyContent={"center"}>
				<slots.MapPrintContent />
			</Box>
		</Stack>
	);
};
