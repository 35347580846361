import { FC } from "react";

import {
	ToolsProximityAnalysisDetailsForm,
	ToolsProximityAnalysisDetailsFormProps,
} from "~ui-components/components/organisms/tools-proximity-analysis-panels/details-forms";
import {
	ToolsProximityAnalysisLayerConfigsForm,
	ToolsProximityAnalysisLayerConfigsFormProps,
} from "~ui-components/components/organisms/tools-proximity-analysis-panels/layer-configs-form";

export interface ToolsProximityAnalysisProps {
	showLayerConfigsForm: boolean;
	detailsFormProps: ToolsProximityAnalysisDetailsFormProps;
	layerConfigsFormProps: ToolsProximityAnalysisLayerConfigsFormProps;
}
export const ToolsProximityAnalysis: FC<ToolsProximityAnalysisProps> = ({
	showLayerConfigsForm,
	detailsFormProps,
	layerConfigsFormProps,
}) => {
	return (
		<>
			{showLayerConfigsForm ? (
				<ToolsProximityAnalysisLayerConfigsForm {...layerConfigsFormProps} />
			) : (
				<ToolsProximityAnalysisDetailsForm {...detailsFormProps} />
			)}
		</>
	);
};
