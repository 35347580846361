import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import {
	AnalysisLayerInput,
	AttributeFilterInputCollapse,
	ReferenceLayerInput,
	SelectionToggleInput,
	SpatialCriteriaInput,
} from "~ui-components/components/molecules/map-view-tools";

export const ProximityToolForm = ({ layers }) => {
	return (
		<Stack
			p={2}
			divider={<Divider />}
			spacing={2}>
			<AnalysisLayerInput
				layers={layers}
				filter={<AttributeFilterInputCollapse />}
			/>
			<SpatialCriteriaInput />
			<ReferenceLayerInput layers={layers} />
			<SelectionToggleInput />
		</Stack>
	);
};
