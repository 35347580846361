import { paths } from "config/paths";
import { useRouter } from "next/router";
import { FC, ReactNode } from "react";

import { useAuthContext } from "~auth/contexts/auth";
import { useGetCurrentAccountCache } from "~auth/queries/useGetCurrentAccount";
import { MangoLogoLoadingBackdrop } from "~ui-components/index";

export const RedirectToPortal: FC<{ children: ReactNode }> = ({ children }) => {
	const router = useRouter();

	const { data } = useGetCurrentAccountCache();
	const { isLoading, isAuthenticated } = useAuthContext();

	if (isLoading) return <MangoLogoLoadingBackdrop />;

	if (!isAuthenticated) {
		return children;
	}

	if (data) {
		void router.push(paths.accountName.maps.root(data.slug));
	}

	return <MangoLogoLoadingBackdrop />;
};
