import axios from "axios";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import {
	toast,
	UpdatePasswordFormValues,
	UpdatePasswordSchema,
	UpdatePassword as View,
} from "ui-components";
import { tryCatch } from "utils";

import { useUpdatePassword } from "~auth/queries/useUpdatePassword";

export const UpdatePassword = () => {
	const router = useRouter();

	const updatePassword = useUpdatePassword();
	const form = useFormik<UpdatePasswordFormValues>({
		validateOnMount: true,
		initialValues: {
			password: "",
			retypedPassword: "",
		},
		validationSchema: UpdatePasswordSchema,
		validate(values) {
			return values.password === values.retypedPassword
				? {}
				: { retypedPassword: "Passwords do not match" };
		},
		onSubmit: (values, helpers) => {
			toast.promise(
				async () => {
					const [error] = await tryCatch(updatePassword.mutateAsync(values));
					if (error) {
						helpers.setSubmitting(false);
						throw error;
					}
					void router.replace("/maps");
				},
				{
					loading: "Updating password...",
					success: "Signing you in to your account...",
					error: (e) =>
						axios.isAxiosError(e) ? "Something went wrong!" : e.message,
				},
			);
		},
	});

	return <View form={form} />;
};
