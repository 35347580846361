import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import { DotsSixVertical as DotsSixVerticalIcon } from "@phosphor-icons/react/dist/ssr/DotsSixVertical";
import { TrashSimple as TrashSimpleIcon } from "@phosphor-icons/react/dist/ssr/TrashSimple";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { FormikProps } from "formik";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { getNanoId } from "utils";

import { ButtonCancel } from "~ui-components/components/atoms/button-cancel";
import { Option } from "~ui-components/components/atoms/option";

type AttributeOption =
	| "equals_to"
	| "is_not_equal_to"
	| "contains"
	| "does_not_contain"
	| "is_greater_than"
	| "is_less_than";

interface Attribute {
	id: string;
	attribute: {
		id: string;
		label: string;
		option: AttributeOption;
	};
}

interface Layer {
	id: string;
	name: string;
	properties: string[];
}

const attributeOptions = {
	equals_to: "equals to",
	is_not_equal_to: "is not equal to",
	contains: "contains",
	does_not_contain: "does not contain",
	is_greater_than: "is greater than",
	is_less_than: "is less than",
};

export const ToolsAdvancedSearchSchema = yup.object({
	name: yup.string(),
	description: yup.string(),
	layer: yup.string(),
	match: yup
		.string()
		.oneOf(["any_of_following_conditions", "all_of_following_conditions"]),
	attributes: yup.array().of(
		yup.object({
			id: yup.string().required(),
			attribute: yup.object({
				id: yup.string().required(),
				label: yup.string().required(),
				option: yup
					.string()
					.oneOf([
						"equals_to",
						"is_not_equal_to",
						"contains",
						"does_not_contain",
						"is_greater_than",
						"is_less_than",
					])
					.required(),
			}),
		}),
	),
	userOptions: yup.object({
		csv: yup.boolean(),
		includeCoordinates: yup.boolean(),
		shapefile: yup.boolean(),
		kml: yup.boolean(),
	}),
});

export type ToolsAdvancedSearchFormValues = yup.InferType<
	typeof ToolsAdvancedSearchSchema
>;

export interface ToolsAdvancedSearchFormProps {
	form: FormikProps<ToolsAdvancedSearchFormValues>;
	layers: Layer[];
}

export function ToolsAdvancedSearchForm(props: ToolsAdvancedSearchFormProps) {
	const { form, layers } = props;

	useEffect(() => {
		if (form.values.userOptions.csv) {
			form.setFieldValue("userOptions.includeCoordinates", false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.values.userOptions]);

	const theme = useTheme();

	return (
		<form onSubmit={form.handleSubmit}>
			<CardContent>
				<Stack spacing={4}>
					<Stack spacing={3}>
						<Grid
							container
							spacing={3}>
							<Grid
								xs={12}
								md={4}>
								<FormControl
									fullWidth
									error={!!form.errors.name}>
									<InputLabel>Tool name</InputLabel>
									<OutlinedInput
										{...form.getFieldProps("name")}
										placeholder="Custom Search"
									/>
								</FormControl>
							</Grid>
						</Grid>
						<Grid
							container
							spacing={3}>
							<Grid
								xs={12}
								md={4}>
								<FormControl fullWidth>
									<InputLabel>Description</InputLabel>
									<OutlinedInput
										minRows={3}
										multiline
										placeholder="Find things you are looking for with this tool."
										{...form.getFieldProps("description")}
									/>
								</FormControl>
							</Grid>
						</Grid>
						<Grid
							container
							spacing={3}>
							<Grid
								xs={12}
								md={4}>
								<FormControl
									fullWidth
									error={!!form.errors.layer}>
									<InputLabel>Find features in</InputLabel>
									<Select
										defaultValue=""
										{...form.getFieldProps("layer")}>
										{layers.map((layer) => (
											<Option
												value={layer.id}
												key={layer.id}>
												{layer.name}
											</Option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Grid
							container
							spacing={3}>
							<Grid
								xs={12}
								md={4}>
								<FormControl
									fullWidth
									error={!!form.errors.match}>
									<InputLabel>that match</InputLabel>
									<Select
										defaultValue=""
										{...form.getFieldProps("match")}>
										<Option value="any_of_following_conditions">
											ANY of the following conditions
										</Option>
										<Option value="all_of_following_conditions">
											ALL of the following conditions
										</Option>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Grid
							container
							spacing={3}
							direction={{ xs: "column", md: "row" }}>
							<Grid flex={1}>
								<AttributesList
									form={form}
									layers={layers}
								/>
							</Grid>
							{theme.breakpoints.up("md") && (
								<Divider
									orientation="vertical"
									flexItem
								/>
							)}
							{theme.breakpoints.down("md") && (
								<Divider
									orientation="horizontal"
									flexItem
								/>
							)}
							<Grid flex={1}>
								<AttributesPreview form={form} />
							</Grid>
						</Grid>
						<Grid>
							<Grid
								xs={12}
								md={4}>
								<Stack spacing={3}>
									<Typography variant="h6">User options</Typography>
									<Stack
										component={FormControl}
										fullWidth
										spacing={3}>
										<FormControlLabel
											control={
												<Checkbox
													size="medium"
													{...form.getFieldProps("userOptions.csv")}
													checked={form.values.userOptions.csv}
												/>
											}
											label="csv"
										/>
										<FormControlLabel
											control={
												<Checkbox
													size="medium"
													disabled={form.values.userOptions.csv}
													{...form.getFieldProps(
														"userOptions.includeCoordinates",
													)}
													checked={form.values.userOptions.includeCoordinates}
												/>
											}
											label="Include coordinates"
										/>
										<FormControlLabel
											control={
												<Checkbox
													size="medium"
													{...form.getFieldProps("userOptions.shapefile")}
													checked={form.values.userOptions.shapefile}
												/>
											}
											label="Shapefile"
										/>
										<FormControlLabel
											control={
												<Checkbox
													size="medium"
													{...form.getFieldProps("userOptions.kml")}
													checked={form.values.userOptions.kml}
												/>
											}
											label="KML"
										/>
									</Stack>
								</Stack>
							</Grid>
						</Grid>
					</Stack>
				</Stack>
			</CardContent>
			<CardActions sx={{ justifyContent: "flex-end" }}>
				<ButtonCancel />
				<Button
					type="submit"
					variant="contained"
					disabled={!form.isValid}>
					Save changes
				</Button>
			</CardActions>
		</form>
	);
}

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	if (removed) result.splice(endIndex, 0, removed);
	return result;
}

function AttributesList(props) {
	const { form, layers = [] } = props;

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const attributes = form.values.attributes;
	const properties =
		layers.find((layer) => layer.id === form.values.layer)?.properties || [];

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		if (properties.length <= 0) return;
		if (form.errors.layer) return;
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleItemClick = (attribute: string) => {
		const { value } = form.getFieldProps("attributes");

		handleClose();

		form.setFieldValue("attributes", [
			...value,
			{
				id: getNanoId(),
				attribute: {
					id: attribute,
					label: attribute,
					option: "equals_to",
				},
			} as Attribute,
		]);
	};

	const handleDeleteClick = (index: number) => {
		const { value } = form.getFieldProps("attributes");

		form.setFieldValue(
			"attributes",
			value.filter((_, i) => i !== index),
		);
	};

	const handleDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = reorder(
			attributes,
			result.source.index,
			result.destination.index,
		);

		form.setFieldValue("attributes", items);
	};

	const open = Boolean(anchorEl);

	return (
		<Stack
			direction="column"
			spacing={2}
			sx={{
				bgcolor: "var(--mui-palette-background-level1)",
				borderRadius: "20px",
				p: 2,
			}}>
			<Button
				color="secondary"
				startIcon={<PlusIcon />}
				sx={{ alignSelf: "center", justifySelf: "center", px: 2 }}
				onClick={handleClick}>
				Attribute
			</Button>

			<Menu
				closeAfterTransition
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}>
				{properties.map((attribute) => (
					<MenuItem
						key={attribute}
						onClick={() => handleItemClick(attribute)}>
						{attribute}
					</MenuItem>
				))}
			</Menu>

			<DragDropContext onDragEnd={handleDragEnd}>
				{attributes.length > 0 && (
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<Stack
								{...provided.droppableProps}
								ref={provided.innerRef}
								component={Paper}
								spacing={2}
								variant="outlined"
								sx={{
									p: 3,
									borderRadius: 3,
								}}>
								{attributes.map((item, index) => (
									<Draggable
										key={item?.id}
										draggableId={item.id}
										index={index}>
										{(provided) => (
											<Stack
												{...provided.draggableProps}
												ref={provided.innerRef}
												component={Paper}
												direction="row"
												alignItems="center"
												spacing={1}
												sx={{
													p: 1,
													borderRadius: 2,
													backgroundColor: (theme) => theme.palette.neutral[50],
													border: (theme) =>
														`solid 1px ${theme.palette.divider}`,
													"&:hover": {
														backgroundColor: (theme) =>
															theme.palette.neutral[100],
													},
												}}
												{...(snapshot.isDragging
													? { elevation: 10 }
													: { elevation: 0 })}>
												<IconButton
													{...provided.dragHandleProps}
													size="small"
													sx={{ alignSelf: "flex-start" }}>
													<DotsSixVerticalIcon />
												</IconButton>
												<Stack
													flex={1}
													flexWrap="wrap"
													spacing={1}>
													<Box flex={1}>
														<Chip
															color="default"
															label={item.attribute.id}
														/>
													</Box>
													<Stack
														flex={1}
														direction="row"
														alignItems="center"
														spacing={1}>
														<OutlinedInput
															sx={{
																flex: 1,
																bgcolor: (theme) =>
																	theme.palette.background.paper,
																boxShadow: "unset",
															}}
															{...form.getFieldProps(
																`attributes[${index}].attribute.label`,
															)}
														/>
														<Select
															variant="outlined"
															sx={{
																bgcolor: (theme) =>
																	theme.palette.background.paper,
																boxShadow: "unset",
															}}
															defaultValue="equal_to"
															{...form.getFieldProps(
																`attributes[${index}].attribute.option`,
															)}>
															<Option value="equals_to">
																{attributeOptions.equals_to}
															</Option>
															<Option value="is_not_equal_to">
																{attributeOptions.is_not_equal_to}
															</Option>
															<Option value="contains">
																{attributeOptions.contains}
															</Option>
															<Option value="does_not_contain">
																{attributeOptions.does_not_contain}
															</Option>
															<Option value="is_greater_than">
																{attributeOptions.is_greater_than}
															</Option>
															<Option value="is_less_than">
																{attributeOptions.is_less_than}
															</Option>
														</Select>
														<IconButton
															size="small"
															onClick={() => handleDeleteClick(index)}>
															<TrashSimpleIcon fontSize="var(--Icon-fontSize)" />
														</IconButton>
													</Stack>
												</Stack>
											</Stack>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</Stack>
						)}
					</Droppable>
				)}
			</DragDropContext>
		</Stack>
	);
}

function AttributesPreview(props) {
	const { form } = props;
	const attributes = form.values.attributes;

	return (
		<FormControl fullWidth>
			<InputLabel>Tool preview</InputLabel>
			<Stack
				mt={1}
				component={Card}
				variant="outlined"
				sx={{ borderRadius: 1 }}>
				<CardHeader
					sx={{
						m: 0,
						p: 1,
						bgcolor: "secondary.dark",
						color: "secondary.contrastText",
					}}
					title={
						<Typography variant="subtitle1">
							{form.values.name || "Custom Search"}
						</Typography>
					}
				/>
				{attributes.length > 0 && (
					<>
						<CardContent sx={{ m: 0, p: 1 }}>
							<InputLabel>
								{form.values.description ||
									"Find things you are looking for with this tool."}
							</InputLabel>
						</CardContent>
						<Divider />
						<CardContent sx={{ m: 0, p: 1 }}>
							<Stack
								divider={
									<Typography variant="overline">
										{form.values.match === "all_of_following_conditions"
											? "and"
											: form.values.match === "any_of_following_conditions"
												? "or"
												: ""}
									</Typography>
								}
								spacing={1}>
								{attributes.map((item) => (
									<FormControl
										key={item.id}
										fullWidth>
										<InputLabel>
											{item.attribute.label}{" "}
											{attributeOptions[item.attribute.option]}
										</InputLabel>
										<OutlinedInput />
									</FormControl>
								))}
							</Stack>
						</CardContent>
					</>
				)}
				<CardActions sx={{ m: 0, p: 1, justifyContent: "flex-end" }}>
					{attributes.length > 0 && (
						<Button
							type="submit"
							variant="contained"
							disabled>
							Search
						</Button>
					)}
				</CardActions>
			</Stack>
		</FormControl>
	);
}
