import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";

import { ButtonCancel } from "~ui-components/components/atoms/button-cancel";
import { TextEditor } from "~ui-components/components/atoms/text-editor/text-editor";

export const ToolsInformationSidebarSchema = yup.object({
	size: yup
		.string()
		.notOneOf([""], "Required")
		.oneOf(["small", "large"])
		.required("Required"),
	content: yup.string().required("Required"),
});

export type ToolsInformationSidebarFormValues = yup.InferType<
	typeof ToolsInformationSidebarSchema
>;

export interface ToolsInformationSidebarFormProps {
	form: FormikProps<ToolsInformationSidebarFormValues>;
}

export function ToolsInformationSidebarForm(
	props: ToolsInformationSidebarFormProps,
): React.JSX.Element {
	const { form } = props;
	return (
		<form onSubmit={form.handleSubmit}>
			<CardContent>
				<Stack spacing={4}>
					<Stack spacing={3}>
						<Typography variant="subtitle2">Size</Typography>
						<Grid
							container
							spacing={3}>
							<Grid xs={12}>
								<FormControl fullWidth>
									<Stack
										component={RadioGroup}
										spacing={3}
										defaultValue="small"
										{...form.getFieldProps("radioOption")}>
										<FormControlLabel
											control={<Radio />}
											label="Small"
											value="small"
										/>
										<FormControlLabel
											control={<Radio />}
											label="Large"
											value="large"
										/>
									</Stack>
								</FormControl>
							</Grid>
						</Grid>
						<FormControl>
							<InputLabel>Content</InputLabel>
							<Box
								sx={{
									mt: "8px",
									"& .tiptap-container": { height: "200px" },
								}}>
								<TextEditor
									placeholder="Write something"
									content={form.values.content}
									toolsProps={{
										Code: true,
									}}
								/>
							</Box>
						</FormControl>
					</Stack>
				</Stack>
			</CardContent>
			<CardActions sx={{ justifyContent: "flex-end" }}>
				<ButtonCancel />
				<Button
					type="submit"
					variant="contained"
					disabled={!form.isValid}>
					Save changes
				</Button>
			</CardActions>
		</form>
	);
}
