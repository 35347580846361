import * as React from "react";

import type { Selection } from "~ui-components/hooks/use-selection";
import { useSelection } from "~ui-components/hooks/use-selection";

import type { AttributeFormatting } from "./formatting-table";

function noop(): void {
	return undefined;
}

export const FormattingSelectionContext = React.createContext<Selection>({
	deselectAll: noop,
	deselectOne: noop,
	selectAll: noop,
	selectOne: noop,
	selected: new Set(),
	selectedAny: false,
	selectedAll: false,
});

interface FormattingSelectionProviderProps {
	children: React.ReactNode;
	rows: AttributeFormatting[];
}

export function FormattingSelectionProvider({
	children,
	rows = [],
}: FormattingSelectionProviderProps): React.JSX.Element {
	const customerIds = React.useMemo(
		() => rows.map((customer) => customer.id),
		[rows],
	);
	const selection = useSelection(customerIds);

	return (
		<FormattingSelectionContext.Provider value={{ ...selection }}>
			{children}
		</FormattingSelectionContext.Provider>
	);
}

export function useCustomersSelection(): Selection {
	return React.useContext(FormattingSelectionContext);
}
