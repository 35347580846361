import { createErrorClass } from "~utils/functions/createErrorClass";

const CustomError = createErrorClass("Unsupported Type Error");

function UnsupportedTypeError({ message }) {
	return new CustomError({ message });
}

UnsupportedTypeError.UnknownType = () =>
	new CustomError({
		message: "Upload feature properties contain unexpected type.",
	});

export { UnsupportedTypeError };
