import {
	Box,
	IconButton,
	IconButtonProps,
	InputAdornment,
	ListItemIcon,
	Menu,
	MenuItem,
	Paper,
	Stack,
	SvgIcon,
	TextField,
	Tooltip,
	TooltipProps,
	Typography,
} from "@mui/material";
import {
	ArrowClockwise,
	Bookmarks,
	CircleDashed,
	Copy,
	Crosshair,
	CrosshairSimple,
	DotsNine,
	Info,
	List,
	ListMagnifyingGlass,
	MagnifyingGlass,
	MapPin,
	Minus,
	PenNib,
	PersonArmsSpread,
	Plus,
	Printer,
	Ruler,
	Share,
	Stack as StackIcon,
	StackSimple,
	X,
} from "@phosphor-icons/react/dist/ssr";
import { FC, ReactNode } from "react";

import { usePopover } from "~ui-components/hooks/use-popover";

export type MapTool =
	| "zoom-in"
	| "zoom-out"
	| "reset-map"
	| "share-map"
	| "print"
	| "legend"
	| "measure"
	| "geolocation"
	| "query"
	| "custom-search"
	| "proximity"
	| "bookmarks"
	| "sketch"
	| "streetview"
	| "map-menu"
	| "search"
	| "attribution"
	| "scale-bar"
	| "mouse-position";
const mapToolIcons: Partial<Record<MapTool, React.ReactNode>> = {
	"zoom-in": <Plus />,
	"zoom-out": <Minus />,
	"reset-map": <ArrowClockwise />,
	"share-map": <Share />,
	print: <Printer />,
	legend: <StackIcon />,
	measure: <Ruler />,
	geolocation: <Crosshair />,
	query: <CircleDashed />,
	"custom-search": <ListMagnifyingGlass />,
	proximity: <DotsNine />,
	bookmarks: <Bookmarks />,
	sketch: <PenNib />,
	streetview: <PersonArmsSpread />,
	"map-menu": <List />,
};
const toolTooltips: Partial<Record<MapTool, React.ReactNode>> = {
	"zoom-in": "Zoom in",
	"zoom-out": "Zoom out",
	"reset-map": "Reset map",
	"share-map": "Share map",
	print: "Print",
	legend: "Legend",
	measure: "Measure",
	geolocation: "Geolocation",
	query: "Query",
	"custom-search": "Custom search",
	proximity: "Proximity",
	bookmarks: "Bookmarks",
	sketch: "Sketch",
	streetview: "Street view",
};
type ToolIconButton = IconButtonProps & IconButtonProps<"a">;
interface ToolsProps {
	"zoom-in": ToolIconButton;
	"zoom-out": ToolIconButton;
	"reset-map": WithToolEnablerProps<ToolIconButton>;
	"share-map": WithToolEnablerProps<ToolIconButton>;
	print: WithToolEnablerProps<ToolIconButton>;
	legend: WithToolEnablerProps<ToolIconButton>;
	measure: WithToolEnablerProps<ToolIconButton>;
	geolocation: WithToolEnablerProps<ToolIconButton>;
	query: WithToolEnablerProps<ToolIconButton>;
	"custom-search": WithToolEnablerProps<ToolIconButton>;
	proximity: WithToolEnablerProps<ToolIconButton>;
	bookmarks: WithToolEnablerProps<ToolIconButton>;
	sketch: WithToolEnablerProps<ToolIconButton>;
	streetview: WithToolEnablerProps<ToolIconButton>;
	"map-menu": ToolIconButton;
	search: WithToolEnablerProps<SearchProps>;
	attribution: AttributionProps;
	"scale-bar": WithToolEnablerProps<ScaleBarProps>;
	"mouse-position": WithToolEnablerProps<MousePositionProps>;
}
export interface MapToolsProps {
	showStreetviewMarker: boolean;
	toolsProps: Partial<ToolsProps>;
}

export const MapTools: FC<MapToolsProps> = ({
	showStreetviewMarker,
	toolsProps,
}) => {
	return (
		<>
			{showStreetviewMarker && (
				<SvgIcon
					sx={{
						position: "absolute",
						zIndex: 1,
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						color: (t) => t.palette.warning.main,
					}}>
					<PersonArmsSpread />
				</SvgIcon>
			)}

			<Box
				position="absolute"
				zIndex={1}
				top={(t) => t.spacing(2)}
				right={(t) => t.spacing(2)}>
				<Stack spacing={1}>
					<ToolButton
						type="zoom-in"
						tooltipProps={{ placement: "left" }}
						buttonProps={{ ...toolsProps["zoom-in"] }}
					/>
					<ToolButton
						type="zoom-out"
						tooltipProps={{ placement: "left" }}
						buttonProps={{ ...toolsProps["zoom-out"] }}
					/>
					<ToolEnabler $enabled={!!toolsProps["reset-map"]?.$enabled}>
						<ToolButton
							type="reset-map"
							tooltipProps={{ placement: "left" }}
							buttonProps={{ ...toolsProps["reset-map"] }}
						/>
					</ToolEnabler>
					<ToolEnabler $enabled={!!toolsProps["share-map"]?.$enabled}>
						<ToolButton
							type="share-map"
							tooltipProps={{ placement: "left" }}
							buttonProps={{ ...toolsProps["share-map"] }}
						/>
					</ToolEnabler>
					<ToolEnabler $enabled={!!toolsProps.print?.$enabled}>
						<ToolButton
							type="print"
							tooltipProps={{ placement: "left" }}
							buttonProps={{ ...toolsProps.print }}
						/>
					</ToolEnabler>
				</Stack>
			</Box>

			<Box
				sx={(t) => ({
					position: "absolute",
					zIndex: 1,
					top: t.spacing(2),
					left: t.spacing(2),
				})}>
				<Stack spacing={2}>
					<Stack
						direction="row"
						spacing={1}>
						<ToolButton
							type="map-menu"
							buttonProps={{ ...toolsProps["map-menu"] }}
						/>
						{!!toolsProps.search && (
							<ToolEnabler $enabled={toolsProps.search.$enabled}>
								<Search {...toolsProps.search} />
							</ToolEnabler>
						)}
					</Stack>
					<Stack spacing={1}>
						<ToolEnabler $enabled={!!toolsProps.legend?.$enabled}>
							<ToolButton
								type="legend"
								tooltipProps={{ placement: "right" }}
								buttonProps={{ ...toolsProps.legend }}
							/>
						</ToolEnabler>
						<ToolEnabler $enabled={!!toolsProps.measure?.$enabled}>
							<ToolButton
								type="measure"
								tooltipProps={{ placement: "right" }}
								buttonProps={{ ...toolsProps.measure }}
							/>
						</ToolEnabler>
						<ToolEnabler $enabled={!!toolsProps.geolocation?.$enabled}>
							<ToolButton
								type="geolocation"
								tooltipProps={{ placement: "right" }}
								buttonProps={{ ...toolsProps.geolocation }}
							/>
						</ToolEnabler>
						<ToolEnabler $enabled={!!toolsProps.query?.$enabled}>
							<ToolButton
								type="query"
								tooltipProps={{ placement: "right" }}
								buttonProps={{ ...toolsProps.query }}
							/>
						</ToolEnabler>
						<ToolEnabler $enabled={!!toolsProps["custom-search"]?.$enabled}>
							<ToolButton
								type="custom-search"
								tooltipProps={{ placement: "right" }}
								buttonProps={{ ...toolsProps["custom-search"] }}
							/>
						</ToolEnabler>
						<ToolEnabler $enabled={!!toolsProps.proximity?.$enabled}>
							<ToolButton
								type="proximity"
								tooltipProps={{ placement: "right" }}
								buttonProps={{ ...toolsProps.proximity }}
							/>
						</ToolEnabler>
						<ToolEnabler $enabled={!!toolsProps.bookmarks?.$enabled}>
							<ToolButton
								type="bookmarks"
								tooltipProps={{ placement: "right" }}
								buttonProps={{ ...toolsProps.bookmarks }}
							/>
						</ToolEnabler>
						<ToolEnabler $enabled={!!toolsProps.sketch?.$enabled}>
							<ToolButton
								type="sketch"
								tooltipProps={{ placement: "right" }}
								buttonProps={{ ...toolsProps.sketch }}
							/>
						</ToolEnabler>
						<ToolEnabler $enabled={!!toolsProps.streetview?.$enabled}>
							<ToolButton
								type="streetview"
								tooltipProps={{ placement: "right" }}
								buttonProps={{ ...toolsProps.streetview }}
							/>
						</ToolEnabler>
					</Stack>
				</Stack>
			</Box>

			<Box
				sx={(t) => ({
					position: "absolute",
					zIndex: 1,
					bottom: t.spacing(2),
					right: t.spacing(2),
				})}>
				<Stack
					direction="row"
					spacing={2}>
					<ToolEnabler $enabled={!!toolsProps["scale-bar"]?.$enabled}>
						<ScaleBar {...toolsProps["scale-bar"]!} />
					</ToolEnabler>
					<ToolEnabler $enabled={!!toolsProps["mouse-position"]?.$enabled}>
						<MousePosition {...toolsProps["mouse-position"]!} />
					</ToolEnabler>
					{!!toolsProps.attribution && (
						<Attribution {...toolsProps.attribution} />
					)}
				</Stack>
			</Box>
		</>
	);
};

export type SearchType = "address" | "data" | "coordinate";
const searchTypeIcon: Record<SearchType, ReactNode> = {
	address: <MapPin />,
	data: <StackSimple />,
	coordinate: <CrosshairSimple />,
};
const searchTypeLabel: Record<SearchType, string> = {
	address: "Address search",
	data: "Data search",
	coordinate: "Coordinate search",
};
export const searchTypePlaceholder: Record<SearchType, string> = {
	address: "Address search",
	data: "Data search",
	coordinate: "Coordinate search",
};
export interface SearchProps {
	inputAutoFocus?: boolean;
	currentType: SearchType;
	currentInput: string;
	onClick?: () => void;
	onInputChange?: (value: string) => void;
	onTypeChange?: (type: SearchType) => void;
	onInputClear?: () => void;
}
export const Search: FC<SearchProps> = ({
	inputAutoFocus,
	currentType,
	currentInput,
	onClick,
	onInputChange,
	onTypeChange,
	onInputClear,
}) => {
	const popover = usePopover<HTMLButtonElement>();

	return (
		<>
			<Paper>
				<TextField
					autoFocus={inputAutoFocus}
					value={currentInput}
					onChange={(e) => onInputChange?.(e.target.value)}
					onClick={() => onClick?.()}
					placeholder={searchTypePlaceholder[currentType]}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								{currentInput ? (
									<IconButton
										size="small"
										onClick={() => onInputClear?.()}>
										<X />
									</IconButton>
								) : (
									<MagnifyingGlass />
								)}
							</InputAdornment>
						),
					}}
					sx={{ "& input": { cursor: "pointer" } }}
				/>
			</Paper>
			<IconButton
				onClick={popover.handleOpen}
				ref={popover.anchorRef}
				sx={(t) => ({
					bgcolor: t.palette.background.paper,
					color: t.palette.text.primary,
				})}>
				{searchTypeIcon[currentType]}
			</IconButton>
			<Menu
				anchorEl={popover.anchorRef.current}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				onClose={popover.handleClose}
				open={popover.open}
				slotProps={{ paper: { sx: { width: "220px" } } }}
				transformOrigin={{ horizontal: "right", vertical: "top" }}>
				{Object.keys(searchTypeIcon).map((type) => (
					<MenuItem
						key={type}
						onClick={() => {
							onTypeChange?.(type as SearchType);
							popover.handleClose();
						}}>
						<ListItemIcon>{searchTypeIcon[type]}</ListItemIcon>
						<Typography variant="subtitle2">{searchTypeLabel[type]}</Typography>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

interface AttributionProps {
	isExpanded: boolean;
	customContent: string;
	expandedButtonProps: ToolIconButton;
	collapsedButtonProps: ToolIconButton;
}
const Attribution: FC<AttributionProps> = ({
	isExpanded,
	customContent,
	expandedButtonProps,
	collapsedButtonProps,
}) => {
	return (
		<>
			{!!isExpanded && (
				<Paper sx={{ px: 1, gap: 1, display: "flex", alignItems: "center" }}>
					{!!customContent && (
						<Typography
							variant="caption"
							color="text.secondary">
							{customContent}
						</Typography>
					)}
					<Typography
						variant="caption"
						color="text.secondary">
						Map tiles &#169; 2013 Microsoft Terms of Use
					</Typography>
				</Paper>
			)}
			{isExpanded ? (
				<IconButton
					sx={(t) => ({
						bgcolor: t.palette.background.paper,
						color: t.palette.text.primary,
					})}
					{...expandedButtonProps}>
					<X />
				</IconButton>
			) : (
				<IconButton
					sx={(t) => ({
						bgcolor: t.palette.background.paper,
						color: t.palette.text.primary,
					})}
					{...collapsedButtonProps}>
					<Info />
				</IconButton>
			)}
		</>
	);
};

interface MousePositionProps {
	position: { lat: ReactNode; lng: ReactNode };
	onCopyClick: () => void;
}
const MousePosition: FC<MousePositionProps> = ({ position, onCopyClick }) => {
	return (
		<Paper sx={{ px: 1, gap: 1, display: "flex", alignItems: "center" }}>
			<Typography
				variant="caption"
				color="text.secondary">
				{position.lat}, {position.lng}
			</Typography>
			<IconButton
				size="small"
				onClick={onCopyClick}>
				<Copy />
			</IconButton>
		</Paper>
	);
};

interface ScaleBarProps {
	value: { km: number; mi: number };
}
const ScaleBar: FC<ScaleBarProps> = ({ value }) => {
	return (
		<Paper
			sx={{
				px: 1,
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-start",
				justifyContent: "center",
			}}>
			<Typography
				variant="caption"
				color="text.secondary"
				border={(t) => `1px solid ${t.palette.divider}`}
				px={1}
				borderTop={0}
				width="100px"
				lineHeight={1}>
				{value.km}km
			</Typography>
			<Typography
				variant="caption"
				color="text.secondary"
				border={(t) => `1px solid ${t.palette.divider}`}
				px={1}
				borderBottom={0}
				mt="-1px"
				lineHeight={1}>
				{value.mi}mi
			</Typography>
		</Paper>
	);
};

type WithToolEnablerProps<T> = T & {
	$enabled: boolean;
};
type ToolEnablerProps = WithToolEnablerProps<{ children: ReactNode }>;
export const ToolEnabler: FC<ToolEnablerProps> = ({ $enabled, children }) =>
	$enabled ? children : null;

interface ToolButtonProps {
	type: MapTool;
	buttonProps?: ToolIconButton;
	tooltipProps?: Omit<TooltipProps, "title" | "children">;
}
export const ToolButton: FC<ToolButtonProps> = ({
	type,
	buttonProps,
	tooltipProps,
}) => {
	return (
		<Tooltip
			{...tooltipProps}
			title={toolTooltips[type]}>
			<IconButton
				sx={(t) => ({
					bgcolor: t.palette.background.paper,
					color: t.palette.text.primary,
				})}
				{...buttonProps}>
				{mapToolIcons[type]}
			</IconButton>
		</Tooltip>
	);
};
