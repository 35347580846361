export function displayBytes(bytes: number): string {
	if (bytes < 0) {
		throw new Error("Bytes cannot be negative.");
	}

	const units = ["B", "KB", "MB", "GB"];
	let index = 0;

	let formattedSize = bytes;

	while (formattedSize >= 1024 && index < units.length - 1) {
		formattedSize /= 1024;
		index++;
	}

	// Return the formatted size with one decimal place
	return `${formattedSize.toFixed(1)} ${units[index]}`;
}
