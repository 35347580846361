import { Link } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { User } from "@phosphor-icons/react/dist/ssr";
import { CreditCard } from "@phosphor-icons/react/dist/ssr/CreditCard";
import { HardDrives } from "@phosphor-icons/react/dist/ssr/HardDrives";
import { MapTrifold } from "@phosphor-icons/react/dist/ssr/MapTrifold";
import { PencilSimple } from "@phosphor-icons/react/dist/ssr/PencilSimple";
import { paths } from "config/paths";
import NextLink from "next/link";
import { useRouter } from "next/router";

import { SummaryProps } from "./summary";

export const usePlanSummary = (): SummaryProps["items"] => {
	const router = useRouter();

	return [
		{
			title: "Plan",
			value: "Enterprise",
			footer: (
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between">
					<Stack
						direction="row"
						alignItems="center"
						spacing={1}>
						<CreditCard />
						<Typography variant="caption">**** 1234 (12/24)</Typography>
						<Link
							component={NextLink}
							href={paths.accountName.settings.editBilling(
								router.query["account-name"],
							)}
							color="unset"
							mt={0.5}>
							<PencilSimple />
						</Link>
					</Stack>
					<Typography variant="caption">$3,810.80/year</Typography>
				</Stack>
			),
		},
		{
			title: "Maps",
			value: 24,
			footer: (
				<Stack
					direction="row"
					alignItems="center"
					spacing={1}>
					<MapTrifold />
					<Typography variant="caption">of 40</Typography>
				</Stack>
			),
		},
		{
			title: "Storage",
			value: "9.54 GB",
			footer: (
				<Stack
					direction="row"
					alignItems="center"
					spacing={1}>
					<HardDrives />
					<Typography variant="caption">of 16 GB</Typography>
				</Stack>
			),
		},
	];
};

export const useTrialPlanSummary = (): SummaryProps["items"] => {
	const router = useRouter();

	return [
		{
			title: "Plan",
			value: "Enterprise",
			footer: (
				<Stack
					spacing={1}
					direction="row"
					alignItems="center"
					justifyContent="space-between">
					<Button
						LinkComponent={NextLink}
						size="small"
						variant="outlined"
						href={paths.accountName.settings.checkout(
							router.query["account-name"],
						)}>
						Upgrade to paid
					</Button>
				</Stack>
			),
		},
		{
			title: "Maps",
			value: 24,
			footer: (
				<Stack
					direction="row"
					alignItems="center"
					spacing={1}>
					<MapTrifold />
					<Typography variant="caption">of 40</Typography>
				</Stack>
			),
		},
		{
			title: "Storage",
			value: "9.54 GB",
			footer: (
				<Stack
					direction="row"
					alignItems="center"
					spacing={1}>
					<HardDrives />
					<Typography variant="caption">of 16 GB</Typography>
				</Stack>
			),
		},
	];
};

export const USERS_SUMMARY: SummaryProps["items"] = [
	{
		title: "Administrators",
		value: 2,
		footer: (
			<Stack
				direction="row"
				alignItems="center"
				spacing={1}>
				<User />
				<Typography variant="caption">of 2</Typography>
			</Stack>
		),
	},
	{
		title: "Editors",
		value: 4,
		footer: (
			<Stack
				direction="row"
				alignItems="center"
				spacing={1}>
				<User />
				<Typography variant="caption">of 5</Typography>
			</Stack>
		),
	},
	{
		title: "Viewers",
		value: 58,
		footer: (
			<Stack
				direction="row"
				alignItems="center"
				spacing={1}>
				<User />
				<Typography variant="caption">of unlimited</Typography>
			</Stack>
		),
	},
];
