import { FC } from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import { ConfirmationModal } from "~ui-components/index";
import {
	BasicDetails,
	BasicDetailsProps,
} from "~ui-components/components/organisms/edit-profile-panels/basic-details";

export interface UserProfileProps {
	form: BasicDetailsProps["form"];
	showUrlChangeConfirmationModal: boolean;
	onUrlChangeConfirmationModalClose: () => void;
	onUrlChangeConfirmationModalConfirm: () => void;
}

export const UserProfile: FC<UserProfileProps> = ({
	form,
	showUrlChangeConfirmationModal,
	onUrlChangeConfirmationModalClose,
	onUrlChangeConfirmationModalConfirm,
}) => {
	return (
		<DashboardContentLayout pageTitle="User profile">
			<ConfirmationModal
				open={showUrlChangeConfirmationModal}
				title="Confirm Account URL Change"
				content="Changing your account URL will affect all shared links to your maps and data. We will not set up redirects for any old links.
        \n Are you sure you want to proceed?"
				actionText="Yes, Change URL"
				onClose={onUrlChangeConfirmationModalClose}
				onConfirm={onUrlChangeConfirmationModalConfirm}
			/>
			<BasicDetails form={form} />
		</DashboardContentLayout>
	);
};
