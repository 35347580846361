import * as React from "react";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import { FormikProps } from "formik";
import FormHelperText from "@mui/material/FormHelperText";

import { Option } from "~ui-components/components/atoms/option";
import { ButtonCancel } from "~ui-components/components/atoms/button-cancel";

export const ToolsMouseHighlightSchema = yup.object({
	layer: yup.string().notOneOf([""], "Select layer").required("Required"),
});

export type ToolsMouseHighlightFormValues = yup.InferType<
	typeof ToolsMouseHighlightSchema
>;

export interface ToolsMouseHighlightFormProps {
	form: FormikProps<ToolsMouseHighlightFormValues>;
}

export function ToolsMouseHighlightForm(
	props: ToolsMouseHighlightFormProps,
): React.JSX.Element {
	const { form } = props;
	return (
		<form onSubmit={form.handleSubmit}>
			<CardContent>
				<Stack spacing={4}>
					<Stack spacing={3}>
						<Grid
							container
							spacing={3}>
							<Grid
								xs={12}
								md={4}>
								<FormControl
									fullWidth
									error={!!form.errors.layer}>
									<InputLabel>Layer</InputLabel>
									<Select
										defaultValue=""
										{...form.getFieldProps("layer")}
										renderValue={(value) => {
											if (value === "")
												return (
													<Typography color="text.secondary">
														Select layer
													</Typography>
												);
											return <>{value}</>;
										}}>
										<Option
											value=""
											disabled
											sx={{ display: "none" }}>
											Select layer
										</Option>
										<Option value="ne_50m_admin_0_counties">
											ne_50m_admin_0_counties
										</Option>
										<Option value="1-2k US towns">1-2k US towns</Option>
										<Option value="layer_3">layer_3</Option>
									</Select>
									{form.errors.layer && (
										<FormHelperText>{form.errors.layer}</FormHelperText>
									)}
								</FormControl>
							</Grid>
						</Grid>
					</Stack>
				</Stack>
			</CardContent>
			<CardActions sx={{ justifyContent: "flex-end" }}>
				<ButtonCancel />
				<Button
					type="submit"
					variant="contained"
					disabled={!form.isValid}>
					Save changes
				</Button>
			</CardActions>
		</form>
	);
}
