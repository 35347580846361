import Card from "@mui/material/Card";
import { FC } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import { Lock as LockIcon } from "@phosphor-icons/react/dist/ssr";
import Box from "@mui/material/Box";

interface AccountFormConfirmationCardProps {
	disableSubmit?: boolean;
}

export const AccountFormConfirmationCard: FC<
	AccountFormConfirmationCardProps
> = ({ disableSubmit }) => {
	return (
		<Card
			sx={{
				bgcolor: "background.level2",
			}}>
			<CardContent>
				<Stack spacing={2}>
					<Button
						fullWidth
						variant="contained"
						type="submit"
						disabled={disableSubmit}>
						Update billing information
					</Button>
					<Stack
						padding={2}
						spacing={2}>
						<Stack
							direction="row"
							spacing={2}
							sx={{ alignItems: "center" }}>
							<Box
								sx={{
									alignItems: "center",
									color: "var(--mui-palette-success-main)",
									display: "flex",
									justifyContent: "center",
								}}>
								<LockIcon />
							</Box>
							<Typography variant="subtitle2">Secure processing</Typography>
						</Stack>
						<Stack spacing={2}>
							<Typography
								variant="body2"
								color={"text.secondary"}>
								Your payment information is protected by 256-bit SSL encryption
								and stored with our PCI-compliant processor, certified by a
								leading security auditor.
							</Typography>
						</Stack>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	);
};
