import * as React from "react";
import { create } from "zustand";

import { MobileNav } from "./mobile-nav";

interface UseNav {
	open: boolean;
	onOpen: () => void;
	onClose: () => void;
}

export const useNav = create<UseNav>((set) => ({
	open: false,
	onOpen: () => {
		set({ open: true });
	},
	onClose: () => {
		set({ open: false });
	},
}));

export interface MainNavProps {
	children?: React.ReactNode;
}

export function MainNav({ children }: MainNavProps): React.JSX.Element {
	const { open, onClose } = useNav();

	return (
		<React.Fragment>
			<MobileNav
				onClose={onClose}
				open={open}>
				{children}
			</MobileNav>
		</React.Fragment>
	);
}
