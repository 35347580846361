import * as React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { X as XIcon } from "@phosphor-icons/react/dist/ssr/X";
import { Divider } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import _ from "lodash";

import { Option } from "~ui-components/components/atoms/option";

export interface SettingsDrawerProps {
	onClose?: () => void;
	open?: boolean;
}

export function FormattingDrawer({
	onClose,
	open,
}: SettingsDrawerProps): React.JSX.Element {
	return (
		<Drawer
			ModalProps={{ BackdropProps: { invisible: false }, sx: { zIndex: 1200 } }}
			PaperProps={{
				elevation: 4,
				sx: {
					display: "flex",
					flexDirection: "column",
					maxWidth: "100%",
					width: "440px",
				},
			}}
			anchor="right"
			disableScrollLock
			onClose={onClose}
			open={open}>
			<Stack
				direction="row"
				spacing={3}
				sx={{
					alignItems: "center",
					justifyContent: "space-between",
					px: 3,
					pt: 2,
				}}>
				<Typography variant="h6">Attribute formatting</Typography>
				<Stack
					direction="row"
					spacing={0.5}
					sx={{ alignItems: "center" }}>
					<IconButton onClick={onClose}>
						<XIcon />
					</IconButton>
				</Stack>
			</Stack>
			<Divider sx={{ mt: 2 }} />
			<Stack
				spacing={3}
				sx={{ overflowY: "auto", p: 3 }}>
				<RadioGroup
					aria-labelledby="demo-radio-buttons-group-label"
					defaultValue="auto-detect"
					name="radio-buttons-group">
					<FormControl>
						<FormControlLabel
							value="auto-detect"
							control={<Radio />}
							label="Auto detect"
						/>
						<FormControlLabel
							sx={{ mt: 2 }}
							value="no-formatting"
							control={<Radio />}
							label="No formatting"
						/>
					</FormControl>
					<Typography variant="h6">Number</Typography>
					<FormControl>
						<RadioFormatting
							value="integer"
							label="Integer"
							sample="3"
						/>
						<RadioFormatting
							value="float"
							label="Float"
							sample="3.7639839"
						/>
						<RadioFormatting
							showDecimal={true}
							value="rounded"
							label="Rounded"
							sample="3.76"
						/>
					</FormControl>
					<Typography variant="h6">Currency</Typography>
					<FormControl>
						<RadioFormatting
							value="dollars"
							label="Dollars"
							sample="$3.00"
						/>
						<RadioFormatting
							value="pound"
							label="Pounds"
							sample="£3.00"
						/>
						<RadioFormatting
							value="euros"
							label="Euros"
							sample="€3.00"
						/>
					</FormControl>
					<Typography variant="h6">Measurement</Typography>
					<FormControl>
						<RadioFormatting
							value="metres"
							label="Metres"
							sample="3 m"
						/>
						<RadioFormatting
							value="kilometres"
							label="Kilometres"
							sample="3 km"
						/>
						<RadioFormatting
							value="hectares"
							label="Hectares"
							sample="3 ha"
						/>
						<RadioFormatting
							value="feet"
							label="Feet"
							sample="3 ft"
						/>
						<RadioFormatting
							value="miles"
							label="Miles"
							sample="3 mi"
						/>
						<RadioFormatting
							value="acres"
							label="Acres"
							sample="3 ac"
						/>
					</FormControl>
					<Typography variant="h6">Date & time</Typography>
					<FormControl>
						<RadioFormatting
							value="dd-mm-yy"
							label="DD-MM-YY"
							sample="03-02-2026"
						/>
					</FormControl>
				</RadioGroup>
			</Stack>
		</Drawer>
	);
}

interface RadioFormattingProps {
	value: string;
	label: string;
	sample: string;
	showDecimal?: boolean;
}
const RadioFormatting = (props: RadioFormattingProps) => {
	const { value, label, sample, showDecimal = false } = props;
	return (
		<FormControlLabel
			sx={{
				":not(:first-child)": {
					marginTop: "15px",
				},
				"> span.MuiFormControlLabel-label": {
					width: "100%",
				},
			}}
			value={value}
			control={<Radio />}
			label=<Stack
				direction="row"
				sx={{ width: "100%", alignItems: "center" }}>
				<Typography variant="body2">{label}</Typography>
				<Stack
					direction="row"
					sx={{ flexGrow: 1, justifyContent: "end", alignItems: "center" }}>
					{showDecimal && (
						<>
							<Typography
								variant="body2"
								color="text.secondary">
								Decimals
							</Typography>
							<Select
								sx={{ ml: 1, mr: 2 }}
								value={2}>
								{_.range(2, 7).map((decimal) => (
									<Option
										key={decimal}
										value={decimal}>
										{decimal}
									</Option>
								))}
							</Select>
						</>
					)}

					<Typography
						variant="body2"
						color="text.secondary">
						{sample}
					</Typography>
				</Stack>
			</Stack>
		/>
	);
};
