import {
	Divider,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import { BubbleMenu, Editor } from "@tiptap/react";
import * as React from "react";

export const TableMenu: React.FC<{ editor: Editor }> = ({ editor }) => {
	return (
		<BubbleMenu
			editor={editor}
			pluginKey="TableMenu"
			tippyOptions={{ zIndex: 1, placement: "bottom" }}
			shouldShow={({ editor }) => editor.isActive("table")}>
			<ToggleButtonGroup
				exclusive
				orientation="vertical"
				sx={{ bgcolor: (t) => t.palette.background.paper }}>
				{[
					{
						value: "add-column-before",
						label: "Add column before",
						onClick: () => editor.chain().focus().addColumnBefore().run(),
					},
					{
						value: "add-column-after",
						label: "Add column after",
						onClick: () => editor.chain().focus().addColumnAfter().run(),
					},
					{
						value: "add-row-before",
						label: "Add row before",
						onClick: () => editor.chain().focus().addRowBefore().run(),
					},
					{
						value: "add-row-after",
						label: "Add row after",
						onClick: () => editor.chain().focus().addRowAfter().run(),
					},
					{},
					{
						value: "delete-column",
						label: "Delete column",
						onClick: () => editor.chain().focus().deleteColumn().run(),
					},
					{
						value: "delete-row",
						label: "Delete row",
						onClick: () => editor.chain().focus().deleteRow().run(),
					},
					{
						value: "delete-table",
						label: "Delete table",
						onClick: () => editor.chain().focus().deleteTable().run(),
					},
					{},
					{
						value: "merge-cells",
						label: "Merge cells",
						onClick: () => editor.chain().focus().mergeCells().run(),
					},
					{
						value: "split-cell",
						label: "Split cell",
						onClick: () => editor.chain().focus().splitCell().run(),
					},
					{},
					{
						value: "toggle-header-column",
						label: "Toggle header column",
						onClick: () => editor.chain().focus().toggleHeaderColumn().run(),
					},
					{
						value: "toggle-header-row",
						label: "Toggle header row",
						onClick: () => editor.chain().focus().toggleHeaderRow().run(),
					},
					{
						value: "toggle-header-cell",
						label: "Toggle header cell",
						onClick: () => editor.chain().focus().toggleHeaderCell().run(),
					},
					{},
				].map(({ value, onClick, label }, index) => [
					value ? (
						<ToggleButton
							key={value}
							value={value}
							onClick={onClick}>
							<Typography variant="caption">{label}</Typography>
						</ToggleButton>
					) : (
						<Divider
							key={`divider-${index}`}
							sx={{ mx: 1 }}
						/>
					),
				])}
			</ToggleButtonGroup>
		</BubbleMenu>
	);
};
