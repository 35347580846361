import { FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import { paths } from "config/paths";
import { useFormik } from "formik";
import RouterLink from "next/link";
import { useRouter } from "next/router";
import { FC } from "react";
import * as Yup from "yup";
import { MangoLogo } from "~ui-components/components/atoms/mango-logo";
import { CenteredLayout } from "~ui-components/components/organisms/centered-layout";

export const AccountSignInSchema = Yup.object({
	email: Yup.string().email("Invalid email").required("Required"),
	password: Yup.string().required("Required"),
});

export type AccountSignInFormValues = Yup.InferType<typeof AccountSignInSchema>;

export interface AccountSignInProps {
	form: ReturnType<typeof useFormik<AccountSignInFormValues>>;
}

export const AccountSignIn: FC<AccountSignInProps> = ({ form }) => {
	const router = useRouter();

	return (
		<CenteredLayout>
			<Stack spacing={4}>
				<div>
					<Box
						component={RouterLink}
						href={paths.home}
						sx={{ display: "inline-block", fontSize: 0 }}>
						<MangoLogo
							width={122}
							height={32}
						/>
					</Box>
				</div>
				<Card>
					<CardHeader
						title={
							<>
								Sign in to&nbsp;
								<Box
									component="span"
									color="primary.main">
									{router.query["account-name"]}
								</Box>
							</>
						}
					/>
					<CardContent>
						<Stack
							spacing={2}
							component="form"
							onSubmit={form.handleSubmit}>
							<FormControl>
								<InputLabel>Email address</InputLabel>
								<OutlinedInput
									autoFocus
									type="email"
									{...form.getFieldProps("email")}
									error={!!form.touched.email && !!form.errors.email}
								/>
								{!!form.touched.email && !!form.errors.email && (
									<FormHelperText error>{form.errors.email}</FormHelperText>
								)}
							</FormControl>
							<FormControl>
								<InputLabel>Password</InputLabel>
								<OutlinedInput
									type="password"
									{...form.getFieldProps("password")}
									error={!!form.touched.password && !!form.errors.password}
								/>
								{!!form.touched.password && !!form.errors.password && (
									<FormHelperText error>{form.errors.password}</FormHelperText>
								)}
							</FormControl>
							<Button
								variant="contained"
								disabled={form.isSubmitting || !form.isValid}
								type="submit">
								Sign in
							</Button>
						</Stack>
					</CardContent>
				</Card>
			</Stack>
		</CenteredLayout>
	);
};
