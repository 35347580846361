import { BubbleConfigs } from "~ui-components/types/__generated/gql/graphql";

interface Props {
	style: BubbleConfigs;
}
export const Bubble = ({ style }: Props) => {
	const min = Number(style?.radius?.min);
	const max = Number(style?.radius?.max);
	const minLabel = style?.symbolValues?.[0]?.min ?? 0;
	const maxLabel =
		style?.symbolValues?.[style.symbolValues?.length - 1]?.max ?? 0;
	return (
		<svg
			width={max * 2 + 250}
			height={max * 2 + 10}
			xmlns="http://www.w3.org/2000/svg">
			<circle
				cx={max + 2}
				cy={max + 2}
				r={max}
				fill={style?.symbolStyle?.fill?.color ?? "none"}
				stroke={style?.symbolStyle?.stroke?.color ?? "none"}
				strokeWidth="2"
			/>
			<circle
				cx={max + 2}
				cy={max + max - min}
				r={min}
				fill={style?.symbolStyle?.fill?.color ?? "none"}
				stroke={style?.symbolStyle?.stroke?.color ?? "none"}
				strokeWidth="2"
			/>
			<text
				x={max * 2 + 10}
				y={max * 2}
				fontSize="12"
				fill="#666666">
				{">"} {maxLabel}
			</text>
			<text
				x={max * 2 + 10}
				y={min + 2}
				fontSize="12"
				fill="#666666">
				{">"}= {minLabel}
			</text>
		</svg>
	);
};
