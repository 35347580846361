import { PortalSettings as Form } from "~ui-components/components/molecules/portal-settings";

import { BrandLayout as Layout } from "./layout";

export function BrandPortal(props) {
	return (
		<Layout>
			<Form {...props} />
		</Layout>
	);
}
