import {
	Avatar,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Checkbox,
	FormControl,
	FormControlLabel,
	IconButton,
	InputLabel,
	Link,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { DotsNine, Question } from "@phosphor-icons/react/dist/ssr";
import { EditorEvents } from "@tiptap/react";
import { useFormik } from "formik";
import { FC } from "react";
import * as Yup from "yup";
import { DEFAULT_ATTRIBUTES } from "config/constants";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import { Option } from "~ui-components/components/atoms/option";
import { TextEditor } from "~ui-components/components/atoms/text-editor/text-editor";

const MEASUREMENT_UNITS = ["KILOMETERS", "METERS", "MILES", "FEET"] as const;

const MEASUREMENT_UNIT_NAMES: Record<
	(typeof MEASUREMENT_UNITS)[number],
	string
> = {
	KILOMETERS: "Kilometers",
	METERS: "Meters",
	MILES: "Miles",
	FEET: "Feet",
};

export const ToolsProximityAnalysisLayerConfigsSchema = Yup.object({
	id: Yup.string().required("Required"),
	configs: Yup.object({
		isFilterByAttributeEnabled: Yup.boolean().required("Required"),
		attributes: Yup.array().of(Yup.string().required()),
		isFilterbyAreaEnabled: Yup.boolean().required("Required"),
		measurementUnit: Yup.string()
			.oneOf(MEASUREMENT_UNITS as unknown as string[])
			.required("Required"),
		isResultsTableEnabled: Yup.boolean().required("Required"),
		isReportEnabled: Yup.boolean().required("Required"),
		reportContent: Yup.string(),
		exportFormats: Yup.object({
			shapefile: Yup.boolean().required("Required"),
			kml: Yup.boolean().required("Required"),
			csv: Yup.object({
				enabled: Yup.boolean().required("Required"),
				isCoordsIncluded: Yup.boolean().required("Required"),
			}).required("Required"),
		}).required(),
	}).required(),
});

export type ToolsProximityAnalysisLayerConfigsFormValues = Yup.InferType<
	typeof ToolsProximityAnalysisLayerConfigsSchema
>;

export interface ToolsProximityAnalysisLayerConfigsFormProps {
	displayName: string;
	form: ReturnType<
		typeof useFormik<ToolsProximityAnalysisLayerConfigsFormValues>
	>;
	attributeOptions: { value: string; label: string }[];
	onCancel: () => void;
}

export const ToolsProximityAnalysisLayerConfigsForm: FC<
	ToolsProximityAnalysisLayerConfigsFormProps
> = ({ form, displayName, attributeOptions, onCancel }) => {
	return (
		<DashboardContentLayout pageTitle={displayName}>
			<Card
				component="form"
				onSubmit={form.handleSubmit}>
				<CardHeader
					avatar={
						<Avatar>
							<DotsNine fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Find features by spatial relationships"
					action={
						<Link
							href={
								"https://help.mangomap.com/hc/en-us/articles/360022190152-Proximity-Analysis"
							}
							target="_blank">
							<IconButton aria-label="settings">
								<Question />
							</IconButton>
						</Link>
					}
				/>
				<CardContent>
					<Stack spacing={3}>
						<FormControl>
							<InputLabel>Layer display name</InputLabel>
							<TextField
								disabled
								value={displayName}
							/>
						</FormControl>
						<Typography variant="h5">Filter selection options</Typography>
						<div>
							<FormControlLabel
								control={
									<Checkbox
										{...form.getFieldProps(
											"configs.isFilterByAttributeEnabled",
										)}
										checked={form.values.configs.isFilterByAttributeEnabled}
									/>
								}
								label="Filter by attribute value"
							/>
						</div>
						<FormControl>
							<InputLabel>Filter attributes</InputLabel>
							<Select
								{...form.getFieldProps("configs.attributes")}
								multiple
								renderValue={(value) => `${value.length} selected`}
								sx={{ width: "200px" }}>
								{attributeOptions.map((option) => (
									<Option
										key={option.value}
										value={option.value}>
										{option.label}
									</Option>
								))}
							</Select>
						</FormControl>
						<div>
							<FormControlLabel
								control={
									<Checkbox
										{...form.getFieldProps("configs.isFilterbyAreaEnabled")}
										checked={form.values.configs.isFilterbyAreaEnabled}
									/>
								}
								label="Filter by area or location"
							/>
						</div>
						<FormControl>
							<InputLabel>Measurement units</InputLabel>
							<Select
								{...form.getFieldProps("configs.measurementUnit")}
								sx={{ width: "200px" }}>
								{MEASUREMENT_UNITS.map((option) => (
									<Option
										key={option}
										value={option}>
										{MEASUREMENT_UNIT_NAMES[option]}
									</Option>
								))}
							</Select>
						</FormControl>
						<Typography variant="h5">Results</Typography>
						<div>
							<FormControlLabel
								control={
									<Checkbox
										{...form.getFieldProps("configs.isResultsTableEnabled")}
										checked={form.values.configs.isResultsTableEnabled}
									/>
								}
								label="Generate results table"
							/>
						</div>
						<div>
							<FormControlLabel
								control={
									<Checkbox
										{...form.getFieldProps("configs.isReportEnabled")}
										checked={form.values.configs.isReportEnabled}
									/>
								}
								label="Generate report"
							/>
						</div>
						{form.values.configs.isReportEnabled && (
							<Stack
								spacing={3}
								direction={{ md: "row" }}
								alignItems={{ md: "stretch" }}>
								<FormControl sx={{ flex: 1 }}>
									<InputLabel>Report</InputLabel>
									<Box
										sx={{
											"& .tiptap-container": { height: "400px" },
										}}>
										<TextEditor
											placeholder="Write something"
											content={form.values.configs.reportContent ?? ""}
											onUpdate={({ editor }: EditorEvents["update"]) => {
												void form.setFieldValue(
													"configs.reportContent",
													editor.getText(),
												);
											}}
											toolsProps={{
												Blockquote: true,
												CodeBlock: true,
												Code: true,
												Table: true,
												TextAlign: true,
												HorizontalRule: true,
												Equation: { attributes: DEFAULT_ATTRIBUTES },
												ImageInput: {
													srcAttributes: DEFAULT_ATTRIBUTES,
												},
												IFrameInput: {
													srcAttributes: DEFAULT_ATTRIBUTES,
												},
												LinkInput: {
													hrefAttributes: DEFAULT_ATTRIBUTES,
													textAttributes: DEFAULT_ATTRIBUTES,
												},
												AggregateAttributeSelect: {
													totalAttributes: DEFAULT_ATTRIBUTES,
													minAttributes: DEFAULT_ATTRIBUTES,
													maxAttributes: DEFAULT_ATTRIBUTES,
													meanAttributes: DEFAULT_ATTRIBUTES,
													medianAttributes: DEFAULT_ATTRIBUTES,
												},
											}}
										/>
									</Box>
								</FormControl>
								<FormControl sx={{ flex: 1 }}>
									<Stack height="100%">
										<InputLabel>Report preview</InputLabel>
										<Box
											sx={{
												px: 2,
												py: 1,
												flex: 1,
												minHeight: "400px",
												border: "1px solid var(--mui-palette-divider)",
												borderRadius: "var(--mui-shape-borderRadius)",
											}}>
											{form.values.configs.reportContent ?? ""}
										</Box>
									</Stack>
								</FormControl>
							</Stack>
						)}
						<Typography variant="h5">Results export</Typography>
						<div>
							<FormControlLabel
								control={
									<Checkbox
										{...form.getFieldProps("configs.exportFormats.csv.enabled")}
										checked={form.values.configs.exportFormats.csv.enabled}
									/>
								}
								label="CSV"
							/>
							<FormControlLabel
								disabled={!form.values.configs.exportFormats.csv.enabled}
								control={
									<Checkbox
										{...form.getFieldProps(
											"configs.exportFormats.csv.isCoordsIncluded",
										)}
										checked={
											form.values.configs.exportFormats.csv.isCoordsIncluded
										}
									/>
								}
								label="Include coordinates"
								sx={{ ml: 2 }}
							/>
						</div>
						<div>
							<FormControlLabel
								control={
									<Checkbox
										{...form.getFieldProps("configs.exportFormats.shapefile")}
										checked={form.values.configs.exportFormats.shapefile}
									/>
								}
								label="Shapefile"
							/>
						</div>
						<div>
							<FormControlLabel
								control={
									<Checkbox
										{...form.getFieldProps("configs.exportFormats.kml")}
										checked={form.values.configs.exportFormats.kml}
									/>
								}
								label="KML"
							/>
						</div>
					</Stack>
				</CardContent>
				<CardActions sx={{ justifyContent: "flex-end", alignItems: "center" }}>
					<Button
						color="inherit"
						onClick={onCancel}>
						Cancel
					</Button>
					<Button
						variant="contained"
						type="submit"
						disabled={form.isSubmitting || !form.isValid}>
						Save changes
					</Button>
				</CardActions>
			</Card>
		</DashboardContentLayout>
	);
};
