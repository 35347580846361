import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { Rows as RowsIcon } from "@phosphor-icons/react/dist/ssr/Rows";
import * as React from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

import { ToolsLegendForm, ToolsLegendFormProps } from "./tools-legend-form";

interface ToolsLegendProps {
	form: ToolsLegendFormProps["form"];
}

export function ToolsLegend(props: ToolsLegendProps): React.JSX.Element {
	const { form } = props;

	return (
		<DashboardContentLayout pageTitle="Legend">
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<RowsIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Display a legend to help user interpret symbols, colors, and data on map, enhancing understanding and usability"
				/>
				<ToolsLegendForm form={form} />
			</Card>
		</DashboardContentLayout>
	);
}

export * from "./tools-legend-form";
