import {
	Box,
	Divider,
	IconButton,
	Paper,
	Stack,
	Typography,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import { FC, ReactNode } from "react";

export interface MapToolDrawerProps {
	title?: string;
	onClose?: () => void;
	Content: ReactNode;
	Action?: ReactNode;
}

export const MapToolDrawer: FC<MapToolDrawerProps> = ({
	title,
	onClose,
	Content,
	Action,
}) => {
	return (
		<Paper
			sx={{ height: "100dvh", width: "var(--MapTool-width)", borderRadius: 0 }}>
			<Stack height="100%">
				{(!!title || !!onClose) && (
					<Stack
						direction="row"
						alignItems="center"
						p={2}>
						<Typography
							variant="h6"
							flex={1}>
							{title ?? ""}
						</Typography>

						{onClose && (
							<IconButton onClick={onClose}>
								<X />
							</IconButton>
						)}
					</Stack>
				)}
				<Divider />
				<Box
					flex={1}
					sx={{ overflowY: "auto" }}>
					{Content}
				</Box>
				{!!Action && <Box p={2}>{Action}</Box>}
			</Stack>
		</Paper>
	);
};
