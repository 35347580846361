import * as React from "react";

import type {
	ColorScheme,
	Direction,
	PrimaryColor,
} from "~ui-components/theme/types";

export type Layout = "horizontal" | "vertical";

export type NavColor = "blend_in" | "discrete" | "evident";

export interface Settings {
	primaryColor: PrimaryColor;
	colorScheme: ColorScheme;
	direction?: Direction;
	layout?: Layout;
	navColor?: NavColor;
}

export const defaultSettings: Settings = {
	colorScheme: "light",
	primaryColor: "mango",
	direction: "ltr",
	navColor: "evident",
	layout: "vertical",
};

export const SettingsContext = React.createContext<Settings>(defaultSettings);

export interface SettingsProviderProps {
	children: React.ReactNode;
}

export function SettingsProvider({
	children,
}: SettingsProviderProps): React.JSX.Element {
	return (
		<SettingsContext.Provider value={defaultSettings}>
			{children}
		</SettingsContext.Provider>
	);
}

export const SettingsConsumer = SettingsContext.Consumer;
