import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { DotsThree as DotsThreeIcon } from "@phosphor-icons/react/dist/ssr/DotsThree";
import { Eye as EyeIcon } from "@phosphor-icons/react/dist/ssr/Eye";
import { Lock as LockIcon } from "@phosphor-icons/react/dist/ssr/Lock";
import { paths } from "config/paths";
import Link from "next/link";
import { useRouter } from "next/router";
import * as React from "react";
import { getMapThumbnailUrl } from "utils";

import { usePopover } from "~ui-components/hooks/use-popover";

import { ItemIcon } from "./item-icon";
import { ItemMenu } from "./item-menu";

export interface ItemRowProps {
	item: Record<string, any>;
	onDuplicate?: (itemId: string) => void;
	onDelete?: (itemId: string) => void;
	onLock?: (itemId: string) => void;
	onMapClick?: (mapId: string, mapName: string) => void;
}

export function ItemRow({
	item,
	onDuplicate,
	onDelete,
	onLock,
	// onMapClick,
}: ItemRowProps): React.JSX.Element {
	const popover = usePopover<HTMLButtonElement>();
	const router = useRouter();

	const handleDuplicate = React.useCallback(() => {
		popover.handleClose();
		onDuplicate?.(item.id);
	}, [item, popover, onDuplicate]);

	const handleDelete = React.useCallback(() => {
		popover.handleClose();
		onDelete?.(item.id);
	}, [item, popover, onDelete]);

	return (
		<React.Fragment>
			<TableRow
				key={item.id}
				sx={{
					bgcolor: "var(--mui-palette-background-paper)",
					borderRadius: 1.5,
					boxShadow: 0,
					transition: "box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
					"&:hover": { boxShadow: "var(--mui-shadows-16)" },
					"& .MuiTableCell-root": {
						borderBottom: "1px solid var(--mui-palette-divider)",
						borderTop: "1px solid var(--mui-palette-divider)",
						"&:first-of-type": {
							borderTopLeftRadius: "12px",
							borderBottomLeftRadius: "12px",
							borderLeft: "1px solid var(--mui-palette-divider)",
						},
						"&:last-of-type": {
							borderTopRightRadius: "12px",
							borderBottomRightRadius: "12px",
							borderRight: "1px solid var(--mui-palette-divider)",
						},
					},
					cursor: "pointer",
				}}>
				<TableCell>
					<Stack
						component={Link}
						href={paths.accountName.maps.mapId.mapName.root(
							router.query["account-name"],
							item.id,
							item.name,
						)}
						direction="row"
						spacing={2}
						sx={{
							alignItems: "center",
							color: "unset",
							textDecoration: "none",
						}}>
						<Box>
							<ItemIcon src={getMapThumbnailUrl(item.id, item.updatedAt)} />
						</Box>
						<Box sx={{ minWidth: 0 }}>
							<Typography
								noWrap
								variant="subtitle2">
								{item.name}
							</Typography>
							<Typography
								color="text.secondary"
								variant="caption"
								sx={{
									display: "-webkit-box",
									WebkitLineClamp: 2,
									WebkitBoxOrient: "vertical",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}>
								{item.description}
							</Typography>
						</Box>
					</Stack>
				</TableCell>
				<TableCell
					align="right"
					sx={{ maxWidth: "25px" }}>
					<IconButton
						onClick={() => {
							onLock?.(item.id);
						}}>
						{item?.isPrivate ? <LockIcon /> : <EyeIcon />}
					</IconButton>
				</TableCell>
				<TableCell
					align="right"
					sx={{ maxWidth: "25px" }}>
					<IconButton
						onClick={popover.handleOpen}
						ref={popover.anchorRef}>
						<DotsThreeIcon weight="bold" />
					</IconButton>
				</TableCell>
			</TableRow>
			<ItemMenu
				anchorEl={popover.anchorRef.current ?? undefined}
				onClose={popover.handleClose}
				onDuplicate={handleDuplicate}
				onDelete={handleDelete}
				open={popover.open}
			/>
		</React.Fragment>
	);
}
