import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";

import { ButtonCancel } from "~ui-components/components/atoms/button-cancel";

export const ToolsLegendSchema = yup.object({
	radioOption: yup
		.string()
		.notOneOf([""], "Required")
		.oneOf(["metric", "imperial"])
		.required("Required"),
});

export type ToolsLegendFormValues = yup.InferType<typeof ToolsLegendSchema>;

export interface ToolsLegendFormProps {
	form: FormikProps<ToolsLegendFormValues>;
}

export function ToolsLegendForm(
	props: ToolsLegendFormProps,
): React.JSX.Element {
	const { form } = props;
	return (
		<form onSubmit={form.handleSubmit}>
			<CardContent>
				<Stack spacing={4}>
					<Stack spacing={3}>
						<Typography variant="subtitle2">Legend type</Typography>
						<Grid
							container
							spacing={3}>
							<Grid xs={12}>
								<FormControl fullWidth>
									<Stack
										component={RadioGroup}
										spacing={3}
										defaultValue="standard"
										{...form.getFieldProps("radioOption")}>
										<FormControlLabel
											control={<Radio />}
											label="Standard: Allow users to show or hide any combination of map layer groups."
											value="standard"
										/>
										<FormControlLabel
											control={<Radio />}
											label="Single: Enable users to switch between single layer groups."
											value="single"
										/>
									</Stack>
								</FormControl>
							</Grid>
						</Grid>
					</Stack>
				</Stack>
			</CardContent>
			<CardActions sx={{ justifyContent: "flex-end" }}>
				<ButtonCancel />
				<Button
					type="submit"
					variant="contained"
					disabled={!form.isValid}>
					Save changes
				</Button>
			</CardActions>
		</form>
	);
}
