import * as React from "react";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import * as yup from "yup";
import { FormikProps } from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import Typography from "@mui/material/Typography";

import { ButtonCancel } from "~ui-components/components/atoms/button-cancel";
import { Option } from "~ui-components/components/atoms/option";

export const ToolsSearchSchema = yup.object({
	addressSearch: yup.boolean().required("Required"),
	useEnhancedSearch: yup.boolean().required("Required"),
	openPopupWindow: yup.boolean().required("Required"),
	prioritiseResults: yup.boolean().required("Required"),
	dataSearch: yup.boolean().required("Required"),
	indexedLayers: yup.array().required("Required"),
	coordinateSearch: yup.boolean().required("Required"),
	defaultSearch: yup
		.string()
		.oneOf(["address-search", "data-search", "coordinate-search"])
		.required("Required"),
});

export type ToolsSearchFormValues = yup.InferType<typeof ToolsSearchSchema>;

export interface ToolsSearchFormProps {
	form: FormikProps<ToolsSearchFormValues>;
}

export function ToolsSearchForm(
	props: ToolsSearchFormProps,
): React.JSX.Element {
	const { form } = props;

	return (
		<form onSubmit={form.handleSubmit}>
			<CardContent>
				<Stack spacing={4}>
					<Stack
						spacing={3}
						divider={<Divider />}>
						<Grid
							container
							spacing={3}>
							<Grid xs={12}>
								<FormControlLabel
									control={
										<Switch
											color="primary"
											defaultChecked
											{...form.getFieldProps("addressSearch")}
										/>
									}
									label="Address search"
								/>
							</Grid>
							<Grid xs={12}>
								<FormControl fullWidth>
									<Stack spacing={3}>
										<FormControlLabel
											control={
												<Checkbox
													size="medium"
													{...form.getFieldProps("useEnhancedSearch")}
												/>
											}
											label="Use enhanced search"
											value="use-enhanced-search"
										/>
										<FormControlLabel
											control={
												<Checkbox
													size="medium"
													{...form.getFieldProps("openPopupWindow")}
												/>
											}
											label="Open the popup window for any feature under the search result"
											value="open-popup-window"
										/>
										<FormControlLabel
											control={
												<Checkbox
													size="medium"
													{...form.getFieldProps("prioritiseResults")}
												/>
											}
											label="Prioritise results within the visible map bounds"
											value="prioritise-results"
										/>
									</Stack>
								</FormControl>
							</Grid>
						</Grid>
						<Grid
							container
							spacing={3}>
							<Grid xs={12}>
								<FormControlLabel
									control={
										<Switch
											color="primary"
											defaultChecked
											{...form.getFieldProps("dataSearch")}
										/>
									}
									label="Data search"
								/>
							</Grid>
							<Grid xs={12}>
								<FormControl fullWidth>
									<InputLabel>Indexed layers</InputLabel>
									<Card
										sx={{ borderRadius: 1, mt: 1 }}
										variant="outlined">
										<Stack divider={<Divider />}>
											{form.values.indexedLayers.map((layer, index) => {
												const handleAddClick = () => {
													form.setFieldValue(
														`indexedLayers.${index}.value`,
														true,
													);
												};

												const handleRemoveClick = () => {
													form.setFieldValue(
														`indexedLayers.${index}.value`,
														false,
													);
												};

												return (
													<Layer
														key={layer.id}
														label={layer.label}
														active={layer.value}
														onAddClick={handleAddClick}
														onRemoveClick={handleRemoveClick}
													/>
												);
											})}
										</Stack>
									</Card>
								</FormControl>
							</Grid>
						</Grid>
						<Grid
							container
							spacing={3}>
							<Grid xs={12}>
								<FormControlLabel
									control={
										<Switch
											color="primary"
											defaultChecked
											{...form.getFieldProps("coordinateSearch")}
										/>
									}
									label="Coordinate search"
								/>
							</Grid>
							<Grid
								xs={12}
								md={4}>
								<FormControl
									fullWidth
									error={!!form.errors.defaultSearch}>
									<InputLabel>Layer</InputLabel>
									<Select
										defaultValue="address-search"
										{...form.getFieldProps("defaultSearch")}>
										<Option value="address-search">Address search</Option>
										<Option value="data-search">Data search</Option>
										<Option value="coordinate-search">Coordinate search</Option>
									</Select>
									{form.errors.defaultSearch && (
										<FormHelperText>{form.errors.defaultSearch}</FormHelperText>
									)}
								</FormControl>
							</Grid>
						</Grid>
					</Stack>
				</Stack>
			</CardContent>
			<CardActions sx={{ justifyContent: "flex-end" }}>
				<ButtonCancel />
				<Button
					type="submit"
					variant="contained"
					disabled={!form.isValid}>
					Save changes
				</Button>
			</CardActions>
		</form>
	);
}

interface LayerProps {
	label: string;
	active?: boolean;
	onAddClick?: () => void;
	onRemoveClick?: () => void;
}

function Layer(props: LayerProps) {
	const { label, active, onAddClick, onRemoveClick } = props;
	return (
		<Stack
			direction="row"
			spacing={2}
			sx={{ alignItems: "center", px: 2, py: 1 }}>
			<Box sx={{ flex: "1 1 auto" }}>
				<Typography variant="subtitle2">{label}</Typography>
			</Box>
			<Stack
				direction="row"
				gap={1}>
				{!active ? (
					<Button
						color="secondary"
						startIcon={<PlusIcon />}
						size="small"
						variant="outlined"
						onClick={onAddClick}>
						Add
					</Button>
				) : (
					<Button
						color="error"
						size="small"
						variant="outlined"
						onClick={onRemoveClick}>
						Remove
					</Button>
				)}
			</Stack>
		</Stack>
	);
}
