import * as React from "react";

import {
	SideNav,
	SideNavProps,
} from "~ui-components/components/molecules/side-nav";

export const mapNavItemKeys = {
	details: "details",
	navigation: "navigation",
};

const navItems: SideNavProps["navItems"] = [
	{
		title: "Details",
		icon: "details-icon",
	},
	{
		title: "Menu",
		icon: "nav-icon",
	},
];

export function MapSettingsSideNav(): React.JSX.Element {
	return <SideNav navItems={navItems} />;
}
