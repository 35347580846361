import { Button, Alert, Stack } from "@mui/material";

import { MapToolDrawer } from "../map-tool-drawer";

export const StreetviewDrawer = ({ onClose, onLaunchSteetview }) => {
	return (
		<MapToolDrawer
			title="Streetview"
			onClose={onClose}
			Content={
				<Stack
					alignItems="center"
					spacing={2}
					p={2}>
					<Alert severity="info">
						{
							"Center your desired location under the cross hairs and click the launch streetview button"
						}
					</Alert>
					<Button
						variant="contained"
						onClick={onLaunchSteetview}>
						Launch Streetview
					</Button>
				</Stack>
			}
			Action={null}
		/>
	);
};
