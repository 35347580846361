import * as React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";

import { Option } from "~ui-components/components/atoms/option";

export function ThemeSelect(): React.JSX.Element {
	return (
		<FormControl fullWidth>
			<InputLabel>Theme</InputLabel>
			<Select>
				<Option value="default_v2">Default</Option>
				<Option value="blue_v2">Blue</Option>
				<Option value="darkBlue_v2">Dark Blue</Option>
				<Option value="Slate_v2">Slate</Option>
				<Option value="darkSlate_v2">Dark Slate</Option>
				<Option value="brown_v2">Brown</Option>
				<Option value="red_v2">Red</Option>
				<Option value="darkRed_v2">Dark Red</Option>
				<Option value="orange_v2">Orange</Option>
				<Option value="darkOrange_v2">Dark Orange</Option>
				<Option value="yellow_v2">Yellow</Option>
				<Option value="darkYellow_v2">Dark Yellow</Option>
				<Option value="green_v2">Green</Option>
				<Option value="DarkGreen_v2">Dark Green</Option>
				<Option value="teal_v2">Teal</Option>
				<Option value="darkTeal_v2">Teal Dark</Option>
			</Select>
			<FormHelperText>Highlight opacity</FormHelperText>
		</FormControl>
	);
}
