import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from "@mui/material";
import { FC } from "react";

interface ConfirmationModalProps {
	open: boolean;
	title?: string;
	content?: string;
	actionText?: string;
	onClose: () => void;
	onConfirm: () => void;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
	open,
	title,
	content,
	actionText,
	onClose,
	onConfirm,
}) => {
	return (
		<Dialog
			fullWidth
			maxWidth="sm"
			open={open}
			onClose={onClose}>
			<DialogTitle sx={{ p: 3 }}>{title}</DialogTitle>
			<DialogContent>
				<Typography variant="body2">{content}</Typography>
			</DialogContent>
			<DialogActions sx={{ p: 3 }}>
				<Button
					onClick={onClose}
					color="inherit"
					variant="text">
					Cancel
				</Button>
				<Button
					variant="contained"
					color="error"
					onClick={onConfirm}>
					{actionText}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
