import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import * as React from "react";

import type { Direction } from "~ui-components/theme/types";

import type { EmotionCache } from "@emotion/cache";

function styleCache(): EmotionCache {
	return createCache({
		key: "rtl",
		prepend: true,
	});
}

export interface RTLProps {
	children: React.ReactNode;
	direction?: Direction;
}

export function Rtl({
	children,
	direction = "ltr",
}: RTLProps): React.JSX.Element {
	React.useEffect(() => {
		document.dir = direction;
	}, [direction]);

	if (direction === "rtl") {
		return <CacheProvider value={styleCache()}>{children}</CacheProvider>;
	}

	return <React.Fragment>{children}</React.Fragment>;
}
