import _ from "lodash";

import { PREDEFINED_MARKERS } from "~ui-components/components/molecules/layer-style/marker-setting-drawer";

export const PointQuantity = (props) => {
	const { symbol, symbolStyle } = props;
	const markerValue = _.get(
		symbolStyle,
		"basic.symbol",
		symbolStyle.basic.symbol,
	);
	const { Icon, ...iconProps } = PREDEFINED_MARKERS[markerValue];

	return (
		<Icon
			fontSize={symbolStyle?.basic?.size}
			{...iconProps}
			color={symbol?.fill?.color}
			style={{
				transform: `rotate(${symbolStyle?.basic?.rotation}deg)`,
				opacity: symbolStyle?.fill?.opacity || 1,
			}}
		/>
	);
};
