import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import { BracketsCurly as AttributeIcon } from "@phosphor-icons/react/dist/ssr/BracketsCurly";
import { useState } from "react";

interface Props {
	attributes?: string[];
	onChange?: (value: string) => void;
}

export function AttributeInput(props: Props) {
	const { attributes, onChange } = props;
	const [value, setValue] = useState<string>("");
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (option: string) => {
		setValue(`{${option}}`);
		onChange?.(`{${option}}`);
		setAnchorEl(null);
	};

	const handleChange = (e) => {
		onChange?.(e.target.value);
		setValue(e.target.value);
	};

	return (
		<div>
			<FormControl fullWidth>
				<OutlinedInput
					value={value}
					placeholder="Please enter text"
					onChange={handleChange}
					endAdornment={
						<IconButton
							sx={{ width: 100 }}
							onClick={handleClick}>
							<Box
								display="flex"
								alignItems="center">
								<AttributeIcon />
								<Typography
									variant="body2"
									ml={1}>
									Attribute
								</Typography>
							</Box>
						</IconButton>
					}
				/>
			</FormControl>
			<Menu
				disablePortal
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}>
				{attributes?.map((option) => (
					<MenuItem
						key={option}
						onClick={() => handleClose(option)}>
						{option}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}
