import Card from "@mui/material/Card";
import { FC } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import { Lock as LockIcon } from "@phosphor-icons/react/dist/ssr";
import Box from "@mui/material/Box";

interface SecureTrialCheckoutProps {
	total: string;
	disableSubmit?: boolean;
}

export const SecureTrialCheckout: FC<SecureTrialCheckoutProps> = ({
	total,
	disableSubmit,
}) => {
	return (
		<Card
			sx={{
				bgcolor: "background.level2",
			}}>
			<CardContent>
				<Stack spacing={2}>
					<Button
						fullWidth
						variant="contained"
						type="submit"
						disabled={disableSubmit}>
						Pay {total} and Subscribe
					</Button>
					<Stack
						padding={2}
						spacing={2}>
						<Stack
							direction="row"
							spacing={2}
							sx={{ alignItems: "center" }}>
							<Box
								sx={{
									alignItems: "center",
									color: "var(--mui-palette-success-main)",
									display: "flex",
									justifyContent: "center",
								}}>
								<LockIcon />
							</Box>
							<Typography variant="subtitle2">Secure checkout</Typography>
						</Stack>
						<Stack spacing={2}>
							<Typography
								variant="body2"
								color={"text.secondary"}>
								Your purchases are secured and protected by best encryption
								service - Recurly
							</Typography>
							<Typography
								variant="body2"
								color={"text.secondary"}>
								By providing your card information, you allow Mangomap to charge
								your card for future payments in accordance with the Terms of
								Service.
							</Typography>
							<Typography
								variant="body2"
								color={"text.secondary"}>
								By subscribing you agree to our <a href="#">Terms of Service</a>{" "}
								and <a href="#">Privacy Policy</a>.
							</Typography>
						</Stack>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	);
};
