import {
	Checkbox,
	FormControlLabel,
	FormHelperText,
	Link,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import { paths } from "config/paths";
import { FormikProps } from "formik";
import RouterLink from "next/link";
import { FC } from "react";
import * as Yup from "yup";
import { MangoLogo } from "~ui-components/components/atoms/mango-logo";
import { CenteredLayout } from "~ui-components/components/organisms/centered-layout";
import { PasswordSchema } from "../sign-up-v2";
import { useRouter } from "next/router";

export const UserJoinSchema = Yup.object({
	name: Yup.string().required("Required"),
	password: PasswordSchema,
	retypedPassword: Yup.string().required("Required"),
	acceptedToTermsAndPolicy: Yup.boolean().required().oneOf([true]),
});

export type UserJoinFormValues = Yup.InferType<typeof UserJoinSchema>;

export interface UserJoinProps {
	form: FormikProps<UserJoinFormValues>;
}

export const UserJoin: FC<UserJoinProps> = ({ form }) => {
	const router = useRouter();

	return (
		<CenteredLayout>
			<Stack spacing={4}>
				<div>
					<Box
						component={RouterLink}
						href={paths.home}
						sx={{ display: "inline-block", fontSize: 0 }}>
						<MangoLogo
							width={122}
							height={32}
						/>
					</Box>
				</div>
				<Card>
					<CardHeader
						title={
							<>
								Join&nbsp;
								<Box
									component="span"
									color="primary.main">
									{router.query["account-name"]}
								</Box>
							</>
						}
					/>
					<CardContent>
						<Stack
							spacing={2}
							component="form"
							onSubmit={form.handleSubmit}>
							<FormControl>
								<InputLabel>Your name</InputLabel>
								<OutlinedInput
									autoFocus
									{...form.getFieldProps("name")}
									error={!!form.touched.name && !!form.errors.name}
								/>
								{!!form.touched.name && !!form.errors.name && (
									<FormHelperText error>{form.errors.name}</FormHelperText>
								)}
							</FormControl>
							<FormControl>
								<InputLabel>Set a password</InputLabel>
								<OutlinedInput
									type="password"
									{...form.getFieldProps("password")}
									error={!!form.touched.password && !!form.errors.password}
								/>
								{!!form.touched.password && !!form.errors.password && (
									<FormHelperText error>{form.errors.password}</FormHelperText>
								)}
							</FormControl>
							<FormControl>
								<InputLabel>Re-type password</InputLabel>
								<OutlinedInput
									type="password"
									{...form.getFieldProps("retypedPassword")}
									error={
										!!form.touched.retypedPassword &&
										!!form.errors.retypedPassword
									}
								/>
								{!!form.touched.retypedPassword &&
									!!form.errors.retypedPassword && (
										<FormHelperText error>
											{form.errors.retypedPassword}
										</FormHelperText>
									)}
							</FormControl>
							<div>
								<FormControlLabel
									control={
										<Checkbox
											{...form.getFieldProps("acceptedToTermsAndPolicy")}
										/>
									}
									label={
										<>
											By clicking the &ldquo;Join&ldquo; button you agree to
											our&nbsp;
											<Link href={paths.terms}>Terms</Link> and&nbsp;
											<Link href={paths.privacy}>Privacy policy</Link>
										</>
									}
								/>
							</div>
							<Button
								type="submit"
								variant="contained"
								disabled={form.isSubmitting || !form.isValid}>
								Join
							</Button>
						</Stack>
					</CardContent>
				</Card>
			</Stack>
		</CenteredLayout>
	);
};
