import {
	Avatar,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	OutlinedInput,
	Stack,
	Typography,
} from "@mui/material";
import { Palette as PaletteIcon } from "@phosphor-icons/react/dist/ssr/Palette";
import { useFormik } from "formik";

import { AvatarUploadInput } from "~ui-components/components/atoms/avatar-upload-input";
import { OptionsThemeColor } from "~ui-components/components/atoms/settings";
import type { PrimaryColor } from "~ui-components/theme/types";

import { BrandLayout as Layout } from "./layout";

export interface BrandInput {
	avatar: string;
	themeColor: PrimaryColor;
}

interface BrandProps {
	form: ReturnType<typeof useFormik<BrandInput>>;
}
export const Brand = (props: BrandProps) => {
	const { form } = props;
	const { values, setFieldValue, handleSubmit } = form;
	return (
		<Layout>
			<form onSubmit={handleSubmit}>
				<Card>
					<CardHeader
						avatar={
							<Avatar>
								<PaletteIcon fontSize="var(--Icon-fontSize)" />
							</Avatar>
						}
						title="Let your brand speak directly to your users by customizing the look and feel of your maps and portals."
					/>
					<CardContent>
						<Stack
							divider={<Divider />}
							spacing={4}>
							<Stack spacing={3}>
								<Grid
									container
									gap={3}>
									<Grid xs={12}>
										<AvatarUploadInput
											avatar={values.avatar}
											setFieldValue={setFieldValue}
										/>
									</Grid>
									<Grid xs={12}>
										<Typography
											variant="subtitle2"
											color="text.secondary">
											THEME
										</Typography>
									</Grid>
									<Grid
										md={6}
										xs={12}>
										<OptionsThemeColor
											value={values.themeColor}
											onChange={(color) => setFieldValue("themeColor", color)}
										/>
									</Grid>
									<Grid
										md={6}
										xs={12}>
										<FormControl>
											<InputLabel>Primary colour</InputLabel>
											<OutlinedInput name="portal-name" />
										</FormControl>
									</Grid>
									<Grid
										md={6}
										xs={12}>
										<FormControl>
											<InputLabel>Accent colour</InputLabel>
											<OutlinedInput name="portal-name" />
										</FormControl>
									</Grid>
									<Grid xs={12}>
										<Typography
											variant="subtitle2"
											color="text.secondary">
											MAP COLOURS
										</Typography>
									</Grid>
									<Grid
										md={6}
										xs={12}>
										<FormControl>
											<InputLabel>Map feature highlight</InputLabel>
											<OutlinedInput name="portal-name" />
										</FormControl>
									</Grid>
									<Grid
										md={6}
										xs={12}>
										<FormControl>
											<InputLabel>Feature highlight opacity</InputLabel>
											<OutlinedInput name="portal-name" />
										</FormControl>
									</Grid>
								</Grid>
							</Stack>
						</Stack>
					</CardContent>
					<CardActions sx={{ justifyContent: "flex-end" }}>
						<Button
							color="secondary"
							type="reset">
							Cancel
						</Button>
						<Button
							variant="contained"
							type="submit">
							Save changes
						</Button>
					</CardActions>
				</Card>
			</form>
		</Layout>
	);
};
