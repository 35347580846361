import React from "react";

import { SymbolStyle } from "~ui-components/types/__generated/gql/graphql";

interface Props {
	symbol: SymbolStyle;
}

export const SvgLineComponent: React.FC<Props> = ({ symbol }) => {
	return (
		<svg
			id="SvgjsSvg1015"
			width="30"
			height="20"
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			transform="matrix(0.766044443118978,-0.6427876096865393,0.6427876096865393,0.766044443118978,0,0)">
			<defs id="SvgjsDefs1016"></defs>
			<line
				id="SvgjsLine1017"
				x1="4"
				y1="10"
				x2="26"
				y2="10"
				strokeOpacity="1"
				stroke="#ffffff"
				strokeWidth={(symbol.fill?.width ?? 5) * 2}
				strokeLinecap="round"></line>
			<line
				id="SvgjsLine1018"
				x1="4"
				y1="10"
				x2="26"
				y2="10"
				strokeOpacity={symbol.stroke?.opacity ?? 1}
				stroke={symbol.fill?.color ?? "#000000"}
				strokeWidth={symbol.stroke?.width ?? 5}
				strokeLinecap="round"></line>
		</svg>
	);
};
