import { Chip, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";
import { ButtonCancel } from "~ui-components/components/atoms/button-cancel";
import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import { DataTable } from "~ui-components/components/atoms/data-table";
import {
	ACCOUNT_USER_ROLE,
	UserGroup,
} from "~ui-components/types/__generated/gql/graphql";

export const UserCreateSchema = yup.object({
	email: yup.string().email().required("Required"),
	role: yup
		.string()
		.oneOf(Object.values(ACCOUNT_USER_ROLE))
		.required("Required"),
	groupIds: yup.array().of(yup.string().required()).required("Required"),
});

export type UserCreateFormValues = yup.InferType<typeof UserCreateSchema>;

interface UserCreateProps {
	form: FormikProps<UserCreateFormValues>;
	isGroupsLoading: boolean;
	groups: UserGroup[];
}

export function UserCreate(props: UserCreateProps): React.JSX.Element {
	const { form, groups, isGroupsLoading } = props;

	return (
		<DashboardContentLayout pageTitle="Invite user">
			<form onSubmit={form.handleSubmit}>
				<Card>
					<CardContent>
						<Stack spacing={4}>
							<Stack spacing={3}>
								<Typography variant="h6">User information</Typography>
								<Grid
									container
									spacing={3}>
									<Grid
										xs={12}
										md={6}
										lg={4}>
										<FormControl fullWidth>
											<InputLabel>Email address</InputLabel>
											<OutlinedInput
												autoFocus
												{...form.getFieldProps("email")}
												error={!!form.touched.email && !!form.errors.email}
											/>
											{!!form.touched.email && !!form.errors.email && (
												<FormHelperText error>
													{form.errors.email}
												</FormHelperText>
											)}
										</FormControl>
									</Grid>
									<Grid xs={12}>
										<FormControl fullWidth>
											<Stack
												direction="row"
												component={RadioGroup}
												spacing={3}
												{...form.getFieldProps("role")}>
												<FormControlLabel
													control={<Radio />}
													label="Admin"
													value={ACCOUNT_USER_ROLE.ADMIN}
												/>
												<FormControlLabel
													control={<Radio size="medium" />}
													label="Editor"
													value={ACCOUNT_USER_ROLE.EDITOR}
												/>
												<Box
													display="flex"
													alignItems="center"
													gap={1}>
													<FormControlLabel
														control={<Radio size="medium" />}
														label="Viewer"
														value={ACCOUNT_USER_ROLE.VIEWER}
													/>
													<Chip
														label="Free"
														color="secondary"
														size="small"
													/>
												</Box>
											</Stack>
										</FormControl>
									</Grid>
								</Grid>
							</Stack>
							<Stack spacing={3}>
								<Box>
									<Typography variant="h6">Groups</Typography>
									<Typography variant="caption">
										Organize users into groups to control who can view, edit, or
										modify each map and dataset.
									</Typography>
								</Box>
								<Grid
									container
									spacing={3}>
									<Grid xs={12}>
										{isGroupsLoading ? (
											<CircularProgress />
										) : (
											<Card>
												<DataTable
													selectable
													uniqueRowId={(row) => row.id}
													selected={new Set(form.values.groupIds)}
													columns={[
														{
															name: "Select all",
															formatter: (row): React.JSX.Element => (
																<div>
																	<Typography
																		color="text.primary"
																		sx={{ whiteSpace: "nowrap" }}
																		variant="subtitle2">
																		{row.name}
																	</Typography>
																</div>
															),
														},
													]}
													rows={groups}
													onDeselectAll={() => {
														form.setFieldValue("groupIds", []);
													}}
													onDeselectOne={(_, row) => {
														form.setFieldValue(
															"groupIds",
															form.values.groupIds.filter(
																(id) => id !== row.id,
															),
														);
													}}
													onSelectAll={() => {
														form.setFieldValue(
															"groupIds",
															groups.map((group) => group.id),
														);
													}}
													onSelectOne={(_, row) => {
														form.setFieldValue(
															"groupIds",
															Array.from(
																new Set([...form.values.groupIds, row.id]),
															),
														);
													}}
												/>
												{!groups.length ? (
													<Box sx={{ p: 3 }}>
														<Typography
															color="text.secondary"
															sx={{ textAlign: "center" }}
															variant="body2">
															No groups found
														</Typography>
													</Box>
												) : null}
											</Card>
										)}
									</Grid>
								</Grid>
							</Stack>
						</Stack>
					</CardContent>
					<CardActions sx={{ justifyContent: "flex-end" }}>
						<ButtonCancel />
						<Button
							type="submit"
							variant="contained"
							disabled={!form.isValid || form.isSubmitting}>
							Invite user
						</Button>
					</CardActions>
				</Card>
			</form>
		</DashboardContentLayout>
	);
}
