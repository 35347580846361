import { StackProps } from "@mui/material";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";

import { Option } from "~ui-components/components/atoms/option";
import { FillStrokeStyle } from "~ui-components/components/molecules/simple-style/fill-stroke";
import { PointCategoryConfigs } from "~ui-components/types/__generated/gql/graphql";

interface Slots {
	MarkerSettingsDrawer: any;
}

interface SlotProps {
	MarkerSettingsDrawer: any;
}

const Empty = () => null;

const defaultSlots: Slots = {
	MarkerSettingsDrawer: Empty,
};

type Props = StackProps & {
	form: ReturnType<typeof useFormik<PointCategoryConfigs>>;
	visualisation?: string;
	attributes?: {
		name: string;
		type: string;
	}[];
	slots?: Slots;
	slotProps?: SlotProps;
	ramps: {
		key: string;
		name: string;
		colors: string[];
	}[];
	onMarkerChange: (value: string) => void;
	onColorRampChange: (colorRamp: string) => void;
	onOpenCustomLegend: () => void;
	onFieldChange: (field: string) => void;
	onOpenMarkerDrawer: () => void;
	onMarkerSettingClose: () => void;
};

export const CategoryStyle = (props: Props) => {
	const {
		slots = {},
		slotProps = {},
		visualisation,
		onMarkerSettingClose,
		onOpenCustomLegend,
		onColorRampChange,
		onMarkerChange,
		attributes,
		ramps = [],
		onFieldChange,
		form,
	} = props;
	const { values, handleChange, handleSubmit } = form;

	const Slots = {
		...defaultSlots,
		...slots,
	};

	if (visualisation === "category") {
		return (
			<>
				<Stack spacing={3}>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Colour by</InputLabel>
						<Select
							name="valueField"
							onChange={(e) => {
								onFieldChange(e.target.value ?? "");
								handleChange(e);
							}}
							value={values.valueField}>
							{attributes?.map((value) => (
								<Option
									key={value.name}
									value={value.name}>
									{value.name}
								</Option>
							))}
						</Select>
					</FormControl>

					<FormControl sx={{ width: 200 }}>
						<InputLabel>Color Ramp</InputLabel>
						<Select
							name="colorRamp"
							onChange={(e) => {
								onColorRampChange(e.target.value ?? "");
								handleChange(e);
							}}
							value={values.colorRamp}>
							{ramps?.map((value, key) => (
								<Option
									key={key}
									value={value.key}
									sx={{ width: 250, pr: 2, overflow: "hidden" }}>
									<Stack direction="row">
										<svg
											width={`${value.colors.length * 22}`}
											height="22"
											viewBox={`0 0 ${value.colors.length * 22} 22`}
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											{value?.colors?.map((color, key) => (
												<rect
													key={key}
													x={key * 22}
													width="22"
													height="22"
													fill={color}
												/>
											))}
										</svg>
									</Stack>
								</Option>
							))}
						</Select>
					</FormControl>

					<FillStrokeStyle {...props} />

					<Button
						sx={{ width: 200 }}
						variant="outlined"
						onClick={() => onOpenCustomLegend()}>
						Customise legend
					</Button>
				</Stack>
				<CardActions sx={{ justifyContent: "flex-end", p: 0, mt: 2 }}>
					<Button color="secondary">Cancel</Button>
					<Button
						variant="contained"
						onClick={() => handleSubmit()}>
						Save changes
					</Button>
				</CardActions>
				<Slots.MarkerSettingsDrawer
					onClose={onMarkerSettingClose}
					onChange={(value) => {
						onMarkerChange(value);
						onMarkerSettingClose();
						void form.setFieldValue("symbolStyle.basic.symbol", value);
					}}
					open={
						(slotProps as { MarkerSettingsDrawer: { open: boolean } })
							.MarkerSettingsDrawer?.open
					}
				/>
			</>
		);
	}
	return null;
};
