import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControlLabel,
	Stack,
	Tab,
	Tabs,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import { BubbleMenu, Editor } from "@tiptap/react";
import { useFormik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import { MuiColorInput } from "mui-color-input";

import { useDialog } from "~ui-components/hooks/use-dialog";

export const ChartMenu: React.FC<
	Pick<React.ComponentProps<typeof ChartDialog>, "attributes"> & {
		editor: Editor;
	}
> = ({ editor, ...props }) => {
	const chartDialog = useDialog<ChartFormValues>();

	return (
		<>
			{chartDialog.open && (
				<ChartDialog
					editor={editor}
					onClose={chartDialog.handleClose}
					initialValues={chartDialog.data}
					{...props}
				/>
			)}
			<BubbleMenu
				editor={editor}
				pluginKey="ChartMenu"
				tippyOptions={{ zIndex: 1 }}
				shouldShow={({ editor }) =>
					editor.isActive("image", {
						"data-value": new RegExp(`"type":"${CHART_DATE_VALUE_TYPE}"`),
					})
				}>
				<ToggleButtonGroup
					exclusive
					sx={{ bgcolor: (t) => t.palette.background.paper }}>
					<ToggleButton value="edit">
						<Typography
							variant="caption"
							onClick={() => {
								const chartNode = editor.getAttributes("image");
								const dataValue = chartNode["data-value"]
									? JSON.parse(chartNode["data-value"])
									: {};
								chartDialog.handleOpen(dataValue as ChartFormValues);
							}}>
							Edit
						</Typography>
					</ToggleButton>
					<Divider
						flexItem
						orientation="vertical"
						sx={{ my: 1 }}
					/>
					<ToggleButton
						value="remove-chart"
						onClick={() => editor.chain().focus().deleteSelection().run()}>
						<Typography variant="caption">Remove chart</Typography>
					</ToggleButton>
				</ToggleButtonGroup>
			</BubbleMenu>
		</>
	);
};

export enum CHART_TYPE {
	BAR = "BAR",
	PIE = "PIE",
}

const chartValidationSchema = Yup.object({
	chartType: Yup.string().oneOf(Object.values(CHART_TYPE)).required(),
	startColour: Yup.string().when("chartType", {
		is: CHART_TYPE.PIE,
		then: (schema) => schema.required(),
		otherwise: (schema) => schema.notRequired(),
	}),
	endColour: Yup.string().when("chartType", {
		is: CHART_TYPE.PIE,
		then: (schema) => schema.required(),
		otherwise: (schema) => schema.notRequired(),
	}),
	attributes: Yup.array()
		.of(
			Yup.object({
				key: Yup.string().required(),
				label: Yup.string().required(),
			}).required(),
		)
		.required(),
}).required();

export type ChartFormValues = Yup.InferType<typeof chartValidationSchema>;

export const CHART_DATE_VALUE_TYPE = "chart";

export const ChartDialog: React.FC<{
	editor: Editor;
	initialValues?: ChartFormValues;
	onClose: () => void;
	attributes?: string[];
}> = ({ editor, initialValues, onClose, attributes }) => {
	const chartForm = useFormik<ChartFormValues>({
		enableReinitialize: true,
		initialValues: initialValues ?? {
			chartType: CHART_TYPE.BAR,
			attributes: [],
			startColour: undefined,
			endColour: undefined,
		},
		validationSchema: chartValidationSchema,
		onSubmit: (values) => {
			editor
				.chain()
				.focus()
				.setImage({
					src: `https://placehold.co/1280x720?text=${values.chartType.toLowerCase()}+chart+placeholder`,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					"data-value": JSON.stringify({
						...values,
						type: CHART_DATE_VALUE_TYPE,
					}),
				})
				.run();
			onClose();
		},
	});

	return (
		<Dialog
			open
			fullWidth
			maxWidth="xs"
			onClose={onClose}>
			<form onSubmit={chartForm.handleSubmit}>
				<DialogTitle>Insert chart</DialogTitle>
				<DialogContent>
					<Stack spacing={4}>
						<Tabs
							value={chartForm.values.chartType}
							onChange={(_, newValue) =>
								chartForm.setFieldValue("chartType", newValue)
							}>
							<Tab
								label="Bar chart"
								value="bar"
							/>
							<Tab
								label="Pie chart"
								value="pie"
							/>
						</Tabs>
						{chartForm.values.chartType === CHART_TYPE.PIE && (
							<>
								<MuiColorInput
									fullWidth
									label="Start colour"
									format="hex"
									value={chartForm.values.startColour ?? ""}
									onChange={(value) =>
										chartForm.setFieldValue("startColour", value)
									}
								/>
								<MuiColorInput
									fullWidth
									label="End colour"
									format="hex"
									value={chartForm.values.endColour ?? ""}
									onChange={(value) =>
										chartForm.setFieldValue("endColour", value)
									}
								/>
							</>
						)}
						{!!attributes?.length &&
							attributes.map((property) => (
								<FormControlLabel
									key={property}
									control={
										<Checkbox
											checked={chartForm.values.attributes.some(
												(p) => p.key === property,
											)}
											onChange={(_, checked) => {
												if (checked) {
													void chartForm.setFieldValue("attributes", [
														...chartForm.values.attributes,
														{ key: property, label: "" },
													]);
													document
														.getElementById(`label-input-${property}`)
														?.focus();
												} else {
													void chartForm.setFieldValue(
														"attributes",
														chartForm.values.attributes.filter(
															(p) => p.key !== property,
														),
													);
												}
											}}
										/>
									}
									label={
										<Stack
											direction="row"
											spacing={2}
											alignItems="center">
											<Typography variant="body2">{property}</Typography>
											<TextField
												id={`label-input-${property}`}
												value={
													chartForm.values.attributes.find(
														(p) => p.key === property,
													)?.label ?? ""
												}
												onChange={(e) => {
													const value = e.target.value;
													const index = chartForm.values.attributes.findIndex(
														(p) => p.key === property,
													);
													if (index === -1) return;

													void chartForm.setFieldValue(
														`attributes.${index}.label`,
														value,
													);
												}}
											/>
										</Stack>
									}
								/>
							))}
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={onClose}
						color="inherit">
						Cancel
					</Button>
					<Button
						variant="contained"
						color="error"
						type="submit"
						disabled={!chartForm.isValid}>
						Save
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
