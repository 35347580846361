import {
	Avatar,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	FormControl,
	InputLabel,
	Stack,
	TextField,
} from "@mui/material";
import { Password } from "@phosphor-icons/react/dist/ssr/Password";
import { useFormik } from "formik";
import { FC } from "react";
import * as Yup from "yup";

import { PasswordSchema } from "~ui-components/components/templates/sign-up-v2";

export const ChangePasswordSchema = Yup.object({
	oldPassword: Yup.string().required("Required"),
	newPassword: PasswordSchema,
	retypedNewPassword: Yup.string().required("Required"),
});

export type ChangePasswordFormValues = Yup.InferType<
	typeof ChangePasswordSchema
>;

export interface ChangePasswordProps {
	form: ReturnType<typeof useFormik<ChangePasswordFormValues>>;
}

export const ChangePassword: FC<ChangePasswordProps> = ({ form }) => {
	return (
		<Card
			component="form"
			onSubmit={form.handleSubmit}>
			<CardHeader
				avatar={
					<Avatar>
						<Password fontSize="var(--Icon-fontSize)" />
					</Avatar>
				}
				title="Change password"
			/>
			<CardContent>
				<Stack spacing={3}>
					<FormControl>
						<InputLabel>Old password</InputLabel>
						<TextField
							type="password"
							{...form.getFieldProps("oldPassword")}
							error={!!form.errors.oldPassword}
							helperText={form.errors.oldPassword}
						/>
					</FormControl>
					<FormControl>
						<InputLabel>New password</InputLabel>
						<TextField
							type="password"
							{...form.getFieldProps("newPassword")}
							error={!!form.errors.newPassword}
							helperText={form.errors.newPassword}
						/>
					</FormControl>
					<FormControl>
						<InputLabel>Re-type new password</InputLabel>
						<TextField
							type="password"
							{...form.getFieldProps("retypedNewPassword")}
							error={!!form.errors.retypedNewPassword}
							helperText={form.errors.retypedNewPassword}
						/>
					</FormControl>
				</Stack>
			</CardContent>
			<CardActions sx={{ justifyContent: "flex-end" }}>
				<Button
					variant="contained"
					type="submit"
					disabled={form.isSubmitting || !form.isValid}>
					Update password
				</Button>
			</CardActions>
		</Card>
	);
};
