import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import { TrialCheckoutSubscriptionSummary } from "~ui-components/components/organisms/trial-checkout/checkout-subscription-summary";
import { SecureTrialCheckout } from "~ui-components/components/organisms/trial-checkout/secure-checkout";
import { AccountBillingForm } from "~ui-components/components/organisms/user-accounts/billing-form";

export const TrialCheckout = ({ form }) => {
	return (
		<DashboardContentLayout pageTitle="Checkout">
			<Stack
				spacing={4}
				component={"form"}
				onSubmit={form.handleSubmit}>
				<Grid
					container
					spacing={4}>
					<Grid
						md={8}
						xs={12}>
						<AccountBillingForm form={form} />
					</Grid>
					<Grid
						md={4}
						xs={12}>
						<Stack spacing={4}>
							<TrialCheckoutSubscriptionSummary />
							<SecureTrialCheckout
								total="$4,050.00"
								disableSubmit={form.isSubmitting || !form.isValid}
							/>
						</Stack>
					</Grid>
				</Grid>
			</Stack>
		</DashboardContentLayout>
	);
};
