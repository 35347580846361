import {
	Avatar,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Checkbox,
	Chip,
	Divider,
	Link,
	Stack,
	Typography,
} from "@mui/material";
import { FileCloud } from "@phosphor-icons/react/dist/ssr/FileCloud";
import { FC, Fragment } from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

export interface StyleData {
	name: string;
	title?: string;
	legendUrl?: string;
}

export interface LayerData {
	id: string; // we generate this
	name: string;
	title?: string;
	abstract?: string;
	styles: StyleData[];
	onlineResource?: string;
	bbox: [number, number, number, number];
}

export interface ServiceData {
	name: string;
	title: string;
	abstract?: string;
	keywords?: string[];
	contact?: {
		person?: string;
		organization?: string;
		position?: string;
		address?: {
			type?: string;
			street?: string;
			city?: string;
			state?: string;
			postCode?: string;
			country?: string;
		};
		phone?: string;
		fax?: string;
		email?: string;
	};
	fees?: string;
	accessConstraints?: string;
}

export interface WmsData {
	info: ServiceData;
	layers: LayerData[];
}

export interface WmsSummaryProps {
	wmsData: WmsData;
	selectedLayers: LayerData[];
	onImportLayers: () => void;
	onSelectLayer: (layer: LayerData) => void;
	onBack: () => void;
}

export const WmsSummary: FC<WmsSummaryProps> = ({
	wmsData,
	selectedLayers,
	onImportLayers,
	onSelectLayer,
	onBack,
}) => {
	const { info, layers } = wmsData;

	return (
		<DashboardContentLayout disableBackButton>
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<FileCloud fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title={info.title}
				/>

				<CardContent>
					<Stack
						direction={{ xs: "column", md: "row" }}
						spacing={3}
						alignItems="flex-start">
						<Card sx={{ flex: 1 }}>
							<CardContent>
								<Typography
									variant="body2"
									fontWeight={600}
									gutterBottom>
									Description
								</Typography>
								{!!info.abstract && (
									<Typography
										variant="body2"
										color="text.secondary">
										{info.abstract}
									</Typography>
								)}

								{!!info.contact && (
									<Fragment>
										<Divider sx={{ my: 3, mx: -3 }} />
										<Typography
											variant="body2"
											fontWeight={600}
											gutterBottom>
											Contact information
										</Typography>
										<Typography
											variant="body2"
											color="text.secondary">
											{[info.contact.person, info.contact.organization]
												.filter(Boolean)
												.join(" - ")}
										</Typography>

										{!!info.contact.email && (
											<Link
												href={`mailto:${info.contact.email}`}
												variant="body2">
												{info.contact.email}
											</Link>
										)}
										<br />
										{!!info.contact.phone && (
											<Link
												href={`tel:${info.contact.phone}`}
												variant="body2">
												{info.contact.phone}
											</Link>
										)}
									</Fragment>
								)}
								{!!info.keywords?.length && (
									<Fragment>
										<Divider sx={{ my: 3, mx: -3 }} />
										<Typography
											variant="body2"
											fontWeight={600}
											gutterBottom>
											Keywords
										</Typography>
										{info.keywords.map((keyword) => (
											<Chip
												variant="soft"
												key={keyword}
												label={keyword}
											/>
										))}
									</Fragment>
								)}
								{!!info.fees && (
									<Fragment>
										<Divider sx={{ my: 3, mx: -3 }} />
										<Typography
											variant="body2"
											fontWeight={600}
											gutterBottom>
											Fees
										</Typography>
										<Typography
											variant="body2"
											color="text.secondary">
											{info.fees ?? "none"}
										</Typography>
									</Fragment>
								)}
								{!!info.accessConstraints && (
									<Fragment>
										<Divider sx={{ my: 3, mx: -3 }} />
										<Typography
											variant="body2"
											fontWeight={600}
											gutterBottom>
											Access constraints
										</Typography>
										<Typography
											variant="body2"
											color="text.secondary"
											sx={{ wordBreak: "break-word" }}>
											{info.accessConstraints}
										</Typography>
									</Fragment>
								)}
							</CardContent>
						</Card>

						<Card
							sx={{
								flex: 3,
								width: { xs: "-webkit-fill-available", md: "unset" },
							}}>
							<CardContent>
								<Typography
									variant="body2"
									fontWeight={600}
									gutterBottom>
									Select layers to import
								</Typography>
							</CardContent>
							<Stack
								p={3}
								spacing={3}
								bgcolor={(t) => t.palette.background.level1}>
								{layers.map((layer) => (
									<Card key={layer.id}>
										<CardContent>
											<Stack
												direction={{ xs: "column", md: "row" }}
												spacing={3}>
												<div>
													<Checkbox
														checked={selectedLayers.some(
															(l) => l.id === layer.id,
														)}
														onChange={() => onSelectLayer(layer)}
													/>
												</div>
												<Stack
													spacing={3}
													flex={1}>
													<Typography variant="h6">{layer.name}</Typography>
													<Typography
														variant="body2"
														color="text.secondary">
														{layer.abstract}
													</Typography>
												</Stack>
												<Box width="200px">
													{layer.styles[0]?.legendUrl ? (
														<Box
															component="img"
															src={layer.styles[0].legendUrl}
															alt={layer.name}
															sx={{ maxWidth: "200px", height: "auto" }}
														/>
													) : (
														<Typography
															variant="body2"
															color="text.secondary">
															No legend available
														</Typography>
													)}
												</Box>
											</Stack>
										</CardContent>
									</Card>
								))}
							</Stack>
						</Card>
					</Stack>
				</CardContent>

				<CardActions sx={{ justifyContent: "flex-end" }}>
					<Button
						color="secondary"
						onClick={onBack}>
						Cancel
					</Button>
					<Button
						variant="contained"
						disabled={false}
						onClick={onImportLayers}>
						Import layers
					</Button>
				</CardActions>
			</Card>
		</DashboardContentLayout>
	);
};
