import { StackProps } from "@mui/material";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import { useFormik } from "formik";

import { PointSimpleConfigs } from "~ui-components/types/__generated/gql/graphql";

import { FillStrokeStyle } from "./fill-stroke";

interface Slots {
	MarkerSettingsDrawer: any;
}

interface SlotProps {
	MarkerSettingsDrawer: any;
}

type Props = StackProps & {
	form: ReturnType<typeof useFormik<PointSimpleConfigs>>;
	visualisation?: string;
	slots?: Slots;
	slotProps?: SlotProps;
	onOpenMarkerDrawer: () => void;
	onMarkerChange: () => void;
	onMarkerSettingClose: () => void;
};

export const SimpleStyle = (props: Props) => {
	const { visualisation, form } = props;
	const { handleSubmit } = form;

	if (visualisation === "simple") {
		return (
			<form onSubmit={handleSubmit}>
				<FillStrokeStyle {...props} />
				<CardActions sx={{ justifyContent: "flex-end", p: 0, mt: 2 }}>
					<Button
						color="secondary"
						type="reset">
						Cancel
					</Button>
					<Button
						variant="contained"
						type="submit">
						Save changes
					</Button>
				</CardActions>
			</form>
		);
	}
	return null;
};
