export const RasterBar = () => (
	<svg
		width="360"
		height="20"
		xmlns="http://www.w3.org/2000/svg">
		<defs>
			<linearGradient
				id="gradient"
				x1="0%"
				y1="50%"
				x2="100%"
				y2="50%">
				<stop
					offset="0%"
					style={{ stopColor: "#ffff00", stopOpacity: 1 }}
				/>
				<stop
					offset="20%"
					style={{ stopColor: "#00ff00", stopOpacity: 1 }}
				/>
				<stop
					offset="40%"
					style={{ stopColor: "#00ffff", stopOpacity: 1 }}
				/>
				<stop
					offset="60%"
					style={{ stopColor: "#0000ff", stopOpacity: 1 }}
				/>
				<stop
					offset="80%"
					style={{ stopColor: "#ff00ff", stopOpacity: 1 }}
				/>
				<stop
					offset="100%"
					style={{ stopColor: "#ff0000", stopOpacity: 1 }}
				/>
			</linearGradient>
		</defs>
		<rect
			width="360"
			height="20"
			fill="url(#gradient)"
			rx="10"
			ry="10"
		/>
	</svg>
);
