import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";

export const MapPrintContent = ({ children }) => {
	return (
		<Paper
			square
			elevation={3}
			sx={{
				display: "flex",
				width: "80%",
				aspectRatio: "317/230",
			}}>
			<Box
				sx={{
					maxWidth: "297mm",
					aspectRatio: "297/210",
					margin: "10mm",
				}}>
				{children}
			</Box>
		</Paper>
	);
};
