import { Checkbox, Select } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
	DataGridPro,
	GridAlignment,
	GridRenderCellParams,
	GridRowModel,
	GridRowOrderChangeParams,
} from "@mui/x-data-grid-pro";
import { useFormik } from "formik";
import { MuiColorInput } from "mui-color-input";
import * as React from "react";
import { useEffect, useState } from "react";

import { Option } from "~ui-components/components/atoms/option";
import { SymbolValue } from "~ui-components/types/__generated/gql/graphql";

function updateRowPosition(
	initialIndex: number,
	newIndex: number,
	rows: GridRowModel[],
): Promise<GridRowModel[]> {
	return new Promise((resolve) => {
		setTimeout(
			() => {
				const rowsClone = [...rows];
				const [row] = rowsClone.splice(initialIndex, 1);
				if (row) rowsClone.splice(newIndex, 0, row);
				resolve(rowsClone);
			},
			Math.random() * 500 + 100,
		); // simulate network latency
	});
}

interface Props {
	form: ReturnType<
		typeof useFormik<{ symbolValues: SymbolValue[]; type: string }>
	>;
	onRowsChange: (rows: GridRowModel[]) => void;
}

export const PolygonSortable = React.memo(({ form, onRowsChange }: Props) => {
	const {
		values: { symbolValues },
	} = form;
	const [rows, setRows] = useState<GridRowModel[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		onRowsChange(rows);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rows]);

	useEffect(() => {
		const mappedRows = symbolValues.map((symbolValue, index) => ({
			id: index,
			"fill-color": symbolValue?.symbol?.fill?.color ?? "",
			"fill-type": symbolValue?.symbol?.fill?.fillType ?? "",
			"category-label": symbolValue.label ?? "",
			"category-value": symbolValue.value,
			"legend-visibility": symbolValue.legendVisibility,
		}));
		setRows(mappedRows);
	}, [symbolValues]);

	const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
		setLoading(true);
		const newRows = await updateRowPosition(
			params.oldIndex,
			params.targetIndex,
			rows,
		);
		setRows(newRows);
		setLoading(false);
	};

	const ColorInputComponent = (params: GridRenderCellParams) => {
		const { id, value } = params;
		const [colorValue, setColorValue] = useState(value || "");
		return (
			<MuiColorInput
				sx={{ width: 200 }}
				format="hex"
				value={colorValue}
				onChange={(value) => setColorValue(value)}
				onBlur={() => {
					setRows((prevRows) =>
						prevRows.map((row) =>
							row.id === id ? { ...row, "fill-color": colorValue } : row,
						),
					);
				}}
			/>
		);
	};

	const CategoryLabelComponent = (params: GridRenderCellParams) => {
		const [labelValue, setLabelValue] = useState(params.value || "");
		return (
			<OutlinedInput
				value={labelValue}
				onChange={(event) => {
					setLabelValue(event.target.value);
				}}
				onBlur={() => {
					setRows((prevRows) =>
						prevRows.map((row) =>
							row.id === params.id
								? { ...row, "category-label": labelValue }
								: row,
						),
					);
				}}
				onKeyDown={(event) => {
					// Prevent the space key from causing the cell to lose focus
					if (
						event.key === " " ||
						event.key === "ArrowRight" ||
						event.key === "ArrowLeft"
					) {
						event.stopPropagation();
					}
				}}
				sx={{ width: "100%" }}
			/>
		);
	};

	const LegendVisibilityComponent = (params: GridRenderCellParams) => {
		const [visibility, setVisibility] = useState(params.value);
		return (
			<Checkbox
				sx={{ mr: 4 }}
				checked={visibility}
				onChange={(event) => {
					setVisibility(event.target.checked);
					setRows((prevRows) =>
						prevRows.map((row) =>
							row.id === params.id
								? { ...row, "legend-visibility": event.target.checked }
								: row,
						),
					);
				}}
			/>
		);
	};

	const FillTypeComponent = (params: GridRenderCellParams) => {
		const [type, setType] = useState(params.value);
		return (
			<Select
				onChange={(event) => {
					setType(event.target.value as string);
					setRows((prevRows) =>
						prevRows.map((row) =>
							row.id === params.id
								? { ...row, "fill-type": event.target.value as string }
								: row,
						),
					);
				}}
				value={type}>
				<Option
					key="none"
					value="none">
					None
				</Option>
				<Option
					key="solid"
					value="solid">
					Solid
				</Option>
				<Option
					key="dash"
					value="dash">
					Dash
				</Option>
				<Option
					key="dot"
					value="dot">
					Dot
				</Option>
			</Select>
		);
	};

	const columns = React.useMemo(
		() => [
			{
				field: "fill-color",
				headerName: "Fill color",
				width: 200,
				renderCell: ColorInputComponent,
			},
			{
				field: "fill-type",
				headerName: "Fill style",
				width: 200,
				renderCell: FillTypeComponent,
			},
			{ field: "category-value", headerName: "Category value", width: 350 },
			{
				field: "category-label",
				width: 350,
				headerName: "Category label",
				renderCell: CategoryLabelComponent,
			},
			{
				field: "legend-visibility",
				headerName: "Legend visibility",
				flex: 1,
				align: "right" as GridAlignment,
				headerAlign: "right" as GridAlignment,
				renderCell: LegendVisibilityComponent,
			},
		],
		[],
	);

	return (
		<div style={{ height: 400, width: "100%" }}>
			<DataGridPro
				loading={loading}
				columns={columns}
				rows={rows}
				rowReordering
				onRowOrderChange={handleRowOrderChange}
				hideFooter
			/>
		</div>
	);
});
