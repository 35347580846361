import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import { FC } from "react";

export const PortalCreateMap: FC<{ title: string; onCreate: () => void }> = ({
	title,
	onCreate,
}) => {
	return (
		<Box
			sx={{
				bgcolor: "var(--mui-palette-neutral-50)",
				borderRadius: "20px",
				overflow: "hidden",
				position: "relative",
				p: 2,
				"&:hover": {
					cursor: "pointer",
				},
			}}
			onClick={() => onCreate()}>
			<Stack
				height="100%"
				justifyContent="center"
				alignItems="center">
				<Button
					startIcon={<PlusIcon />}
					variant="contained"
					sx={{ maxWidth: "240px" }}>
					{title}
				</Button>
			</Stack>
		</Box>
	);
};
