import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider as Provider } from "@mui/x-date-pickers/LocalizationProvider";
import * as React from "react";
import countries from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";

countries.registerLocale(en);

export interface LocalizationProviderProps {
	children: React.ReactNode;
}

export function LocalizationProvider({
	children,
}: LocalizationProviderProps): React.JSX.Element {
	return <Provider dateAdapter={AdapterDayjs}>{children}</Provider>;
}
