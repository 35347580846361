import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Camera as CameraIcon } from "@phosphor-icons/react/dist/ssr/Camera";
import { useCallback, useRef } from "react";
import { fileToBase64 } from "utils";

interface AvatarUploadInputProps {
	setFieldValue: (name: string, value: any) => void;
	avatar: string;
}

export const AvatarUploadInput = (props: AvatarUploadInputProps) => {
	const { setFieldValue, avatar } = props;

	const avatarInputRef = useRef<HTMLInputElement>(null);

	const handleAvatarChange = useCallback(
		async (event: React.ChangeEvent<HTMLInputElement>) => {
			const file = event.target.files?.[0];

			if (file) {
				const url = await fileToBase64(file);
				setFieldValue("avatar", url);
			}
		},
		[setFieldValue],
	);
	return (
		<Stack
			direction="row"
			spacing={3}
			sx={{ alignItems: "center" }}>
			<Box
				sx={{
					border: "1px dashed var(--mui-palette-divider)",
					borderRadius: "5%",
					display: "inline-flex",
					p: "4px",
				}}>
				<Avatar
					variant="square"
					src={avatar}
					sx={{
						"--Avatar-size": "100px",
						"--Icon-fontSize": "var(--icon-fontSize-lg)",
						alignItems: "center",
						bgcolor: "var(--mui-palette-background-level1)",
						color: "var(--mui-palette-text-primary)",
						display: "flex",
						justifyContent: "center",
					}}>
					<CameraIcon fontSize="var(--Icon-fontSize)" />
				</Avatar>
			</Box>
			<Stack
				spacing={1}
				sx={{ alignItems: "flex-start" }}>
				<Typography variant="subtitle1">Logo</Typography>
				<Typography variant="caption">Min 400x400px, PNG or JPEG</Typography>
				<Button
					color="secondary"
					onClick={() => {
						avatarInputRef.current?.click();
					}}
					variant="outlined">
					Select
				</Button>
				<input
					hidden
					onChange={handleAvatarChange}
					ref={avatarInputRef}
					type="file"
				/>
			</Stack>
		</Stack>
	);
};
