import maplibregl from "maplibre-gl";
import * as pmtiles from "pmtiles";
import { useEffect } from "react";

export const usePmtilesProtocol = () => {
	useEffect(() => {
		const protocol = new pmtiles.Protocol();
		maplibregl.addProtocol("pmtiles", protocol.tile);
	}, []);
};
