import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { FC } from "react";
import Box from "@mui/material/Box";

export interface PortalBannerHeadlineProps {
	header: string;
	subheader: string;
}

export const PortalBannerHeadline: FC<PortalBannerHeadlineProps> = ({
	header,
	subheader,
}) => {
	return (
		<Box
			sx={{
				bgcolor: "primary.main",
				borderRadius: "20px",
				color: "var(--mui-palette-common-white)",
				overflow: "hidden",
				position: "relative",
				p: { xs: "16px 12px", md: "32px 28px", lg: "60px 40px" },
			}}>
			<Stack
				spacing={4}
				sx={{ position: "relative", zIndex: 1 }}>
				<Stack spacing={1}>
					<Typography variant="h3">{header}</Typography>
					<Typography>{subheader}</Typography>
				</Stack>
			</Stack>
		</Box>
	);
};
