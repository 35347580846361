import {
	Box,
	Button,
	ButtonGroup,
	Chip,
	CircularProgress,
	Menu,
	MenuItem,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { DropboxLogo } from "@phosphor-icons/react";
import { CaretDown as CaretDownIcon } from "@phosphor-icons/react/dist/ssr/CaretDown";
import { FC } from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import {
	DatasetInfo,
	DatasetInfoProps,
} from "~ui-components/components/organisms/dataset-view-panels/dataset-info";
import {
	DatasetPreview,
	DatasetPreviewProps,
} from "~ui-components/components/organisms/dataset-view-panels/dataset-preview";
import { usePopover } from "~ui-components/hooks/use-popover";
import { GEOMETRY_TYPE } from "~ui-components/types/__generated/gql/graphql";

export type DatasetViewProps = DatasetPreviewProps & Partial<DatasetInfoProps>;

export const DatasetView: FC<DatasetViewProps> = ({
	datasetInfo,
	currentTab,
	onTabChange,
	isExpandedView,
	onExpandViewChange,
	slots: Slots,
	onDeleteDataset = console.log,
}) => {
	const popover = usePopover<HTMLDivElement>();

	if (isExpandedView)
		return (
			<Box
				sx={{
					"--Content-margin": "0 auto",
					"--Content-maxWidth": "var(--maxWidth-xl)",
					"--Content-paddingX": "24px",
					"--Content-paddingY": { xs: "24px", lg: "64px" },
					"--Content-padding":
						"var(--Content-paddingY) var(--Content-paddingX)",
					"--Content-width": "100%",
					display: "flex",
					flex: "1 1 auto",
					flexDirection: "column",
				}}>
				<Box
					sx={{
						maxWidth: "var(--Content-maxWidth)",
						m: "var(--Content-margin)",
						p: "var(--Content-padding)",
						width: "var(--Content-width)",
					}}>
					<DatasetPreview
						datasetInfo={datasetInfo}
						currentTab={currentTab}
						onTabChange={onTabChange}
						isExpandedView={isExpandedView}
						onExpandViewChange={onExpandViewChange}
						slots={Slots}
					/>
				</Box>
			</Box>
		);

	return (
		<DashboardContentLayout
			pageTitle={
				datasetInfo ? (
					<>
						{datasetInfo.name}&nbsp;
						{datasetInfo.isSyncActive && (
							<Chip
								icon={<DropboxLogo fontSize="var(--Icon-fontSize)" />}
								label="Sync active"
								color="success"
								variant="outlined"
							/>
						)}
					</>
				) : null
			}
			action={
				[GEOMETRY_TYPE.WMS, GEOMETRY_TYPE.RASTER].includes(
					datasetInfo?.type,
				) ? null : (
					<Box>
						<ButtonGroup
							ref={popover.anchorRef}
							variant="contained">
							<Button onClick={popover.handleToggle}>Download</Button>
							<Button
								onClick={popover.handleToggle}
								size="small">
								<CaretDownIcon />
							</Button>
						</ButtonGroup>
						<Menu
							anchorEl={popover.anchorRef.current}
							anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
							onClose={popover.handleClose}
							open={popover.open}>
							<MenuItem>Shapefile</MenuItem>
							<MenuItem>CSV</MenuItem>
							<MenuItem>Mapinfo TAB</MenuItem>
						</Menu>
					</Box>
				)
			}>
			<Grid
				container
				spacing={4}>
				{!isExpandedView && (
					<Grid
						lg={4}
						xs={12}>
						{datasetInfo ? (
							<DatasetInfo
								datasetInfo={datasetInfo}
								onDeleteDataset={onDeleteDataset}
							/>
						) : (
							<CircularProgress />
						)}
					</Grid>
				)}
				<Grid
					lg={8}
					xs={12}>
					<DatasetPreview
						datasetInfo={datasetInfo}
						currentTab={currentTab}
						onTabChange={onTabChange}
						isExpandedView={isExpandedView}
						onExpandViewChange={onExpandViewChange}
						slots={Slots}
					/>
				</Grid>
			</Grid>
		</DashboardContentLayout>
	);
};
