import Button from "@mui/material/Button";
import { useRouter } from "next/router";

export function ButtonCancel() {
	const router = useRouter();
	return (
		<Button
			color="secondary"
			onClick={router.back}>
			Cancel
		</Button>
	);
}
