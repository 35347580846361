import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { X as XIcon } from "@phosphor-icons/react/dist/ssr/X";
import { FC } from "react";

interface PortalAboutUsModalProps {
	open: boolean;
	onClose: () => void;
}

export const PortalAboutUsModal: FC<PortalAboutUsModalProps> = (props) => {
	const { open, onClose } = props;

	return (
		<Dialog
			maxWidth="sm"
			onClose={onClose}
			open={open}
			sx={{
				"& .MuiDialog-container": { justifyContent: "center" },
				"& .MuiDialog-paper": { width: "100%" },
			}}>
			<DialogContent>
				<Stack
					direction="row"
					spacing={2}
					sx={{
						alignItems: "center",
						borderBottom: "1px solid var(--mui-palette-divider)",
						flex: "0 0 auto",
						justifyContent: "space-between",
						p: 2,
					}}>
					<Typography variant="h6">About us</Typography>
					<IconButton onClick={onClose}>
						<XIcon />
					</IconButton>
				</Stack>
				<Stack padding={2}>
					<Typography
						color="text.secondary"
						variant="subtitle1">
						At GeoVision Consulting, we are passionate about transforming
						spatial data into actionable insights. With a team of seasoned GIS
						professionals, we specialize in providing innovative geospatial
						solutions that empower businesses, governments, and organizations to
						make informed decisions.
					</Typography>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};
