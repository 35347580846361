import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Textbox } from "@phosphor-icons/react/dist/ssr/Textbox";
import { Editor } from "@tiptap/react";
import { FC, useState } from "react";

interface Option {
	label: string;
	value: string;
}

export interface UserInputSelectProps {
	editor: Editor;
	options?: Option[];
}

export const UserInputSelect: FC<UserInputSelectProps> = ({
	editor,
	options,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<IconButton
				sx={{ width: 130 }}
				onClick={(e) => setAnchorEl(e.currentTarget)}>
				<Box
					display="flex"
					alignItems="center">
					<Textbox />
					<Typography
						variant="body2"
						ml={1}>
						User input
					</Typography>
				</Box>
			</IconButton>
			<Menu
				disablePortal
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}>
				{!!options &&
					options.map((option) => (
						<MenuItem
							key={option.value}
							onClick={() => {
								editor.chain().focus().insertContent(`{${option.value}}`).run();
								handleClose();
							}}>
							{option.label}
						</MenuItem>
					))}
			</Menu>
		</div>
	);
};
