import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";

export interface LayerGroupContextMenuProps {
	anchorEl?: HTMLElement | null;
	onClose?: () => void;
	open?: boolean;
	visible?: boolean;
	onDelete?: () => void;
	isEmptyLayerGroup?: boolean;
	onVisibilityChange?: (visible: boolean) => void;
	onAddLayer: () => void;
	disableDelete?: boolean;
	onAddLayerGroup?: (position: "above" | "below") => void;
}

export function LayerGroupContextMenu({
	anchorEl,
	onClose,
	open = false,
	onDelete,
	visible,
	isEmptyLayerGroup,
	onVisibilityChange,
	onAddLayer,
	disableDelete,
	onAddLayerGroup,
}: LayerGroupContextMenuProps): React.JSX.Element {
	return (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			onClose={onClose}
			open={open}
			transformOrigin={{ horizontal: "right", vertical: "top" }}>
			<MenuItem onClick={onAddLayer}>Add Layer</MenuItem>
			<MenuItem
				onClick={() => {
					if (!isEmptyLayerGroup) onVisibilityChange?.(!visible);
				}}>
				<Checkbox checked={visible} />
				Show this Layer Group on load
			</MenuItem>
			<Divider />
			{!isEmptyLayerGroup && (
				<>
					<MenuItem onClick={() => onAddLayerGroup?.("above")}>
						Insert Layer Group above
					</MenuItem>
					<MenuItem onClick={() => onAddLayerGroup?.("below")}>
						Insert Layer Group below
					</MenuItem>
					<Divider />
				</>
			)}
			<MenuItem
				onClick={onDelete}
				sx={{ color: "var(--mui-palette-error-main)" }}
				disabled={disableDelete}>
				Delete Layer Group
			</MenuItem>
		</Menu>
	);
}
