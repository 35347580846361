import { Box, Button, Divider, Stack } from "@mui/material";
import { map, upperFirst } from "lodash";
import React from "react";

import { PropertyItem } from "~ui-components/components/atoms/property-item";
import { PropertyList } from "~ui-components/components/atoms/property-list";

export const FeatureProperties = ({ properties }) => {
	return (
		<>
			<Box flex={1}>
				<Stack spacing={3}>
					<PropertyList>
						{map(properties, (value, name) => {
							return (
								<React.Fragment key={name}>
									<PropertyItem
										key={name}
										name={upperFirst(name)}
										value={value}
										boxProps={{ sx: { padding: "16px 24px" } }}
									/>
									<Divider />
								</React.Fragment>
							);
						})}
					</PropertyList>
				</Stack>
			</Box>
			<Stack
				direction={"row-reverse"}
				spacing={3}
				p={2}>
				<Button color="primary">Share</Button>
				<Button color="primary">Bookmark</Button>
			</Stack>
		</>
	);
};
