import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import { paths } from "config/paths";
import RouterLink from "next/link";
import * as React from "react";
import { MangoLogo } from "~ui-components/components/atoms/mango-logo";

export interface MobileNavProps {
	onClose?: () => void;
	open?: boolean;
	children?: React.ReactNode;
}

export function MobileNav({
	open,
	onClose,
	children,
}: MobileNavProps): React.JSX.Element {
	return (
		<Drawer
			PaperProps={{
				sx: {
					"--MobileNav-background": "var(--mui-palette-neutral-950)",
					"--MobileNav-color": "var(--mui-palette-common-white)",
					"--NavGroup-title-color": "var(--mui-palette-neutral-400)",
					"--NavItem-color": "var(--mui-palette-neutral-300)",
					"--NavItem-hover-background": "rgba(255, 255, 255, 0.04)",
					"--NavItem-active-background": "var(--mui-palette-primary-main)",
					"--NavItem-active-color": "var(--mui-palette-primary-contrastText)",
					"--NavItem-disabled-color": "var(--mui-palette-neutral-500)",
					"--NavItem-icon-color": "var(--mui-palette-neutral-400)",
					"--NavItem-icon-active-color":
						"var(--mui-palette-primary-contrastText)",
					"--NavItem-icon-disabled-color": "var(--mui-palette-neutral-600)",
					"--NavItem-expand-color": "var(--mui-palette-neutral-400)",
					"--NavItem-children-border": "var(--mui-palette-neutral-700)",
					"--NavItem-children-indicator": "var(--mui-palette-neutral-400)",
					"--Workspaces-background": "var(--mui-palette-neutral-950)",
					"--Workspaces-border-color": "var(--mui-palette-neutral-700)",
					"--Workspaces-title-color": "var(--mui-palette-neutral-400)",
					"--Workspaces-name-color": "var(--mui-palette-neutral-300)",
					"--Workspaces-expand-color": "var(--mui-palette-neutral-400)",
					bgcolor: "var(--MobileNav-background)",
					color: "var(--MobileNav-color)",
					display: "flex",
					flexDirection: "column",
					maxWidth: "100%",
					width: "var(--MobileNav-width)",
					zIndex: "var(--MobileNav-zIndex)",
					scrollbarWidth: "none",
					"&::-webkit-scrollbar": { display: "none" },
				},
			}}
			onClose={onClose}
			open={open}>
			<Stack
				spacing={2}
				sx={{ p: 2 }}>
				<div>
					<Box
						component={RouterLink}
						href={paths.home}
						sx={{ display: "inline-flex" }}>
						<MangoLogo
							height={32}
							width={122}
						/>
					</Box>
				</div>
			</Stack>
			<Box
				component="nav"
				sx={{ flex: "1 1 auto", p: 2 }}>
				{children}
			</Box>
		</Drawer>
	);
}
