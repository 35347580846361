import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { FC, useState } from "react";

import { PortalAboutUsModal } from "../portal-about-us-modal";
import { PortalTermsModal } from "../portal-terms-modal";

export const PortalHeaderActionButtons: FC = () => {
	const [openAboutUs, setOpenAboutUs] = useState(false);
	const [openTerms, setOpenTerms] = useState(false);

	return (
		<>
			<PortalAboutUsModal
				open={openAboutUs}
				onClose={() => setOpenAboutUs(false)}
			/>
			<PortalTermsModal
				open={openTerms}
				onClose={() => setOpenTerms(false)}
			/>
			<Stack
				direction="row"
				spacing={2}>
				<Button
					variant="text"
					onClick={() => setOpenAboutUs(true)}>
					About us
				</Button>
				<Button variant="text">Contact</Button>
				<Button
					variant="text"
					onClick={() => setOpenTerms(true)}>
					Terms
				</Button>
			</Stack>
		</>
	);
};
