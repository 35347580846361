import { Box, Stack, Typography } from "@mui/material";

import { PolygonCategoryConfigs } from "~ui-components/types/__generated/gql/graphql";

interface Props {
	style: PolygonCategoryConfigs;
}
export const PolygonCategory = ({ style }: Props) => {
	const { symbolValues } = style;
	return (
		<Box>
			{symbolValues?.map((symbol, index) => {
				return (
					<Stack
						key={index}
						direction="row"
						display="flex"
						alignItems="center">
						<Box
							width={20}
							height={20}
							bgcolor={symbol?.color ?? "#fff"}
						/>
						<Box sx={{ pl: 1 }}>
							<Typography
								color="text.secondary"
								variant="caption">
								{symbol?.label ?? ""}
							</Typography>
						</Box>
					</Stack>
				);
			})}
		</Box>
	);
};
