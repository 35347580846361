import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	Menu,
	MenuItem,
	OutlinedInput,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import { BracketsCurly as AttributeIcon } from "@phosphor-icons/react/dist/ssr/BracketsCurly";
import { BubbleMenu, type Editor } from "@tiptap/react";
import { useFormik } from "formik";
import * as React from "react";
import * as Yup from "yup";

import { useDialog } from "~ui-components/hooks/use-dialog";
import { usePopover } from "~ui-components/hooks/use-popover";

interface IFrameMenuProps {
	editor: Editor;
}

export const IFrameMenu: React.FC<IFrameMenuProps> = ({ editor, ...props }) => {
	const iframeDialog = useDialog<IFrameFormValues>();

	return (
		<>
			{iframeDialog.open && (
				<IFrameDialog
					editor={editor}
					onClose={iframeDialog.handleClose}
					initialValues={iframeDialog.data}
					{...props}
				/>
			)}
			<BubbleMenu
				editor={editor}
				pluginKey="IFrameMenu"
				tippyOptions={{ zIndex: 1 }}
				shouldShow={({ editor }) => editor.isActive("iframe")}>
				<ToggleButtonGroup
					exclusive
					sx={{ bgcolor: (t) => t.palette.background.paper }}>
					<ToggleButton value="edit">
						<Typography
							variant="caption"
							onClick={() => {
								const iframeNode = editor.getAttributes("iframe");
								iframeDialog.handleOpen({ src: iframeNode.src });
							}}>
							Edit
						</Typography>
					</ToggleButton>
					<Divider
						flexItem
						orientation="vertical"
						sx={{ my: 1 }}
					/>
					<ToggleButton
						value="remove-iframe"
						onClick={() => editor.chain().focus().deleteSelection().run()}>
						<Typography variant="caption">Remove iframe</Typography>
					</ToggleButton>
				</ToggleButtonGroup>
			</BubbleMenu>
		</>
	);
};

const iframeValidationSchema = Yup.object({
	src: Yup.string().required(),
}).required();

export type IFrameFormValues = Yup.InferType<typeof iframeValidationSchema>;

interface IFrameDialogProps {
	editor: Editor;
	initialValues?: IFrameFormValues;
	onClose: () => void;
	srcAttributes?: string[];
}

export const IFrameDialog: React.FC<IFrameDialogProps> = ({
	editor,
	initialValues,
	onClose,
	srcAttributes,
}) => {
	const iframeForm = useFormik<IFrameFormValues>({
		enableReinitialize: true,
		initialValues: initialValues ?? {
			src: "",
		},
		validationSchema: iframeValidationSchema,
		onSubmit: (values) => {
			const chained = editor.chain().focus() as any;
			chained.setIframe({ src: values.src }).run();
			onClose();
		},
	});
	const srcPopover = usePopover<HTMLButtonElement>();

	return (
		<Dialog
			open
			fullWidth
			maxWidth="xs"
			onClose={onClose}>
			<form onSubmit={iframeForm.handleSubmit}>
				<DialogTitle>Insert IFrame</DialogTitle>
				<DialogContent>
					<FormControl fullWidth>
						<InputLabel>IFrame URL</InputLabel>
						<OutlinedInput
							{...iframeForm.getFieldProps("src")}
							error={!!iframeForm.errors.src}
							endAdornment={
								srcAttributes?.length ? (
									<InputAdornment position="end">
										<IconButton
											sx={{ width: 100 }}
											ref={srcPopover.anchorRef}
											onClick={srcPopover.handleOpen}>
											<Box
												display="flex"
												alignItems="center">
												<AttributeIcon />
												<Typography
													variant="body2"
													ml={1}>
													Attribute
												</Typography>
											</Box>
										</IconButton>
										<Menu
											disablePortal
											anchorEl={srcPopover.anchorRef.current}
											open={srcPopover.open}
											onClose={srcPopover.handleClose}
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "left",
											}}
											transformOrigin={{
												vertical: "top",
												horizontal: "left",
											}}>
											{srcAttributes.map((attribute) => (
												<MenuItem
													key={attribute}
													onClick={() => {
														void iframeForm.setFieldValue(
															"src",
															iframeForm.values.src.concat(`{${attribute}}`),
														);
														srcPopover.handleClose();
													}}>
													{attribute}
												</MenuItem>
											))}
										</Menu>
									</InputAdornment>
								) : null
							}
						/>
						{!!iframeForm.errors.src && (
							<FormHelperText error>{iframeForm.errors.src}</FormHelperText>
						)}
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={onClose}
						color="inherit">
						Cancel
					</Button>
					<Button
						variant="contained"
						color="error"
						type="submit">
						Save
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
