import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	List,
	ListItem,
	Stack,
	Typography,
} from "@mui/material";
import { ArrowSquareOut } from "@phosphor-icons/react/dist/ssr/ArrowSquareOut";
import { X } from "@phosphor-icons/react/dist/ssr/X";
import { paths } from "config/paths";
import { useRouter } from "next/router";
import { FC } from "react";

import { Map } from "~ui-components/types/__generated/gql/graphql";

export interface DataInUseModalModalProps {
	onClose: () => void;
	maps: Map[];
}

export const DataInUseModalModal: FC<DataInUseModalModalProps> = ({
	onClose,
	maps,
}) => {
	const router = useRouter();

	return (
		<Dialog open>
			<DialogTitle>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					gap={1}>
					Data in use
					<IconButton onClick={onClose}>
						<X />
					</IconButton>
				</Stack>
			</DialogTitle>
			<DialogContent>
				<Typography
					variant="body2"
					mb={2}>
					This dataset cannot be deleted because it is still being used by
					layers in the following maps. Please delete the layers using this
					dataset first.
				</Typography>
				<Typography
					variant="subtitle2"
					color="text.secondary"
					mb={2}>
					Maps using this data:
				</Typography>
				<List
					sx={{
						"& .MuiListItem-root": {
							justifyContent: "space-between",
							px: 0,
							py: 0,
						},
					}}>
					{maps.map((map) => (
						<ListItem key={map.id}>
							<Typography variant="subtitle2">{map.name}</Typography>
							<IconButton
								href={paths.accountName.maps.mapId.mapName.root(
									router.query["account-name"],
									map.id,
									map.name,
								)}
								target="_blank">
								<ArrowSquareOut />
							</IconButton>
						</ListItem>
					))}
				</List>
			</DialogContent>
		</Dialog>
	);
};
