import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import GlobalStyles from "@mui/material/GlobalStyles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ArrowSquareOut as ArrowSquareOutIcon } from "@phosphor-icons/react/dist/ssr/ArrowSquareOut";
import { CaretDown as CaretDownIcon } from "@phosphor-icons/react/dist/ssr/CaretDown";
import { CaretRight as CaretRightIcon } from "@phosphor-icons/react/dist/ssr/CaretRight";
import { ChartLine as ChartLineIcon } from "@phosphor-icons/react/dist/ssr/ChartLine";
import { CompassTool as CompassToolIcon } from "@phosphor-icons/react/dist/ssr/CompassTool";
import { CreditCard as CreditCardIcon } from "@phosphor-icons/react/dist/ssr/CreditCard";
import { Database } from "@phosphor-icons/react/dist/ssr/Database";
import { Faders as FadersIcon } from "@phosphor-icons/react/dist/ssr/Faders";
import { FadersHorizontal as FadersHorizontalIcon } from "@phosphor-icons/react/dist/ssr/FadersHorizontal";
import { Globe as GlobeIcon } from "@phosphor-icons/react/dist/ssr/Globe";
import { IdentificationCard as IdentificationCardIcon } from "@phosphor-icons/react/dist/ssr/IdentificationCard";
import { Info as InfoIcon } from "@phosphor-icons/react/dist/ssr/Info";
import { MapTrifold as MapTrifoldIcon } from "@phosphor-icons/react/dist/ssr/MapTrifold";
import { Palette as PaletteIcon } from "@phosphor-icons/react/dist/ssr/Palette";
import { Password as PasswordIcon } from "@phosphor-icons/react/dist/ssr/Password";
import { Question as QuestionIcon } from "@phosphor-icons/react/dist/ssr/Question";
import { Receipt as ReceiptIcon } from "@phosphor-icons/react/dist/ssr/Receipt";
import { SealCheck as SealCheckIcon } from "@phosphor-icons/react/dist/ssr/SealCheck";
import { Share as ShareIcon } from "@phosphor-icons/react/dist/ssr/Share";
import { SignOut as SignOutIcon } from "@phosphor-icons/react/dist/ssr/SignOut";
import { Stack as StackIcon } from "@phosphor-icons/react/dist/ssr/Stack";
import { StackSimple as StackSimpleIcon } from "@phosphor-icons/react/dist/ssr/StackSimple";
import { Swap as SwapIcon } from "@phosphor-icons/react/dist/ssr/Swap";
import { Translate as TranslateIcon } from "@phosphor-icons/react/dist/ssr/Translate";
import { UserCircle as UserCircleIcon } from "@phosphor-icons/react/dist/ssr/UserCircle";
import { UserPlus as UserPlusIcon } from "@phosphor-icons/react/dist/ssr/UserPlus";
import { UsersFour as UsersFourIcon } from "@phosphor-icons/react/dist/ssr/UsersFour";
import { UsersThree as UsersThreeIcon } from "@phosphor-icons/react/dist/ssr/UsersThree";
import { paths, routes } from "config/paths";
import RouterLink from "next/link";
import { useRouter } from "next/router";
import React, { useContext } from "react";

import { useSettings } from "~ui-components/hooks/use-settings";
import { isNavItemActive } from "~ui-components/libs/is-nav-item-active";
import { NavItemConfig } from "~ui-components/types/nav";
import { useAuthContext, useGetCurrentUserAsserted } from "~auth/index";

import { MainNav, useNav } from "./main-nav";
import { SideNav } from "./side-nav";

const NavVariantContext = React.createContext<"side" | "mobile">("side");
const NavVariant = NavVariantContext.Provider;

export interface VerticalLayoutProps {
	sideNav?: boolean;
	mainNav?: boolean;
	children?: React.ReactNode;
}

export function VerticalLayout(props: VerticalLayoutProps): React.JSX.Element {
	const { children, sideNav = true, mainNav = true } = props;

	const settings = useSettings();

	return (
		<React.Fragment>
			<GlobalStyles
				styles={{
					body: {
						"--MainNav-height": "56px",
						"--MainNav-zIndex": 1000,
						"--SideNav-width": "280px",
						"--SideNav-zIndex": 1100,
						"--MobileNav-width": "320px",
						"--MobileNav-zIndex": 1100,
						"--MapTool-width": "400px",
					},
				}}
			/>
			<Box
				sx={{
					bgcolor: "var(--mui-palette-background-default)",
					display: "flex",
					flexDirection: "column",
					position: "relative",
					minHeight: "100%",
				}}>
				{sideNav && (
					<SideNav color={settings.navColor}>
						<NavGroups variant="side" />
					</SideNav>
				)}
				<Box
					sx={{
						display: "flex",
						flex: "1 1 auto",
						flexDirection: "column",
						pl: { lg: "var(--SideNav-width)" },
					}}>
					{mainNav && (
						<MainNav>
							<NavGroups variant="mobile" />
						</MainNav>
					)}
					<Box
						component="main"
						sx={{
							"--Content-margin": "0 auto",
							"--Content-maxWidth": "var(--maxWidth-xl)",
							"--Content-paddingX": "24px",
							"--Content-paddingY": { xs: "24px", lg: "64px" },
							"--Content-padding":
								"var(--Content-paddingY) var(--Content-paddingX)",
							"--Content-width": "100%",
							display: "flex",
							flex: "1 1 auto",
							flexDirection: "column",
						}}>
						{children}
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}

function NavGroups(props) {
	const { variant } = props;

	const { route, query, pathname } = useRouter();
	const { signOut } = useAuthContext();
	const { data } = useGetCurrentUserAsserted();

	return (
		<NavVariant value={variant}>
			<Stack
				component="ul"
				spacing={2}
				sx={{ listStyle: "none", m: 0, p: 0 }}>
				<NavGroup title="Navigation">
					<NavItems>
						<NavItem
							title="Map portal"
							icon={MapTrifoldIcon}
							href={paths.accountName.maps.root(query["account-name"])}
							matcher={{
								href: routes.accountName.maps.root,
								type: "startsWith",
							}}
						/>
						<NavItem
							title="Data portal"
							icon={Database}
							href={paths.accountName.data.root(query["account-name"])}
							matcher={{
								href: routes.accountName.data.root,
								type: "startsWith",
							}}
						/>
					</NavItems>
				</NavGroup>

				{route.startsWith(routes.accountName.maps.mapId.mapName.root) && (
					<NavGroup
						indent
						title="Map settings">
						<NavItems>
							<NavItem
								title="Map"
								icon={MapTrifoldIcon}
								href={paths.accountName.maps.mapId.mapName.root(
									query["account-name"],
									query["map-id"],
									query["map-name"],
								)}
								matcher={{
									href: routes.accountName.maps.mapId.mapName.root,
									type: "equals",
								}}
							/>
							<NavItem
								title="Layers"
								icon={StackIcon}
								href={paths.accountName.maps.mapId.mapName.layers.root(
									query["account-name"],
									query["map-id"],
									query["map-name"],
								)}
								matcher={{
									href: routes.accountName.maps.mapId.mapName.layers.root,
									type: "equals",
								}}
							/>
							<NavItem
								title="Toolbox"
								icon={CompassToolIcon}
								href={paths.accountName.maps.mapId.mapName.tools.root(
									query["account-name"],
									query["map-id"],
									query["map-name"],
								)}
								matcher={{
									href: routes.accountName.maps.mapId.mapName.tools,
									type: "startsWith",
								}}
							/>
							<NavItem
								title="Access"
								icon={UserPlusIcon}
								href={paths.accountName.maps.mapId.mapName.access(
									query["account-name"],
									query["map-id"],
									query["map-name"],
								)}
								matcher={{
									href: routes.accountName.maps.mapId.mapName.access,
									type: "equals",
								}}
							/>
							<NavItem
								title="Share"
								icon={ShareIcon}
								href={paths.accountName.maps.mapId.mapName.share(
									query["account-name"],
									query["map-id"],
									query["map-name"],
								)}
								matcher={{
									href: routes.accountName.maps.mapId.mapName.share,
									type: "equals",
								}}
							/>
							<NavItem
								title="Map config"
								icon={FadersHorizontalIcon}
							/>
						</NavItems>
					</NavGroup>
				)}

				{route.startsWith(routes.accountName.data.datasetId.root) && (
					<NavGroup
						indent
						title="Data settings">
						<NavItems>
							<NavItem
								title="Dataset"
								icon={StackSimpleIcon}
								href={paths.accountName.data.datasetId.root(
									query["account-name"],
									query["dataset-id"],
								)}
								matcher={{
									href: routes.accountName.data.datasetId.root,
									type: "equals",
								}}
							/>
							<NavItem
								title="Data config"
								icon={FadersHorizontalIcon}
								href={paths.accountName.data.datasetId.update(
									query["account-name"],
									query["dataset-id"],
								)}
								matcher={{
									href: routes.accountName.data.datasetId.update,
									type: "equals",
								}}
							/>
							<NavItem
								title="Sync"
								icon={SwapIcon}
								href={paths.accountName.data.datasetId.sync(
									query["account-name"],
									query["dataset-id"],
								)}
								matcher={{
									href: routes.accountName.data.datasetId.sync,
									type: "startsWith",
								}}
							/>
							<NavItem
								title="Access"
								icon={UserPlusIcon}
								href={paths.accountName.data.datasetId.access(
									query["account-name"],
									query["dataset-id"],
								)}
								matcher={{
									href: routes.accountName.data.datasetId.access,
									type: "startsWith",
								}}
							/>
						</NavItems>
					</NavGroup>
				)}

				<NavGroup title="Global settings">
					<NavItems>
						<NavItem
							title="Team"
							icon={UsersFourIcon}
							forceOpen={[
								routes.accountName.users.root,
								routes.accountName.groups.root,
							].includes(pathname)}>
							<NavItem
								title="Users"
								icon={UserCircleIcon}
								href={paths.accountName.users.root(query["account-name"])}
								matcher={{
									href: routes.accountName.users.root,
									type: "startsWith",
								}}
							/>
							<NavItem
								title="Access groups"
								icon={UsersThreeIcon}
								href={paths.accountName.groups.root(query["account-name"])}
								matcher={{
									href: routes.accountName.groups.root,
									type: "startsWith",
								}}
							/>
						</NavItem>

						<NavItem
							title="Customisation"
							icon={PaletteIcon}
							forceOpen={[
								routes.accountName.branding.root,
								routes.accountName.branding.localisation,
								routes.accountName.branding.domainAndWhiteLabel,
							].includes(pathname)}>
							<NavItem
								title="Brand"
								icon={SealCheckIcon}
								href={paths.accountName.branding.root(query["account-name"])}
								matcher={{
									href: routes.accountName.branding.root,
									type: "equals",
								}}
							/>
							<NavItem
								title="Localisation"
								icon={TranslateIcon}
								href={paths.accountName.branding.localisation(
									query["account-name"],
								)}
								matcher={{
									href: routes.accountName.branding.localisation,
									type: "equals",
								}}
							/>
							<NavItem
								title="Domain & whitelabel"
								icon={GlobeIcon}
								href={paths.accountName.branding.domainAndWhiteLabel(
									query["account-name"],
								)}
								matcher={{
									href: routes.accountName.branding.domainAndWhiteLabel,
									type: "equals",
								}}
							/>
						</NavItem>
					</NavItems>
				</NavGroup>

				<NavGroup title="Account">
					<NavItems>
						<NavItem
							title="Support"
							icon={QuestionIcon}
							href={paths.accountName.support(query["account-name"])}
							matcher={{
								href: routes.accountName.support.root,
								type: "startsWith",
							}}
						/>
						<NavItem
							title="Analytics"
							icon={ChartLineIcon}
						/>
						<NavItem
							title="Checkout"
							icon={CreditCardIcon}
							href={paths.accountName.settings.checkout(query["account-name"])}
							matcher={{
								href: routes.accountName.settings.checkout,
								type: "equals",
							}}
						/>
						<NavItem
							title="Subscription & billing"
							icon={SealCheckIcon}
							forceOpen={[
								routes.accountName.settings.root,
								routes.accountName.settings.editSubscription,
								routes.accountName.settings.editBilling,
							].includes(pathname)}>
							<NavItem
								title="Subscription summary"
								icon={InfoIcon}
								href={paths.accountName.settings.root(query["account-name"])}
								matcher={{
									href: routes.accountName.settings.root,
									type: "equals",
								}}
							/>
							<NavItem
								title="Manage subscription"
								icon={FadersIcon}
								href={paths.accountName.settings.editSubscription(
									query["account-name"],
								)}
								matcher={{
									href: routes.accountName.settings.editSubscription,
									type: "equals",
								}}
							/>
							<NavItem
								title="Update billing"
								icon={CreditCardIcon}
								href={paths.accountName.settings.editBilling(
									query["account-name"],
								)}
								matcher={{
									href: routes.accountName.settings.editBilling,
									type: "equals",
								}}
							/>
							<NavItem
								title="Invoice"
								icon={ReceiptIcon}
							/>
						</NavItem>
					</NavItems>
				</NavGroup>

				<NavGroup title="Profile">
					<NavItems>
						<NavItem
							title={data.name!}
							icon={UserCircleIcon}
							forceOpen={[
								routes.accountName.settings.profile,
								routes.accountName.settings.password,
							].includes(pathname)}>
							<NavItem
								title="User profile"
								icon={IdentificationCardIcon}
								href={paths.accountName.settings.profile(query["account-name"])}
								matcher={{
									href: routes.accountName.settings.profile,
									type: "equals",
								}}
							/>
							<NavItem
								title="Change password"
								icon={PasswordIcon}
								href={paths.accountName.settings.password(
									query["account-name"],
								)}
								matcher={{
									href: routes.accountName.settings.password,
									type: "equals",
								}}
							/>
							<NavItem
								title="Sign out"
								icon={SignOutIcon}
								onClick={signOut}
							/>
						</NavItem>
					</NavItems>
				</NavGroup>
			</Stack>
		</NavVariant>
	);
}

export function NavGroup(props) {
	const { indent, title, children } = props;
	return (
		<Stack
			component="li"
			key={title}
			spacing={1.5}>
			{title ? (
				<div>
					<Typography
						sx={{
							color: "var(--NavGroup-title-color)",
							fontSize: "0.875rem",
							fontWeight: 500,
						}}>
						{title}
					</Typography>
				</div>
			) : null}

			{indent ? (
				<Box sx={{ pl: "24px" }}>
					<Box
						sx={{
							borderLeft: "1px solid var(--NavItem-children-border)",
							pl: "12px",
						}}>
						{React.Children.map(children, (child) => {
							return React.cloneElement(child, { depth: 1 });
						})}
					</Box>
				</Box>
			) : (
				<div>{children}</div>
			)}
		</Stack>
	);
}

export function NavItems(props) {
	const { depth = 0, children } = props;
	return (
		<Stack
			component="ul"
			data-depth={depth}
			spacing={1}
			sx={{ listStyle: "none", m: 0, p: 0 }}>
			{React.Children.map(children, (child: any) => {
				return React.cloneElement(child, { depth });
			})}
		</Stack>
	);
}

interface NavItemProps extends Omit<NavItemConfig, "key" | "items" | "icon"> {
	children?: React.ReactNode;
	depth?: number;
	forceOpen?: boolean;
	icon: React.ElementType;
	onClick?: () => void;
}

export function NavItem(props: NavItemProps): React.JSX.Element {
	const {
		children,
		depth = 0,
		disabled,
		external,
		forceOpen = false,
		href,
		icon,
		label,
		matcher,
		title,
		onClick,
	} = props;

	const { onClose } = useNav();

	const variant = useContext(NavVariantContext);
	const router = useRouter();
	const { pathname } = router;

	const [open, setOpen] = React.useState<boolean>(forceOpen);

	const active = isNavItemActive({
		disabled,
		external,
		href,
		matcher,
		pathname,
	});

	const Icon = icon ?? null;
	const ExpandIcon = open ? CaretDownIcon : CaretRightIcon;
	const isBranch = children && !href;
	const showChildren = Boolean(children && open);

	return (
		<Box
			component="li"
			data-depth={depth}
			sx={{ userSelect: "none" }}>
			<Box
				{...(isBranch
					? {
							onClick: (): void => {
								setOpen(!open);
							},
							onKeyUp: (event: React.KeyboardEvent<HTMLDivElement>): void => {
								if (event.key === "Enter" || event.key === " ") {
									setOpen(!open);
								}
							},
							role: "button",
						}
					: {
							...(href
								? {
										component: external ? "a" : RouterLink,
										href,
										target: external ? "_blank" : undefined,
										rel: external ? "noreferrer" : undefined,
										...(variant === "mobile"
											? {
													onClick: onClose,
												}
											: {}),
									}
								: {
										role: "button",
										onKeyUp: (
											event: React.KeyboardEvent<HTMLDivElement>,
										): void => {
											if (event.key === "Enter" || event.key === " ") {
												//
											}
										},
										onClick: () => onClick?.(),
									}),
						})}
				sx={{
					alignItems: "center",
					borderRadius: 1,
					color: "var(--NavItem-color)",
					cursor: "pointer",
					display: "flex",
					flex: "0 0 auto",
					gap: 1,
					p: "6px 16px",
					position: "relative",
					textDecoration: "none",
					whiteSpace: "nowrap",
					...(disabled && {
						bgcolor: "var(--NavItem-disabled-background)",
						color: "var(--NavItem-disabled-color)",
						cursor: "not-allowed",
					}),
					...(active && {
						bgcolor: "var(--NavItem-active-background)",
						color: "var(--NavItem-active-color)",
						...(depth > 0 && {
							"&::before": {
								bgcolor: "var(--NavItem-children-indicator)",
								borderRadius: "2px",
								content: '" "',
								height: "20px",
								left: "-14px",
								position: "absolute",
								width: "3px",
							},
						}),
					}),
					...(open && { color: "var(--NavItem-open-color)" }),
					"&:hover": {
						...(!disabled &&
							!active && {
								bgcolor: "var(--NavItem-hover-background)",
								color: "var(--NavItem-hover-color)",
							}),
					},
				}}
				tabIndex={0}>
				<Box
					sx={{
						alignItems: "center",
						display: "flex",
						justifyContent: "center",
						flex: "0 0 auto",
					}}>
					{Icon ? (
						<Icon
							fill={
								active
									? "var(--NavItem-icon-active-color)"
									: "var(--NavItem-icon-color)"
							}
							fontSize="var(--icon-fontSize-md)"
							weight={forceOpen || active ? "fill" : undefined}
						/>
					) : null}
				</Box>
				<Box sx={{ flex: "1 1 auto" }}>
					<Typography
						component="span"
						sx={{
							color: "inherit",
							fontSize: "0.875rem",
							fontWeight: 500,
							lineHeight: "28px",
						}}>
						{title}
					</Typography>
				</Box>
				{label ? (
					<Chip
						color="primary"
						label={label}
						size="small"
					/>
				) : null}
				{external ? (
					<Box sx={{ alignItems: "center", display: "flex", flex: "0 0 auto" }}>
						<ArrowSquareOutIcon
							color="var(--NavItem-icon-color)"
							fontSize="var(--icon-fontSize-sm)"
						/>
					</Box>
				) : null}
				{isBranch ? (
					<Box sx={{ alignItems: "center", display: "flex", flex: "0 0 auto" }}>
						<ExpandIcon
							color="var(--NavItem-expand-color)"
							fontSize="var(--icon-fontSize-sm)"
						/>
					</Box>
				) : null}
			</Box>
			{showChildren ? (
				<Box sx={{ pl: "24px" }}>
					<Box
						sx={{
							borderLeft: "1px solid var(--NavItem-children-border)",
							pl: "12px",
						}}>
						{React.Children.map(children, (child: any) => {
							return React.cloneElement(child, { depth: depth + 1 });
						})}
					</Box>
				</Box>
			) : null}
		</Box>
	);
}
