import { Box, StackProps } from "@mui/material";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import _ from "lodash";

import { Option } from "~ui-components/components/atoms/option";
import { FillStrokeStyle } from "~ui-components/components/molecules/simple-style/fill-stroke";
import { VISUALISATION_COLORS } from "~ui-components/constants/colors";
import {
	LineQuantityConfigs,
	PointQuantityConfigs,
	PolygonQuantityConfigs,
} from "~ui-components/types/__generated/gql/graphql";

interface Slots {
	MarkerSettingsDrawer: any;
}

interface SlotProps {
	MarkerSettingsDrawer: any;
}

type Props = StackProps & {
	form: ReturnType<
		typeof useFormik<
			PointQuantityConfigs | LineQuantityConfigs | PolygonQuantityConfigs
		>
	>;
	attributes: string[];
	slots?: Slots;
	slotProps?: SlotProps;
	onOpenCustomLegend: () => void;
	onOpenMarkerDrawer: () => void;
	onMarkerChange: (value: string | undefined) => void;
	onMarkerSettingClose: () => void;
};

export const QuantityStyle = (props: Props) => {
	const {
		onOpenMarkerDrawer,
		onMarkerSettingClose,
		onOpenCustomLegend,
		attributes,
		form,
	} = props;
	const { values, handleChange, handleSubmit } = form;

	return (
		<>
			<Stack spacing={3}>
				<FormControl sx={{ width: 200 }}>
					<InputLabel>Colour by</InputLabel>
					<Select
						name="valueField"
						onChange={handleChange}
						value={values.valueField}>
						{attributes.map((value) => (
							<Option
								key={value}
								value={value}>
								{value}
							</Option>
						))}
					</Select>
				</FormControl>

				<FormControl sx={{ width: 200 }}>
					<InputLabel>Steps</InputLabel>
					<Select
						name="classNumber"
						onChange={handleChange}
						value={values.classNumber}>
						{_.range(3, 10).map((value) => (
							<Option
								key={value}
								value={value}>
								{value}
							</Option>
						))}
					</Select>
				</FormControl>
				<FormControl sx={{ width: 200 }}>
					<InputLabel>Mode</InputLabel>
					<Select
						name="classification"
						onChange={handleChange}
						value={values.classification}>
						<Option
							key="manual"
							value="manual">
							Manual
						</Option>
						<Option
							key="equal-interval"
							value="equal-interval">
							Equal Interval
						</Option>
						<Option
							key="jenks"
							value="jenksnatural">
							Jenks
						</Option>
						<Option
							key="quantile"
							value="quantile">
							Quantile
						</Option>
						<Option
							key="heads-tails"
							value="heat-tails">
							Head/Tails
						</Option>
					</Select>
				</FormControl>

				<FormControl sx={{ width: 200 }}>
					<InputLabel>Fill </InputLabel>
					<Select
						name="colorRamp"
						value={values.colorRamp}
						onChange={handleChange}
						renderValue={(value) =>
							value && VISUALISATION_COLORS[value] ? (
								<Stack direction="row">
									{VISUALISATION_COLORS[value].slice(2).map((color) => (
										<Box
											key={color}
											sx={{ width: 20, height: 20, backgroundColor: color }}
										/>
									))}
								</Stack>
							) : null
						}>
						{Object.keys(VISUALISATION_COLORS).map((key) => (
							<Option
								key={key}
								value={key}>
								<Stack direction="row">
									{VISUALISATION_COLORS[key].slice(2).map((color) => (
										<Box
											key={color}
											sx={{ width: 20, height: 20, backgroundColor: color }}
										/>
									))}
								</Stack>
							</Option>
						))}
					</Select>
				</FormControl>

				<FillStrokeStyle
					onMarkerChange={onMarkerSettingClose}
					slots={props.slots}
					slotProps={props.slotProps}
					onOpenMarkerDrawer={onOpenMarkerDrawer}
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onMarkerSettingClose={() => {}}
					form={form as any}
				/>

				<Button
					sx={{ width: 200 }}
					variant="outlined"
					onClick={() => onOpenCustomLegend()}>
					Customise legend
				</Button>
			</Stack>
			<CardActions sx={{ justifyContent: "flex-end", p: 0, mt: 2 }}>
				<Button color="secondary">Cancel</Button>
				<Button
					variant="contained"
					onClick={() => handleSubmit()}>
					Save changes
				</Button>
			</CardActions>
		</>
	);
};
