import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

export interface PropertyItemProps {
	name: string | React.ReactNode;
	value: string | React.ReactNode;
	boxProps?: BoxProps;
}

export function PropertyItem({
	name,
	value,
	boxProps,
}: PropertyItemProps): React.JSX.Element {
	return (
		<Box
			{...boxProps}
			sx={{
				alignItems: "center",
				display: "grid",
				gridGap: "var(--PropertyItem-gap, 8px)",
				gridTemplateColumns: "var(--PropertyItem-columns)",
				p: "var(--PropertyItem-padding, 8px)",
				...boxProps?.sx,
			}}>
			<div>
				{typeof name === "string" ? (
					<Typography
						color="text.secondary"
						variant="body2">
						{name}
					</Typography>
				) : (
					<React.Fragment>{name}</React.Fragment>
				)}
			</div>
			<div>
				{typeof value === "string" ? (
					<Typography
						color={value ? "text.primary" : "text.secondary"}
						variant="subtitle2">
						{value || "None"}
					</Typography>
				) : (
					<React.Fragment>{value}</React.Fragment>
				)}
			</div>
		</Box>
	);
}
