import * as React from "react";

import type { Settings } from "~ui-components/contexts/settings";
import { SettingsContext } from "~ui-components/contexts/settings";

export function useSettings(): Settings {
	const context = React.useContext(SettingsContext);

	if (!context) {
		throw new Error("useSettings must be used within a SettingsProvider");
	}

	return context;
}
