/* eslint-disable @typescript-eslint/no-empty-function */

import { Stack, Divider, Button, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { PencilSimple, TrashSimple } from "@phosphor-icons/react/dist/ssr";

export interface Sketch {
	id: string;
	cookie_code?: string;
	title: string;
	description: string;
	geojson?: string;
	editable: boolean;
	created_at?: string;
}

interface Props {
	onCreate: () => void;
	onEditSketch: (sketch: Sketch) => void;
	onDeleteSketch: (id: string) => void;
	sketches: Sketch[];
}

export const Sketches = ({
	onCreate = () => {},
	onEditSketch = () => {},
	onDeleteSketch = () => {},
	sketches = [],
}: Props) => {
	return (
		<Stack
			sx={{
				height: "100%",
				flexDirection: "column",
				pt: 2,
			}}>
			<Stack
				spacing={2}
				sx={{ flexGrow: 1, overflowY: "auto", overflowX: "hidden" }}>
				{sketches.map((sketch) => (
					<>
						<Stack
							px={2}
							direction="row"
							key={sketch.id}
							sx={{
								alignItems: "center",
								flexWrap: "wrap",
								justifyContent: "space-between",
							}}>
							<div>
								<Typography variant="subtitle1">{sketch.title}</Typography>
								<Typography
									color="text.secondary"
									variant="caption">
									{sketch.description}
								</Typography>
							</div>
							<Stack
								direction="row"
								spacing={2}
								sx={{ alignItems: "center" }}>
								<IconButton
									onClick={() => {
										onEditSketch(sketch);
									}}
									aria-label="edit">
									<PencilSimple fontSize={24} />
								</IconButton>
								<IconButton
									onClick={() => {
										onDeleteSketch(sketch.id);
									}}
									aria-label="trash">
									<TrashSimple fontSize={24} />
								</IconButton>
							</Stack>
						</Stack>
						<Divider />
					</>
				))}
			</Stack>
			<Stack
				p={2}
				spacing={1}>
				<Button
					onClick={onCreate}
					variant="outlined"
					color="success"
					size="large">
					Create
				</Button>
			</Stack>
		</Stack>
	);
};
