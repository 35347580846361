import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { Copyright as CopyrightIcon } from "@phosphor-icons/react/dist/ssr/Copyright";
import { Question as QuestionIcon } from "@phosphor-icons/react/dist/ssr/Question";
import RouterLink from "next/link";
import * as React from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

import {
	ToolsCopyrightForm,
	ToolsCopyrightFormProps,
} from "./tools-copyright-form";

const href =
	"https://help.mangomap.com/hc/en-us/articles/360022190112-Custom-Copyright";

interface ToolsCopyrightProps {
	form: ToolsCopyrightFormProps["form"];
}

export function ToolsCopyright(props: ToolsCopyrightProps): React.JSX.Element {
	const { form } = props;

	return (
		<DashboardContentLayout pageTitle="Copyright">
			<Card>
				<CardHeader
					action={
						<IconButton
							sx={{ borderRadius: 99 }}
							component={RouterLink}
							href={href}>
							<QuestionIcon fontSize="inherit" />
						</IconButton>
					}
					avatar={
						<Avatar>
							<CopyrightIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Display custom attribution"
				/>
				<ToolsCopyrightForm form={form} />
			</Card>
		</DashboardContentLayout>
	);
}

export * from "./tools-copyright-form";
