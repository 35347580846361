import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import { FC } from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import {
	AccountEditSubscription,
	EditSubscriptionFormValues,
} from "~ui-components/components/organisms/user-accounts/edit-subscription";
import { EditSubscriptionConfirmCard } from "~ui-components/components/organisms/user-accounts/edit-subscription-confirm-card";
import { AccountEditSubscriptionSummary } from "~ui-components/components/organisms/user-accounts/edit-subscription-summary";

interface EditSubscriptionProps {
	form: ReturnType<typeof useFormik<EditSubscriptionFormValues>>;
}

export const EditSubscription: FC<EditSubscriptionProps> = ({ form }) => {
	return (
		<DashboardContentLayout pageTitle="Edit Subscription">
			<Stack
				spacing={4}
				component={"form"}
				onSubmit={form.handleSubmit}>
				<Grid
					container
					spacing={4}>
					<Grid
						item
						md={8}
						xs={12}>
						<AccountEditSubscription form={form} />
					</Grid>
					<Grid
						item
						md={4}
						xs={12}>
						<Stack spacing={4}>
							<AccountEditSubscriptionSummary />
							<EditSubscriptionConfirmCard
								disabledCheckout={!form.isValid || form.isSubmitting}
							/>
						</Stack>
					</Grid>
				</Grid>
			</Stack>
		</DashboardContentLayout>
	);
};
