import { Button, Stack } from "@mui/material";
import { useState } from "react";

import { MapToolDrawer } from "~ui-components/components/molecules/map-tool-drawer";
import { QueryTableResults } from "~ui-components/components/molecules/map-view-tools";

import { QueryToolForm } from "./query-tool-form";

type ViewProps = "query" | "results";

export const QueryToolDrawer = ({ onClose, layers }) => {
	const [view, setView] = useState<ViewProps>("query");

	return (
		<MapToolDrawer
			title="Query Tool"
			onClose={onClose}
			Content={
				<Stack spacing={2}>
					{view === "query" && <QueryToolForm layers={layers} />}
					{view === "results" && (
						<QueryTableResults onClickTryAgain={() => setView("query")} />
					)}
				</Stack>
			}
			Action={
				<Stack
					direction={"row-reverse"}
					spacing={2}>
					<Button
						variant="contained"
						onClick={() => setView("results")}>
						Get results
					</Button>
					<Button color="inherit">Reset</Button>
				</Stack>
			}
		/>
	);
};
