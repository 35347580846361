import { FormControl } from "@mui/material";
import {
	FormHelperText,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import { Hash, TextAa } from "@phosphor-icons/react/dist/ssr";
import { map, upperFirst } from "lodash";

const InputAdornmentIcon = {
	string: <TextAa />,
	number: <Hash />,
};

export const PropertiesForm = ({ properties }) => {
	return (
		<form>
			{map(properties, (value, name) => {
				const type = typeof value;
				return (
					<FormControl>
						<InputLabel>{upperFirst(name)}</InputLabel>
						<OutlinedInput
							fullWidth
							name={name}
							endAdornment={
								<InputAdornment position="end">
									{InputAdornmentIcon[type] || ""}
								</InputAdornment>
							}
						/>
						<FormHelperText>{name}</FormHelperText>
					</FormControl>
				);
			})}
		</form>
	);
};
