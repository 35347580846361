import {
	Stack,
	FormControl,
	InputLabel,
	OutlinedInput,
	Button,
} from "@mui/material";
import { useFormik } from "formik";

export interface TextAnnotation {
	text: string;
}

interface Props {
	onDelete: () => void;
	form: ReturnType<typeof useFormik<TextAnnotation>>;
}

export const SketchTextAnnotationEditor = ({
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onDelete = () => {},
	form,
}: Props) => {
	const { values, handleChange, handleSubmit } = form;
	return (
		<Stack
			p={2}
			sx={{
				height: "100%",
				flexDirection: "column",
			}}>
			<Stack
				spacing={1}
				sx={{ flexGrow: 1 }}>
				<FormControl>
					<InputLabel>Text</InputLabel>
					<OutlinedInput
						multiline
						sx={{ width: 200 }}
						name="text"
						onChange={handleChange}
						onBeforeInput={handleChange}
						value={values.text}
					/>
				</FormControl>
			</Stack>
			<Stack
				direction="row"
				spacing={1}
				justifyContent="end">
				<Button
					onClick={onDelete}
					color="secondary">
					Delete
				</Button>
				<Button
					onClick={() => handleSubmit()}
					variant="contained"
					color="primary">
					Save
				</Button>
			</Stack>
		</Stack>
	);
};
