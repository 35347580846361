import TablePagination, {
	TablePaginationProps,
} from "@mui/material/TablePagination";
import { FC } from "react";

export type InvoicesPaginationProps = Pick<
	TablePaginationProps,
	"count" | "page" | "onPageChange" | "onRowsPerPageChange"
>;

export const InvoicesPagination: FC<InvoicesPaginationProps> = ({
	count,
	page,
	onPageChange,
	onRowsPerPageChange,
}) => {
	return (
		<TablePagination
			component="div"
			count={count}
			onPageChange={onPageChange}
			onRowsPerPageChange={onRowsPerPageChange}
			page={page}
			rowsPerPage={5}
			rowsPerPageOptions={[5, 10, 25]}
		/>
	);
};
