import { useFormik } from "formik";
import {
	ForgotPasswordFormValues,
	ForgotPasswordSchema,
	toast,
	ForgotPassword as View,
} from "ui-components";

import { useSendUpdatePasswordEmail } from "~auth/queries/useSendUpdatePasswordEmail";

export const ForgotPassword = () => {
	const sendEmail = useSendUpdatePasswordEmail();
	const form = useFormik<ForgotPasswordFormValues>({
		validateOnMount: true,
		initialValues: {
			email: "",
		},
		validationSchema: ForgotPasswordSchema,
		onSubmit: (values, helpers) => {
			toast.promise(
				async () => {
					await sendEmail.mutateAsync(values).catch(console.error);
					helpers.setSubmitting(false);
				},
				{
					loading: "Sending reset link...",
					success:
						"If the email is linked to an account, reset instructions will be sent shortly.",
				},
			);
		},
	});

	return <View form={form} />;
};
