import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { At as AtIcon } from "@phosphor-icons/react/dist/ssr/At";
import { CheckSquare as CheckSquareIcon } from "@phosphor-icons/react/dist/ssr/CheckSquare";
import { FrameCorners as FrameCornersIcon } from "@phosphor-icons/react/dist/ssr/FrameCorners";
import { LinkSimple as LinkSimpleIcon } from "@phosphor-icons/react/dist/ssr/LinkSimple";
import { List as ListIcon } from "@phosphor-icons/react/dist/ssr/List";
import { useState } from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

interface Slots {
	TermModal?: any;
}

const Empty = () => null;

const defaultSlots: Slots = {
	TermModal: Empty,
};

interface Props {
	slots?: Slots;
	slotProps?: any;
}

export function BrandNavigation(props: Props) {
	return (
		<DashboardContentLayout pageTitle="Add navigation">
			<Root {...props} />
		</DashboardContentLayout>
	);
}

function Root(props: Props): React.JSX.Element {
	const { slots = {} } = props;

	const Slots = {
		...defaultSlots,
		...slots,
	};

	const [tab, setTab] = useState<string>("overview");

	return (
		<Card>
			<CardContent>
				<Stack spacing={3}>
					<Stack spacing={2}>
						<Tabs
							onChange={(_, value: string) => {
								setTab(value);
							}}
							value={tab}>
							<Tab
								icon={<ListIcon />}
								iconPosition="start"
								label="Maps"
								value="maps"
							/>
							<Tab
								icon={<FrameCornersIcon />}
								iconPosition="start"
								label="Modal"
								value="modal"
							/>

							<Tab
								icon={<LinkSimpleIcon />}
								iconPosition="start"
								label="Link"
								value="link"
							/>
							<Tab
								icon={<AtIcon />}
								iconPosition="start"
								label="Email"
								value="email"
							/>
							<Tab
								icon={<CheckSquareIcon />}
								iconPosition="start"
								label="Terms Modal"
								value="terms"
							/>
						</Tabs>
						<Box
							sx={{
								display: "flex",
								flex: "1 1 auto",
								flexDirection: "column",
								minHeight: 0,
								overflowY: "auto",
							}}>
							{tab === "terms" ? (
								<Stack
									spacing={4}
									sx={{ flex: "1 1 auto" }}>
									<Slots.TermModal />
								</Stack>
							) : null}
							{tab === "subtasks" ? <Stack spacing={2}></Stack> : null}
							{tab === "comments" ? <Stack spacing={5}></Stack> : null}
						</Box>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	);
}
