import { FC } from "react";

import {
	ToolsQueryDetailsForm,
	ToolsQueryDetailsFormProps,
} from "~ui-components/components/organisms/tools-query-panels/details-forms";
import {
	ToolsQueryLayerConfigsForm,
	ToolsQueryLayerConfigsFormProps,
} from "~ui-components/components/organisms/tools-query-panels/layer-configs-form";

export interface ToolsQueryProps {
	showLayerConfigsForm: boolean;
	detailsFormProps: ToolsQueryDetailsFormProps;
	layerConfigsFormProps: ToolsQueryLayerConfigsFormProps;
}
export const ToolsQuery: FC<ToolsQueryProps> = ({
	showLayerConfigsForm,
	detailsFormProps,
	layerConfigsFormProps,
}) => {
	return (
		<>
			{showLayerConfigsForm ? (
				<ToolsQueryLayerConfigsForm {...layerConfigsFormProps} />
			) : (
				<ToolsQueryDetailsForm {...detailsFormProps} />
			)}
		</>
	);
};
