import Box from "@mui/material/Box";
import { EditorContent } from "@tiptap/react";
import * as React from "react";

import { RichTextStyleWrapper } from "../rich-text-view";

import {
	TextEditorToolbar,
	TextEditorToolbarProps,
} from "./text-editor-toolbar";
import { useEditor, UseEditorOptions } from "./use-editor";
import { LinkMenu } from "./link-menu";
import { ImageMenu } from "./image-menu";
import { IFrameMenu } from "./iframe-menu";
import { TableMenu } from "./table-menu";
import { ChartMenu } from "./chart-menu";

type TextEditorProps = UseEditorOptions & {
	toolsProps?: TextEditorToolbarProps["toolsProps"];
};

const defaultToolsProps: TextEditorToolbarProps["toolsProps"] = {
	Typography: true,
	Bold: true,
	Italic: true,
	Strike: true,
	BulletList: true,
	OrderedList: true,
};

export const TextEditor: React.FC<TextEditorProps> = ({
	autofocus,
	content,
	editable = true,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onUpdate = () => {},
	placeholder,
	toolsProps: $toolsProps,
}) => {
	const editor = useEditor({
		autofocus,
		content,
		editable,
		onUpdate,
		placeholder,
	});

	if (!editor) return null;

	const toolsProps = { ...defaultToolsProps, ...$toolsProps };

	return (
		<Box
			className="tiptap-root"
			sx={{
				display: "flex",
				flexDirection: "column",
				...(editable && {
					border: "1px solid var(--mui-palette-divider)",
					borderRadius: 1,
					boxShadow: "var(--mui-shadows-1)",
				}),
				"& .tiptap-container": {
					display: "flex",
					flex: "1 1 auto",
					flexDirection: "column",
					minHeight: 0,
				},
				"& .tiptap": {
					color: "var(--mui-palette-text-primary)",
					flex: "1 1 auto",
					overflow: "auto",
					p: "8px 16px",
					"&:focus-visible": { outline: "none" },
					"&.resize-cursor": {
						cursor: "ew-resize",
						"& table": { cursor: "col-resize" },
					},
					"& .is-editor-empty:before": {
						color: "var(--mui-palette-text-secondary)",
						content: "attr(data-placeholder)",
						float: "left",
						height: 0,
						pointerEvents: "none",
					},
				},
			}}>
			{!!toolsProps?.LinkInput && (
				<LinkMenu
					editor={editor}
					{...toolsProps.LinkInput}
				/>
			)}
			{!!toolsProps?.ImageInput && (
				<ImageMenu
					editor={editor}
					{...toolsProps.ImageInput}
				/>
			)}
			{!!toolsProps?.IFrameInput && (
				<IFrameMenu
					editor={editor}
					{...toolsProps.IFrameInput}
				/>
			)}
			{!!toolsProps?.Table && <TableMenu editor={editor} />}
			{!!toolsProps?.Chart && (
				<ChartMenu
					editor={editor}
					{...toolsProps.Chart}
				/>
			)}
			<TextEditorToolbar
				editor={editor}
				toolsProps={toolsProps}
			/>
			<RichTextStyleWrapper>
				<EditorContent
					className="tiptap-container"
					editor={editor}
				/>
			</RichTextStyleWrapper>
		</Box>
	);
};
