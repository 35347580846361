import axios from "axios";
import { get as getIdb, set as setIdb } from "idb-keyval";

export const fetchDatasetById = async (
	datasetId: string,
): Promise<GeoJSON.FeatureCollection> => {
	const localDataset = await getIdb<GeoJSON.FeatureCollection>(datasetId);
	if (localDataset) return localDataset;

	const { data: remoteDataset } = await axios.get<GeoJSON.FeatureCollection>(
		getDatasetUrl(datasetId),
	);

	await setIdb(datasetId, remoteDataset);

	return remoteDataset;
};

export const getDatasetUrl = (datasetId: string) =>
	`https://${process.env.DATASETS_TEMP_BUCKET_NAME}.s3.${process.env.REGION}.amazonaws.com/datasets/${datasetId}/input.geojson`;
