import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { ListMagnifyingGlass as ListMagnifyingGlassIcon } from "@phosphor-icons/react/dist/ssr/ListMagnifyingGlass";
import { Question as QuestionIcon } from "@phosphor-icons/react/dist/ssr/Question";
import RouterLink from "next/link";
import * as React from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

import {
	ToolsAdvancedSearchForm,
	ToolsAdvancedSearchFormProps,
} from "./tools-advanced-search-form";

const href =
	"https://help.mangomap.com/hc/en-us/articles/360022190172-Custom-Search";

interface ToolsAdvancedSearchProps {
	form: ToolsAdvancedSearchFormProps["form"];
	layers: ToolsAdvancedSearchFormProps["layers"];
}

export function ToolsAdvancedSearch(
	props: ToolsAdvancedSearchProps,
): React.JSX.Element {
	const { form, layers } = props;

	return (
		<DashboardContentLayout pageTitle="Advanced Search">
			<Card>
				<CardHeader
					action={
						<Button
							href={href}
							component={RouterLink}
							color="secondary"
							endIcon={<QuestionIcon />}>
							Learn more
						</Button>
					}
					avatar={
						<Avatar>
							<ListMagnifyingGlassIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Find a features in a layer by one or more attributes"
				/>
				<ToolsAdvancedSearchForm
					form={form}
					layers={layers}
				/>
			</Card>
		</DashboardContentLayout>
	);
}

export * from "./tools-advanced-search-form";
