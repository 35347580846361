import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { PencilSimple as PencilSimpleIcon } from "@phosphor-icons/react/dist/ssr/PencilSimple";
import { TrashSimple as TrashSimpleIcon } from "@phosphor-icons/react/dist/ssr/TrashSimple";
import { paths } from "config/paths";
import RouterLink from "next/link";
import { useRouter } from "next/router";
import * as React from "react";

import {
	ColumnDef,
	DataTable,
} from "~ui-components/components/atoms/data-table";
import { ConfirmationModal } from "~ui-components/index";
import { UserGroup } from "~ui-components/types/__generated/gql/graphql";

export interface AccessGroupsTableProps {
	rows: UserGroup[];
	onItemDelete?: (item: UserGroup) => void;
}

export function AccessGroupsTable(props: AccessGroupsTableProps) {
	const router = useRouter();

	const { rows, onItemDelete } = props;

	const [open, setOpen] = React.useState(false);
	const [item, setItem] = React.useState(null);

	const columns = React.useMemo(
		() =>
			[
				{
					formatter: (row): React.JSX.Element => (
						<Typography
							variant="subtitle2"
							color="text.primary">
							{row.name}
						</Typography>
					),
					name: "Name",
					width: "250px",
				},
				{
					formatter: (row): React.JSX.Element => (
						<Typography
							variant="body2"
							color="text.primary">
							{row._userGroupMembers.length}
						</Typography>
					),
					name: "Members",
					width: "250px",
				},
				{
					formatter: (row): React.JSX.Element => (
						<Stack
							spacing={1}
							direction="row"
							justifyContent="flex-end">
							<IconButton
								component={RouterLink}
								href={paths.accountName.groups.edit(
									router.query["account-name"],
									row.id,
								)}>
								<PencilSimpleIcon />
							</IconButton>
							<IconButton onClick={() => handleClick(row)}>
								<TrashSimpleIcon />
							</IconButton>
						</Stack>
					),
					width: "100px",
					align: "right",
				},
			] as ColumnDef<UserGroup>[],

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[router.query["account-name"]],
	);

	const handleClick = (row) => {
		setOpen(true);
		setItem(row);
	};

	const handleClose = () => {
		setOpen(false);
		setItem(null);
	};

	const handleConfirm = () => {
		if (item) onItemDelete?.(item);
		handleClose();
	};

	return (
		<React.Fragment>
			<ConfirmationModal
				open={open}
				title="Permanently delete this access group?"
				content="All access granted by this group will be immediately revoked."
				actionText="Permanently delete"
				onClose={handleClose}
				onConfirm={handleConfirm}
			/>

			<DataTable
				columns={columns}
				rows={rows}
			/>
			{!rows.length ? (
				<Box sx={{ p: 3 }}>
					<Typography
						color="text.secondary"
						sx={{ textAlign: "center" }}
						variant="body2">
						No groups found
					</Typography>
				</Box>
			) : null}
		</React.Fragment>
	);
}
