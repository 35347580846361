import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export interface SplitLayoutProps {
	title: string;
	subtitle: string;
	caption?: string;
	children: React.ReactNode;
}

export function SplitLayout({
	title,
	subtitle,
	caption,
	children,
}: SplitLayoutProps): React.JSX.Element {
	return (
		<Box
			sx={{
				display: "grid",
				gridTemplateColumns: { xs: "1fr", lg: "1fr 800px" },
				minHeight: "100dvh",
			}}>
			<Box
				sx={{
					alignItems: "center",
					justifyContent: "center",
					bgcolor: "var(--mui-palette-background-level1)",
					display: { xs: "none", lg: "flex" },
					flexDirection: "column",
					p: 3,
				}}>
				<Stack
					spacing={4}
					sx={{ maxWidth: "700px" }}>
					<Stack spacing={1}>
						<Typography variant="h4">{title}</Typography>
						<Typography color="text.secondary">{subtitle}</Typography>
					</Stack>

					<Stack>
						{!!caption && (
							<Typography
								variant="caption"
								color="text.secondary"
								gutterBottom>
								{caption}
							</Typography>
						)}
						<Stack
							direction="row"
							spacing={3}
							sx={{
								alignItems: "center",
								color: "var(--mui-palette-neutral-500)",
								flexWrap: "wrap",
							}}>
							<Box
								component="img"
								src="/new-mangomap-assets/burgex.svg"
								alt="burgex"
								height="24px"
							/>
							<Box
								component="img"
								src="/new-mangomap-assets/gis4ag.svg"
								alt="gis4ag"
								height="24px"
							/>
							<Box
								component="img"
								src="/new-mangomap-assets/ogl.png"
								alt="ogl"
								height="24px"
							/>
							<Box
								component="img"
								src="/new-mangomap-assets/plumas.png"
								alt="plumas"
								height="24px"
							/>
							<Box
								component="img"
								src="/new-mangomap-assets/sensar.png"
								alt="sensar"
								height="24px"
							/>
							<Box
								component="img"
								src="/new-mangomap-assets/swift.svg"
								alt="swift"
								height="24px"
							/>
							<Box
								component="img"
								src="/new-mangomap-assets/thinkcity.png"
								alt="thinkcity"
								height="24px"
							/>
						</Stack>
					</Stack>
				</Stack>
			</Box>
			<Box
				sx={{
					boxShadow: "var(--mui-shadows-8)",
					display: "flex",
					flexDirection: "column",
				}}>
				<Box
					sx={{
						alignItems: "center",
						display: "flex",
						flexDirection: "column",
						flex: "1 1 auto",
						justifyContent: "center",
						p: 3,
					}}>
					<Box sx={{ maxWidth: "420px", width: "100%" }}>{children}</Box>
				</Box>
			</Box>
		</Box>
	);
}
