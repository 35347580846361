import * as React from "react";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import * as yup from "yup";
import { FormikProps } from "formik";

import { ButtonCancel } from "~ui-components/components/atoms/button-cancel";

export const ToolsPrintSchema = yup.object({
	title: yup.string().required("Required"),
	description: yup.string().required("Required"),
	popup: yup.boolean(),
	legend: yup.boolean(),
	selectedFeatureHighlights: yup.boolean(),
	basemap: yup.boolean(),
	scaleBar: yup.boolean(),
	northing: yup.boolean(),
	printOption: yup
		.string()
		.oneOf(["users-change-settings", "all-prints-use-settings"])
		.required("Required"),
});

export type ToolsPrintFormValues = yup.InferType<typeof ToolsPrintSchema>;

export interface ToolsPrintFormProps {
	form: FormikProps<ToolsPrintFormValues>;
}

export function ToolsPrintForm(props: ToolsPrintFormProps): React.JSX.Element {
	const { form } = props;
	return (
		<form onSubmit={form.handleSubmit}>
			<CardContent>
				<Stack spacing={4}>
					<Stack spacing={3}>
						<Grid
							container
							spacing={3}>
							<Grid xs={12}>
								<FormControl
									fullWidth
									error={!!form.errors.title}>
									<InputLabel>Map title</InputLabel>
									<OutlinedInput {...form.getFieldProps("title")} />
									{form.errors.title && (
										<FormHelperText>{form.errors.title}</FormHelperText>
									)}
								</FormControl>
							</Grid>
							<Grid xs={12}>
								<FormControl
									fullWidth
									error={!!form.errors.description}>
									<InputLabel>Description</InputLabel>
									<OutlinedInput
										minRows={3}
										multiline
										{...form.getFieldProps("description")}
									/>
									{form.errors.description && (
										<FormHelperText>{form.errors.description}</FormHelperText>
									)}
								</FormControl>
							</Grid>
							<Grid xs={12}>
								<Stack
									component={FormControl}
									fullWidth
									spacing={3}>
									<FormControlLabel
										control={
											<Checkbox
												size="medium"
												{...form.getFieldProps("popup")}
											/>
										}
										label="Popup"
										value="popup"
									/>
									<FormControlLabel
										control={
											<Checkbox
												size="medium"
												{...form.getFieldProps("legend")}
											/>
										}
										label="Legend"
										value="legend"
									/>
									<FormControlLabel
										control={
											<Checkbox
												size="medium"
												{...form.getFieldProps("selectedFeatureHighlights")}
											/>
										}
										label="Selected feature highlights"
										value="selected-feature-highlights"
									/>
									<FormControlLabel
										control={
											<Checkbox
												size="medium"
												{...form.getFieldProps("basemap")}
											/>
										}
										label="Basemap"
										value="basemap"
									/>
									<FormControlLabel
										control={
											<Checkbox
												size="medium"
												{...form.getFieldProps("scaleBar")}
											/>
										}
										label="Scale bar"
										value="scale-bar"
									/>
									<FormControlLabel
										control={
											<Checkbox
												size="medium"
												{...form.getFieldProps("northing")}
											/>
										}
										label="Northing"
										value="northing"
									/>
								</Stack>
							</Grid>
						</Grid>
					</Stack>
					<Stack spacing={3}>
						<Typography variant="h6">User options</Typography>
						<Grid
							container
							spacing={3}>
							<Grid xs={12}>
								<FormControl fullWidth>
									<Stack
										component={RadioGroup}
										spacing={3}
										defaultValue="users-change-settings"
										{...form.getFieldProps("printOption")}>
										<FormControlLabel
											control={<Radio />}
											label="Users can change these settings"
											value="users-change-settings"
										/>
										<FormControlLabel
											control={<Radio size="medium" />}
											label="All prints use these settings"
											value="all-prints-use-settings"
										/>
									</Stack>
								</FormControl>
							</Grid>
						</Grid>
					</Stack>
				</Stack>
			</CardContent>
			<CardActions sx={{ justifyContent: "flex-end" }}>
				<ButtonCancel />
				<Button
					type="submit"
					variant="contained"
					disabled={!form.isValid}>
					Save changes
				</Button>
			</CardActions>
		</form>
	);
}
