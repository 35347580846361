import { Box } from "@mui/material";
import { FC } from "react";

const HEIGHT = 32;
const WIDTH = 122;

export interface MangoFranchiseLogoProps {
	height?: number;
	width?: number;
}

export const MangoFranchiseLogo: FC<MangoFranchiseLogoProps> = ({
	width = WIDTH,
	height = HEIGHT,
}) => {
	return (
		<Box
			width={width}
			height={height}>
			<svg
				width="200"
				height="56"
				viewBox="0 0 200 56"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#clip0_1096_39)">
					<path
						d="M16.0717 32.425C24.9479 32.425 32.1434 25.1664 32.1434 16.2125C32.1434 7.25859 24.9479 0 16.0717 0C7.19555 0 0 7.25859 0 16.2125C0 25.1664 7.19555 32.425 16.0717 32.425Z"
						fill="#FF5722"
					/>
					<path
						d="M11.098 27.3702C17.2272 27.3702 22.196 22.3684 22.196 16.1982C22.196 10.0281 17.2272 5.02625 11.098 5.02625C4.96874 5.02625 0 10.0281 0 16.1982C0 22.3684 4.96874 27.3702 11.098 27.3702Z"
						fill="#FF9800"
					/>
					<path
						d="M30.5475 9.15297C30.1025 9.15297 29.6909 9.13159 28.4136 9.3225C25.7924 9.71501 23.4641 10.7851 21.4207 12.2681C13.7237 17.8569 10.053 29.1058 9.74647 30.0832C9.73637 30.1117 9.73384 30.1219 9.73081 30.1316C9.72879 30.1377 9.72374 30.1535 9.72374 30.1535C9.72374 30.1535 9.72879 30.1397 9.72879 30.1367C9.73283 30.126 9.73485 30.1189 9.74344 30.0914C10.0965 28.8716 15.2828 10.3234 2.82576 9.18912C2.43637 9.15195 2.03283 9.13261 1.60758 9.13159C0.579802 11.2667 0.00202403 13.6635 3.82924e-06 16.1968C-0.00605678 25.1512 7.18283 32.4174 16.0606 32.4245C24.9354 32.4317 32.1379 25.1781 32.1439 16.2222C32.1455 13.6905 31.5722 11.2927 30.5475 9.15297Z"
						fill="url(#paint0_linear_1096_39)"
					/>
					<path
						d="M56.6824 10.3697L49.4531 20.3408H48.6288L41.5596 10.3361V27.0312H37.3738V4.07275H42.1925L49.1364 13.9445L56.0798 4.07275H60.8667V27.0317H56.6824V10.3697Z"
						// fill="#EBEEF0"
						fill="#263238"
					/>
					<path
						d="M77.5955 10.7731H81.403V26.9428H77.6606L77.4652 24.5832C76.5545 26.4841 74.0485 27.404 72.2591 27.4361C67.5091 27.4687 63.9081 24.7659 63.9081 19.0926C63.9081 13.515 67.6712 10.3459 72.3566 10.3795C74.504 10.3795 76.555 11.3972 77.4652 13.0049L77.5955 10.7731ZM67.8788 19.0926C67.8788 22.1762 70.0783 23.7625 72.7136 23.7625C78.9611 23.7625 78.9611 13.9554 72.7136 13.9554C70.0788 13.9554 67.8788 16.0091 67.8788 19.0926Z"
						// fill="#EBEEF0"
						fill="#263238"
					/>
					<path
						d="M95.6546 26.989V18.5264C95.6546 16.0685 94.6324 14.0673 92.0935 14.0673C89.6536 14.0673 88.5324 16.2635 88.5324 18.7234V26.9885H84.2596L84.2733 10.4777H87.8203L88.0844 12.9386C89.7122 11.3299 91.5006 10.4777 93.5172 10.4777C97.292 10.4777 99.9263 13.2899 99.9263 18.3742V26.9885H95.6546V26.989Z"
						// fill="#EBEEF0"
						fill="#263238"
					/>
					<path
						d="M118.444 18.4473C118.444 14.1516 114.989 10.2484 110.34 10.2484C105.85 10.2484 102.206 13.3982 102.206 18.4473C102.206 23.4985 105.33 26.5826 110.34 26.5826C112.326 26.5826 114.409 27.5982 114.409 29.8286C114.409 32.0599 112.619 33.3056 110.34 33.3056C108.063 33.3056 106.337 31.9616 106.337 29.8596H102.064C102.064 34.2215 105.687 37.0424 110.34 37.0424C114.993 37.0424 118.377 34.2882 118.377 29.828C118.377 27.7616 117.726 25.8266 115.059 24.4826C117.693 23.2699 118.444 20.4496 118.444 18.4473ZM110.34 22.9406C108.03 22.9406 106.143 21.2692 106.143 18.4478C106.143 15.6275 108.029 13.8889 110.34 13.8889C112.619 13.8889 114.506 15.66 114.506 18.4478C114.506 21.2371 112.618 22.9406 110.34 22.9406Z"
						// fill="#EBEEF0"
						fill="#263238"
					/>
					<path
						d="M136.248 18.3745C136.248 23.0322 133.387 26.9899 128.183 26.9899C122.976 26.9899 119.868 23.0327 119.868 18.3745C119.868 13.751 123.04 10.1318 128.148 10.1318C133.259 10.1313 136.248 13.751 136.248 18.3745ZM123.822 18.5603C123.822 21.0193 125.286 23.3183 128.183 23.3183C131.079 23.3183 132.542 21.0193 132.542 18.5603C132.542 16.1315 130.85 13.7729 128.183 13.7729C125.32 13.7729 123.822 16.132 123.822 18.5603Z"
						// fill="#EBEEF0"
						fill="#263238"
					/>
					<path
						d="M60.8376 55.4472V47.0261H72.1971V43.0594H60.8376V36.8492H72.8444V32.72H56.5657V55.4472H60.8376Z"
						fill="#FF5722"
					/>
					<path
						d="M75.2474 39.4178V55.4472H79.1957V46.9936C79.1957 44.0348 81.1051 42.9294 83.1764 42.9294C84.4709 42.9294 85.2152 43.287 86.0243 43.9698L87.8043 40.5233C86.9305 39.6454 85.3447 38.9952 83.6942 38.9952C82.076 38.9952 80.4255 39.2878 79.1957 41.2711L78.9044 39.4178H75.2474Z"
						fill="#FF5722"
					/>
					<path
						d="M101.562 41.6288C100.656 40.0356 98.6167 39.0277 96.4807 39.0277C91.8204 38.9952 88.1633 41.8889 88.1633 47.4163C88.1633 53.0412 91.6586 55.9675 96.3836 55.9349C98.1636 55.9024 100.656 54.992 101.562 53.1062L101.756 55.4472H105.478V39.4178H101.691L101.562 41.6288ZM96.8367 42.5717C103.05 42.5717 103.05 52.2934 96.8367 52.2934C94.2153 52.2934 92.1116 50.4726 92.1116 47.4163C92.1116 44.36 94.2153 42.5717 96.8367 42.5717Z"
						fill="#FF5722"
					/>
					<path
						d="M124.275 55.4472V47.0261C124.275 41.9864 121.265 39.1577 117.511 39.1577C115.505 39.1577 113.887 39.9706 112.268 41.5638L112.009 39.3853H108.482V55.4472H112.398V47.2537C112.398 44.8152 114.048 42.7668 116.476 42.7668C119 42.7668 120.327 44.6201 120.327 47.0586V55.4472H124.275Z"
						fill="#FF5722"
					/>
					<path
						d="M138.461 50.7977C137.49 51.7732 136.228 52.1308 134.966 52.1308C132.507 52.1308 130.435 50.5051 130.435 47.4488C130.435 44.6526 132.377 42.7018 135.031 42.7018C136.131 42.7018 137.296 43.1244 138.235 43.9698L140.727 41.3362C138.979 39.6129 137.232 38.9627 134.966 38.9627C130.338 38.9627 126.487 41.7589 126.487 47.4488C126.487 53.1387 130.338 55.9349 134.966 55.9349C137.329 55.9349 139.27 55.2196 141.115 53.3988L138.461 50.7977Z"
						fill="#FF5722"
					/>
					<path
						d="M142.606 32.6875V55.4472H146.554V47.2537C146.554 44.8477 148.172 42.8643 150.503 42.8643C152.606 42.8643 154.127 44.0999 154.127 47.0261V55.4472H158.076V46.9936C158.076 42.2791 156.069 39.1903 151.538 39.1903C149.726 39.1903 147.978 39.743 146.554 41.5963V32.6875H142.606Z"
						fill="#FF5722"
					/>
					<path
						d="M165.116 39.3528V55.4472H161.168V39.3528H165.116ZM160.812 34.8984C160.812 37.9872 165.472 37.9872 165.472 34.8984C165.472 31.8096 160.812 31.8096 160.812 34.8984Z"
						fill="#FF5722"
					/>
					<path
						d="M181.069 41.0761C179.224 39.4504 177.347 38.9627 174.855 38.9627C171.942 38.9627 168.124 40.2632 168.124 43.9698C168.124 47.6114 171.716 48.7168 174.726 48.9444C176.991 49.0745 177.897 49.5297 177.897 50.6352C177.897 51.8057 176.506 52.586 175.017 52.5535C173.237 52.521 170.648 51.5781 169.515 50.31L167.573 53.1387C169.904 55.5773 172.428 56 174.952 56C179.515 56 181.813 53.5614 181.813 50.7327C181.813 46.4734 177.994 45.7906 174.985 45.5955C172.946 45.4654 172.007 44.8802 172.007 43.8397C172.007 42.8318 173.043 42.2466 174.92 42.2466C176.441 42.2466 177.735 42.6042 178.868 43.6772L181.069 41.0761Z"
						fill="#FF5722"
					/>
					<path
						d="M199.927 48.8469C200.542 42.2466 197.209 38.9301 191.869 38.9301C186.691 38.9301 183.39 42.4416 183.39 47.3187C183.39 52.4559 186.658 55.8699 192.16 55.8699C194.587 55.8699 197.338 55.0245 199.021 53.2688L196.497 50.7652C195.591 51.7081 193.681 52.2609 192.225 52.2609C189.442 52.2609 187.726 50.8302 187.467 48.8469H199.927ZM187.532 45.563C188.115 43.4821 189.862 42.4416 191.998 42.4416C194.264 42.4416 195.882 43.4821 196.141 45.563H187.532Z"
						fill="#FF5722"
					/>
				</g>
				<defs>
					<linearGradient
						id="paint0_linear_1096_39"
						x1="3.82924e-06"
						y1="20.7783"
						x2="32.1437"
						y2="20.7783"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#8BC34A" />
						<stop
							offset="0.3929"
							stopColor="#79B041"
						/>
						<stop
							offset="1"
							stopColor="#558B2F"
						/>
					</linearGradient>
					<clipPath id="clip0_1096_39">
						<rect
							width="200"
							height="56"
							fill="white"
						/>
					</clipPath>
				</defs>
			</svg>
		</Box>
	);
};
