import { Dataset } from "~ui-components/types/__generated/gql/graphql";

export interface AddLayerPanelItemsViewProps {
	isLoading: boolean;
	items: Dataset[];
	onItemClick: (dataset: Dataset) => void;
}

export { AddLayerPanelFilters } from "./filters";
export { AddLayerPanelItemsView } from "./items-view";
