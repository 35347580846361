import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { Question as QuestionIcon } from "@phosphor-icons/react/dist/ssr/Question";
import { Sun as SunIcon } from "@phosphor-icons/react/dist/ssr/Sun";
import RouterLink from "next/link";
import * as React from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

import {
	ToolsMouseHighlightForm,
	ToolsMouseHighlightFormProps,
} from "./tools-mouse-highlight-form";

const href =
	"https://help.mangomap.com/hc/en-us/articles/360022375251-Mouse-Highlight";

interface ToolsMouseHighlightProps {
	form: ToolsMouseHighlightFormProps["form"];
}

export function ToolsMouseHighlight(
	props: ToolsMouseHighlightProps,
): React.JSX.Element {
	const { form } = props;

	return (
		<DashboardContentLayout pageTitle="Mouse Highlight">
			<Card>
				<CardHeader
					action={
						<IconButton
							sx={{ borderRadius: 99 }}
							component={RouterLink}
							href={href}>
							<QuestionIcon fontSize="inherit" />
						</IconButton>
					}
					avatar={
						<Avatar>
							<SunIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Highlight features in a layer on mouse hover"
				/>
				<ToolsMouseHighlightForm form={form} />
			</Card>
		</DashboardContentLayout>
	);
}

export * from "./tools-mouse-highlight-form";
