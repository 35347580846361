import { Box, Paper, Stack, Typography } from "@mui/material";
import {
	Bar,
	BarChart as RechartsBarChart,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { FC } from "react";

import { NoSsr } from "~ui-components/components/atoms/no-ssr";
import { ChartFormValues } from "../../text-editor/chart-menu";

const CHART_HEIGHT = 300;
const X_DATA_KEY = "name";
const Y_DATA_KEY = "value";

export const BarChart: FC<{
	dataValue: ChartFormValues;
	valuesToUsed: Record<string, any>;
}> = ({ dataValue, valuesToUsed }) => {
	const data = dataValue.attributes.map((att) => ({
		[X_DATA_KEY]: att.label,
		[Y_DATA_KEY]: valuesToUsed[att.key],
	}));

	return (
		<NoSsr fallback={<Box sx={{ height: `${CHART_HEIGHT}px` }} />}>
			<ResponsiveContainer height={CHART_HEIGHT}>
				<RechartsBarChart
					barGap={12}
					data={data}
					margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
					<CartesianGrid
						strokeDasharray="2 4"
						vertical={false}
					/>
					<XAxis
						axisLine={false}
						dataKey={X_DATA_KEY}
						tickLine={false}
						type="category"
					/>
					<YAxis
						axisLine={false}
						hide
						type="number"
					/>
					<Bar
						animationDuration={300}
						barSize={24}
						fill="var(--mui-palette-primary-main)"
						radius={[5, 5, 0, 0]}
						dataKey={Y_DATA_KEY}
					/>
					<Tooltip
						animationDuration={50}
						content={<TooltipContent />}
						cursor={false}
					/>
				</RechartsBarChart>
			</ResponsiveContainer>
		</NoSsr>
	);
};

interface TooltipContentProps {
	active?: boolean;
	payload?: { fill: string; name: string; value: number }[];
	label?: string;
}

function TooltipContent({
	active,
	payload,
}: TooltipContentProps): React.JSX.Element | null {
	if (!active) {
		return null;
	}

	return (
		<Paper
			sx={{
				border: "1px solid var(--mui-palette-divider)",
				boxShadow: "var(--mui-shadows-16)",
				p: 1,
			}}>
			<Stack spacing={2}>
				{payload?.map(
					(entry): React.JSX.Element => (
						<Stack
							direction="row"
							key={entry.name}
							spacing={3}
							sx={{ alignItems: "center" }}>
							<Stack
								direction="row"
								spacing={1}
								sx={{ alignItems: "center", flex: "1 1 auto" }}>
								<Box
									sx={{
										bgcolor: entry.fill,
										borderRadius: "2px",
										height: "8px",
										width: "8px",
									}}
								/>
								<Typography sx={{ whiteSpace: "nowrap" }}>
									{entry.name}
								</Typography>
							</Stack>
							<Typography
								color="text.secondary"
								variant="body2">
								{new Intl.NumberFormat("en-US").format(entry.value)}
							</Typography>
						</Stack>
					),
				)}
			</Stack>
		</Paper>
	);
}
