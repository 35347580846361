import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { paths } from "config/paths";
import * as React from "react";
import { useRouter } from "next/router";

import { PortalActionCard } from "~ui-components/components/molecules/portal-action-card";

import { ItemRow } from "./items/item-row";

import { DataViewProps } from ".";

export function DataPortalRowView(props: DataViewProps): React.JSX.Element {
	const router = useRouter();

	const { items, onItemDelete, isLoading } = props;

	return (
		<React.Fragment>
			<PortalActionCard
				title="Import Data"
				href={paths.accountName.data.import(router.query["account-name"])}
			/>
			{isLoading && <CircularProgress sx={{ mx: "auto" }} />}
			<Box sx={{ mx: -3, my: -6 }}>
				<Box sx={{ overflowX: "auto", px: 3 }}>
					<Table sx={{ borderCollapse: "separate", borderSpacing: "0 24px" }}>
						<TableHead sx={{ visibility: "collapse" }}>
							<TableRow>
								<TableCell
									sx={{ width: "250px", minWidth: "250px", maxWidth: "250px" }}
								/>
								<TableCell
									sx={{ width: "25px", minWidth: "25px", maxWidth: "25px" }}
								/>
								<TableCell
									sx={{ width: "25px", minWidth: "25px", maxWidth: "25px" }}
								/>
								<TableCell
									sx={{ width: "25px", minWidth: "25px", maxWidth: "25px" }}
								/>
							</TableRow>
						</TableHead>
						<TableBody>
							{Array.from(items.values()).map((item) => (
								<ItemRow
									item={item}
									key={item.id}
									onDelete={onItemDelete}
								/>
							))}
						</TableBody>
					</Table>
				</Box>
			</Box>
		</React.Fragment>
	);
}
