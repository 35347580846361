import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import { LayerSettingsSideNav } from "~ui-components/index";

interface Slots {
	Content: React.ElementType;
}

const Empty = () => null;

const defaultSlots: Slots = {
	Content: Empty,
};

interface Props {
	slots?: Slots;
}

function LayerDetailsLayout(props: Props) {
	return (
		<DashboardContentLayout pageTitle="Layers">
			<Root {...props} />
		</DashboardContentLayout>
	);
}

function Root(props: Props) {
	const { slots = {} } = props;

	const Slots = {
		...defaultSlots,
		...slots,
	};

	return (
		<Stack
			direction={{ xs: "column", md: "row" }}
			spacing={4}
			sx={{ position: "relative" }}>
			<LayerSettingsSideNav />
			<Box sx={{ flex: "1 1 auto", minWidth: 0 }}>
				<Slots.Content />
			</Box>
		</Stack>
	);
}

export { LayerDetailsLayout };
