import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

interface Slots {
	CustomQuantityClassbreak: any;
}

const Empty = () => null;

const defaultSlots: Slots = {
	CustomQuantityClassbreak: Empty,
};

interface Props {
	slots?: Slots;
}

function CustomQuantityLegend(props: Props) {
	return (
		<DashboardContentLayout disableBackButton={true}>
			<Root {...props} />
		</DashboardContentLayout>
	);
}

function Root(props: Props) {
	const { slots = {} } = props;

	const Slots = {
		...defaultSlots,
		...slots,
	};

	return (
		<Box>
			<Box>
				<Stack
					direction={{ xs: "column", md: "row" }}
					spacing={4}
					sx={{ position: "relative", mt: "44px" }}>
					<Slots.CustomQuantityClassbreak />
				</Stack>
			</Box>
		</Box>
	);
}

export { CustomQuantityLegend };
