import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { ArrowSquareOut as ArrowSquareOutIcon } from "@phosphor-icons/react/dist/ssr/ArrowSquareOut";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import { UserPlus as UserPlusIcon } from "@phosphor-icons/react/dist/ssr/UserPlus";
import { paths } from "config/paths";
import RouterLink from "next/link";
import { useRouter } from "next/router";
import * as React from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

import { DataAccessForm, DatasetAccessFormProps } from "./dataset-access-form";

interface DatasetAccessProps {
	form: DatasetAccessFormProps["form"];
}

export function DatasetAccess(props: DatasetAccessProps): React.JSX.Element {
	const router = useRouter();

	const { form } = props;

	return (
		<DashboardContentLayout
			pageTitle="Access"
			actionButtonProps={{
				children: "Add Group",
				component: RouterLink,
				startIcon: <PlusIcon />,
				variant: "contained",
				href: paths.accountName.groups.create(router.query["account-name"]),
			}}>
			<Card>
				<CardHeader
					action={
						<Button
							color="secondary"
							endIcon={<ArrowSquareOutIcon />}>
							Learn more
						</Button>
					}
					avatar={
						<Avatar>
							<UserPlusIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Data access"
				/>
				<DataAccessForm form={form} />
			</Card>
		</DashboardContentLayout>
	);
}

export * from "./dataset-access-form";
