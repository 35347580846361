import { paths } from "config/paths";
import { useRouter } from "next/router";

export const useMapPath = () => {
	const router = useRouter();
	const accountName = router.query["account-name"] as string;
	const mapId = router.query["map-id"] as string;
	const mapName = router.query["map-name"] as string;
	return paths.accountName.maps.mapId.mapName.root(accountName, mapId, mapName);
};
