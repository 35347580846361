/* eslint-disable @typescript-eslint/no-empty-function */

import {
	Stack,
	FormControl,
	FormControlLabel,
	Switch,
	TextField,
	Typography,
	Button,
} from "@mui/material";
import { useFormik } from "formik";
import { Sketch } from "ui-components";

import {
	SketchTools,
	SelectionMode,
} from "~ui-components/components/molecules/sktech-tools";

interface Props {
	onSketchModeChange: (mode: SelectionMode) => void;
	onCancel: () => void;
	form: ReturnType<typeof useFormik<Sketch>>;
}

export const SketchEditor = ({
	form,
	onCancel = () => {},
	onSketchModeChange = () => {},
}: Props) => {
	const { values, handleChange, handleSubmit, handleBlur } = form;

	return (
		<Stack
			p={2}
			sx={{
				height: "100%",
				flexDirection: "column",
			}}>
			<Stack
				spacing={1}
				sx={{ flexGrow: 1 }}>
				<Stack spacing={2}>
					<FormControl>
						<TextField
							name="title"
							value={values.title}
							onBlur={handleBlur}
							onChange={handleChange}
							label="Title"
						/>
					</FormControl>
					<FormControl>
						<TextField
							name="description"
							value={values.description}
							multiline
							minRows={3}
							label="Description"
							onBlur={handleBlur}
							onChange={handleChange}
						/>
					</FormControl>
					<FormControl>
						<FormControlLabel
							control={<Switch />}
							label="Allow editing"
						/>
						<Typography
							color="text.secondary"
							variant="caption">
							Editable sketches can be modified by all map users.
						</Typography>
					</FormControl>
					<SketchTools onSketchModeChange={onSketchModeChange} />
				</Stack>
			</Stack>
			<Stack
				direction="row"
				spacing={1}
				justifyContent="end">
				<Button
					onClick={onCancel}
					color="secondary">
					Cancel
				</Button>
				<Button
					onClick={() => handleSubmit()}
					variant="contained"
					color="primary">
					Save
				</Button>
			</Stack>
		</Stack>
	);
};
