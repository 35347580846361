import { StackProps, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { Question as QuestionIcon } from "@phosphor-icons/react/dist/ssr/Question";
import { TextAa as LabelsIcon } from "@phosphor-icons/react/dist/ssr/TextAa";
import { TextB as BoldIcon } from "@phosphor-icons/react/dist/ssr/TextB";
import { TextItalic as ItalicIcon } from "@phosphor-icons/react/dist/ssr/TextItalic";
import { useFormik } from "formik";
import _ from "lodash";
import { MuiColorInput } from "mui-color-input";
import Link from "next/link";

import { Option } from "~ui-components/components/atoms/option";
import { LabelConfigs } from "~ui-components/types/__generated/gql/graphql";

type Props = StackProps & {
	attributes: string[];
	form: ReturnType<typeof useFormik<LabelConfigs>>;
	displayLabelChecked: boolean;
	onDisplayLabelToggled: () => void;
};

export const LayerLabels = (props: Props) => {
	const { form, attributes, displayLabelChecked, onDisplayLabelToggled } =
		props;
	const {
		values,
		handleChange,
		handleBlur,
		errors,
		handleSubmit,
		setFieldValue,
	} = form;

	return (
		<>
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<LabelsIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Labels"
					action={
						<Link
							href={
								"https://help.mangomap.com/hc/en-us/articles/360022122592-The-Layers-panel"
							}
							target="_blank">
							<IconButton aria-label="settings">
								<QuestionIcon />
							</IconButton>
						</Link>
					}
				/>
				<CardContent>
					<Stack spacing={3}>
						<FormControl>
							<FormControlLabel
								control={
									<Switch
										checked={displayLabelChecked}
										onChange={onDisplayLabelToggled}
									/>
								}
								label="Display labels"
							/>
						</FormControl>
						{displayLabelChecked && (
							<>
								<FormControl sx={{ width: 200 }}>
									<InputLabel>Source Attribute</InputLabel>
									<Select
										name="attribute"
										onChange={handleChange}
										value={values.attribute}>
										{attributes.map((attribute) => (
											<Option
												key={attribute}
												value={attribute}>
												{attribute}
											</Option>
										))}
									</Select>
								</FormControl>
								<Typography variant="subtitle1">Label text</Typography>
								<FormControl sx={{ width: 200 }}>
									<InputLabel>Font</InputLabel>
									<Select
										name="font"
										onChange={handleChange}
										value={values.font}>
										<Option
											key="arial"
											value="arial">
											Arial
										</Option>
										<Option
											key="courier-new"
											value="courier-new">
											Courier New
										</Option>
										<Option
											key="tahoma"
											value="tahoma">
											Tahoma
										</Option>
										<Option
											key="time-new-roman"
											value="time-new-roman">
											Time New Roman
										</Option>
										<Option
											key="verdana"
											value="verdana">
											Verdana
										</Option>
									</Select>
								</FormControl>
								<FormControl sx={{ width: 200 }}>
									<InputLabel>Font size</InputLabel>
									<OutlinedInput
										type="number"
										name="fontSize"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.fontSize}
										error={!!errors?.fontSize}
									/>
								</FormControl>
								<InputLabel>Font style</InputLabel>
								<Stack
									direction="row"
									spacing={2}>
									<IconButton aria-label="bold">
										<BoldIcon />
									</IconButton>
									<IconButton aria-label="italic">
										<ItalicIcon />
									</IconButton>
								</Stack>
								<Typography variant="subtitle1">Label appearance</Typography>
								<FormControl sx={{ width: 200 }}>
									<InputLabel>Text colour</InputLabel>
									<MuiColorInput
										format="hex"
										value={values.fontColour ?? ""}
										onChange={(value) => setFieldValue("fontColour", value)}
										error={!!errors?.fontColour}
									/>
								</FormControl>
								<FormControl sx={{ width: 200 }}>
									<InputLabel>Text opacity</InputLabel>
									<Select
										name="fontOpacity"
										onChange={handleChange}
										value={values.fontOpacity}>
										{_.range(0, 11).map((value) => (
											<Option
												key={value}
												value={value / 10}>
												{value * 10}%
											</Option>
										))}
									</Select>
								</FormControl>
								<FormControl sx={{ width: 200 }}>
									<InputLabel>Halo colour</InputLabel>
									<MuiColorInput
										format="hex"
										value={values.haloColour ?? ""}
										onChange={(value) => setFieldValue("haloColour", value)}
										error={!!errors?.haloColour}
									/>
								</FormControl>
								<FormControl sx={{ width: 200 }}>
									<InputLabel>Halo opacity</InputLabel>
									<Select
										name="haloOpacity"
										onChange={handleChange}
										value={values.haloOpacity}>
										{_.range(0, 11).map((value) => (
											<Option
												key={value}
												value={value / 10}>
												{value * 10}%
											</Option>
										))}
									</Select>
								</FormControl>
								<FormControl sx={{ width: 200 }}>
									<InputLabel>Halo Size</InputLabel>
									<OutlinedInput
										type="number"
										name="haloSize"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.haloSize}
										error={!!errors?.haloSize}
									/>
								</FormControl>
							</>
						)}
					</Stack>
				</CardContent>
				<CardActions sx={{ justifyContent: "flex-end" }}>
					<Button color="secondary">Cancel</Button>
					<Button
						variant="contained"
						onClick={() => handleSubmit()}>
						Save changes
					</Button>
				</CardActions>
			</Card>
		</>
	);
};
