import { ClassAttributes, FC, ImgHTMLAttributes } from "react";

import { populateTemplateString } from "../utils/populate-template-string";
import { ImageFormValues } from "../../text-editor/image-menu";

export const Image: FC<{
	dataValue: ImageFormValues;
	valuesToUsed?: Record<string, any>;
	imgProps: ClassAttributes<HTMLImageElement> &
		ImgHTMLAttributes<HTMLImageElement>;
}> = ({ dataValue, valuesToUsed, imgProps }) => {
	return (
		<img
			{...imgProps}
			src={populateTemplateString(dataValue.src, valuesToUsed)}
		/>
	);
};
