import { Button, Stack } from "@mui/material";
import { Fragment, useState } from "react";

import { MapToolDrawer } from "~ui-components/components/molecules/map-tool-drawer";
import { QueryTableResults } from "~ui-components/components/molecules/map-view-tools";

import { ProximityToolForm } from "./proximity-tool-form";

type ViewProps = "proximity" | "results";

export const ProximityToolDrawer = ({ onClose, layers }) => {
	const [view, setView] = useState<ViewProps>("proximity");

	return (
		<MapToolDrawer
			title="Proximity Tool"
			onClose={onClose}
			Content={
				<Fragment>
					{view === "proximity" && <ProximityToolForm layers={layers} />}
					{view === "results" && (
						<QueryTableResults onClickTryAgain={() => setView("proximity")} />
					)}
				</Fragment>
			}
			Action={
				<Stack
					direction={"row-reverse"}
					spacing={2}>
					<Button
						variant="contained"
						onClick={() => setView("results")}>
						Get results
					</Button>
					<Button color="inherit">Reset</Button>
				</Stack>
			}
		/>
	);
};
