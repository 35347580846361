import { Box, StackProps } from "@mui/material";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Hash as AttributeFormatIcon } from "@phosphor-icons/react/dist/ssr/Hash";
import { Question as QuestionIcon } from "@phosphor-icons/react/dist/ssr/Question";
import { useFormik } from "formik";
import Link from "next/link";
import * as React from "react";

import { FakeSelectButton } from "~ui-components/components/atoms/fake-select-button";

import { FormattingDrawer } from "./formatting-drawer";
import { FormattingSelectionProvider } from "./formatting-selection-context";
import { AttributeFormatting, FormattingTable } from "./formatting-table";

interface Schema {
	id: string;
	column: string;
	alias: string;
	visible: boolean;
	isTitle: boolean;
	formatting: string;
}
interface LayerSchemaInput {
	title: string;
	layerSchema: Schema[];
}

type Props = StackProps & {
	form: ReturnType<typeof useFormik<LayerSchemaInput>>;
};

export const Formatting = (props: Props) => {
	const { form } = props;
	const [open, setOpen] = React.useState(false);
	const { handleSubmit } = form;

	return (
		<>
			<FormattingDrawer
				open={open}
				onClose={() => setOpen(false)}
			/>
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<AttributeFormatIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Attribute format"
					action={
						<Link
							href={
								"https://help.mangomap.com/hc/en-us/articles/360022122592-The-Layers-panel"
							}
							target="_blank">
							<IconButton aria-label="settings">
								<QuestionIcon />
							</IconButton>
						</Link>
					}
				/>
				<CardContent>
					<Stack spacing={3}>
						<FormattingSelectionProvider rows={customers}>
							<FormattingTable
								onOpenFormattingDrawer={() => setOpen(true)}
								rows={customers}
							/>
						</FormattingSelectionProvider>
						<Box>
							<Box sx={{ textAlign: "right" }}>
								<FakeSelectButton onClick={() => setOpen(true)} />
							</Box>
						</Box>
						<Alert severity="info">
							<Stack
								direction="row"
								spacing={1}>
								<Typography variant="body2">
									Checked fields will be displayed in default popup and data
									search results
								</Typography>
							</Stack>
						</Alert>
					</Stack>
					<CardActions sx={{ justifyContent: "flex-end", mt: 2, p: 0 }}>
						<Button color="secondary">Cancel</Button>
						<Button
							variant="contained"
							onClick={() => handleSubmit()}>
							Save changes
						</Button>
					</CardActions>
				</CardContent>
			</Card>
		</>
	);
};

const customers = [
	{
		id: "USR-005",
		field: "Fran Perez",
		alias: "/assets/avatar-5.png",
		formatting: "fran.perez@domain.com",
	},
	{
		id: "USR-004",
		field: "Penjani Inyene",
		alias: "/assets/avatar-4.png",
		formatting: "penjani.inyene@domain.com",
	},
	{
		id: "USR-003",
		field: "Carson Darrin",
		alias: "/assets/avatar-3.png",
		formatting: "carson.darrin@domain.com",
	},
	{
		id: "USR-002",
		field: "Siegbert Gottfried",
		alias: "/assets/avatar-2.png",
		formatting: "siegbert.gottfried@domain.com",
	},
	{
		id: "USR-001",
		field: "Miron Vitold",
		alias: "/assets/avatar-1.png",
		formatting: "miron.vitold@domain.com",
	},
] satisfies AttributeFormatting[];
