import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
	ArrowClockwise,
	ArrowsOutLineHorizontal,
	CircleDashed,
	CompassTool,
	Copyright,
	Crosshair,
	CursorClick,
	DotsNine,
	ListMagnifyingGlass,
	MagnifyingGlass,
	MapPin,
	MapTrifold,
	Notebook,
	PenNib,
	Plus,
	Printer,
	PuzzlePiece,
	Question,
	Ruler,
	ShareNetwork,
	SquareHalf,
	SunDim,
} from "@phosphor-icons/react";
import * as React from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import { Option } from "~ui-components/components/atoms/option";

interface ToolsProps {
	children?: React.ReactNode;
}

export function Tools(props: ToolsProps): React.JSX.Element {
	const { children = <ToolsSlots /> } = props;

	return (
		<DashboardContentLayout pageTitle="Extensions">
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<PuzzlePiece fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Enhance your map with extensions"
					action={
						<Stack
							direction="row"
							gap={3}
							alignItems="center">
							<Typography>Default tool</Typography>
							<Select
								name="sort"
								sx={{ width: "120px", ml: "-1rem" }}
								value={"desc"}>
								<Option value="desc">Legend</Option>
								<Option value="asc">Search</Option>
							</Select>
							<Typography>Load state</Typography>
							<Select
								name="sort"
								sx={{ width: "160px", ml: "-1rem" }}
								value={"desc"}>
								<Option value="desc">Panel Expanded</Option>
								<Option value="asc">Panel Collapsed</Option>
							</Select>
							<IconButton aria-label="settings">
								<Question />
							</IconButton>
						</Stack>
					}
				/>
				<CardContent>
					<Card
						sx={{ borderRadius: 1 }}
						variant="outlined">
						<Stack divider={<Divider />}>{children}</Stack>
					</Card>
				</CardContent>
			</Card>
		</DashboardContentLayout>
	);
}

export function ToolsSlots() {
	return (
		<>
			<ToolLegend />
			<ToolSearch />
			<ToolAdvancedSearch />
			<ToolQuery />
			<ToolProximityAnalysis />
			<ToolInformationSidebar />
			<ToolPrint />
			<ToolMeasure />
			<ToolCopyright />
			<ToolMouseHighlight />
			<ToolGeolocation />
			<ToolStreetView />
			<ToolSlideToCompare />
			<ToolMousePosition />
			<ToolScaleBar />
			<ToolShareAndBookmark />
			<ToolBasemapChooser />
			<ToolRefreshMap />
			<ToolSketch />
		</>
	);
}

export function ToolLegend(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			icon={Notebook}
			title="Legend"
			description="Display a legend to help users interpret symbols, colors, and data on the map, enhancing understanding and usability"
		/>
	);
}

export function ToolSearch(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			settings
			icon={MagnifyingGlass}
			title="Search"
			description="Full text address search, data attribute, and coordinate search"
		/>
	);
}

export function ToolAdvancedSearch(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			settings
			icon={ListMagnifyingGlass}
			title="Advanced Search"
			description="Find features in a layer by one or more attributes"
		/>
	);
}

export function ToolQuery(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			settings
			icon={CircleDashed}
			title="Query"
			description="Find features by attribute and location"
		/>
	);
}

export function ToolProximityAnalysis(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			settings
			icon={DotsNine}
			title="Proximity Analysis"
			description="Find features by spatial relationships"
		/>
	);
}

export function ToolInformationSidebar(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			settings
			icon={SquareHalf}
			title="Information sidebar"
			description=" Display a collapsible sidebar with rich content enhancing understanding and usability of your map"
		/>
	);
}

export function ToolPrint(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			settings
			icon={Printer}
			title="Print"
			description="Print to PDF with a print composer"
		/>
	);
}

export function ToolMeasure(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			settings
			icon={Ruler}
			title="Measure"
			description="Allow users to measure distance and area"
		/>
	);
}

export function ToolCopyright(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			settings
			icon={Copyright}
			title="Copyright"
			description="Displays custom attribution"
		/>
	);
}

export function ToolMouseHighlight(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			settings
			icon={SunDim}
			title="Mouse highlight"
			description="Highlights features in a layer on mouse hover"
		/>
	);
}

export function ToolGeolocation(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			icon={Crosshair}
			title="Geolocation"
			description="Allow users to find and follow their location"
		/>
	);
}

export function ToolStreetView(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			icon={MapPin}
			title="Street view"
			description="Opens location in Google Streetview"
		/>
	);
}

export function ToolSlideToCompare(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			icon={ArrowsOutLineHorizontal}
			title="Slide to compare"
			description="Compare layers with a slider"
		/>
	);
}

export function ToolMousePosition(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			icon={CursorClick}
			title="Mouse position"
			description="Displays the coordinates under the cursor"
		/>
	);
}

export function ToolScaleBar(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			icon={CompassTool}
			title="Scale bar"
			description="Displays a scale bar on the map"
		/>
	);
}

export function ToolShareAndBookmark(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			icon={ShareNetwork}
			title="Share and bookmark"
			description="Allow users to easily share and bookmark places, features, and sketches"
		/>
	);
}

export function ToolBasemapChooser(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			icon={MapTrifold}
			title="Basemap chooser"
			description="Allow users to change the basemap"
		/>
	);
}

export function ToolRefreshMap(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			icon={ArrowClockwise}
			title="Refresh map"
			description="Reset the map do the default position"
		/>
	);
}

export function ToolSketch(props: Partial<ToolProps>) {
	return (
		<Tool
			{...props}
			icon={PenNib}
			title="Sketch"
			description="Create and share overlays and annotations"
		/>
	);
}

interface ToolProps {
	title: string;
	description: string;
	icon: React.ElementType;
	active?: boolean;
	settings?: boolean;
	onAddClick?: () => void;
	onSettingsClick?: () => void;
	onRemoveClick?: () => void;
}

function Tool(props: ToolProps) {
	const {
		title,
		description,
		icon: Icon,
		active,
		settings,
		onAddClick,
		onRemoveClick,
		onSettingsClick,
	} = props;
	return (
		<Stack
			direction="row"
			spacing={2}
			sx={{ alignItems: "center", px: 2, py: 1 }}>
			<Avatar
				sx={{
					bgcolor: "var(--mui-palette-background-paper)",
					boxShadow: "var(--mui-shadows-8)",
					color: "var(--mui-palette-text-primary)",
				}}>
				<Icon fontSize="var(--Icon-fontSize)" />
			</Avatar>
			<Box sx={{ flex: "1 1 auto" }}>
				<Typography variant="subtitle2">{title}</Typography>
				<Typography
					color="text.secondary"
					variant="caption">
					{description}
				</Typography>
			</Box>
			<Stack
				direction="row"
				gap={1}>
				{!active ? (
					<Button
						color="secondary"
						startIcon={<Plus />}
						size="small"
						variant="outlined"
						onClick={onAddClick}>
						Add
					</Button>
				) : (
					<>
						{settings && (
							<Button
								color="info"
								size="small"
								variant="outlined"
								onClick={onSettingsClick}>
								Settings
							</Button>
						)}
						<Button
							color="error"
							size="small"
							variant="outlined"
							onClick={onRemoveClick}>
							Remove
						</Button>
					</>
				)}
			</Stack>
		</Stack>
	);
}
