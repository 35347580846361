import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import { List as NavIcon } from "@phosphor-icons/react/dist/ssr/List";
import { StackProps } from "@mui/material";
import { useFormik } from "formik";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";

import { SortableMenu } from "~ui-components/components/molecules/map-menu/sortable-menu";
import { mapNavItemKeys } from "~ui-components/components/molecules/map-settings-side-nav";

interface Navigator {
	active: boolean;
	content: string;
	index: number;
	key: string;
	label: string;
	width: number;
	id: string;
}

interface MapMenuInput {
	enableNavigation: boolean;
	navigators: Navigator[];
}

export type MapMenuProps = StackProps & {
	form: ReturnType<typeof useFormik<MapMenuInput>>;
	navigatorSettings: any;
	onCreateTask?: () => void | undefined;
	active?: string;
};

export const MapMenu = (props: MapMenuProps) => {
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const { form, active, onCreateTask = () => {} } = props;
	const { values, handleChange } = form;

	if (active !== mapNavItemKeys.navigation) return null;

	return (
		<form onSubmit={form.handleSubmit}>
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<NavIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Map menu"
				/>

				<CardContent>
					<Stack spacing={3}>
						<FormControl>
							<FormControlLabel
								control={
									<Switch
										name="enableNavigation"
										checked={values.enableNavigation}
										onChange={handleChange}
									/>
								}
								label="Display menu"
							/>
						</FormControl>

						{values.enableNavigation && (
							<Stack spacing={2}>
								<Button
									color="secondary"
									onClick={() => onCreateTask()}
									startIcon={<PlusIcon />}>
									Add menu item
								</Button>
								<SortableMenu
									navigators={form.values.navigators}
									onChange={(navigators) =>
										handleChange({
											target: {
												name: "navigators",
												value: navigators,
											},
										})
									}
								/>
							</Stack>
						)}
					</Stack>
				</CardContent>

				<CardActions sx={{ justifyContent: "flex-end" }}>
					<Button color="secondary">Cancel</Button>
					<Button
						variant="contained"
						type="submit">
						Save changes
					</Button>
				</CardActions>
			</Card>
		</form>
	);
};
