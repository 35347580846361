import { StackProps } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import { useState } from "react";

import { Option } from "~ui-components/components/atoms/option";
import { TextEditor } from "~ui-components/components/atoms/text-editor/text-editor";

import type { EditorEvents } from "@tiptap/react";

interface CustomPopupInput {
	popupType: string;
	template: string;
	title: string;
}

type Props = StackProps & {
	popupType?: string;
	attributes: string[];
	form: ReturnType<typeof useFormik<CustomPopupInput>>;
};

export const CustomPoup = (props: Props) => {
	const { popupType, form, attributes } = props;
	const { values, handleChange, handleSubmit } = form;
	const [preview, setPreview] = useState<string>("");

	if (popupType === "custom")
		return (
			<>
				<Stack
					direction="row"
					sx={{ pt: 2 }}>
					<Stack
						direction="column"
						spacing={2}
						sx={{ flexGrow: 1 }}>
						<FormControl fullWidth>
							<InputLabel>Popup title</InputLabel>
							<Select
								name="title"
								onChange={handleChange}
								value={values.title}>
								{attributes.map((attribute) => (
									<Option
										key={attribute}
										value={attribute}>
										{attribute}
									</Option>
								))}
							</Select>
						</FormControl>
						<FormControl
							fullWidth
							sx={{ mt: 2 }}>
							<InputLabel>Banner content</InputLabel>
							<Box
								sx={{
									mt: "8px",
									"& .tiptap-container": { height: "400px" },
								}}>
								<TextEditor
									placeholder="Write something"
									toolsProps={{
										Code: true,
										CodeBlock: true,
										Blockquote: true,
										LinkInput: {
											hrefAttributes: attributes,
											textAttributes: attributes,
										},
										ImageInput: {
											srcAttributes: attributes,
										},
										IFrameInput: {
											srcAttributes: attributes,
										},
										AttributeSelect: {
											attributes,
										},
										Equation: {
											attributes,
										},
										Table: true,
										TextAlign: true,
										HorizontalRule: true,
									}}
									onUpdate={({ editor }: EditorEvents["update"]) => {
										setPreview(editor.getText());
										handleChange({
											target: { name: "template", value: editor.getHTML() },
										});
									}}
								/>
							</Box>
						</FormControl>
					</Stack>
					<Stack sx={{ ml: 3 }}>
						<InputLabel>Popup title</InputLabel>
						<Card
							sx={{ mt: 1, borderRadius: 1, width: "400px", height: "600px" }}>
							<Stack
								sx={{
									p: 1,
									alignItems: "center",
									backgroundColor: "var(--mui-palette-background-dark)",
								}}
								direction="row"
								spacing={2}>
								{values.title}
							</Stack>
							<Divider />
							<CardContent>{preview}</CardContent>
						</Card>
					</Stack>
				</Stack>
				<CardActions sx={{ justifyContent: "flex-end", m: 0, p: 0, mt: 2 }}>
					<Button color="secondary">Cancel</Button>
					<Button
						variant="contained"
						onClick={() => handleSubmit()}>
						Save changes
					</Button>
				</CardActions>
			</>
		);
	return null;
};
