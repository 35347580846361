import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { StackProps } from "@mui/material";
import { useFormik } from "formik";
import Stack from "@mui/material/Stack";

import { Option } from "~ui-components/components/atoms/option";

interface DefaultPopupInput {
	popupType: string;
	title: string;
}

type Props = StackProps & {
	popupType?: string;
	attributes: string[];
	form: ReturnType<typeof useFormik<DefaultPopupInput>>;
};

export const DefaultPoup = (props: Props) => {
	const { popupType, form, attributes } = props;
	const { values, handleChange, handleSubmit } = form;

	if (popupType === "default")
		return (
			<Stack sx={{ pt: 2 }}>
				<FormControl sx={{ width: 200 }}>
					<InputLabel>Source Attribute</InputLabel>
					<Select
						name="title"
						onChange={handleChange}
						value={values.title}>
						{attributes.map((attribute) => (
							<Option
								key={attribute}
								value={attribute}>
								{attribute}
							</Option>
						))}
					</Select>
				</FormControl>
				<CardActions sx={{ justifyContent: "flex-end", m: 0, p: 0, mt: 2 }}>
					<Button color="secondary">Cancel</Button>
					<Button
						variant="contained"
						onClick={() => handleSubmit()}>
						Save changes
					</Button>
				</CardActions>
			</Stack>
		);
	return null;
};
