import {
	Chip,
	CircularProgress,
	FormHelperText,
	InputAdornment,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { paths } from "config/paths";
import { useFormik } from "formik";
import RouterLink from "next/link";
import * as React from "react";
import * as Yup from "yup";

import { MangoLogo } from "~ui-components/components/atoms/mango-logo";
import { SplitLayout } from "~ui-components/components/organisms/split-layout";

export const PasswordSchema = Yup.string()
	.test(
		"is-valid",
		"Password must be at least 6 characters and contain only alphanumeric characters, dashes, or underscores.",
		(value) => /^[a-zA-Z0-9\-_]{6,}$/.test(value ?? ""),
	)
	.required("Required");

export const SignUpV2Schema = Yup.object({
	name: Yup.string().required("Required"),
	password: PasswordSchema,
	acceptedToTermsAndPolicy: Yup.boolean().required().oneOf([true]),
	companyName: Yup.string()
		.required("Required")
		.test(
			"is-not-reserved-name",
			({ value }) => `${value} is a reserved keyword`,
			(value) => {
				return "franchise" !== value?.toLowerCase();
			},
		),
});

export type SignUpV2FormValues = Yup.InferType<typeof SignUpV2Schema>;

export const SignUpV2RemoteSchema = Yup.object({
	email: Yup.string().email("Invalid email").required("Required"),
});

export type SignUpV2RemoteFormValues = Yup.InferType<
	typeof SignUpV2RemoteSchema
>;

export interface SignUpV2Props {
	form: ReturnType<typeof useFormik<SignUpV2FormValues>>;
	remoteForm: ReturnType<typeof useFormik<SignUpV2RemoteFormValues>>;
	onEmailChange: React.ChangeEventHandler<HTMLInputElement>;
	onEmailBlur: React.FocusEventHandler<HTMLInputElement>;
	isRemotelyValidatingEmail: boolean;
}

export const SignUpV2: React.FC<SignUpV2Props> = ({
	form,
	remoteForm,
	onEmailChange,
	onEmailBlur,
	isRemotelyValidatingEmail,
}) => {
	return (
		<SplitLayout
			title="Get started with Mango"
			subtitle="The accessible and user-friendly alternative to Esri for smart
              spenders."
			caption="Join 6,000+ forward-thinking companies:">
			<Stack
				spacing={4}
				component="form"
				onSubmit={form.handleSubmit}>
				<div>
					<Box
						component={RouterLink}
						href={paths.home}
						sx={{ display: "inline-block", fontSize: 0 }}>
						<MangoLogo
							width={122}
							height={32}
						/>
					</Box>
				</div>
				<Stack spacing={1}>
					<Typography variant="h5">
						Sign up&nbsp;
						<Chip
							size="small"
							label="v2"
						/>
					</Typography>
					<Typography
						color="text.secondary"
						variant="body2">
						Already have an account?&nbsp;
						<Link
							href={paths.signInV2}
							variant="subtitle2">
							Sign in
						</Link>
					</Typography>
				</Stack>
				<Stack spacing={2}>
					<FormControl>
						<InputLabel>Name</InputLabel>
						<OutlinedInput
							autoFocus
							{...form.getFieldProps("name")}
							error={!!form.touched.name && !!form.errors.name}
						/>
						{!!form.touched.name && !!form.errors.name && (
							<FormHelperText error>{form.errors.name}</FormHelperText>
						)}
					</FormControl>
					<FormControl>
						<InputLabel>Work email</InputLabel>
						<OutlinedInput
							type="email"
							name="email"
							value={remoteForm.values.email}
							onChange={onEmailChange}
							onBlur={onEmailBlur}
							error={!!remoteForm.touched.email && !!remoteForm.errors.email}
							endAdornment={
								isRemotelyValidatingEmail ? (
									<InputAdornment position="end">
										<CircularProgress size={24} />
									</InputAdornment>
								) : null
							}
						/>
						{!!remoteForm.touched.email && !!remoteForm.errors.email && (
							<FormHelperText error>{remoteForm.errors.email}</FormHelperText>
						)}
					</FormControl>
					<FormControl>
						<InputLabel>Company name</InputLabel>
						<OutlinedInput
							{...form.getFieldProps("companyName")}
							error={!!form.touched.companyName && !!form.errors.companyName}
						/>
						{!!form.touched.companyName && !!form.errors.companyName && (
							<FormHelperText error>{form.errors.companyName}</FormHelperText>
						)}
						<FormHelperText>You can change this later</FormHelperText>
					</FormControl>
					<FormControl>
						<InputLabel>Set a password</InputLabel>
						<OutlinedInput
							type="password"
							{...form.getFieldProps("password")}
							error={!!form.touched.password && !!form.errors.password}
						/>
						{!!form.touched.password && !!form.errors.password && (
							<FormHelperText error>{form.errors.password}</FormHelperText>
						)}
					</FormControl>
					<div>
						<FormControlLabel
							control={
								<Checkbox {...form.getFieldProps("acceptedToTermsAndPolicy")} />
							}
							label={
								<React.Fragment>
									By clicking the &ldquo;Start trial&ldquo; button you agree to
									our <Link href={paths.terms}>Terms</Link> and&nbsp;
									<Link href={paths.privacy}>Privacy policy</Link>
								</React.Fragment>
							}
						/>
					</div>
					<Button
						type="submit"
						variant="contained"
						disabled={
							form.isSubmitting || !form.isValid || !remoteForm.isValid
						}>
						Start trial
					</Button>
				</Stack>
			</Stack>
		</SplitLayout>
	);
};
