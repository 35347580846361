import { FC } from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import {
	ChangePassword,
	ChangePasswordProps,
} from "~ui-components/components/organisms/edit-profile-panels/change-password";

export interface PasswordProps {
	form: ChangePasswordProps["form"];
}

export const Password: FC<PasswordProps> = ({ form }) => {
	return (
		<DashboardContentLayout pageTitle="Password">
			<ChangePassword form={form} />
		</DashboardContentLayout>
	);
};
