import { Box, Button } from "@mui/material";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DotsSixVertical as DragIcon } from "@phosphor-icons/react/dist/ssr/DotsSixVertical";
import { DotsThree as MenuIcon } from "@phosphor-icons/react/dist/ssr/DotsThree";
import { paths } from "config/paths";
import RouterLink from "next/link";
import { useRouter } from "next/router";
import { FC } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { usePopover } from "~ui-components/hooks/use-popover";
import { LayerGroup } from "~ui-components/types/__generated/gql/graphql";

import { LayerContextMenu } from "./layer-context-menu";

interface LayersComponentProps {
	layerGroup: LayerGroup;
	onDragEnd: any;
	onSelectLayer: (layerId: string) => void;
	onRemoveLayer: (layerId: string) => void;
}

export const LayersComponent: FC<LayersComponentProps> = ({
	layerGroup,
	onDragEnd,
	onSelectLayer,
	onRemoveLayer,
}) => {
	const popover = usePopover<HTMLButtonElement, string>();
	const router = useRouter();

	return (
		<>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable
					droppableId="all-columns"
					direction="vertical"
					type="COLUMN">
					{(provided) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={{ display: "flex", flexDirection: "column" }}>
							{layerGroup.layerCollection.items.map((layer, index) => (
								<Draggable
									draggableId={layer.id}
									index={index}
									key={layer.id}>
									{(provided) => (
										<Card
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											sx={{
												m: 2,
												backgroundColor:
													"var(--mui-palette-background-level2);",
											}}>
											<Stack
												direction="row"
												key={index}
												sx={{
													alignItems: "center",
													flexWrap: "wrap",
													justifyContent: "space-between",
													px: 1,
													py: 2,
												}}>
												<Stack
													direction="row"
													spacing={2}
													sx={{ alignItems: "center" }}>
													<IconButton>
														<DragIcon size={24} />
													</IconButton>
													<Link
														onClick={() => onSelectLayer(layer.id)}
														color="CaptionText">
														<Typography variant="subtitle1">
															{layer.name}
														</Typography>
													</Link>
												</Stack>
												<Stack
													direction="row"
													spacing={2}
													sx={{ alignItems: "center" }}>
													<IconButton
														onClick={(e) => {
															popover.anchorRef.current = e.currentTarget;
															popover.handleOpen(layer.id);
														}}
														ref={popover.anchorRef}>
														<MenuIcon size={24} />
													</IconButton>
												</Stack>
											</Stack>
										</Card>
									)}
								</Draggable>
							))}
							<Box
								bgcolor={"var(--mui-palette-background-level2)"}
								sx={{ m: 2, p: 2, borderRadius: 2 }}
								display="flex"
								flexDirection="row"
								justifyContent="space-between">
								<Button
									component={RouterLink}
									href={paths.accountName.maps.mapId.mapName.addLayer(
										router.query["account-name"],
										router.query["map-id"],
										router.query["map-name"],
										layerGroup.id,
									)}>
									Add a layer
								</Button>
							</Box>
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			<LayerContextMenu
				anchorEl={popover.anchorRef.current}
				onClose={popover.handleClose}
				open={popover.open}
				onDelete={() => {
					if (popover.data) onRemoveLayer(popover.data);
					popover.handleClose();
				}}
				onMoveAbove={console.log}
				onMoveBelow={console.log}
			/>
		</>
	);
};
