import { Box } from "@mui/material";

import { LineSimple } from "~ui-components/components/molecules/legend/shapes/simple/line";
import { PointSimple } from "~ui-components/components/molecules/legend/shapes/simple/point";
import { PolygonSimple } from "~ui-components/components/molecules/legend/shapes/simple/polygon";
import { LAYER_STYLE_TYPE } from "~ui-components/types/__generated/gql/graphql";
export const Simple = ({ style }) => {
	return (
		<Box>
			{style.type === LAYER_STYLE_TYPE.POLYGON_SIMPLE && (
				<PolygonSimple style={style} />
			)}
			{style.type === LAYER_STYLE_TYPE.LINE_SIMPLE && (
				<LineSimple style={style} />
			)}
			{style.type === LAYER_STYLE_TYPE.POINT_SIMPLE && (
				<PointSimple style={style} />
			)}
		</Box>
	);
};
