import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import RouterLink from "next/link";
import { usePathname } from "next/navigation";

import { icons } from "~ui-components/components/organisms/vertical-layout/nav-icons";
import { isNavItemActive } from "~ui-components/libs/is-nav-item-active";
import { NavItemConfig } from "~ui-components/types/nav";

export interface SideNavProps {
	navItems: NavItemConfig[];
}

export function SideNav({ navItems }: SideNavProps) {
	const pathname = usePathname();

	return (
		<div>
			<Stack
				spacing={3}
				sx={{
					flex: "0 0 auto",
					flexDirection: { xs: "column-reverse", md: "column" },
					position: { md: "sticky" },
					top: "64px",
					width: { xs: "100%", md: "240px" },
				}}>
				<Stack
					component="ul"
					spacing={3}
					sx={{ listStyle: "none", m: 0, p: 0 }}>
					{navItems.map((group, i) => (
						<Stack
							component="li"
							key={group.key ?? `group-${i}`}
							spacing={2}>
							{group.title ? (
								<div>
									<Typography
										color="text.secondary"
										variant="caption">
										{group.title}
									</Typography>
								</div>
							) : null}
							{group.items && (
								<Stack
									component="ul"
									spacing={1}
									sx={{ listStyle: "none", m: 0, p: 0 }}>
									{group.items.map((item, i) => (
										<NavItem
											{...item}
											key={item.key ?? `item-${i}`}
											pathname={pathname}
										/>
									))}
								</Stack>
							)}
						</Stack>
					))}
				</Stack>
			</Stack>
		</div>
	);
}

interface NavItemProps extends NavItemConfig {
	pathname: string;
}

function NavItem({
	disabled,
	external,
	href,
	icon,
	pathname,
	title,
}: NavItemProps): React.JSX.Element {
	const active = isNavItemActive({ disabled, external, href, pathname });
	const Icon = icon ? icons[icon] : null;

	return (
		<Box
			component="li"
			sx={{ userSelect: "none" }}>
			<Box
				{...(href
					? {
							component: external ? "a" : RouterLink,
							href,
							target: external ? "_blank" : undefined,
							rel: external ? "noreferrer" : undefined,
						}
					: { role: "button" })}
				sx={{
					alignItems: "center",
					borderRadius: 1,
					color: "var(--mui-palette-text-secondary)",
					cursor: "pointer",
					display: "flex",
					flex: "0 0 auto",
					gap: 1,
					p: "6px 16px",
					textDecoration: "none",
					whiteSpace: "nowrap",
					...(disabled && {
						color: "var(--mui-palette-text-disabled)",
						cursor: "not-allowed",
					}),
					...(active && {
						bgcolor: "var(--mui-palette-action-selected)",
						color: "var(--mui-palette-text-primary)",
					}),
					"&:hover": {
						...(!active &&
							!disabled && {
								bgcolor: "var(--mui-palette-action-hover)",
								color: "var(---mui-palette-text-primary)",
							}),
					},
				}}
				tabIndex={0}>
				{Icon ? (
					<Box
						sx={{
							alignItems: "center",
							display: "flex",
							justifyContent: "center",
							flex: "0 0 auto",
						}}>
						<Icon
							fill={
								active
									? "var(--mui-palette-text-primary)"
									: "var(--mui-palette-text-secondary)"
							}
							fontSize="var(--icon-fontSize-md)"
							weight={active ? "fill" : undefined}
						/>
					</Box>
				) : null}
				<Box sx={{ flex: "1 1 auto" }}>
					<Typography
						component="span"
						sx={{
							color: "inherit",
							fontSize: "0.875rem",
							fontWeight: 500,
							lineHeight: "28px",
						}}>
						{title}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}
