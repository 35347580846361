import Box from "@mui/material/Box";
import { FC } from "react";

interface ItemIconProps {
	size?: "small" | "medium" | "large";
	src: string;
}

export const ItemIcon: FC<ItemIconProps> = ({ src, size = "small" }) => {
	return (
		<Box
			sx={[
				{
					overflow: "hidden",
					borderRadius: 1.5,
					alignItems: "center",
					display: "inline-flex",
					flex: "0 0 auto",
					justifyContent: "center",
					width: "100%",
					height: "100%",
					backgroundImage: `url("${src}")`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
				},
				size === "small" && {
					minWidth: "64px",
					minHeight: "64px",
				},
				size === "medium" && {
					minHeight: "242px",
				},
				size === "large" && {
					minHeight: "414px",
				},
			]}
		/>
	);
};
