import * as React from "react";
import {
	signOut as amplifySignOut,
	fetchAuthSession,
	AuthSession,
	getCurrentUser,
} from "aws-amplify/auth";
import { QueryObserverResult, useQueryClient } from "@tanstack/react-query";
import { toast } from "ui-components";

import {
	GetCurrentUserResults,
	useGetCurrentUser,
} from "~auth/queries/useGetCurrentUser";
import {
	GetCurrentAccountResults,
	useGetCurrentAccount,
} from "~auth/queries/useGetCurrentAccount";

export interface AuthContextValue {
	isLoading: boolean;
	isAuthenticated: boolean;
	signOut: () => void;
	refreshSession: () => Promise<{
		session: AuthSession;
		currentUser: QueryObserverResult<GetCurrentUserResults>;
		currentAccount: QueryObserverResult<GetCurrentAccountResults>;
	}>;
}

export const AuthContext = React.createContext<AuthContextValue>({
	isLoading: true,
	isAuthenticated: false,
} as AuthContextValue);

export interface AuthProviderProps {
	children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const queryClient = useQueryClient();
	const { refetch: refetchCurrentUser } = useGetCurrentUser(false);
	const { refetch: refetchCurrentAccount } = useGetCurrentAccount(false);

	const [states, setStates] = React.useState<
		Pick<AuthContextValue, "isLoading" | "isAuthenticated">
	>({
		isLoading: true,
		isAuthenticated: false,
	});

	const refreshSession = async () => {
		const [session, currentUser, currentAccount] = await Promise.all([
			fetchAuthSession(),
			refetchCurrentUser(),
			refetchCurrentAccount(),
		]);

		setStates((prev) => ({
			...prev,
			isAuthenticated:
				!!session?.tokens && !!currentUser.data && !!currentAccount.data,
		}));

		return { session, currentUser, currentAccount };
	};

	const signOut = () => {
		toast.promise(
			async () => {
				await amplifySignOut();
				queryClient.clear();
				setStates((prev) => ({ ...prev, isAuthenticated: false }));
			},
			{
				loading: "Signing you out...",
				error: (e) => {
					console.log("🚀 ~ onClick={ ~ e:", e);
					return "Something went wrong!";
				},
			},
		);
	};

	React.useEffect(() => {
		async function initialCheck() {
			setStates((prev) => ({ ...prev, isLoading: true }));

			const [isAuthenticated, currentUser, currentAccount] = await Promise.all([
				getCurrentUser()
					.then(() => true)
					.catch(() => false),
				refetchCurrentUser().catch(() => ({ data: undefined })),
				refetchCurrentAccount().catch(() => ({ data: undefined })),
			]);

			setStates({
				isLoading: false,
				isAuthenticated:
					isAuthenticated && !!currentUser.data && !!currentAccount.data,
			});
		}

		void initialCheck();

		// eslint-disable-next-line react-hooks/exhaustive-deps -- Expected
	}, []);

	return (
		<AuthContext.Provider
			value={{
				...states,
				signOut,
				refreshSession,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

export const AuthConsumer = AuthContext.Consumer;

export const useAuthContext = () => React.useContext(AuthContext);
