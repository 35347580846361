import {
	FormControl,
	FormHelperText,
	InputLabel,
	Select,
	Stack,
} from "@mui/material";

import { Option } from "~ui-components/components/atoms/option";

export const AnalysisLayerInput = ({ layers, filter }) => {
	return (
		<Stack p={2}>
			<FormControl>
				<InputLabel>Analysis Layer</InputLabel>
				<Select
					name="layer"
					value={layers[0].id}>
					{layers.map(({ id, name }) => (
						<Option
							key={id}
							value={id}>
							{name}
						</Option>
					))}
				</Select>
				<FormHelperText>
					Select the layer with the features you want to find.
				</FormHelperText>
			</FormControl>
			{filter}
		</Stack>
	);
};
