import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

function BrandLayout(props) {
	const { pageTitle = "Brand" } = props;
	return (
		<DashboardContentLayout pageTitle={pageTitle}>
			<Root {...props} />
		</DashboardContentLayout>
	);
}

function Root(props) {
	const { children } = props;

	return (
		<>
			<Stack
				direction={{ xs: "column", md: "row" }}
				spacing={4}
				sx={{ position: "relative" }}>
				<Box sx={{ flex: "1 1 auto", minWidth: 0 }}>{children}</Box>
			</Stack>
		</>
	);
}

export { BrandLayout };
