import { StackProps } from "@mui/material";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import _ from "lodash";
import { MuiColorInput } from "mui-color-input";

import { Option } from "~ui-components/components/atoms/option";
import { BubbleConfigs } from "~ui-components/types/__generated/gql/graphql";

type Props = StackProps & {
	form: ReturnType<typeof useFormik<BubbleConfigs>>;
	visualisation?: string;
	attributes?: {
		name: string;
		type: string;
	}[];
};

export const BubbleStyle = (props: Props) => {
	const { visualisation, attributes, form } = props;
	const { values, handleChange, handleBlur, handleSubmit } = form;

	if (visualisation === "bubble") {
		return (
			<>
				<Stack spacing={3}>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Scale by</InputLabel>
						<Select
							name="valueField"
							onChange={handleChange}
							value={values.valueField}>
							{attributes?.map((value) => (
								<Option
									key={value.name}
									value={value.name}>
									{value.name}
								</Option>
							))}
						</Select>
					</FormControl>

					<FormControl sx={{ width: 200 }}>
						<InputLabel>Steps</InputLabel>
						<Select
							name="classNumber"
							onChange={handleChange}
							value={values.classNumber}>
							{_.range(3, 10).map((value) => (
								<Option
									key={value}
									value={value}>
									{value}
								</Option>
							))}
						</Select>
					</FormControl>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Mode</InputLabel>
						<Select
							name="classification"
							onChange={handleChange}
							value={values.classification}>
							<Option
								key="manual"
								value="manual">
								Manual
							</Option>
							<Option
								key="equal-interval"
								value="equalinterval">
								Equal Interval
							</Option>
							<Option
								key="jenks"
								value="jenksnatural">
								Jenks
							</Option>
							<Option
								key="quantile"
								value="quantile">
								Quantile
							</Option>
							<Option
								key="heads-tails"
								value="heat-tails">
								Head/Tails
							</Option>
						</Select>
					</FormControl>

					<FormControl>
						<InputLabel>Min radius</InputLabel>
						<OutlinedInput
							sx={{ width: 200 }}
							name="radius.min"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values?.radius?.min}
						/>
					</FormControl>

					<FormControl>
						<InputLabel>Max radius</InputLabel>
						<OutlinedInput
							sx={{ width: 200 }}
							name="radius.max"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values?.radius?.max}
						/>
					</FormControl>

					<InputLabel>Fill and Stroke</InputLabel>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Fill colour</InputLabel>
						<MuiColorInput
							sx={{ width: 200 }}
							format="hex"
							value={values?.symbolStyle?.fill?.color ?? "#000000"}
							onChange={(value) =>
								form.setFieldValue("symbolStyle.fill.color", value)
							}
						/>
					</FormControl>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Fill Opacity</InputLabel>
						<Select
							name="symbolStyle.fill.opacity"
							onChange={handleChange}
							value={values?.symbolStyle?.fill?.opacity}>
							{_.range(0, 11).map((value) => (
								<Option
									key={value}
									value={value / 10}>
									{value * 10}%
								</Option>
							))}
						</Select>
					</FormControl>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Stroke colour</InputLabel>
						<MuiColorInput
							sx={{ width: 200 }}
							format="hex"
							value={values?.symbolStyle?.stroke?.color ?? "#000000"}
							onChange={(value) =>
								form.setFieldValue("symbolStyle.stroke.color", value)
							}
						/>
					</FormControl>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Stroke opacity</InputLabel>
						<Select
							name="symbolStyle.stroke.opacity"
							onChange={handleChange}
							value={values?.symbolStyle?.stroke?.opacity}>
							{_.range(0, 11).map((value) => (
								<Option
									key={value}
									value={value / 10}>
									{value * 10}%
								</Option>
							))}
						</Select>
					</FormControl>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Stroke style</InputLabel>
						<Select
							name="symbolStyle.stroke.style"
							onChange={handleChange}
							value={values?.symbolStyle?.stroke?.style}>
							<Option
								key="none"
								value="none">
								None
							</Option>
							<Option
								key="solid"
								value="solid">
								Solid
							</Option>
							<Option
								key="dash"
								value="dash">
								Dash
							</Option>
							<Option
								key="dot"
								value="dot">
								Dot
							</Option>
						</Select>
					</FormControl>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Stroke width</InputLabel>
						<OutlinedInput
							sx={{ width: 200 }}
							name="symbolStyle.stroke.width"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values?.symbolStyle?.stroke?.width}
						/>
					</FormControl>
				</Stack>
				<CardActions sx={{ justifyContent: "flex-end", p: 0, mt: 2 }}>
					<Button color="secondary">Cancel</Button>
					<Button
						variant="contained"
						onClick={() => handleSubmit()}>
						Save changes
					</Button>
				</CardActions>
			</>
		);
	}
	return null;
};
