import { Box, Paper, Stack, Typography } from "@mui/material";
import { Cell, Pie, PieChart as RechartsPieChart, Tooltip } from "recharts";
import { FC } from "react";

import { NoSsr } from "~ui-components/components/atoms/no-ssr";
import { generateColorRange } from "../utils/generate-color-range";
import { ChartFormValues } from "../../text-editor/chart-menu";

const CHART_HEIGHT = 300;
const X_DATA_KEY = "name";
const Y_DATA_KEY = "value";
const CHART_THICKNESS = 30;

export const PieChart: FC<{
	dataValue: ChartFormValues;
	valuesToUsed: Record<string, any>;
}> = ({ dataValue, valuesToUsed }) => {
	const data = dataValue.attributes.map((att) => ({
		[X_DATA_KEY]: att.label,
		[Y_DATA_KEY]: valuesToUsed[att.key],
	}));
	const colorRanges = generateColorRange(
		dataValue.startColour!,
		dataValue.endColour!,
		data.length,
	);

	return (
		<NoSsr
			fallback={
				<Box
					sx={{
						height: `${CHART_HEIGHT}px`,
						width: `${CHART_HEIGHT}px`,
					}}
				/>
			}>
			<Stack alignItems="center">
				<RechartsPieChart
					height={CHART_HEIGHT}
					margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
					width={CHART_HEIGHT}>
					<Pie
						animationDuration={300}
						cx={CHART_HEIGHT / 2}
						cy={CHART_HEIGHT / 2}
						data={data}
						dataKey={Y_DATA_KEY}
						innerRadius={CHART_HEIGHT / 2 - CHART_THICKNESS}
						nameKey={X_DATA_KEY}
						outerRadius={CHART_HEIGHT / 2}
						strokeWidth={0}>
						{data.map(
							(entry, i): React.JSX.Element => (
								<Cell
									key={entry.name}
									fill={colorRanges[i]}
								/>
							),
						)}
					</Pie>
					<Tooltip
						animationDuration={50}
						content={<TooltipContent />}
					/>
				</RechartsPieChart>
				<Legend
					payload={data.map((entry, i) => ({
						...entry,
						color: colorRanges[i]!,
					}))}
				/>
			</Stack>
		</NoSsr>
	);
};

interface LegendProps {
	payload?: { name: string; value: number; color: string }[];
}

function Legend({ payload }: LegendProps): React.JSX.Element {
	return (
		<Box
			sx={{
				width: CHART_HEIGHT,
				display: "grid",
				gap: 3,
				gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
			}}>
			{payload?.map(
				(entry): React.JSX.Element => (
					<div key={entry.name}>
						<Stack
							direction="row"
							spacing={1}
							sx={{ alignItems: "center" }}>
							<Box
								sx={{
									bgcolor: entry.color,
									borderRadius: "2px",
									height: "4px",
									width: "16px",
								}}
							/>
							<Typography variant="body2">{entry.name}</Typography>
						</Stack>
						<Typography variant="h5">
							{new Intl.NumberFormat("en-US").format(entry.value)}
						</Typography>
					</div>
				),
			)}
		</Box>
	);
}

interface TooltipContentProps {
	active?: boolean;
	payload?: { fill: string; name: string; value: number }[];
	label?: string;
}

function TooltipContent({
	active,
	payload,
}: TooltipContentProps): React.JSX.Element | null {
	if (!active) {
		return null;
	}

	return (
		<Paper
			sx={{
				border: "1px solid var(--mui-palette-divider)",
				boxShadow: "var(--mui-shadows-16)",
				p: 1,
			}}>
			<Stack spacing={2}>
				{payload?.map(
					(entry): React.JSX.Element => (
						<Stack
							direction="row"
							key={entry.name}
							spacing={3}
							sx={{ alignItems: "center" }}>
							<Stack
								direction="row"
								spacing={1}
								sx={{ alignItems: "center", flex: "1 1 auto" }}>
								<Box
									sx={{
										bgcolor: entry.fill,
										borderRadius: "2px",
										height: "8px",
										width: "8px",
									}}
								/>
								<Typography sx={{ whiteSpace: "nowrap" }}>
									{entry.name}
								</Typography>
							</Stack>
							<Typography
								color="text.secondary"
								variant="body2">
								{new Intl.NumberFormat("en-US").format(entry.value)}
							</Typography>
						</Stack>
					),
				)}
			</Stack>
		</Paper>
	);
}
