import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { FC } from "react";

interface PortalTermsModalProps {
	open: boolean;
	onClose: () => void;
}

export const PortalTermsModal: FC<PortalTermsModalProps> = (props) => {
	const { open, onClose } = props;

	return (
		<Dialog
			maxWidth="sm"
			open={open}
			sx={{
				"& .MuiDialog-container": { justifyContent: "center" },
				"& .MuiDialog-paper": { width: "100%" },
			}}>
			<DialogContent>
				<Stack
					direction="row"
					spacing={2}
					sx={{
						alignItems: "flex-start",
						borderBottom: "1px solid var(--mui-palette-divider)",
						flex: "0 0 auto",
						justifyContent: "space-between",
						p: 2,
					}}>
					<Typography variant="h6">Terms</Typography>
				</Stack>
				<Stack p={2}>
					<Typography
						color="text.secondary"
						variant="subtitle1">
						We have made every effort to ensure that the information and
						material on this website is and remains accurate. However no
						representation or warranty, express or implied, is made or given
						about accuracy, completeness or suitability of the information or
						material for a particular purpose. Use of this website is at the
						risk of the user and we shall be liable for any claim damage or loss
						whatsoever which may arise or result from such use. We will not be
						liable for any loss or damage which may arise including, without
						limitation, indirect or consequential loss or damage, or any loss or
						damages whatsoever arising from the use or loss of use of, data or
						profits arising out of or in connection with the use of this
						website.
					</Typography>
				</Stack>
				<Stack
					component={Box}
					p={2}
					direction={"row-reverse"}>
					<Button
						onClick={onClose}
						variant="contained">
						I agree
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};
