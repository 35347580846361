import { Divider, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { indexOf, nth } from "lodash";
import { ArrowLeft, DotsThree } from "@phosphor-icons/react/dist/ssr";

import { usePopover } from "~ui-components/hooks/use-popover";

import { FeatureProperties } from "./feature-properties";
import { FeatureChange } from "./feature-change";
import { ItemMenu } from "./item-menu";
import { ShareFeatureDialog } from "./share-feature-dialog";
import { DeleteFeatureDialog } from "./delete-feature-dialog";
import { FeatureEditAttributes } from "./feature-edit-attributes";
import { FeatureEditGeometry } from "./feature-edit-geometry";
import { CreateFeature } from "./create-feature";

type ViewProps =
	| "properties"
	| "edit-attributes"
	| "edit-geometry"
	| "create-feature";

export const FeaturesInfoDrawer = ({ onClose, features }) => {
	const popover = usePopover<HTMLButtonElement>();
	const [selectedFeature, setSelectedFeature] = useState(features[0]);
	const [view, setView] = useState<ViewProps>("properties");

	const currentIndex = indexOf(features, selectedFeature);
	const featuresCount = features.length;

	const { title, ...properties } = selectedFeature;

	const handleFeatureChange = (change) => {
		const newFeature = nth(features, currentIndex + change);
		setSelectedFeature(newFeature);
	};

	const [showShareDialog, setShowShareDialog] = useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);

	const handleDelete = () => {
		setShowDeleteDialog(true);
	};

	const handleShare = () => {
		setShowShareDialog(true);
	};

	return (
		<Paper sx={{ height: "100dvh", width: "400px", borderRadius: 0 }}>
			<Stack height="100%">
				<Stack
					direction="row"
					alignItems="center"
					justifyContent={"space-between"}
					p={2}>
					<Stack
						spacing={1}
						direction="row"
						alignItems="center">
						<IconButton onClick={onClose}>
							<ArrowLeft />
						</IconButton>
						<Typography
							variant="h6"
							flex={1}>
							{view === "create-feature" ? "Create feature" : title}
						</Typography>
					</Stack>
					<IconButton
						onClick={popover.handleOpen}
						ref={popover.anchorRef}>
						<DotsThree />
					</IconButton>
				</Stack>
				{view === "properties" && (
					<FeatureChange
						handleFeatureChange={handleFeatureChange}
						featuresCount={featuresCount}
						currentIndex={currentIndex}
					/>
				)}
				<Divider />
				{view === "properties" && <FeatureProperties properties={properties} />}
				{view === "edit-attributes" && (
					<FeatureEditAttributes properties={properties} />
				)}
				{view === "edit-geometry" && <FeatureEditGeometry />}
				{view === "create-feature" && <CreateFeature properties={properties} />}
			</Stack>
			<ItemMenu
				anchorEl={popover.anchorRef.current}
				onClose={popover.handleClose}
				onDelete={handleDelete}
				open={popover.open}
				onShare={handleShare}
				onEditAttributes={() => setView("edit-attributes")}
				onEditGeometry={() => setView("edit-geometry")}
				onCreateFeature={() => setView("create-feature")}
			/>
			<DeleteFeatureDialog
				open={showDeleteDialog}
				onClose={() => setShowDeleteDialog(false)}
			/>
			<ShareFeatureDialog
				title={title}
				open={showShareDialog}
				onClose={() => setShowShareDialog(false)}
			/>
		</Paper>
	);
};
