import {
	Stack,
	FormControl,
	InputLabel,
	OutlinedInput,
	Button,
	Select,
} from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import { MuiColorInput } from "mui-color-input";

import { Option } from "~ui-components/components/atoms/option";

export interface PolygonProps {
	fillColour: string;
	fillOpacity: number;
	strokeColour: string;
	strokeOpacity: number;
	strokeWidth: number;
	id: string;
}

interface Props {
	form: ReturnType<typeof useFormik<PolygonProps>>;
	onDelete: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const SketchPolygonEditor = ({ form, onDelete = () => {} }: Props) => {
	const { values, handleChange, handleSubmit, handleBlur } = form;
	return (
		<Stack
			p={2}
			sx={{
				height: "100%",
				flexDirection: "column",
			}}>
			<Stack
				spacing={1}
				sx={{ flexGrow: 1 }}>
				<FormControl>
					<InputLabel>Fill colour</InputLabel>
					<MuiColorInput
						sx={{ width: 200 }}
						format="hex"
						value={values.fillColour}
						onChange={(value) => form.setFieldValue("fillColour", value)}
					/>
				</FormControl>
				<FormControl sx={{ width: 200 }}>
					<InputLabel>Fill opacity</InputLabel>
					<Select
						name="fillOpacity"
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.fillOpacity}>
						{_.range(0, 11).map((value) => (
							<Option
								key={value}
								value={value / 10}>
								{value * 10}%
							</Option>
						))}
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel>Stroke colour</InputLabel>
					<MuiColorInput
						sx={{ width: 200 }}
						format="hex"
						value={values.strokeColour}
						onChange={(value) => form.setFieldValue("strokeColour", value)}
					/>
				</FormControl>
				<FormControl sx={{ width: 200 }}>
					<InputLabel>Stroke opacity</InputLabel>
					<Select
						name="strokeOpacity"
						onChange={handleChange}
						value={values.strokeOpacity}>
						{_.range(0, 11).map((value) => (
							<Option
								key={value}
								value={value / 10}>
								{value * 10}%
							</Option>
						))}
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel>Stroke width</InputLabel>
					<OutlinedInput
						sx={{ width: 200 }}
						name="strokeWidth"
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.strokeWidth}
					/>
				</FormControl>
			</Stack>
			<Stack
				direction="row"
				spacing={1}
				justifyContent="end">
				<Button
					onClick={onDelete}
					color="secondary">
					Delete
				</Button>
				<Button
					onClick={() => handleSubmit()}
					variant="contained"
					color="primary">
					Save
				</Button>
			</Stack>
		</Stack>
	);
};
