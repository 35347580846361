import axios, {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
} from "axios";

export interface AxiosApiInstance extends AxiosInstance {
	request<T = unknown>(config: AxiosRequestConfig): Promise<T>;
	get<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>;
	delete<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>;
	head<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>;
	post<T = unknown>(
		url: string,
		data?: any,
		config?: AxiosRequestConfig,
	): Promise<T>;
	put<T = unknown>(
		url: string,
		data?: any,
		config?: AxiosRequestConfig,
	): Promise<T>;
	patch<T = unknown>(
		url: string,
		data?: any,
		config?: AxiosRequestConfig,
	): Promise<T>;
}

export const apiInstance: AxiosApiInstance = axios.create({
	baseURL: process.env.SST_STAGE === "dev" ? "/api" : "/",
});

apiInstance.interceptors.response.use(
	(response: AxiosResponse) => response.data,
	(error: AxiosError<Record<string, any>>) =>
		error.response?.data?.message
			? Promise.reject(new Error(error.response.data.message))
			: Promise.reject(error), // need to return original error, cos we need to know in some places if its a cancel error
);

declare module "axios" {
	interface Cancel {
		readonly __CANCEL__: true;
	}
}
