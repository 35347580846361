import { signUp } from "aws-amplify/auth";
import axios, { CancelTokenSource } from "axios";
import { paths } from "config/paths";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import Insight from "react-linkedin-insight";
import {
	SignUpV2FormValues,
	SignUpV2RemoteFormValues,
	SignUpV2RemoteSchema,
	SignUpV2Schema,
	toast,
	SignUpV2 as View,
} from "ui-components";
import { useBoolean } from "usehooks-ts";
import { tryCatch } from "utils";

import { isEmailTaken } from "~auth/queries/isEmailTaken";

let emailValidationSource: CancelTokenSource | null = null;

export const SignUpV2 = () => {
	const router = useRouter();

	const form = useFormik<SignUpV2FormValues>({
		validateOnMount: true,
		initialValues: {
			name: "",
			password: "",
			acceptedToTermsAndPolicy: false,
			companyName: "",
		},
		validationSchema: SignUpV2Schema,
		validate: (values) => {
			const [firstName, lastName] = values.name.split(" ");
			if (!firstName || !lastName)
				return {
					name: "Please enter your first and last name",
				};

			return {};
		},
		onSubmit: (values, helpers) => {
			const payload = { ...values, ...remoteForm.values };

			toast.promise(
				async () => {
					const { nextStep } = await signUp({
						username: payload.email,
						password: payload.password,
						options: {
							autoSignIn: true,
							userAttributes: {
								"custom:signup:email": payload.email,
								"custom:signup:name": payload.name,
								"custom:signup:companyName": payload.companyName,
							},
						},
					});
					if (nextStep.signUpStep === "CONFIRM_SIGN_UP") {
						const { default: Pixel } = await import("react-facebook-pixel");
						Pixel.track("StartTrial");
						Insight.track("18368692");

						void router.replace(paths.signUpVerificationV2(payload.email));
						return;
					}

					throw new Error("Something went wrong!");
				},
				{
					loading: "Signing you up...",
					success: "Success!",
					error: (e) => {
						helpers.setSubmitting(false);
						return axios.isAxiosError(e) ? "Something went wrong!" : e.message;
					},
				},
			);
		},
	});
	const remoteForm = useFormik<SignUpV2RemoteFormValues>({
		validateOnMount: true,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			email: "",
		},
		validationSchema: SignUpV2RemoteSchema,
		// eslint-disable-next-line @typescript-eslint/no-empty-function -- only use this form for validation
		onSubmit: () => {},
	});
	const { value: isRemotelyValidatingEmail, setValue: toggleValidatingEmail } =
		useBoolean(false);

	const handleEmailValueChange = async (newValue: string) => {
		const [schemaErrorMessage] = await Promise.all([
			SignUpV2RemoteSchema.fields.email
				.validate(newValue)
				.then(() => null)
				.catch((e) => e.message as string),
			remoteForm.setFieldValue("email", newValue),
			remoteForm.setFieldTouched("email", true),
		]);
		if (schemaErrorMessage) {
			remoteForm.setFieldError("email", schemaErrorMessage);
			return;
		}

		toggleValidatingEmail(true);
		const [remoteError] = await tryCatch(remotelyValidateEmail(newValue));
		if (remoteError) {
			if (axios.isCancel(remoteError)) return;

			toggleValidatingEmail(false);
			remoteForm.setFieldError("email", remoteError.message);
			return;
		}
		toggleValidatingEmail(false);
		remoteForm.setFieldError("email", undefined);
	};

	return (
		<View
			form={form}
			remoteForm={remoteForm}
			onEmailChange={(e) => handleEmailValueChange(e.target.value)}
			onEmailBlur={(e) => handleEmailValueChange(e.target.value)}
			isRemotelyValidatingEmail={isRemotelyValidatingEmail}
		/>
	);
};

const remotelyValidateEmail = async (email: string) => {
	if (emailValidationSource) emailValidationSource.cancel();
	emailValidationSource = axios.CancelToken.source();

	const [error, bool] = await tryCatch(
		isEmailTaken({ email }, { cancelToken: emailValidationSource.token }),
	);

	if (error) {
		if (axios.isCancel(error)) throw error;
		if (axios.isAxiosError(error)) throw new Error("Something went wrong!");
		throw new Error(error.message);
	}

	if (bool) throw new Error("Email is already taken");
};
