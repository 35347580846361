import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

interface Slots {
	SideNav?: any;
	Content: {
		MapDetails: any;
		MapMenu: any;
	};
}

interface SlotProps {
	active?: string;
}

const Empty = () => null;

const defaultSlots: Slots = {
	SideNav: Empty,
	Content: {
		MapDetails: Empty,
		MapMenu: Empty,
	},
};

export interface Props {
	open?: boolean;
	slots?: Slots;
	slotProps?: SlotProps;
}

function MapSettings(props: Props) {
	return (
		<DashboardContentLayout pageTitle="Map settings">
			<Root {...props} />
		</DashboardContentLayout>
	);
}

function Root(props: Props) {
	const { slots = {}, slotProps = {} } = props;

	const Slots = {
		...defaultSlots,
		...slots,
	};

	return (
		<Stack
			direction={{ xs: "column", md: "row" }}
			spacing={4}
			sx={{ position: "relative" }}>
			<Slots.SideNav />
			<Box sx={{ flex: "1 1 auto", minWidth: 0 }}>
				<Slots.Content.MapDetails active={slotProps.active} />
				<Slots.Content.MapMenu active={slotProps.active} />
			</Box>
		</Stack>
	);
}

export { MapSettings };
