import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Box } from "@mui/material";

import { FakeSelectButton } from "~ui-components/components/atoms/fake-select-button";
import { DataTable } from "~ui-components/components/atoms/data-table";
import type { ColumnDef } from "~ui-components/components/atoms/data-table";

import { useCustomersSelection } from "./formatting-selection-context";
import { FormattingDrawer } from "./formatting-drawer";

export interface AttributeFormatting {
	id: string;
	field: string;
	alias?: string;
	formatting: string;
}

const columns = [
	{
		formatter: (row): React.JSX.Element => (
			<Typography
				color="text.secondary"
				variant="body2">
				{row.field}
			</Typography>
		),
		name: "Field",
		width: "250px",
	},
	{
		formatter: (): React.JSX.Element => (
			<Stack
				direction="row"
				spacing={2}
				sx={{ alignItems: "center" }}>
				<OutlinedInput
					name="layerTitle"
					// onChange={handleChange}
					// onBlur={handleBlur}
					// value={values.layerTitle}
					// error={!!errors?.layerTitle}
				/>
			</Stack>
		),
		name: "Alias",
		width: "250px",
	},
	{
		formatter: (): React.JSX.Element => {
			return (
				<Box sx={{ textAlign: "right" }}>
					<FakeSelectButton onClick={() => console.log("clci")} />
				</Box>
			);
		},
		name: "Formatting",
		width: "150px",
	},
] satisfies ColumnDef<AttributeFormatting>[];

export interface CustomersTableProps {
	rows: AttributeFormatting[];
	onOpenFormattingDrawer: () => void;
}

export function FormattingTable({
	rows,
	onOpenFormattingDrawer,
}: CustomersTableProps): React.JSX.Element {
	const { deselectAll, deselectOne, selectAll, selectOne, selected } =
		useCustomersSelection();

	const [open, setOpen] = React.useState(false);

	const handleCellClick = (cell: any) => {
		if (cell.name === "Formatting") onOpenFormattingDrawer(); //setOpen(true);
	};

	return (
		<React.Fragment>
			<FormattingDrawer
				open={open}
				onClose={() => setOpen(false)}
			/>
			<DataTable<AttributeFormatting>
				onCellClick={handleCellClick}
				columns={columns}
				onDeselectAll={deselectAll}
				onDeselectOne={(_, row) => {
					deselectOne(row.id);
				}}
				onSelectAll={selectAll}
				onSelectOne={(_, row) => {
					selectOne(row.id);
				}}
				rows={rows}
				selectable
				selected={selected}
			/>
		</React.Fragment>
	);
}
