import * as React from "react";
import RouterLink from "next/link";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { paths } from "config/paths";

import type { ColorScheme } from "~ui-components/theme/types";
import type { NavColor } from "~ui-components/types/settings";
import { useSettings } from "~ui-components/hooks/use-settings";
import { MangoLogo } from "~ui-components/components/atoms/mango-logo";

import { navColorStyles } from "./styles";

export const logoColors = {
	dark: { blend_in: "light", discrete: "dark", evident: "dark" },
	light: { blend_in: "light", discrete: "light", evident: "dark" },
} as Record<ColorScheme, Record<NavColor, "dark" | "light">>;

export interface SideNavProps {
	color?: NavColor;
	children?: React.ReactNode;
}

export function SideNav({
	color = "evident",
	children,
}: SideNavProps): React.JSX.Element {
	const { colorScheme = "light" } = useSettings();

	const styles = navColorStyles[colorScheme][color];

	return (
		<Box
			sx={{
				...styles,
				bgcolor: "var(--SideNav-background)",
				borderRight: "var(--SideNav-border)",
				color: "var(--SideNav-color)",
				display: { xs: "none", lg: "flex" },
				flexDirection: "column",
				height: "100%",
				left: 0,
				position: "fixed",
				top: 0,
				width: "var(--SideNav-width)",
				zIndex: "var(--SideNav-zIndex)",
			}}>
			<Stack
				spacing={2}
				sx={{ p: 2 }}>
				<div>
					<Box
						component={RouterLink}
						href={paths.home}
						sx={{ display: "inline-flex" }}>
						<MangoLogo
							height={32}
							width={122}
						/>
					</Box>
				</div>
			</Stack>
			<Box
				component="nav"
				sx={{
					flex: "1 1 auto",
					overflowY: "auto",
					p: 2,
					scrollbarWidth: "none",
					"&::-webkit-scrollbar": { display: "none" },
				}}>
				{children}
			</Box>
		</Box>
	);
}
