import {
	GraphQLOptionsV6,
	GraphqlSubscriptionResult,
} from "@aws-amplify/api-graphql";
import { GraphQLResult } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";

import { client } from "../graphql-client";

/** A simple typesafe helper to make appsync subscription easier */
export const graphierQLSubscription = async <
	TResults = unknown,
	TArgs = object,
>(
	_opts: Pick<GraphQLOptionsV6, "query"> & {
		variables?: TArgs;
		useKey?: boolean;
	},
	{
		onNext,
		onError,
	}: {
		onNext: (value: GraphQLResult<TResults>) => void;
		onError: (error) => void;
	},
) => {
	const opts: GraphQLOptionsV6 = {
		query: _opts.query,
	};

	if (_opts.variables) opts.variables = _opts.variables;

	if (_opts.useKey) {
		opts.authMode = "apiKey";
	} else {
		const session = await fetchAuthSession();
		const token = session.tokens?.idToken?.toString();
		if (!token) throw new Error("Failed to get token");

		opts.authMode = "userPool";
		opts.authToken = token;
	}

	const subscription = client.graphql(
		opts,
	) as GraphqlSubscriptionResult<TResults>;

	return subscription.subscribe({
		next: (event) => onNext(event),
		error: (error) => onError(error),
	});
};
