import { useMutation } from "@tanstack/react-query";
import { apiInstance } from "utils";

export interface ResendEmailValues {
	email: string;
}

export const useResendEmail = () => {
	return useMutation({
		mutationKey: ["resendEmail"],
		mutationFn: async (values: ResendEmailValues) => {
			const postForm = new FormData();
			postForm.append("email", values.email);
			return apiInstance.put("/resend_confirmation_email", postForm, {
				headers: {
					Authorization: "Bearer bWdvIGNvbmZpcm1hdGlvbl90b2tlbg==",
					"Content-Type": "application/json",
				},
			});
		},
	});
};
