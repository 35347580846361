import { StackProps } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { ArrowClockwise as SpinningIcon } from "@phosphor-icons/react/dist/ssr/ArrowClockwise";
import { ArrowSquareOut } from "@phosphor-icons/react/dist/ssr/ArrowSquareOut";
import { Gear as DetailsIcon } from "@phosphor-icons/react/dist/ssr/Gear";
import { useFormik } from "formik";

import { Option } from "~ui-components/components/atoms/option";
import { TextEditor } from "~ui-components/components/atoms/text-editor/text-editor";
import { mapNavItemKeys } from "~ui-components/components/molecules/map-settings-side-nav";

interface MapDetailsInput {
	mapTitle: string;
	mapDescription: string;
	mapLoadBounds: string;
	displayTitleBlock: boolean;
	displayCustomTheme: boolean;
	displayLocalisation: boolean;
	displayInfoSidebar: boolean;
	infoSidebarContent: string;
	displayToolPanel: boolean;
	displayToolPanelState: string;
	defaultActiveTool: string;
}

export type MapDetailsProps = StackProps & {
	form: ReturnType<typeof useFormik<MapDetailsInput>>;
	active?: string;
};

export const MapDetails = (props: MapDetailsProps) => {
	const { active, form } = props;

	if (active !== mapNavItemKeys.details) return null;

	return (
		<form onSubmit={form.handleSubmit}>
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<DetailsIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Map details"
				/>

				<CardContent>
					<Stack spacing={3}>
						<Stack spacing={2}>
							<FormControl>
								<InputLabel>Map title</InputLabel>
								<OutlinedInput
									name="mapTitle"
									onChange={form?.handleChange}
									// onBlur={form?.handleBlur}
									value={form.values.mapTitle}
									// error={!!form?.errors?.mapTitle}
								/>
							</FormControl>

							<FormControl>
								<InputLabel>Map description</InputLabel>
								<Box
									sx={{
										mt: "8px",
										"& .tiptap-container": { height: "200px" },
									}}>
									<TextEditor
										placeholder="Write something"
										content={form.values.mapDescription}
										toolsProps={{
											Strike: false,
											BulletList: false,
											OrderedList: false,
										}}
									/>
								</Box>
							</FormControl>

							<FormControl>
								<InputLabel sx={{ my: 2 }}>
									Layout and branding options
								</InputLabel>

								<RadioGroup
									defaultValue="max-bounds"
									name="mapLoadBounds"
									onChange={form.handleChange}>
									<FormControlLabel
										control={<Radio value="max-bounds" />}
										label="Load map showing maximum bounds of all layers"
									/>

									<FormControlLabel
										value="current-bounds"
										control={<Radio value="current-bounds" />}
										label={
											<>
												Load map at current position and zoom level
												<Button
													sx={{ ml: 2 }}
													variant="outlined"
													color="secondary"
													startIcon={<SpinningIcon />}>
													Set to current position
												</Button>
											</>
										}
									/>
								</RadioGroup>
							</FormControl>

							<FormControl>
								<FormControlLabel
									sx={{ my: 2 }}
									control={
										<Switch
											name="displayTitleBlock"
											checked={form.values.displayTitleBlock}
											onChange={form.handleChange}
										/>
									}
									label="Display title block"
								/>

								<Stack
									direction="row"
									spacing={2}
									sx={{ my: 2 }}>
									<FormControlLabel
										control={
											<Switch
												name="displayCustomTheme"
												checked={form.values.displayCustomTheme}
												onChange={form.handleChange}
											/>
										}
										label="Display custom theme"
									/>
									<Button
										variant="outlined"
										color="secondary"
										endIcon={<ArrowSquareOut />}>
										Theme settings
									</Button>
								</Stack>

								<Stack
									direction="row"
									spacing={2}
									sx={{ my: 2 }}>
									<FormControlLabel
										control={
											<Switch
												name="displayLocalisation"
												checked={form.values.displayLocalisation}
												onChange={form.handleChange}
											/>
										}
										label="Display localisation"
									/>
									<Button
										variant="outlined"
										color="secondary"
										endIcon={<ArrowSquareOut />}>
										Localisation settings
									</Button>
								</Stack>

								<FormControlLabel
									sx={{ my: 2 }}
									control={
										<Switch
											name="displayInfoSidebar"
											checked={form.values.displayInfoSidebar}
											onChange={form.handleChange}
										/>
									}
									label="Display info sidebar"
								/>
							</FormControl>

							{form.values.displayInfoSidebar && (
								<FormControl sx={{ flex: "1 1 auto" }}>
									<InputLabel>Info sidebar content</InputLabel>
									<Box
										sx={{
											mt: "8px",
											"& .tiptap-container": { height: "400px" },
										}}>
										<TextEditor
											placeholder="Write something"
											content={form.values.infoSidebarContent}
											toolsProps={{
												Code: true,
												CodeBlock: true,
												Blockquote: true,
												LinkInput: {},
												ImageInput: {},
												IFrameInput: {},
												Table: true,
												TextAlign: true,
												HorizontalRule: true,
											}}
										/>
									</Box>
								</FormControl>
							)}

							<FormControlLabel
								control={
									<Switch
										name="displayToolPanel"
										checked={form.values.displayToolPanel}
										onChange={form.handleChange}
									/>
								}
								label="Display tool panel"
							/>

							{form.values.displayToolPanel && (
								<>
									<FormControl>
										<RadioGroup
											name="displayToolPanelState"
											value={form.values.displayToolPanelState}
											onChange={form.handleChange}>
											<FormControlLabel
												value="open"
												control={<Radio />}
												label="Open"
											/>

											<FormControlLabel
												value="close"
												control={<Radio />}
												label="Close"
											/>
										</RadioGroup>
									</FormControl>

									<FormControl sx={{ width: "360px" }}>
										<InputLabel>Default active tool</InputLabel>
										<Select
											name="defaultActiveTool"
											value={form.values.defaultActiveTool}
											onChange={form.handleChange}>
											<Option value="legend">Legend</Option>
											<Option value="query">Query</Option>
											<Option value="geolocate">Geolocate</Option>
										</Select>

										<FormHelperText>Tool active on map load.</FormHelperText>
									</FormControl>
								</>
							)}
						</Stack>
					</Stack>
				</CardContent>

				<CardActions sx={{ justifyContent: "flex-end" }}>
					<Button color="secondary">Cancel</Button>
					<Button
						type="submit"
						variant="contained"
						disabled={!form.isValid}>
						Save changes
					</Button>
				</CardActions>
			</Card>
		</form>
	);
};
