/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSDate: { input: string; output: string; }
  AWSDateTime: { input: string; output: string; }
  AWSEmail: { input: string; output: string; }
  AWSIPAddress: { input: string; output: string; }
  AWSJSON: { input: string; output: string; }
  AWSPhone: { input: string; output: string; }
  AWSTime: { input: string; output: string; }
  AWSTimestamp: { input: number; output: number; }
  AWSURL: { input: string; output: string; }
};

export enum ACCOUNT_USER_ROLE {
  ADMIN = 'ADMIN',
  EDITOR = 'EDITOR',
  OWNER = 'OWNER',
  VIEWER = 'VIEWER'
}

export type Account = {
  _accountUsers: Array<AccountUser>;
  _myUserAccountRole?: Maybe<ACCOUNT_USER_ROLE>;
  billingAddress?: Maybe<AccountBillingAddress>;
  companyName: Scalars['String']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  paymentMethod?: Maybe<AccountPaymentMethod>;
  slug: Scalars['String']['output'];
  subscription: AccountSubscription;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type AccountBillingAddress = {
  city: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  country: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  province: Scalars['String']['output'];
  streetLine1: Scalars['String']['output'];
  streetLine2?: Maybe<Scalars['String']['output']>;
  vatNumber: Scalars['String']['output'];
};

export type AccountBillingAddressInput = {
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  country: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  province: Scalars['String']['input'];
  streetLine1: Scalars['String']['input'];
  streetLine2?: InputMaybe<Scalars['String']['input']>;
  vatNumber: Scalars['String']['input'];
};

export type AccountPaymentMethod = {
  cardCvv: Scalars['String']['output'];
  cardExpiresAt: Scalars['String']['output'];
  cardName: Scalars['String']['output'];
  cardNumber: Scalars['String']['output'];
};

export type AccountPaymentMethodInput = {
  cardCvv: Scalars['String']['input'];
  cardExpiresAt: Scalars['String']['input'];
  cardName: Scalars['String']['input'];
  cardNumber: Scalars['String']['input'];
};

export type AccountSubscription = {
  paidConfig?: Maybe<AccountSubscriptionPaidConfig>;
  trialExpiresAt?: Maybe<Scalars['AWSDateTime']['output']>;
  type: SUBSCRIPTION_TYPE;
};

export type AccountSubscriptionInput = {
  paidConfig?: InputMaybe<AccountSubscriptionPaidConfigInput>;
  trialExpiresAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  type: SUBSCRIPTION_TYPE;
};

export type AccountSubscriptionPaidConfig = {
  addons: AccountSubscriptionPaidConfigAddons;
  cycle: SUBSCRIPTION_CYCLE;
  plan: SUBSCRIPTION_PLAN;
};

export type AccountSubscriptionPaidConfigAddons = {
  admins: Scalars['Float']['output'];
  editors: Scalars['Float']['output'];
  maps: Scalars['Float']['output'];
  storage: Scalars['Float']['output'];
};

export type AccountSubscriptionPaidConfigAddonsInput = {
  admins: Scalars['Float']['input'];
  editors: Scalars['Float']['input'];
  maps: Scalars['Float']['input'];
  storage: Scalars['Float']['input'];
};

export type AccountSubscriptionPaidConfigInput = {
  addons: AccountSubscriptionPaidConfigAddonsInput;
  cycle: SUBSCRIPTION_CYCLE;
  plan: SUBSCRIPTION_PLAN;
};

export type AccountUser = {
  _user: User;
  accountId: Scalars['ID']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  role: ACCOUNT_USER_ROLE;
  updatedAt: Scalars['AWSDateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type AddDropboxDatasetSyncMetaDataInput = {
  files: Array<DropboxFileInput>;
  id: Scalars['ID']['input'];
};

export type BasicSymbol = {
  opacity?: Maybe<Scalars['Float']['output']>;
  radius?: Maybe<Scalars['Float']['output']>;
  rotation?: Maybe<Scalars['Float']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
};

export type BasicSymbolInput = {
  opacity?: InputMaybe<Scalars['Float']['input']>;
  radius?: InputMaybe<Scalars['Float']['input']>;
  rotation?: InputMaybe<Scalars['Float']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
};

export type BubbleConfigs = {
  classNumber?: Maybe<Scalars['Float']['output']>;
  classification?: Maybe<Scalars['String']['output']>;
  radius?: Maybe<PointBubbleRadius>;
  symbolStyle?: Maybe<SymbolStyle>;
  symbolValues?: Maybe<Array<Maybe<SymbolValue>>>;
  valueField?: Maybe<Scalars['String']['output']>;
};

export type BubbleConfigsInput = {
  classNumber?: InputMaybe<Scalars['Float']['input']>;
  classification?: InputMaybe<Scalars['String']['input']>;
  radius?: InputMaybe<PointBubbleRadiusInput>;
  symbolStyle?: InputMaybe<SymbolStyleInput>;
  symbolValues?: InputMaybe<Array<InputMaybe<SymbolValueInput>>>;
  valueField?: InputMaybe<Scalars['String']['input']>;
};

export type ConnectDropboxTokenInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type CreateLayerInput = {
  bubbleStyle?: InputMaybe<BubbleConfigsInput>;
  dataType: GEOMETRY_TYPE;
  datasetId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  heatMapStyle?: InputMaybe<HeatMapConfigsInput>;
  labelsProps?: InputMaybe<LabelsInput>;
  layerGroupId: Scalars['ID']['input'];
  lineCategoryStyle?: InputMaybe<LineCategoryConfigsInput>;
  lineQuantityStyle?: InputMaybe<LineQuantityConfigsInput>;
  lineSimpleStyle?: InputMaybe<LineSimpleConfigsInput>;
  maxZoom?: InputMaybe<Scalars['Float']['input']>;
  minZoom?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  pointCategoryStyle?: InputMaybe<PointCategoryConfigsInput>;
  pointQuantityStyle?: InputMaybe<PointQuantityConfigsInput>;
  pointSimpleStyle?: InputMaybe<PointSimpleConfigsInput>;
  polygonCategoryStyle?: InputMaybe<PolygonCategoryConfigsInput>;
  polygonQuantityStyle?: InputMaybe<PolygonQuantityConfigsInput>;
  polygonSimpleStyle?: InputMaybe<PolygonSimpleConfigsInput>;
  styleType: LAYER_STYLE_TYPE;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateMapInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateUserGroupInput = {
  name: Scalars['String']['input'];
};

export enum DATASOURCE_TYPE {
  CSV = 'CSV',
  GEOJSON = 'GEOJSON',
  GEOTIFF = 'GEOTIFF',
  GEO_DATABASE = 'GEO_DATABASE',
  KML = 'KML',
  SHAPEFILE = 'SHAPEFILE',
  TAB = 'TAB',
  WMS = 'WMS'
}

export type Dataset = {
  _layers: Array<Layer>;
  accountId: Scalars['String']['output'];
  bbox: Array<Scalars['Float']['output']>;
  columns?: Maybe<Array<DatasetColumn>>;
  createdAt: Scalars['AWSDateTime']['output'];
  datasourceType: DATASOURCE_TYPE;
  description?: Maybe<Scalars['String']['output']>;
  dropboxAccessToken?: Maybe<Scalars['String']['output']>;
  dropboxFiles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  dropboxLatestCursor?: Maybe<Scalars['String']['output']>;
  dropboxRefreshToken?: Maybe<Scalars['String']['output']>;
  dropboxShareFilesLink?: Maybe<Scalars['String']['output']>;
  getCapabilitiesUrl?: Maybe<Scalars['AWSURL']['output']>;
  id: Scalars['ID']['output'];
  isAuthenticatedDropbox?: Maybe<Scalars['Boolean']['output']>;
  isPrivate: Scalars['Boolean']['output'];
  isSyncActive: Scalars['Boolean']['output'];
  legendUrl?: Maybe<Scalars['AWSURL']['output']>;
  mapsCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Float']['output'];
  sourceDatasetId?: Maybe<Scalars['String']['output']>;
  type: GEOMETRY_TYPE;
  updatedAt: Scalars['AWSDateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type DatasetColumn = {
  alias?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  defaultValue?: Maybe<Scalars['String']['output']>;
  domainName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nullable: Scalars['Boolean']['output'];
  precision?: Maybe<Scalars['Int']['output']>;
  subType?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  uniqueConstraint: Scalars['Boolean']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export type DropboxFileInput = {
  bytes: Scalars['Int']['input'];
  icon?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isDir?: InputMaybe<Scalars['Boolean']['input']>;
  link: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type FileUploadUrlResult = {
  formFieldsJson: Scalars['AWSJSON']['output'];
  uploadUrl: Scalars['AWSURL']['output'];
};

export type FillStyle = {
  color?: Maybe<Scalars['String']['output']>;
  fillType?: Maybe<Scalars['String']['output']>;
  isDisabled?: Maybe<Scalars['Boolean']['output']>;
  opacity?: Maybe<Scalars['Float']['output']>;
  style?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type FillStyleInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  fillType?: InputMaybe<Scalars['String']['input']>;
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  opacity?: InputMaybe<Scalars['Float']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export enum GEOMETRY_TYPE {
  LINE = 'LINE',
  POINT = 'POINT',
  POLYGON = 'POLYGON',
  RASTER = 'RASTER',
  WMS = 'WMS'
}

export type HeatMapBasicConfigs = {
  opacity?: Maybe<Scalars['Float']['output']>;
  radius?: Maybe<Scalars['Float']['output']>;
};

export type HeatMapBasicConfigsInput = {
  opacity?: InputMaybe<Scalars['Float']['input']>;
  radius?: InputMaybe<Scalars['Float']['input']>;
};

export type HeatMapConfigs = {
  basic?: Maybe<HeatMapBasicConfigs>;
};

export type HeatMapConfigsInput = {
  basic?: InputMaybe<HeatMapBasicConfigsInput>;
};

export type InviteUserInput = {
  email: Scalars['String']['input'];
  groupIds: Array<Scalars['ID']['input']>;
  role: ACCOUNT_USER_ROLE;
};

export type JoinAccountInput = {
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export enum LAYER_STYLE_TYPE {
  BUBBLE = 'BUBBLE',
  HEATMAP = 'HEATMAP',
  LINE_CATEGORY = 'LINE_CATEGORY',
  LINE_QUANTITY = 'LINE_QUANTITY',
  LINE_SIMPLE = 'LINE_SIMPLE',
  POINT_CATEGORY = 'POINT_CATEGORY',
  POINT_QUANTITY = 'POINT_QUANTITY',
  POINT_SIMPLE = 'POINT_SIMPLE',
  POLYGON_CATEGORY = 'POLYGON_CATEGORY',
  POLYGON_QUANTITY = 'POLYGON_QUANTITY',
  POLYGON_SIMPLE = 'POLYGON_SIMPLE',
  RASTER = 'RASTER',
  WMS = 'WMS'
}

export type LabelConfigs = {
  attribute?: Maybe<Scalars['String']['output']>;
  font?: Maybe<Scalars['String']['output']>;
  fontColour?: Maybe<Scalars['String']['output']>;
  fontOpacity?: Maybe<Scalars['Float']['output']>;
  fontSize?: Maybe<Scalars['Float']['output']>;
  fontStyle?: Maybe<Scalars['String']['output']>;
  haloColour?: Maybe<Scalars['String']['output']>;
  haloOpacity?: Maybe<Scalars['Float']['output']>;
  haloSize?: Maybe<Scalars['Float']['output']>;
};

export type LabelsInput = {
  attribute?: InputMaybe<Scalars['String']['input']>;
  font?: InputMaybe<Scalars['String']['input']>;
  fontColour?: InputMaybe<Scalars['String']['input']>;
  fontOpacity?: InputMaybe<Scalars['Float']['input']>;
  fontSize?: InputMaybe<Scalars['Float']['input']>;
  fontStyle?: InputMaybe<Scalars['String']['input']>;
  haloColour?: InputMaybe<Scalars['String']['input']>;
  haloOpacity?: InputMaybe<Scalars['Float']['input']>;
  haloSize?: InputMaybe<Scalars['Float']['input']>;
};

export type Layer = {
  _dataset: Dataset;
  _layerGroup: LayerGroup;
  bubbleStyle?: Maybe<BubbleConfigs>;
  createdAt: Scalars['AWSDateTime']['output'];
  dataType: GEOMETRY_TYPE;
  datasetId: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  heatMapStyle?: Maybe<HeatMapConfigs>;
  id: Scalars['ID']['output'];
  labelsProps?: Maybe<LabelConfigs>;
  layerGroupId: Scalars['ID']['output'];
  lineCategoryStyle?: Maybe<LineCategoryConfigs>;
  lineQuantityStyle?: Maybe<LineQuantityConfigs>;
  lineSimpleStyle?: Maybe<LineSimpleConfigs>;
  maxZoom?: Maybe<Scalars['Float']['output']>;
  minZoom?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  pointCategoryStyle?: Maybe<PointCategoryConfigs>;
  pointQuantityStyle?: Maybe<PointQuantityConfigs>;
  pointSimpleStyle?: Maybe<PointSimpleConfigs>;
  polygonCategoryStyle?: Maybe<PolygonCategoryConfigs>;
  polygonQuantityStyle?: Maybe<PolygonQuantityConfigs>;
  polygonSimpleStyle?: Maybe<PolygonSimpleConfigs>;
  styleType: LAYER_STYLE_TYPE;
  updatedAt: Scalars['AWSDateTime']['output'];
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type LayerCollection = {
  items: Array<Layer>;
  total: Scalars['Int']['output'];
};

export type LayerGroup = {
  _map: Map;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  layerCollection: LayerCollection;
  layersOrder: Array<Scalars['ID']['output']>;
  mapId: Scalars['ID']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
  visible: Scalars['Boolean']['output'];
};

export type LayerGroupCollection = {
  items: Array<LayerGroup>;
  total: Scalars['Int']['output'];
};

export type LineCategoryConfigs = {
  colorRamp?: Maybe<Scalars['String']['output']>;
  symbolStyle?: Maybe<SymbolStyle>;
  symbolValues?: Maybe<Array<Maybe<SymbolValue>>>;
  valueField?: Maybe<Scalars['String']['output']>;
};

export type LineCategoryConfigsInput = {
  colorRamp?: InputMaybe<Scalars['String']['input']>;
  symbolStyle?: InputMaybe<SymbolStyleInput>;
  symbolValues?: InputMaybe<Array<InputMaybe<SymbolValueInput>>>;
  valueField?: InputMaybe<Scalars['String']['input']>;
};

export type LineQuantityConfigs = {
  classNumber?: Maybe<Scalars['Float']['output']>;
  classbreaks?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  classification?: Maybe<Scalars['String']['output']>;
  colorRamp?: Maybe<Scalars['String']['output']>;
  legendType?: Maybe<Scalars['String']['output']>;
  symbolStyle?: Maybe<SymbolStyle>;
  symbolValues?: Maybe<Array<Maybe<SymbolValue>>>;
  valueField?: Maybe<Scalars['String']['output']>;
};

export type LineQuantityConfigsInput = {
  classNumber?: InputMaybe<Scalars['Float']['input']>;
  classbreaks?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  classification?: InputMaybe<Scalars['String']['input']>;
  colorRamp?: InputMaybe<Scalars['String']['input']>;
  legendType?: InputMaybe<Scalars['String']['input']>;
  symbolStyle?: InputMaybe<SymbolStyleInput>;
  symbolValues?: InputMaybe<Array<InputMaybe<SymbolValueInput>>>;
  valueField?: InputMaybe<Scalars['String']['input']>;
};

export type LineSimpleConfigs = {
  symbolStyle?: Maybe<SymbolStyle>;
};

export type LineSimpleConfigsInput = {
  symbolStyle?: InputMaybe<SymbolStyleInput>;
};

export type Map = {
  accountId: Scalars['ID']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  layerGroupCollection: LayerGroupCollection;
  layerGroupsOrder?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  name: Scalars['String']['output'];
  tools?: Maybe<Array<Maybe<Tool>>>;
  updatedAt: Scalars['AWSDateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type Mutation = {
  addDropboxDatasetSyncMetaData: Dataset;
  connectDropboxGetRefreshToken: Scalars['Boolean']['output'];
  createLayer: Layer;
  createMap: Map;
  createPortalSettings: PortalSettings;
  createUserGroup: UserGroup;
  deleteAccountUser: AccountUser;
  deleteDataset: Scalars['Boolean']['output'];
  deleteLayer: Layer;
  deleteMap: Map;
  deleteUserGroup: UserGroup;
  disConnectDropbox: Scalars['Boolean']['output'];
  inviteUser: Scalars['Boolean']['output'];
  isEmailTaken: Scalars['Boolean']['output'];
  joinAccount: User;
  processGeotiffDataset: ProcessGeotiffDatasetResults;
  processTempDataset: ProcessTempDatasetResults;
  processWmsDataset: ProcessWmsDatasetResults;
  submitContactForm: Scalars['Boolean']['output'];
  submitFranchiseContactForm: Scalars['Boolean']['output'];
  triggerGeotiffProcessingComplete: TriggerGeotiffProcessingCompleteResults;
  triggerGeotiffProcessingError: TriggerGeotiffProcessingErrorResults;
  updateAccountUser: AccountUser;
  updateCurrentAccount: Account;
  updateCurrentUser: User;
  updateDataset: Dataset;
  updateLayer: Layer;
  updateMap: Map;
  updatePortalSettings: PortalSettings;
  updateUser: User;
  updateUserGroup: UserGroup;
};


export type MutationaddDropboxDatasetSyncMetaDataArgs = {
  input?: InputMaybe<AddDropboxDatasetSyncMetaDataInput>;
};


export type MutationconnectDropboxGetRefreshTokenArgs = {
  input: ConnectDropboxTokenInput;
};


export type MutationcreateLayerArgs = {
  input: CreateLayerInput;
};


export type MutationcreateMapArgs = {
  input: CreateMapInput;
};


export type MutationcreatePortalSettingsArgs = {
  input: PortalSettingsInput;
};


export type MutationcreateUserGroupArgs = {
  input: CreateUserGroupInput;
  memberIds: Array<Scalars['ID']['input']>;
};


export type MutationdeleteAccountUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdeleteDatasetArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdeleteLayerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdeleteMapArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdeleteUserGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdisConnectDropboxArgs = {
  id: Scalars['ID']['input'];
};


export type MutationinviteUserArgs = {
  input: InviteUserInput;
};


export type MutationisEmailTakenArgs = {
  email: Scalars['String']['input'];
};


export type MutationjoinAccountArgs = {
  input: JoinAccountInput;
};


export type MutationprocessGeotiffDatasetArgs = {
  input: ProcessGeotiffDatasetInput;
};


export type MutationprocessTempDatasetArgs = {
  input: ProcessTempDatasetInput;
};


export type MutationprocessWmsDatasetArgs = {
  input: ProcessWmsDatasetInput;
};


export type MutationsubmitContactFormArgs = {
  input: SubmitContactFormInput;
  recaptchaToken: Scalars['String']['input'];
};


export type MutationsubmitFranchiseContactFormArgs = {
  input: SubmitFranchiseContactFormInput;
  recaptchaToken: Scalars['String']['input'];
};


export type MutationtriggerGeotiffProcessingCompleteArgs = {
  input: TriggerGeotiffProcessingCompleteInput;
};


export type MutationtriggerGeotiffProcessingErrorArgs = {
  input: TriggerGeotiffProcessingErrorInput;
};


export type MutationupdateAccountUserArgs = {
  groupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
  role?: InputMaybe<ACCOUNT_USER_ROLE>;
};


export type MutationupdateCurrentAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationupdateCurrentUserArgs = {
  input: UpdateUserInput;
};


export type MutationupdateDatasetArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDatasetInput;
};


export type MutationupdateLayerArgs = {
  input: UpdateLayerInput;
};


export type MutationupdateMapArgs = {
  input: UpdateMapInput;
};


export type MutationupdatePortalSettingsArgs = {
  id: Scalars['ID']['input'];
  input: PortalSettingsInput;
};


export type MutationupdateUserArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserInput;
};


export type MutationupdateUserGroupArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserGroupInput;
  memberIds: Array<Scalars['ID']['input']>;
};

export type Navigator = {
  active: Scalars['Boolean']['output'];
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export type NavigatorInput = {
  active: Scalars['Boolean']['input'];
  content: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  index: Scalars['Int']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  width: Scalars['Int']['input'];
};

export type PointBubbleRadius = {
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

export type PointBubbleRadiusInput = {
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
};

export type PointCategoryConfigs = {
  colorRamp?: Maybe<Scalars['String']['output']>;
  symbolStyle?: Maybe<SymbolStyle>;
  symbolValues?: Maybe<Array<Maybe<SymbolValue>>>;
  valueField?: Maybe<Scalars['String']['output']>;
};

export type PointCategoryConfigsInput = {
  colorRamp?: InputMaybe<Scalars['String']['input']>;
  symbolStyle?: InputMaybe<SymbolStyleInput>;
  symbolValues?: InputMaybe<Array<InputMaybe<SymbolValueInput>>>;
  valueField?: InputMaybe<Scalars['String']['input']>;
};

export type PointQuantityConfigs = {
  classNumber?: Maybe<Scalars['Float']['output']>;
  classbreaks?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  classification?: Maybe<Scalars['String']['output']>;
  colorRamp?: Maybe<Scalars['String']['output']>;
  legendType?: Maybe<Scalars['String']['output']>;
  symbolStyle?: Maybe<SymbolStyle>;
  symbolValues?: Maybe<Array<Maybe<SymbolValue>>>;
  valueField?: Maybe<Scalars['String']['output']>;
};

export type PointQuantityConfigsInput = {
  classNumber?: InputMaybe<Scalars['Float']['input']>;
  classbreaks?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  classification?: InputMaybe<Scalars['String']['input']>;
  colorRamp?: InputMaybe<Scalars['String']['input']>;
  legendType?: InputMaybe<Scalars['String']['input']>;
  symbolStyle?: InputMaybe<SymbolStyleInput>;
  symbolValues?: InputMaybe<Array<InputMaybe<SymbolValueInput>>>;
  valueField?: InputMaybe<Scalars['String']['input']>;
};

export type PointSimpleConfigs = {
  symbolStyle?: Maybe<SymbolStyle>;
};

export type PointSimpleConfigsInput = {
  symbolStyle?: InputMaybe<SymbolStyleInput>;
};

export type PolygonCategoryConfigs = {
  colorRamp?: Maybe<Scalars['String']['output']>;
  symbolStyle?: Maybe<SymbolStyle>;
  symbolValues?: Maybe<Array<Maybe<SymbolValue>>>;
  valueField?: Maybe<Scalars['String']['output']>;
};

export type PolygonCategoryConfigsInput = {
  colorRamp?: InputMaybe<Scalars['String']['input']>;
  symbolStyle?: InputMaybe<SymbolStyleInput>;
  symbolValues?: InputMaybe<Array<InputMaybe<SymbolValueInput>>>;
  valueField?: InputMaybe<Scalars['String']['input']>;
};

export type PolygonQuantityConfigs = {
  classNumber?: Maybe<Scalars['Float']['output']>;
  classbreaks?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  classification?: Maybe<Scalars['String']['output']>;
  colorRamp?: Maybe<Scalars['String']['output']>;
  legendType?: Maybe<Scalars['String']['output']>;
  symbolStyle?: Maybe<SymbolStyle>;
  symbolValues?: Maybe<Array<Maybe<SymbolValue>>>;
  valueField?: Maybe<Scalars['String']['output']>;
};

export type PolygonQuantityConfigsInput = {
  classNumber?: InputMaybe<Scalars['Float']['input']>;
  classbreaks?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  classification?: InputMaybe<Scalars['String']['input']>;
  colorRamp?: InputMaybe<Scalars['String']['input']>;
  legendType?: InputMaybe<Scalars['String']['input']>;
  symbolStyle?: InputMaybe<SymbolStyleInput>;
  symbolValues?: InputMaybe<Array<InputMaybe<SymbolValueInput>>>;
  valueField?: InputMaybe<Scalars['String']['input']>;
};

export type PolygonSimpleConfigs = {
  symbolStyle?: Maybe<SymbolStyle>;
};

export type PolygonSimpleConfigsInput = {
  symbolStyle?: InputMaybe<SymbolStyleInput>;
};

export type PortalSettings = {
  bannerContent: Scalars['String']['output'];
  bannerTitle: Scalars['String']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  footer: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  layout: Scalars['String']['output'];
  name: Scalars['String']['output'];
  navigators?: Maybe<Array<Maybe<Navigator>>>;
  updatedAt: Scalars['AWSDateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type PortalSettingsInput = {
  bannerContent?: InputMaybe<Scalars['String']['input']>;
  bannerTitle?: InputMaybe<Scalars['String']['input']>;
  footer?: InputMaybe<Scalars['String']['input']>;
  layout?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  navigators?: InputMaybe<Array<InputMaybe<NavigatorInput>>>;
};

export type ProcessGeotiffDatasetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type ProcessGeotiffDatasetResults = {
  subscriptionId: Scalars['ID']['output'];
};

export type ProcessTempDatasetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type ProcessTempDatasetLayerError = {
  error: Scalars['String']['output'];
  sourceLayerName: Scalars['String']['output'];
};

export type ProcessTempDatasetResults = {
  createdDatasets: Array<Dataset>;
  errors: Array<ProcessTempDatasetLayerError>;
};

export type ProcessWmsDatasetInput = {
  getCapabilitiesUrl: Scalars['AWSURL']['input'];
  layers: Array<ProcessWmsDatasetLayerInput>;
};

export type ProcessWmsDatasetLayerInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  extent: Array<Scalars['Float']['input']>;
  legendUrl?: InputMaybe<Scalars['AWSURL']['input']>;
  name: Scalars['String']['input'];
};

export type ProcessWmsDatasetResults = {
  createdDatasets: Array<Dataset>;
  errors: Array<ProcessTempDatasetLayerError>;
};

export type Query = {
  getAccountUser: AccountUser;
  getCurrentAccount: Account;
  getCurrentUser: User;
  getDatasetById: Dataset;
  getDatasets: Array<Dataset>;
  getLayer: Layer;
  getMapById: Map;
  getMaps: Array<Map>;
  getPortalSettings: PortalSettings;
  getPortalSettingsByUserId: PortalSettings;
  getTempDatasetUploadUrl: FileUploadUrlResult;
  getUser: User;
  getUserGroup: UserGroup;
  getUserGroups: Array<UserGroup>;
};


export type QuerygetAccountUserArgs = {
  id: Scalars['ID']['input'];
};


export type QuerygetDatasetByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QuerygetLayerArgs = {
  id: Scalars['ID']['input'];
};


export type QuerygetMapByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QuerygetPortalSettingsArgs = {
  id: Scalars['ID']['input'];
};


export type QuerygetPortalSettingsByUserIdArgs = {
  userId: Scalars['ID']['input'];
};


export type QuerygetTempDatasetUploadUrlArgs = {
  extension: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type QuerygetUserArgs = {
  id: Scalars['ID']['input'];
};


export type QuerygetUserGroupArgs = {
  id: Scalars['ID']['input'];
};

export enum SUBSCRIPTION_CYCLE {
  ANNUAL = 'ANNUAL',
  FIVE_YEARS = 'FIVE_YEARS',
  FOUR_YEARS = 'FOUR_YEARS',
  MONTHLY = 'MONTHLY',
  THREE_YEARS = 'THREE_YEARS',
  TWO_YEARS = 'TWO_YEARS'
}

export enum SUBSCRIPTION_PLAN {
  AGENCY = 'AGENCY',
  BUSINESS = 'BUSINESS',
  ENTERPRISE = 'ENTERPRISE'
}

export enum SUBSCRIPTION_TYPE {
  PAID = 'PAID',
  PAID_TRIAL = 'PAID_TRIAL',
  TRIAL = 'TRIAL'
}

export type StrokeStyle = {
  color?: Maybe<Scalars['String']['output']>;
  isDisabled?: Maybe<Scalars['Boolean']['output']>;
  opacity?: Maybe<Scalars['Float']['output']>;
  style?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type StrokeStyleInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  opacity?: InputMaybe<Scalars['Float']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type SubmitContactFormInput = {
  company: Scalars['String']['input'];
  email: Scalars['String']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  source: Scalars['String']['input'];
  sourceDetails?: InputMaybe<Scalars['String']['input']>;
  teamSize: Scalars['String']['input'];
};

export type SubmitFranchiseContactFormInput = {
  company: Scalars['String']['input'];
  email: Scalars['String']['input'];
  goalNumber: Scalars['String']['input'];
  isRegistered: Scalars['Boolean']['input'];
  locations: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  source: Scalars['String']['input'];
  sourceDetails?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  onGeotiffProcessingComplete?: Maybe<TriggerGeotiffProcessingCompleteResults>;
  onGeotiffProcessingError?: Maybe<TriggerGeotiffProcessingErrorResults>;
};


export type SubscriptiononGeotiffProcessingCompleteArgs = {
  subscriptionId: Scalars['ID']['input'];
};


export type SubscriptiononGeotiffProcessingErrorArgs = {
  subscriptionId: Scalars['ID']['input'];
};

export type SymbolStyle = {
  basic?: Maybe<BasicSymbol>;
  fill?: Maybe<FillStyle>;
  isGraphic?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stroke?: Maybe<StrokeStyle>;
};

export type SymbolStyleInput = {
  basic?: InputMaybe<BasicSymbolInput>;
  fill?: InputMaybe<FillStyleInput>;
  isGraphic?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stroke?: InputMaybe<StrokeStyleInput>;
};

export type SymbolValue = {
  color?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  legendVisibility?: Maybe<Scalars['Boolean']['output']>;
  max?: Maybe<Scalars['String']['output']>;
  min?: Maybe<Scalars['String']['output']>;
  range?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<SymbolStyle>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SymbolValueInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  count?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  legendVisibility?: InputMaybe<Scalars['Boolean']['input']>;
  max?: InputMaybe<Scalars['String']['input']>;
  min?: InputMaybe<Scalars['String']['input']>;
  range?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<SymbolStyleInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type Tool = {
  type: Scalars['String']['output'];
};

export type TriggerGeotiffProcessingCompleteInput = {
  datasetId: Scalars['ID']['input'];
  subscriptionId: Scalars['ID']['input'];
};

export type TriggerGeotiffProcessingCompleteResults = {
  datasetId: Scalars['ID']['output'];
  subscriptionId: Scalars['ID']['output'];
};

export type TriggerGeotiffProcessingErrorInput = {
  devError: Scalars['String']['input'];
  error: Scalars['String']['input'];
  subscriptionId: Scalars['ID']['input'];
};

export type TriggerGeotiffProcessingErrorResults = {
  devError: Scalars['String']['output'];
  error: Scalars['String']['output'];
  subscriptionId: Scalars['ID']['output'];
};

export type UpdateAccountInput = {
  billingAddress?: InputMaybe<AccountBillingAddressInput>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<AccountPaymentMethodInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  subscription?: InputMaybe<AccountSubscriptionInput>;
};

export type UpdateDatasetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isSyncActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tempDatasetId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateLayerInput = {
  bubbleStyle?: InputMaybe<BubbleConfigsInput>;
  dataType?: InputMaybe<GEOMETRY_TYPE>;
  datasetId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  heatMapStyle?: InputMaybe<HeatMapConfigsInput>;
  id: Scalars['ID']['input'];
  labelsProps?: InputMaybe<LabelsInput>;
  layerGroupId: Scalars['ID']['input'];
  lineCategoryStyle?: InputMaybe<LineCategoryConfigsInput>;
  lineQuantityStyle?: InputMaybe<LineQuantityConfigsInput>;
  lineSimpleStyle?: InputMaybe<LineSimpleConfigsInput>;
  maxZoom?: InputMaybe<Scalars['Float']['input']>;
  minZoom?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  pointCategoryStyle?: InputMaybe<PointCategoryConfigsInput>;
  pointQuantityStyle?: InputMaybe<PointQuantityConfigsInput>;
  pointSimpleStyle?: InputMaybe<PointSimpleConfigsInput>;
  polygonCategoryStyle?: InputMaybe<PolygonCategoryConfigsInput>;
  polygonQuantityStyle?: InputMaybe<PolygonQuantityConfigsInput>;
  polygonSimpleStyle?: InputMaybe<PolygonSimpleConfigsInput>;
  styleType?: InputMaybe<LAYER_STYLE_TYPE>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateMapInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserGroupInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  _userGroupMembers: Array<UserGroupMember>;
  cognitoId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isActivated: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  portalSettings?: Maybe<PortalSettings>;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type UserGroup = {
  _userGroupMembers: Array<UserGroupMember>;
  accountId: Scalars['ID']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type UserGroupMember = {
  _user: User;
  _userGroup: UserGroup;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
  userGroupId: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
};
