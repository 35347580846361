import { FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import { paths } from "config/paths";
import { useFormik } from "formik";
import RouterLink from "next/link";
import { FC } from "react";
import * as Yup from "yup";

import { MangoLogo } from "~ui-components/components/atoms/mango-logo";
import { CenteredLayout } from "~ui-components/components/organisms/centered-layout";

export const UpdatePasswordSchema = Yup.object({
	password: Yup.string().required("Required"),
	retypedPassword: Yup.string().required("Required"),
});

export type UpdatePasswordFormValues = Yup.InferType<
	typeof UpdatePasswordSchema
>;

export interface UpdatePasswordProps {
	form: ReturnType<typeof useFormik<UpdatePasswordFormValues>>;
}

export const UpdatePassword: FC<UpdatePasswordProps> = ({ form }) => {
	return (
		<CenteredLayout>
			<Stack spacing={4}>
				<div>
					<Box
						component={RouterLink}
						href={paths.home}
						sx={{ display: "inline-block", fontSize: 0 }}>
						<MangoLogo
							width={122}
							height={32}
						/>
					</Box>
				</div>
				<Card>
					<CardHeader title="Reset your password" />
					<CardContent>
						<Stack
							spacing={2}
							component="form"
							onSubmit={form.handleSubmit}>
							<FormControl>
								<InputLabel>Password</InputLabel>
								<OutlinedInput
									autoFocus
									type="password"
									{...form.getFieldProps("password")}
									error={!!form.touched.password && !!form.errors.password}
								/>
								{!!form.touched.password && !!form.errors.password && (
									<FormHelperText error>{form.errors.password}</FormHelperText>
								)}
							</FormControl>
							<FormControl>
								<InputLabel>Re-type password</InputLabel>
								<OutlinedInput
									type="password"
									{...form.getFieldProps("retypedPassword")}
									error={
										!!form.touched.retypedPassword &&
										!!form.errors.retypedPassword
									}
								/>
								{!!form.touched.retypedPassword &&
									!!form.errors.retypedPassword && (
										<FormHelperText error>
											{form.errors.retypedPassword}
										</FormHelperText>
									)}
							</FormControl>
							<Button
								type="submit"
								variant="contained"
								disabled={form.isSubmitting || !form.isValid}>
								Update
							</Button>
						</Stack>
					</CardContent>
				</Card>
			</Stack>
		</CenteredLayout>
	);
};
