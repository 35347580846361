import { Alert, Box, Button, Stack } from "@mui/material";

import { PropertiesForm } from "./properties-form";

export const FeatureEditAttributes = ({ properties }) => {
	return (
		<>
			<Box
				flex={1}
				p={2}>
				<Stack
					spacing={2}
					p={2}>
					<Alert severity="info">
						Attribute edits are applied to source data and will be reflected in
						all maps using this data.
					</Alert>
					<PropertiesForm properties={properties} />
				</Stack>
			</Box>
			<Stack
				direction={"row-reverse"}
				spacing={3}
				p={2}>
				<Button
					color="primary"
					variant="contained">
					Save changes
				</Button>
				<Button color="primary">Cancel</Button>
			</Stack>
		</>
	);
};
