import React from "react";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import Stack from "@mui/material/Stack";
import _ from "lodash";
import { StackProps } from "@mui/material";
import { useFormik } from "formik";
import CodeMirror from "@uiw/react-codemirror";
import { xml } from "@codemirror/lang-xml";
import { githubDarkInit } from "@uiw/codemirror-theme-github";

interface StyleInput {
	sldValue: {
		symbol_values: string;
	};
}

type Props = StackProps & {
	form: ReturnType<typeof useFormik<StyleInput>>;
	visualisation?: string;
};

export const CustomSLD = (props: Props) => {
	const { visualisation, form } = props;
	const { values, handleChange, handleSubmit } = form;
	const [value, setValue] = React.useState(values.sldValue.symbol_values);
	const onChange = React.useCallback((val) => {
		handleChange({
			target: { name: "sldValue", value: { symbol_values: val } },
		});
		setValue(val);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (visualisation === "customSLD") {
		return (
			<>
				<Stack>
					<CodeMirror
						value={value}
						maxHeight="500px"
						extensions={[xml()]}
						theme={githubDarkInit({
							settings: {
								caret: "#c6c6c6",
								fontFamily: "monospace",
							},
						})}
						onChange={onChange}
					/>
				</Stack>
				<CardActions sx={{ justifyContent: "flex-end", p: 0, mt: 2 }}>
					<Button color="secondary">Cancel</Button>
					<Button
						variant="contained"
						onClick={() => handleSubmit()}>
						Save changes
					</Button>
				</CardActions>
			</>
		);
	}
	return null;
};
