import { Divider, Stack, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";

import { Bubble } from "~ui-components/components/molecules/legend/shapes/bubble";
import { Category } from "~ui-components/components/molecules/legend/shapes/category";
import { Quantity } from "~ui-components/components/molecules/legend/shapes/quantity";
import { Simple } from "~ui-components/components/molecules/legend/shapes/simple";
import { MapToolDrawer } from "../map-tool-drawer";

import { RasterBar } from "./shapes/heatmap";

interface Props {
	onClose: () => void;
	layerGroups: any[];
}

export const Legend = ({ onClose, layerGroups = [] }: Props) => {
	return (
		<MapToolDrawer
			title="Legend"
			onClose={onClose}
			Content={layerGroups.map((layerGroup, key) => (
				<LayerGroup
					key={key}
					layerGroup={layerGroup}
				/>
			))}
			Action={<BaseMap />}
		/>
	);
};

const StyledBadge = styled(Badge)(({ theme }) => ({
	"& .MuiBadge-badge": {
		backgroundColor: "#44b700",
		color: "#44b700",
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			animation: "ripple 1.2s infinite ease-in-out",
			border: "1px solid currentColor",
			content: '""',
		},
	},
	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1,
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0,
		},
	},
}));

const BaseMap = () => {
	return (
		<>
			<Stack
				direction="row"
				spacing={1}>
				<StyledBadge
					overlap="circular"
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					variant="dot">
					<Avatar variant="rounded" />
				</StyledBadge>
				<Avatar variant="rounded" />
				<Avatar variant="rounded" />
				<Avatar variant="rounded" />
				<Avatar variant="rounded" />
				<Avatar variant="rounded" />
			</Stack>
		</>
	);
};

const LayerGroup = ({ layerGroup }) => {
	return (
		<>
			<Stack
				direction="row"
				spacing={1}>
				<Stack
					direction="column"
					spacing={0}
					sx={{ flexGrow: 1 }}>
					{layerGroup.children.map((layer, index) => {
						const lastChild = index === layerGroup.children.length - 1;
						return (
							<>
								<Layer
									firstChild={!index}
									layer={layer}
								/>
								{!lastChild && (
									<Divider
										sx={{ borderColor: "var(--mui-palette-neutral-100)" }}
									/>
								)}
							</>
						);
					})}
				</Stack>
			</Stack>
			<Divider />
		</>
	);
};

const Layer = ({ layer, firstChild }) => {
	return (
		<Stack
			direction="row"
			spacing={0}
			sx={{ p: 2 }}>
			<Stack
				direction="column"
				spacing={2}
				sx={{ flexGrow: 1 }}>
				<Stack spacing={0}>
					<Typography variant="subtitle1">{layer?.name}</Typography>
				</Stack>

				{layer.style.type.match("BUBBLE") && <Bubble style={layer?.style} />}
				{layer.style.type.match("_SIMPLE") && (
					<Stack>
						<Simple style={layer?.style} />
					</Stack>
				)}
				{layer.style.type.match("_CATEGORY") && (
					<Stack>
						<Category style={layer?.style} />
					</Stack>
				)}
				{layer.style.type.match("_QUANTITY") && (
					<Stack>
						<Quantity style={layer?.style} />
					</Stack>
				)}
				{layer.style.type.match("HEATMAP") && (
					<Stack>
						<RasterBar />
						<Stack
							spacing={0}
							direction="row">
							<Typography
								sx={{ flexGrow: 1 }}
								color="text.secondary"
								variant="caption">
								Low
							</Typography>
							<Typography
								color="text.secondary"
								variant="caption">
								Hight
							</Typography>
						</Stack>
					</Stack>
				)}
				<Stack spacing={0}>
					<Typography
						color="text.secondary"
						variant="body2">
						this is hardcode description
						{layer?.description}
					</Typography>
				</Stack>
			</Stack>
			{firstChild && (
				<Stack spacing={0}>
					<Checkbox
						checked={true}
						onChange={(event) => console.log("checked", event)}
					/>
				</Stack>
			)}
		</Stack>
	);
};
