import { Map } from "~ui-components/types/__generated/gql/graphql";
export interface MapsViewProps {
	isLoading: boolean;
	items: Map[];
	onCreateMap?: () => void;
	onItemDelete?: (itemId: string) => void;
	onItemClick?: (mapId: string, mapName: string) => void;
	onItemLock: (itemId: string) => void;
	onItemDuplicate?: (itemId: string) => void;
}

export { MapsPortalLargeView } from "./large-view";
export { MapsPortalMediumView } from "./medium-view";
export { MapsPortalRowView } from "./row-view";
