import { Polygon as PolygonIcon } from "@phosphor-icons/react/dist/ssr/Polygon";
import { LineSegment as LineSegmentIcon } from "@phosphor-icons/react/dist/ssr/LineSegment";
import { MapPin as MapPinIcon } from "@phosphor-icons/react/dist/ssr/MapPin";
import { SquaresFour as SquaresFourIcon } from "@phosphor-icons/react/dist/ssr/SquaresFour";
import { FileCloud as FileCloudIcon } from "@phosphor-icons/react/dist/ssr/FileCloud";

export const DataTypeIcon = {
	polygon: <PolygonIcon />,
	line: <LineSegmentIcon />,
	point: <MapPinIcon />,
	raster: <SquaresFourIcon />,
	"wms/external": <FileCloudIcon />,
};
