import Stack from "@mui/material/Stack";
import { isEmpty } from "lodash";
import { useState } from "react";

import { ConfirmationModal } from "~ui-components/components/organisms/confirmation";
import { LayerGroup } from "~ui-components/types/__generated/gql/graphql";

import { LayerGroupComponent } from "./layer-group";

interface Props {
	layerGroups: LayerGroup[];
	onLayerGroupVisibilityChange: (id: string, visible: boolean) => void;
	onAddLayerGroup: (id?: string, position?: "above" | "below") => void;
	onDragEnd: (result: any) => void;
	onDeleteLayerGroup: (id: string) => void;
	onDeleteLayer: (layerId: string) => void;
	onSelectLayer: (layerId: string) => void;
	onAddLayer: (id: string) => void;
}

export const LayerGroups = (props: Props) => {
	const [openConfirmation, setOpenConfirmation] = useState(false);
	const [openLayerConfirmation, setOpenLayerConfirmation] = useState(false);
	const [layerGroupId, setLayerGroupId] = useState<string | null>(null);
	const [layerId, setLayerId] = useState<string | null>(null);

	const {
		onSelectLayer,
		onDeleteLayer,
		onLayerGroupVisibilityChange,
		onAddLayerGroup,
		onDragEnd,
		onDeleteLayerGroup,
		layerGroups,
		onAddLayer,
	} = props;

	const disableDelete = layerGroups.length <= 1;

	return (
		<Stack
			sx={{
				backgroundColor: "var(--mui-palette-background-level2);",
				p: 3,
				borderRadius: 2,
			}}>
			<ConfirmationModal
				open={openConfirmation}
				title="Permanently delete this layer group?"
				content="Once deleted, this group and all contained layers will no longer be accessible. Source datasets are not deleted"
				actionText="Permanently delete"
				onClose={() => {
					setOpenConfirmation(false);
				}}
				onConfirm={() => {
					setOpenConfirmation(false);
					if (layerGroupId) onDeleteLayerGroup(layerGroupId);
				}}
			/>

			<ConfirmationModal
				open={openLayerConfirmation}
				title="Permanently delete this layer?"
				content="Once deleted, this layer will no longer be accessible. Source datasets are not deleted"
				actionText="Permanently delete"
				onClose={() => {
					setOpenConfirmation(false);
				}}
				onConfirm={() => {
					setOpenLayerConfirmation(false);
					if (layerId) onDeleteLayer(layerId);
				}}
			/>

			{/* {isEmpty(layerGroups) && (
				<EmptyLayerGroup onAddLayer={() => onAddLayer()} />
			)} */}

			{!isEmpty(layerGroups) &&
				layerGroups.map((layerGroup, index) => {
					return (
						<LayerGroupComponent
							key={index}
							disableDelete={disableDelete}
							layerGroup={layerGroup}
							onSelectLayer={onSelectLayer}
							onLayerGroupVisibilityChange={onLayerGroupVisibilityChange}
							onDragEnd={onDragEnd}
							onAddLayer={onAddLayer}
							onDeleteLayerGroup={() => {
								setLayerGroupId(layerGroup.id);
								setOpenConfirmation(true);
							}}
							onRemoveLayer={(layerId) => {
								setLayerGroupId(layerGroup.id);
								setLayerId(layerId);
								setOpenLayerConfirmation(true);
							}}
							onAddLayerGroup={onAddLayerGroup}
						/>
					);
				})}
		</Stack>
	);
};
