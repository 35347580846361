import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { Translate as LocalisationIcon } from "@phosphor-icons/react/dist/ssr/Translate";
import _ from "lodash";
import { useState } from "react";

import languages from "./language.json";
import { BrandLayout as Layout } from "./layout";

export const BrandLocalisation = () => {
	const [checked, setChecked] = useState(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	return (
		<Layout pageTitle="Localisation">
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<LocalisationIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Localisation"
				/>
				<CardContent>
					<Stack spacing={3}>
						<FormControl>
							<FormControlLabel
								control={
									<Switch
										checked={checked}
										onChange={handleChange}
									/>
								}
								label="Enable localisation"
							/>
						</FormControl>
					</Stack>
					{checked && (
						<Stack
							spacing={3}
							sx={{ mt: 2 }}>
							{_.map(languages, (value, key) => (
								<FormControl
									key={key}
									variant="outlined"
									fullWidth>
									<InputLabel>{value}</InputLabel>
									<OutlinedInput value={value} />
								</FormControl>
							))}
						</Stack>
					)}
				</CardContent>
				<CardActions sx={{ justifyContent: "flex-end" }}>
					<Button color="secondary">Cancel</Button>
					<Button variant="contained">Save changes</Button>
				</CardActions>
			</Card>
		</Layout>
	);
};
