import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { BracketsCurly as AttributeIcon } from "@phosphor-icons/react/dist/ssr/BracketsCurly";
import { Editor } from "@tiptap/react";
import { FC, Fragment } from "react";

import { usePopover } from "~ui-components/hooks/use-popover";

export interface AggregateAttributeSelectProps {
	editor: Editor;
	totalAttributes?: string[];
	minAttributes?: string[];
	maxAttributes?: string[];
	meanAttributes?: string[];
	medianAttributes?: string[];
}

export const AggregateAttributeSelect: FC<AggregateAttributeSelectProps> = ({
	editor,
	totalAttributes,
	minAttributes,
	maxAttributes,
	meanAttributes,
	medianAttributes,
}) => {
	const popover = usePopover<HTMLButtonElement>();

	return [
		{ label: "Total", operator: "total", attributes: totalAttributes },
		{ label: "Min", operator: "min", attributes: minAttributes },
		{ label: "Max", operator: "max", attributes: maxAttributes },
		{ label: "Mean", operator: "mean", attributes: meanAttributes },
		{ label: "Median", operator: "median", attributes: medianAttributes },
	].map(({ label, operator, attributes }) => {
		if (!attributes?.length) return null;

		return (
			<Fragment key={operator}>
				<IconButton
					sx={{ width: 100 }}
					onClick={(e) => {
						popover.anchorRef.current = e.currentTarget;
						popover.handleOpen();
					}}>
					<Box
						display="flex"
						alignItems="center">
						<AttributeIcon />
						<Typography
							variant="body2"
							ml={1}>
							{label}
						</Typography>
					</Box>
				</IconButton>
				<Menu
					disablePortal
					anchorEl={popover.anchorRef.current}
					open={popover.open}
					onClose={popover.handleClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left",
					}}>
					{attributes.map((attribute) => (
						<MenuItem
							key={attribute}
							onClick={() => {
								editor
									.chain()
									.focus()
									.insertContent(`{${attribute}:${operator}}`)
									.run();
								popover.handleClose();
							}}>
							{attribute}
						</MenuItem>
					))}
				</Menu>
			</Fragment>
		);
	});
};
