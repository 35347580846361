import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { paths } from "config/paths";
import RouterLink from "next/link";
import * as React from "react";

import { MangoLogo } from "~ui-components/components/atoms/mango-logo";
import { SplitLayout } from "~ui-components/components/organisms/split-layout";

interface SignUpVerificationProps {
	email: string;
	onResendEmail: () => void;
}

export const SignUpVerification: React.FC<SignUpVerificationProps> = ({
	email,
	onResendEmail,
}) => {
	return (
		<SplitLayout
			title="Get started with Mango"
			subtitle="The accessible and user-friendly alternative to Esri for smart
              spenders."
			caption="Join 6,000+ forward-thinking companies:">
			<Stack spacing={4}>
				<Box
					component={RouterLink}
					href={paths.home}
					sx={{ display: "inline-block", fontSize: 0 }}>
					<MangoLogo
						width={122}
						height={32}
					/>
				</Box>
				<Stack spacing={4}>
					<Stack spacing={1}>
						<Typography variant="h5">Thank you for signing up!</Typography>
						<Typography
							color="text.secondary"
							variant="body2">
							We’ve just sent an email to {email}. Please check your inbox and
							click the activation link to start your free trial.
						</Typography>
					</Stack>
					<Stack>
						<Typography
							color="text.secondary"
							variant="body2">
							Didn&apos;t receive the email?
						</Typography>
						<Typography
							color="text.secondary"
							variant="body2">
							Check your spam folder or&nbsp;
							<Link
								href="#"
								variant="subtitle2"
								onClick={onResendEmail}>
								Resend Activation Email
							</Link>
							.
						</Typography>
					</Stack>
				</Stack>
				<Stack spacing={2}></Stack>
			</Stack>
		</SplitLayout>
	);
};
