import {
	Alert,
	AlertTitle,
	Avatar,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	FormControl,
	FormHelperText,
	InputLabel,
	LinearProgress,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { CloudArrowUp as CloudArrowUpIcon } from "@phosphor-icons/react/dist/ssr/CloudArrowUp";
import { AxiosRequestConfig } from "axios";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import { FC, Fragment, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Else, If, Then, When } from "react-if";
import { getExtensionFromFileName } from "utils";
import * as Yup from "yup";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import { toast } from "~ui-components/components/atoms/toaster";
import { DATASOURCE_TYPE } from "~ui-components/types/__generated/gql/graphql";
import { LayerData } from "../wms-summary";

export const DataImportSchema = Yup.object({
	name: Yup.string().when("dataFormat", (dataFormat, schema) => {
		if (dataFormat === DATASOURCE_TYPE.WMS) return schema.notRequired();

		return schema.required("Required");
	}),
	description: Yup.string().notRequired(),
	dataFormat: Yup.mixed<DATASOURCE_TYPE>().required("Required"),
	files: Yup.array<File>().when("dataFormat", (dataFormat, schema) => {
		if (dataFormat === DATASOURCE_TYPE.WMS) return schema.notRequired();

		const requiredExts =
			getRequiredFileExtensionsFromDatasourceType(dataFormat);
		return schema
			.min(requiredExts.length, `${requiredExts.length} files are required`)
			.required("Required");
	}),
	url: Yup.string()
		.url("Invalid URL")
		.matches(/^https:\/\/.+$/, "Only HTTPS URLs are supported")
		.when("dataFormat", (dataFormat, schema) => {
			if (dataFormat !== DATASOURCE_TYPE.WMS) return schema.notRequired();

			return schema.required("Required");
		}),
	selectedWmsLayers: Yup.array<LayerData>().when(
		"dataFormat",
		(dataFormat, schema) => {
			if (dataFormat !== DATASOURCE_TYPE.WMS) return schema.notRequired();

			return schema.min(1, "At least 1 layer is required").required("Required");
		},
	),
});

export type DataImportFormValues = Yup.InferType<typeof DataImportSchema>;

export interface DataImportProps {
	form: ReturnType<typeof useFormik<DataImportFormValues>>;
	onUploadFile: (
		file: File,
		onUploadProgressCallback: AxiosRequestConfig["onUploadProgress"],
	) => Promise<void>;
	onConnectToWmsUrl: () => void;
}

const DATASOURCE_TYPE_OPTIONS: Record<DATASOURCE_TYPE, string> = {
	[DATASOURCE_TYPE.CSV]: "CSV",
	[DATASOURCE_TYPE.GEO_DATABASE]: "Geo database",
	[DATASOURCE_TYPE.GEOJSON]: "GeoJSON",
	[DATASOURCE_TYPE.KML]: "KML",
	[DATASOURCE_TYPE.SHAPEFILE]: "Shapefile",
	[DATASOURCE_TYPE.TAB]: "Tab",
	[DATASOURCE_TYPE.GEOTIFF]: "GeoTIFF",
	[DATASOURCE_TYPE.WMS]: "WMS",
};

export const DataImport: FC<DataImportProps> = ({
	form,
	onUploadFile,
	onConnectToWmsUrl,
}) => {
	const router = useRouter();

	const requiredFileExtensions =
		form.values.dataFormat === DATASOURCE_TYPE.WMS
			? []
			: getRequiredFileExtensionsFromDatasourceType(form.values.dataFormat);
	const areFilesSelected = form.values.files && form.values.files.length > 0;
	const [uploadProgresses, setUploadProgresses] = useState<
		Record<string, number>
	>({});

	const {
		getRootProps,
		getInputProps,
		open: openFileDialog,
	} = useDropzone({
		accept: {
			"*/*": requiredFileExtensions.map((ext) => `.${ext}`),
		},
		multiple: requiredFileExtensions.length > 1,
		maxFiles: requiredFileExtensions.length,
		onDrop: async (acceptedFiles, rejectedFiles) => {
			if (rejectedFiles.length) {
				toast.error("This data type is not currently supported.");
			}

			const missingExts = requiredFileExtensions.filter((ext) =>
				form.values.files!.every((file) => !file.name.endsWith(`.${ext}`)),
			);
			const newFiles = acceptedFiles.filter((file) =>
				missingExts.some((ext) => file.name.endsWith(ext)),
			);
			await form.setFieldValue("files", [...form.values.files!, ...newFiles]);

			// no need to await here
			newFiles.forEach((file) =>
				onUploadFile(file, (event) => {
					setUploadProgresses((prev) => ({
						...prev,
						[getExtensionFromFileName(file.name)]:
							(event.loaded / (event.total ?? 1)) * 100,
					}));
				}),
			);
		},
	});

	useEffect(() => {
		setUploadProgresses({});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.values.dataFormat]);

	return (
		<DashboardContentLayout pageTitle="Upload data">
			<Stack
				direction={{ xs: "column", md: "row" }}
				spacing={4}
				sx={{ position: "relative" }}>
				<Box sx={{ flex: "1 1 auto", minWidth: 0 }}>
					<form onSubmit={form.handleSubmit}>
						<Card>
							<CardHeader
								avatar={
									<Avatar>
										<CloudArrowUpIcon fontSize="var(--Icon-fontSize)" />
									</Avatar>
								}
								title="Upload your data to add to a map"
							/>

							<CardContent>
								<Stack spacing={3}>
									<When
										condition={form.values.dataFormat !== DATASOURCE_TYPE.WMS}>
										<FormControl>
											<InputLabel>Name</InputLabel>
											<TextField
												autoFocus
												{...form.getFieldProps("name")}
												error={!!form.touched.name && !!form.errors.name}
												helperText={!!form.touched.name && form.errors.name}
											/>
										</FormControl>

										<FormControl fullWidth>
											<InputLabel>Description</InputLabel>
											<TextField
												minRows={3}
												multiline
												{...form.getFieldProps("description")}
												error={
													!!form.touched.description &&
													!!form.errors.description
												}
												helperText={
													!!form.touched.description && form.errors.description
												}
											/>
										</FormControl>
									</When>

									<FormControl fullWidth>
										<InputLabel>Data format</InputLabel>
										<Stack
											direction="row"
											gap={3}>
											<TextField
												select
												{...form.getFieldProps("dataFormat")}
												error={
													!!form.touched.dataFormat && !!form.errors.dataFormat
												}
												helperText={
													!!form.touched.dataFormat && form.errors.dataFormat
												}
												sx={{ width: "150px" }}>
												{Object.keys(DATASOURCE_TYPE_OPTIONS).map((key) => (
													<MenuItem
														key={key}
														value={key}>
														{DATASOURCE_TYPE_OPTIONS[key]}
													</MenuItem>
												))}
											</TextField>
											<Button
												variant="contained"
												sx={{ whiteSpace: "nowrap" }}
												onClick={openFileDialog}>
												Upload files
											</Button>
										</Stack>
									</FormControl>

									<When
										condition={form.values.dataFormat === DATASOURCE_TYPE.WMS}>
										<FormControl fullWidth>
											<InputLabel>WMS GetCapabilities URL</InputLabel>
											<TextField
												fullWidth
												{...form.getFieldProps("url")}
												error={!!form.touched.url && !!form.errors.url}
												helperText={!!form.touched.url && form.errors.url}
											/>
										</FormControl>

										{!!form.status.wmsError && (
											<Alert severity="error">
												<AlertTitle>
													An error occurred while fetching or parsing the WMS
													data
												</AlertTitle>
												Please verify the URL and try again. If the issue
												persists, contact the WMS provider to ensure the service
												is operational and properly configured.
												<br />
												{form.status.wmsError}
											</Alert>
										)}
									</When>

									<When
										condition={form.values.dataFormat !== DATASOURCE_TYPE.WMS}>
										<Stack
											{...getRootProps()}
											spacing={3}>
											<input {...getInputProps()} />

											<Stack>
												<InputLabel>Required files</InputLabel>
												<Divider />
												{!!form.errors.files && (
													<FormHelperText error>
														{form.errors.files.toString()}
													</FormHelperText>
												)}
											</Stack>

											{requiredFileExtensions.map((ext, index) => {
												const matchingFile = form.values.files!.find((file) =>
													file.name.endsWith(`.${ext}`),
												);

												return (
													<Fragment key={ext}>
														<Stack
															direction="row"
															spacing={3}>
															<Typography
																flex={1}
																color="text.secondary"
																variant="body2">
																{`.${ext}`}
															</Typography>
															{!!areFilesSelected && (
																<Typography
																	flex={2}
																	fontWeight={600}
																	variant="body2"
																	sx={{
																		maxWidth: "100%",
																		whiteSpace: "nowrap",
																		overflow: "hidden",
																		textOverflow: "ellipsis",
																	}}>
																	{matchingFile ? matchingFile.name : ""}
																</Typography>
															)}
															<Box flex={3}>
																{!!areFilesSelected &&
																	(matchingFile ? (
																		<LinearProgress
																			variant={
																				uploadProgresses[ext]
																					? "determinate"
																					: "indeterminate"
																			}
																			color={
																				(uploadProgresses[ext] ?? 0) < 100
																					? "primary"
																					: "success"
																			}
																			value={uploadProgresses[ext] ?? 0}
																		/>
																	) : (
																		<Alert
																			severity="error"
																			action={
																				<Button
																					color="error"
																					size="small"
																					onClick={() => {
																						openFileDialog();
																					}}>
																					Upload
																				</Button>
																			}>
																			File missing
																		</Alert>
																	))}
															</Box>
														</Stack>
														{index !== requiredFileExtensions.length - 1 && (
															<Divider />
														)}
													</Fragment>
												);
											})}
										</Stack>
									</When>
								</Stack>
							</CardContent>

							<CardActions sx={{ justifyContent: "flex-end" }}>
								<Button
									color="secondary"
									onClick={router.back}>
									Cancel
								</Button>
								<If condition={form.values.dataFormat === DATASOURCE_TYPE.WMS}>
									<Then>
										<Button
											variant="contained"
											disabled={!!form.errors.url}
											onClick={onConnectToWmsUrl}>
											Connect
										</Button>
									</Then>
									<Else>
										<Button
											variant="contained"
											type="submit"
											disabled={
												form.isSubmitting ||
												!form.isValid ||
												!areFilesSelected ||
												Object.keys(uploadProgresses).some(
													(key) => (uploadProgresses[key] ?? 0) < 100,
												)
											}>
											Save
										</Button>
									</Else>
								</If>
							</CardActions>
						</Card>
					</form>
				</Box>
			</Stack>
		</DashboardContentLayout>
	);
};

export const getRequiredFileExtensionsFromDatasourceType = (
	type: DATASOURCE_TYPE,
) => {
	switch (type) {
		case DATASOURCE_TYPE.CSV:
			return ["csv"];
		case DATASOURCE_TYPE.GEO_DATABASE:
			return ["gbd.zip"];
		case DATASOURCE_TYPE.GEOJSON:
			return ["geojson"];
		case DATASOURCE_TYPE.KML:
			return ["kml"];
		case DATASOURCE_TYPE.SHAPEFILE:
			return ["shp", "dbf", "shx", "prj"];
		case DATASOURCE_TYPE.TAB:
			return ["dat", "id", "map", "tab"];
		case DATASOURCE_TYPE.GEOTIFF:
			return ["zip"];
		default:
			throw new Error("Unsupported datasource type");
	}
};
