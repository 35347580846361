import { StackProps } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { PaintBrush as StyleIcon } from "@phosphor-icons/react/dist/ssr/PaintBrush";
import { Question as QuestionIcon } from "@phosphor-icons/react/dist/ssr/Question";
import Link from "next/link";

import { Option } from "~ui-components/components/atoms/option";

interface Slots {
	SimpleStyle: any;
	CategoryStyle: any;
	QuantityStyle: any;
	BubbleStyle: any;
	HeatmapStyle: any;
	CustomSLD: any;
}

const Empty = () => null;

interface SlotProps {
	visualisation?: string;
}

const defaultSlots: Slots = {
	SimpleStyle: Empty,
	CategoryStyle: Empty,
	QuantityStyle: Empty,
	BubbleStyle: Empty,
	HeatmapStyle: Empty,
	CustomSLD: Empty,
};

type Props = StackProps & {
	layerType?: string;
	onVisualisationChange: (value: string) => void;
	slots?: Slots;
	slotProps?: SlotProps;
};

export const LayerStyle = (props: Props) => {
	const {
		slots = {},
		layerType = "",
		slotProps,
		onVisualisationChange,
	} = props;

	const Slots = {
		...defaultSlots,
		...slots,
	};

	// Internalize visualizationTypes and conditionally add 'heatmap'
	const visualizationTypes = [
		{ value: "simple", label: "Simple" },
		{ value: "category", label: "Category" },
		{ value: "quantity", label: "Quantity" },
		{ value: "bubble", label: "Bubble" },
	];

	if (layerType === "POINT") {
		visualizationTypes.push({ value: "heatmap", label: "Heatmap" });
	}

	return (
		<>
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<StyleIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Layer style"
					action={
						<Link
							href={
								"https://help.mangomap.com/hc/en-us/articles/360022122592-The-Layers-panel"
							}
							target="_blank">
							<IconButton aria-label="settings">
								<QuestionIcon />
							</IconButton>
						</Link>
					}
				/>
				<CardContent>
					<Stack spacing={3}>
						<FormControl>
							<InputLabel>Layer title</InputLabel>
							<Select
								value={slotProps?.visualisation}
								onChange={(event) => onVisualisationChange(event.target.value)}>
								{visualizationTypes.map((visualization) => (
									<Option
										key={visualization.value}
										value={visualization.value}>
										{visualization.label}
									</Option>
								))}
							</Select>
						</FormControl>
						<Slots.SimpleStyle visualisation={slotProps?.visualisation} />
						<Slots.CategoryStyle visualisation={slotProps?.visualisation} />
						<Slots.QuantityStyle visualisation={slotProps?.visualisation} />
						<Slots.BubbleStyle visualisation={slotProps?.visualisation} />
						<Slots.HeatmapStyle visualisation={slotProps?.visualisation} />
						<Slots.CustomSLD visualisation={slotProps?.visualisation} />
					</Stack>
				</CardContent>
			</Card>
		</>
	);
};
