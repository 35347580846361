import * as React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

import { Option } from "~ui-components/components/atoms/option";

export function FeatureHighlightOpacitySelect(): React.JSX.Element {
	return (
		<FormControl fullWidth>
			<InputLabel>Theme</InputLabel>
			<Select>
				<Option value="0">0</Option>
				<Option value="10">10</Option>
				<Option value="20">20</Option>
				<Option value="30">30</Option>
				<Option value="40">40</Option>
				<Option value="50">50</Option>
				<Option value="60">60</Option>
				<Option value="70">70</Option>
				<Option value="80">80</Option>
				<Option value="90">90</Option>
				<Option value="100">100</Option>
			</Select>
		</FormControl>
	);
}
