import _ from "lodash";

import { typeConversion } from "~utils/functions/geojson/typeConversion";

const convertPropertiesType = (featureProperties) => {
	return _.chain(featureProperties).map(typeConversion).value();
};

const getTypeOfEachProperties = (arrayOfProperties) => {
	const geojsonProperties = arrayOfProperties.reduce((result, properties) => {
		const typeArray = convertPropertiesType(properties);
		typeArray.forEach((convertedType) => {
			const { name } = convertedType;
			if (!result[name] || result[name].type === "object") {
				result[name] = convertedType;
			}
		});

		return result;
	}, {});

	return geojsonProperties;
};

export { getTypeOfEachProperties };
