import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

export const QueryTableResults = ({ onClickTryAgain }) => {
	return (
		<Alert
			severity="warning"
			action={
				<Button
					onClick={onClickTryAgain}
					color="inherit"
					size="small">
					Try again
				</Button>
			}>
			No matching results
		</Alert>
	);
};
