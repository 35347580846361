import { Box } from "@mui/material";

import { SvgLineComponent } from "~ui-components/components/molecules/legend/shapes/canvas/line";
import { LineCategoryConfigs } from "~ui-components/types/__generated/gql/graphql";

interface Props {
	style: LineCategoryConfigs;
}

export const LineSimple = ({ style }: Props) => {
	return (
		<Box>
			{style.symbolStyle && <SvgLineComponent symbol={style.symbolStyle} />}
		</Box>
	);
};
