import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { User as UserIcon } from "@phosphor-icons/react/dist/ssr/User";
import { FC } from "react";

import {
	PropertyItem,
	PropertyItemProps,
} from "~ui-components/components/atoms/property-item";
import { PropertyList } from "~ui-components/components/atoms/property-list";

export const ITEMS: UserProfileProps["items"] = [
	{ name: "Name", value: "Miron Vitold" },
	{ name: "Email", value: "miron.vitold@domain.com" },
	{ name: "Password", value: "********" },
	{ name: "Role", value: "Account Owner" },
	{ name: "Phone number", value: "(425) 434-5535" },
	{ name: "Company", value: "Devias IO" },
	{ name: "URL Slug", value: "https://mangomap.com/deviasio" },
];

export interface UserProfileProps {
	items: PropertyItemProps[];
}

export const UserProfile: FC<UserProfileProps> = ({ items }) => {
	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<UserIcon fontSize="var(--Icon-fontSize)" />
					</Avatar>
				}
				title="User profile"
			/>
			<PropertyList
				divider={<Divider />}
				sx={{ "--PropertyItem-padding": "12px 24px" }}>
				{items.map(
					(item, key): React.JSX.Element => (
						<PropertyItem
							key={key}
							name={item.name}
							value={item.value}
						/>
					),
				)}
			</PropertyList>
		</Card>
	);
};
