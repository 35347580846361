import { StackProps } from "@mui/material";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import _ from "lodash";

import { Option } from "~ui-components/components/atoms/option";
import { HeatMapConfigs } from "~ui-components/types/__generated/gql/graphql";

type Props = StackProps & {
	form: ReturnType<typeof useFormik<HeatMapConfigs>>;
	visualisation?: string;
};

export const HeatmapStyle = (props: Props) => {
	const { visualisation, form } = props;
	const { values, handleChange, handleBlur, handleSubmit } = form;

	if (visualisation === "heatmap") {
		return (
			<form onSubmit={handleSubmit}>
				<Stack spacing={3}>
					<FormControl>
						<InputLabel>Radius</InputLabel>
						<OutlinedInput
							sx={{ width: 200 }}
							name="basic.radius"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.basic?.radius}
							type="number"
						/>
					</FormControl>

					<FormControl sx={{ width: 200 }}>
						<InputLabel>Opacity</InputLabel>
						<Select
							name="basic.opacity"
							onChange={handleChange}
							value={values.basic?.opacity}
							type="number">
							{_.range(0, 11).map((value) => (
								<Option
									key={value}
									value={value / 10}>
									{value * 10}%
								</Option>
							))}
						</Select>
					</FormControl>
				</Stack>
				<CardActions sx={{ justifyContent: "flex-end", p: 0, mt: 2 }}>
					<Button
						color="secondary"
						type="reset">
						Cancel
					</Button>
					<Button
						variant="contained"
						type="submit">
						Save changes
					</Button>
				</CardActions>
			</form>
		);
	}
	return null;
};
