import { Chip, FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { paths } from "config/paths";
import { useFormik } from "formik";
import RouterLink from "next/link";
import Alert from "@mui/material/Alert";
import { FC } from "react";
import * as Yup from "yup";

import { MangoLogo } from "~ui-components/components/atoms/mango-logo";
import { SplitLayout } from "~ui-components/components/organisms/split-layout";

export const SignInV2Schema = Yup.object({
	email: Yup.string().email("Invalid email").required("Required"),
	password: Yup.string().required("Required"),
});

export type SignInV2FormValues = Yup.InferType<typeof SignInV2Schema>;

export interface SignInV2Props {
	form: ReturnType<typeof useFormik<SignInV2FormValues>>;
}

export const SignInV2: FC<SignInV2Props> = ({ form }) => (
	<SplitLayout
		title="Welcome to Mango"
		subtitle="The accessible and user-friendly alternative to Esri for smart
          spenders.">
		<Stack
			spacing={4}
			component="form"
			onSubmit={form.handleSubmit}>
			<div>
				<Box
					component={RouterLink}
					href={paths.home}
					sx={{ display: "inline-block", fontSize: 0 }}>
					<MangoLogo
						width={122}
						height={32}
					/>
				</Box>
			</div>
			<Stack spacing={1}>
				<Typography variant="h5">
					Sign in&nbsp;
					<Chip
						size="small"
						label="v2"
					/>
				</Typography>
				<Typography
					color="text.secondary"
					variant="body2">
					Don&apos;t have an account?&nbsp;
					<Link
						href={paths.signUpV2}
						variant="subtitle2">
						Sign up
					</Link>
				</Typography>
			</Stack>

			{process.env.SST_STAGE === "dev" && (
				<Alert color="info">
					Psst! Looking for default account?
					<br />
					Use&nbsp;
					<Typography
						component="span"
						sx={{ fontWeight: 700 }}
						variant="inherit">
						john-doe@mangomap.com
					</Typography>
					&nbsp;with&nbsp;
					<Typography
						component="span"
						sx={{ fontWeight: 700 }}
						variant="inherit">
						12345aA!
					</Typography>
				</Alert>
			)}

			<Stack spacing={2}>
				<Stack spacing={2}>
					<FormControl>
						<InputLabel>Email address</InputLabel>
						<OutlinedInput
							autoFocus
							type="email"
							{...form.getFieldProps("email")}
							error={!!form.touched.email && !!form.errors.email}
						/>
						{!!form.touched.email && !!form.errors.email && (
							<FormHelperText error>{form.errors.email}</FormHelperText>
						)}
					</FormControl>
					<FormControl>
						<InputLabel>Password</InputLabel>
						<OutlinedInput
							type="password"
							{...form.getFieldProps("password")}
							error={!!form.touched.password && !!form.errors.password}
						/>
						{!!form.touched.password && !!form.errors.password && (
							<FormHelperText error>{form.errors.password}</FormHelperText>
						)}
					</FormControl>
					<Button
						variant="contained"
						disabled={form.isSubmitting || !form.isValid}
						type="submit">
						Sign in
					</Button>
				</Stack>
				<div>
					<Link
						href={paths.forgotPasswordV2}
						variant="subtitle2">
						Forgot password?
					</Link>
				</div>
			</Stack>
		</Stack>
	</SplitLayout>
);
