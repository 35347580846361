import jmespath from "jmespath";

import { GEOMETRY_TYPE } from "~utils/types/__generated/gql/graphql";

const TYPE_CONVERSION = {
	Point: "POINT",
	MultiPoint: "POINT",
	LineString: "LINE",
	MultiLineString: "LINE",
	Polygon: "POLYGON",
	MultiPolygon: "POLYGON",
};

type Keys = keyof typeof TYPE_CONVERSION;

const getGeojsonGeometryType = (geojson) => {
	const types = jmespath.search(geojson.features, "[*].geometry.type");
	const [firstType] = [...new Set(types)];
	return TYPE_CONVERSION[firstType as Keys] as GEOMETRY_TYPE;
};

export { getGeojsonGeometryType };
