import { autoSignIn, confirmSignUp, resendSignUpCode } from "aws-amplify/auth";
import { paths } from "config/paths";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import {
	SignUpVerificationV2FormValues,
	SignUpVerificationV2Schema,
	SignUpVerificationV2 as View,
	toast,
} from "ui-components";
import Clarity from "@microsoft/clarity";
import { useAuthContext } from "~auth/contexts/auth";
import {
	sendAnalyticsEvent,
	useAnalyticsStore,
} from "~utils/providers/analytics-provider";

export const SignUpVerificationV2 = () => {
	const {
		push,
		query: { email },
	} = useRouter();

	const analyticsStore = useAnalyticsStore();
	const { refreshSession } = useAuthContext();
	const form = useFormik<SignUpVerificationV2FormValues>({
		validateOnMount: true,
		initialValues: {
			code: "",
		},
		validationSchema: SignUpVerificationV2Schema,
		onSubmit: (values, helpers) => {
			toast.promise(
				async () => {
					const { nextStep } = await confirmSignUp({
						username: String(email),
						confirmationCode: values.code,
					});

					if (nextStep.signUpStep === "COMPLETE_AUTO_SIGN_IN") {
						toast.success("Account verified! Signing you in...");

						sendAnalyticsEvent("activatedTrial");
						if (analyticsStore.isClarityInit) Clarity.event("activatedTrial");

						const { nextStep } = await autoSignIn();
						if (nextStep.signInStep === "DONE") {
							await refreshSession();
							return;
						}

						throw new Error("Unexpected error");
					}

					if (nextStep.signUpStep === "DONE") {
						toast.success("Account verified! You can login now.");
						void push(paths.signInV2);
						return;
					}

					throw new Error("Unexpected error");
				},
				{
					loading: "Verifying code...",
					error: (e) => {
						helpers.setSubmitting(false);
						return e.message;
					},
				},
			);
		},
	});

	const onResendEmail = () => {
		toast.promise(resendSignUpCode({ username: String(email) }), {
			loading: "Sending email...",
			success: "Email sent!",
			error: (e) => e.message,
		});
	};

	return (
		<View
			email={email as string}
			onResendEmail={onResendEmail}
			form={form}
		/>
	);
};
