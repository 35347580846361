import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import {
	Rectangle,
	Circle,
	Polygon,
	LineSegments,
	MapPin,
	ChatCenteredText,
	PencilSimple,
} from "@phosphor-icons/react/dist/ssr";

export type SelectionMode =
	| null
	| "line"
	| "rectangle"
	| "circle"
	| "polygon"
	| "marker"
	| "text-annotation";

const SELECTION_MODES = [
	{
		value: "linestring",
		tooltip: "Draw a line",
		icon: <LineSegments />,
	},
	{
		value: "polygon",
		tooltip: "Draw a polygon",
		icon: <Polygon />,
	},
	{
		value: "rectangle",
		tooltip: "Draw a rectangle",
		icon: <Rectangle />,
	},
	{
		value: "circle",
		tooltip: "Draw a radius",
		icon: <Circle />,
	},
	{
		value: "marker",
		tooltip: "Click on the map to create marker",
		icon: <MapPin />,
	},
	{
		value: "text-annotation",
		tooltip: "Click on the map to create text annotation",
		icon: <ChatCenteredText />,
	},
	{
		value: "select",
		tooltip: "Click on sketch to edit",
		icon: <PencilSimple />,
	},
];

interface Props {
	onSketchModeChange: (mode: SelectionMode) => void;
}

export const SketchTools = ({ onSketchModeChange }: Props) => {
	const [selectionMode, setSelectionMode] = useState<SelectionMode>(null);

	return (
		<FormControl>
			<InputLabel>Sketch Tools</InputLabel>
			<ToggleButtonGroup
				color="primary"
				exclusive
				onChange={(_, value: SelectionMode) => {
					setSelectionMode(value);
					onSketchModeChange(value);
				}}
				tabIndex={0}
				value={selectionMode}
				sx={{
					mt: 2,
					display: "flex",
					width: "fit-content",
				}}>
				{SELECTION_MODES.map(({ value, tooltip, icon }) => (
					<Tooltip
						key={value}
						title={tooltip}
						placement="bottom">
						<ToggleButton value={value}>{icon}</ToggleButton>
					</Tooltip>
				))}
			</ToggleButtonGroup>
			<FormHelperText sx={{ ml: 0 }}>
				Choose features or area from query layer.
			</FormHelperText>
		</FormControl>
	);
};
