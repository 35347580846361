import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { ShoppingCartSimple as ShoppingCartSimpleIcon } from "@phosphor-icons/react/dist/ssr/ShoppingCartSimple";
import { Tag as TagIcon } from "@phosphor-icons/react/dist/ssr/Tag";
import { FC, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { InputAdornment, TextField } from "@mui/material";
import Button from "@mui/material/Button";

import { PropertyList } from "~ui-components/components/atoms/property-list";
import { PropertyItem } from "~ui-components/components/atoms/property-item";

const CouponInput = () => {
	const [coupon, setCoupon] = useState<string | null>(null);
	return coupon ? (
		<Typography
			color="text.secondary"
			variant="caption">
			{coupon}
		</Typography>
	) : (
		<TextField
			name="coupon"
			variant="outlined"
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Button
							color="success"
							onClick={() => {
								setCoupon("coupon_1");
							}}>
							Apply
						</Button>
					</InputAdornment>
				),
			}}
		/>
	);
};

export const ITEMS = [
	{
		name: (
			<Stack
				direction={"row"}
				spacing={3}>
				<Typography
					color="text.primary"
					variant="body2">
					Enterprise
				</Typography>
				<Chip
					label="Renews annually"
					size="small"
					variant="filled"
					color="success"
				/>
			</Stack>
		),
		value: (
			<Typography
				align="right"
				color="text.primary"
				variant="body2">
				$1910.00
			</Typography>
		),
	},
	{
		name: (
			<Typography
				color="text.primary"
				variant="body2">
				1 Admin
			</Typography>
		),
		value: (
			<Typography
				align="right"
				color="text.primary"
				variant="body2">
				$470.00
			</Typography>
		),
	},
	{
		name: (
			<Typography
				color="text.primary"
				variant="body2">
				5 Editors
			</Typography>
		),
		value: (
			<Typography
				align="right"
				color="text.primary"
				variant="body2">
				$950.00
			</Typography>
		),
	},
	{
		name: (
			<Typography
				color="text.primary"
				variant="body2">
				5 Additional maps
			</Typography>
		),
		value: (
			<Typography
				align="right"
				color="text.primary"
				variant="body2">
				$250.00
			</Typography>
		),
	},
	{
		name: (
			<Typography
				color="text.primary"
				variant="body2">
				50 GB Additional storage
			</Typography>
		),
		value: (
			<Typography
				align="right"
				color="text.primary"
				variant="body2">
				$480.00
			</Typography>
		),
	},
	{
		name: (
			<Stack spacing={1}>
				<Stack
					direction={"row"}
					spacing={1}
					alignItems={"center"}>
					<Typography
						color="text.primary"
						variant="body2">
						Discount
					</Typography>
					<TagIcon />
				</Stack>
				<CouponInput />
			</Stack>
		),
		value: (
			<Typography
				align="right"
				color="text.primary"
				variant="body2">
				-$900.00
			</Typography>
		),
	},
	{
		name: (
			<Stack spacing={1}>
				<Typography
					color="text.primary"
					variant="body2">
					Taxes
				</Typography>
				<Typography
					color="text.secondary"
					variant="caption">
					UK VAT 20%
				</Typography>
			</Stack>
		),
		value: (
			<Typography
				align="right"
				color="text.primary"
				variant="body2">
				$630.00
			</Typography>
		),
	},
	{
		name: (
			<Typography
				color="text.primary"
				variant="subtitle2">
				Total
			</Typography>
		),
		value: (
			<Typography
				align="right"
				color="text.primary"
				variant="subtitle2">
				USD $4,050.00
			</Typography>
		),
	},
];

export const AccountEditSubscriptionSummary: FC = () => {
	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<ShoppingCartSimpleIcon fontSize="var(--Icon-fontSize)" />
					</Avatar>
				}
				title="Subscription summary"
			/>
			<PropertyList
				divider={<Divider />}
				sx={{
					"--PropertyItem-padding": "12px 24px",
					"--PropertyItem-Value-Align": "right",
				}}>
				{ITEMS.map(
					(item, key): React.JSX.Element => (
						<PropertyItem
							key={key}
							name={item.name}
							value={item.value}
						/>
					),
				)}
			</PropertyList>
		</Card>
	);
};
