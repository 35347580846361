import * as React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { TrashSimple as TrashIcon } from "@phosphor-icons/react/dist/ssr/TrashSimple";
import { Divider } from "@mui/material";
import {
	BookmarkSimple,
	Copy,
	NotePencil,
	PlusSquare,
	Share,
	Textbox,
	UsersFour,
} from "@phosphor-icons/react/dist/ssr";

export interface ItemMenuProps {
	anchorEl: HTMLElement | null;
	onClose: () => void;
	onDelete: () => void;
	open: boolean;
	onShare: () => void;
	onEditAttributes: () => void;
	onEditGeometry: () => void;
	onCreateFeature: () => void;
}

export function ItemMenu({
	anchorEl,
	onClose,
	onDelete,
	onShare,
	onEditAttributes,
	onEditGeometry,
	onCreateFeature,
	open = false,
}: ItemMenuProps): React.JSX.Element {
	return (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
			onClose={onClose}
			open={open}
			transformOrigin={{ horizontal: "left", vertical: "top" }}>
			<MenuItem>
				<ListItemIcon>
					<Copy />
				</ListItemIcon>
				Copy Link
			</MenuItem>
			<MenuItem onClick={onShare}>
				<ListItemIcon>
					<Share />
				</ListItemIcon>
				Share
			</MenuItem>
			<MenuItem>
				<ListItemIcon>
					<BookmarkSimple />
				</ListItemIcon>
				Bookmark
			</MenuItem>
			<Divider />
			<MenuItem onClick={onEditAttributes}>
				<ListItemIcon>
					<Textbox />
				</ListItemIcon>
				Edit Attributes
			</MenuItem>
			<MenuItem onClick={onEditGeometry}>
				<ListItemIcon>
					<NotePencil />
				</ListItemIcon>
				Edit Geometry
			</MenuItem>
			<MenuItem>
				<ListItemIcon>
					<UsersFour />
				</ListItemIcon>
				Edit data access
			</MenuItem>
			<Divider />
			<MenuItem onClick={onCreateFeature}>
				<ListItemIcon>
					<PlusSquare />
				</ListItemIcon>
				Add feature
			</MenuItem>
			<MenuItem
				onClick={onDelete}
				sx={{ color: "var(--mui-palette-error-main)" }}>
				<ListItemIcon>
					<TrashIcon />
				</ListItemIcon>
				Delete feature
			</MenuItem>
		</Menu>
	);
}
