import { useMutation } from "@tanstack/react-query";
import { ForgotPasswordFormValues } from "ui-components";
import { apiInstance } from "utils";

export type SendUpdatePasswordEmailBody = Record<string, unknown>;
export type SendUpdatePasswordEmailResponse = Record<string, unknown>;

export const useSendUpdatePasswordEmail = () => {
	return useMutation({
		mutationKey: ["sendUpdatePasswordEmail"],
		mutationFn: async (values: ForgotPasswordFormValues) => {
			const postForm = new FormData();
			postForm.append("password[email]", values.email);
			return apiInstance.post("/passwords", postForm);
		},
	});
};
