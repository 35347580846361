import {
	FormControl,
	FormHelperText,
	InputLabel,
	OutlinedInput,
	Select,
	Stack,
	Typography,
} from "@mui/material";

import { Option } from "~ui-components/components/atoms/option";

const CRITERIA_OPTIONS = [
	"are within",
	"touch",
	"are closer than",
	"are further than",
	"are outside",
];

export const SpatialCriteriaInput = () => {
	return (
		<Stack>
			<FormControl>
				<InputLabel>Spatial Criteria</InputLabel>
				<Select
					name="spatial_criteria"
					value={CRITERIA_OPTIONS[3]}>
					{CRITERIA_OPTIONS.map((value) => (
						<Option
							key={value}
							value={value}>
							{value}
						</Option>
					))}
				</Select>
				<FormHelperText>Select a spatial relationship.</FormHelperText>
			</FormControl>
			<Stack
				direction="row"
				alignItems="center"
				spacing={1}
				mt={2}>
				<FormControl>
					<OutlinedInput name="distance" />
				</FormControl>
				<Typography variant="caption">miles</Typography>
			</Stack>
		</Stack>
	);
};
