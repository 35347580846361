import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import { FC } from "react";
import * as Yup from "yup";

import { StepIcon } from "~ui-components/components/atoms/step-icon";

import { InputField } from "./input-field";
import { CountriesSelectField } from "./countries-select-field";

export const AccountBillingSchema = Yup.object({
	companyName: Yup.string().required("Required!"),
	vatNumber: Yup.string().required("Required!"),
	firstName: Yup.string().required("Required!"),
	lastName: Yup.string().required("Required!"),
	streetLine1: Yup.string().required("Required!"),
	streetLine2: Yup.string(),
	city: Yup.string().required("Required!"),
	province: Yup.string().required("Required!"),
	postalCode: Yup.string().required("Required!"),
	country: Yup.string().required("Required!"),
	cardName: Yup.string().required("Required!"),
	cardNumber: Yup.string().required("Required!"),
	cardExpiresAt: Yup.string().required("Required!"),
	cardCvv: Yup.string().required("Required!"),
});

export type AccountBillingFormValues = Yup.InferType<
	typeof AccountBillingSchema
>;

interface AccountBillingFormProps {
	form: ReturnType<typeof useFormik<AccountBillingFormValues>>;
}

export const AccountBillingForm: FC<AccountBillingFormProps> = ({ form }) => {
	return (
		<Card>
			<CardContent>
				<Stack spacing={6}>
					<Stack spacing={3}>
						<Stack
							direction="row"
							spacing={2}
							sx={{ alignItems: "center" }}>
							<StepIcon number={1} />
							<Typography variant="h6">Billing Address</Typography>
						</Stack>
						<Grid
							container
							spacing={3}>
							<InputField
								form={form}
								name="companyName"
								label="Company Name"
							/>
							<InputField
								form={form}
								name="vatNumber"
								label="VAT number"
							/>
							<InputField
								form={form}
								name="firstName"
								label="First Name"
							/>
							<InputField
								form={form}
								name="lastName"
								label="Last Name"
							/>
							<InputField
								form={form}
								name="streetLine1"
								label="Street line 1"
							/>
							<InputField
								form={form}
								name="streetLine2"
								label="Street line 2 (optional)"
							/>
							<InputField
								form={form}
								name="city"
								label="City"
							/>
							<InputField
								form={form}
								name="province"
								label="State / Province"
							/>
							<InputField
								form={form}
								name="postalCode"
								label="Zip / Postal code"
							/>
							<CountriesSelectField
								form={form}
								name="country"
								label="Country"
							/>
						</Grid>
					</Stack>
					<Stack spacing={3}>
						<Stack
							direction="row"
							spacing={2}
							sx={{ alignItems: "center" }}>
							<StepIcon number={2} />
							<Typography variant="h6">Payment method</Typography>
						</Stack>
						<Grid
							container
							sm={6}
							spacing={3}
							xs={12}>
							<InputField
								form={form}
								name="cardName"
								label="Name on card"
								md={12}
							/>
							<InputField
								form={form}
								name="cardNumber"
								label="Card Number"
								md={12}
								placeholder="**** **** **** 1234"
							/>
							<InputField
								form={form}
								name="cardExpiresAt"
								label="Exp. Date"
								placeholder="MM/YY"
							/>
							<InputField
								form={form}
								name="cardCvv"
								label="Security Code"
								placeholder="XXX"
							/>
						</Grid>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	);
};
