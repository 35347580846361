import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

interface Slots {
	SideNav?: any;

	Content: {
		LayerDetails: any;
		LayerStyle: any;
		LayerLabels: any;
		LayerPopup: any;
		Formatting: any;
	};
}

const Empty = () => null;

const defaultSlots: Slots = {
	SideNav: Empty,
	Content: {
		LayerDetails: Empty,
		LayerStyle: Empty,
		LayerLabels: Empty,
		LayerPopup: Empty,
		Formatting: Empty,
	},
};

interface Props {
	title?: string;
	slots?: Slots;
}

function LayerSettings(props: Props) {
	return (
		<DashboardContentLayout pageTitle={props.title ?? "Layer settings"}>
			<Root {...props} />
		</DashboardContentLayout>
	);
}

function Root(props: Props) {
	const { slots = {} } = props;

	const Slots = {
		...defaultSlots,
		...slots,
	};

	return (
		<Stack
			direction={{ xs: "column", md: "row" }}
			spacing={4}
			sx={{ position: "relative" }}>
			<Slots.SideNav />
			<Box sx={{ flex: "1 1 auto", minWidth: 0 }}>
				<Slots.Content.LayerDetails />
				<Slots.Content.LayerStyle />
				<Slots.Content.LayerLabels />
				<Slots.Content.LayerPopup />
				<Slots.Content.Formatting />
			</Box>
		</Stack>
	);
}

export { LayerSettings };
