import { Box, StackProps } from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import _ from "lodash";
import { MuiColorInput } from "mui-color-input";

import { Option } from "~ui-components/components/atoms/option";
import { PointSimpleConfigs } from "~ui-components/types/__generated/gql/graphql";
import { PREDEFINED_MARKERS } from "../layer-style/marker-setting-drawer";

interface Slots {
	MarkerSettingsDrawer: any;
}

interface SlotProps {
	MarkerSettingsDrawer: any;
}

const Empty = () => null;

const defaultSlots: Slots = {
	MarkerSettingsDrawer: Empty,
};

type Props = StackProps & {
	form: ReturnType<typeof useFormik<PointSimpleConfigs>>;
	visualisation?: string;
	slots?: Slots;
	slotProps?: SlotProps;
	onOpenMarkerDrawer: () => void;
	onMarkerChange: (value: string) => void;
	onMarkerSettingClose: () => void;
};

export const FillStrokeStyle = (props: Props) => {
	const {
		onMarkerChange,
		slots = {},
		slotProps = {},
		onOpenMarkerDrawer,
		onMarkerSettingClose,
		form,
	} = props;
	const {
		values: { symbolStyle },
		handleChange,
		handleBlur,
	} = form;

	const Slots = {
		...defaultSlots,
		...slots,
	};

	const markerValue = _.get(symbolStyle, "basic.symbol", "circle");
	const { Icon, ...iconProps } = PREDEFINED_MARKERS[markerValue];

	return (
		<>
			<Stack spacing={3}>
				<Typography variant="subtitle1">Marker</Typography>
				<FormControl>
					<InputLabel sx={{ pb: 1 }}>Symbol</InputLabel>
					<Stack
						alignItems="center"
						direction="row"
						spacing={2}>
						<Box
							sx={{
								border: "1px solid #ccc",
								alignItems: "center",
								borderRadius: "8px",
								display: "flex",
								height: "100px",
								justifyContent: "center",
								overflow: "hidden",
								width: "100px",
								backgroundImage: 'url("/images/marker-bg.png")',
								backgroundSize: "cover", // Ensures the image covers the entire box
								backgroundPosition: "center", // Centers the image within the box
								position: "relative", // Enables absolute positioning for children
							}}>
							{markerValue === "circle" && (
								<Icon
									fontSize={Number(
										(symbolStyle?.basic?.size ?? 0) +
											(symbolStyle?.stroke?.width ?? 0),
									)}
									{...iconProps}
									color={symbolStyle?.stroke?.color}
									style={{
										position: "absolute", // Overlaps with the other icon
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)", // Centers the icon
										opacity: symbolStyle?.stroke?.opacity ?? 1,
									}}
								/>
							)}
							<Icon
								fontSize={symbolStyle?.basic?.size}
								{...iconProps}
								color={symbolStyle?.fill?.color}
								style={{
									position: "absolute", // Overlaps with the other icon
									top: "50%",
									left: "50%",
									transform: `translate(-50%, -50%) rotate(${symbolStyle?.basic?.rotation}deg)`,
									opacity: symbolStyle?.fill?.opacity ?? 1,
								}}
							/>
						</Box>

						<Button
							color="secondary"
							variant="outlined"
							onClick={onOpenMarkerDrawer}>
							Change marker
						</Button>
					</Stack>
				</FormControl>
				<FormControl>
					<InputLabel>Size</InputLabel>
					<OutlinedInput
						sx={{ width: 200 }}
						type="number"
						name="symbolStyle.basic.size"
						onChange={handleChange}
						onBlur={handleBlur}
						defaultValue={symbolStyle?.basic?.size}
					/>
				</FormControl>
				<FormControl>
					<InputLabel>Rotation</InputLabel>
					<OutlinedInput
						sx={{ width: 200 }}
						name="symbolStyle.basic.rotation"
						onChange={handleChange}
						onBlur={handleBlur}
						defaultValue={symbolStyle?.basic?.rotation}
						type="number"
					/>
				</FormControl>
				<InputLabel>Fill and Stroke</InputLabel>
				<FormControl sx={{ width: 200 }}>
					<InputLabel>Fill colour</InputLabel>
					<MuiColorInput
						sx={{ width: 200 }}
						format="hex"
						value={symbolStyle?.fill?.color ?? ""}
						onChange={(value) =>
							form.setFieldValue("symbolStyle.fill.color", value)
						}
					/>
				</FormControl>
				<FormControl sx={{ width: 200 }}>
					<InputLabel>Fill Opacity</InputLabel>
					<Select
						type="number"
						name="symbolStyle.fill.opacity"
						onChange={handleChange}
						defaultValue={symbolStyle?.fill?.opacity}>
						{_.range(0, 11).map((value) => (
							<Option
								key={value}
								value={value / 10}>
								{value * 10}%
							</Option>
						))}
					</Select>
				</FormControl>
				{markerValue === "circle" && (
					<>
						<FormControl sx={{ width: 200 }}>
							<InputLabel>Stroke colour</InputLabel>
							<MuiColorInput
								sx={{ width: 200 }}
								format="hex"
								value={symbolStyle?.stroke?.color ?? ""}
								onChange={(value) =>
									form.setFieldValue("symbolStyle.stroke.color", value)
								}
							/>
						</FormControl>
						<FormControl sx={{ width: 200 }}>
							<InputLabel>Stroke opacity</InputLabel>
							<Select
								name="symbolStyle.stroke.opacity"
								onChange={handleChange}
								defaultValue={symbolStyle?.stroke?.opacity}
								type="number">
								{_.range(0, 11).map((value) => (
									<Option
										key={value}
										value={value / 10}>
										{value * 10}%
									</Option>
								))}
							</Select>
						</FormControl>
						<FormControl sx={{ width: 200 }}>
							<InputLabel>Stroke width</InputLabel>
							<OutlinedInput
								type="number"
								sx={{ width: 200 }}
								name="symbolStyle.stroke.width"
								onChange={handleChange}
								onBlur={handleBlur}
								defaultValue={symbolStyle?.stroke?.width}
							/>
						</FormControl>
					</>
				)}
			</Stack>
			<Slots.MarkerSettingsDrawer
				value={markerValue}
				onChange={(value) => {
					onMarkerChange(value);
					void form.setFieldValue("symbolStyle.basic.symbol", value);
					onMarkerSettingClose();
				}}
				onClose={onMarkerSettingClose}
				open={
					(slotProps as { MarkerSettingsDrawer: { open: boolean } })
						.MarkerSettingsDrawer?.open
				}
			/>
		</>
	);
};
