import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Monitor as PortalIcon } from "@phosphor-icons/react/dist/ssr/Monitor";
import { MuiColorInput } from "mui-color-input";
import * as React from "react";

import type { File } from "~ui-components/components/atoms/file-dropzone";
import { FileDropzone } from "~ui-components/components/atoms/file-dropzone";
import { BrandLayout as Layout } from "../layout";

import { BodyTextColorSelect } from "./components/bodyTextColor";
import { FeatureHighlightOpacitySelect } from "./components/featureHighlightOpacity";
import { ThemeSelect } from "./components/theme";
import { TitleTextSizeSelect } from "./components/titleTextSize";

export const BrandTheme = () => {
	const [cover, setCover] = React.useState<string | null>(
		"/assets/image-abstract-1.png",
	);

	const handleCoverDrop = React.useCallback(([file]: File[]) => {
		const reader = new FileReader();
		if (file) reader.readAsDataURL(file);
		reader.onload = () => {
			setCover(reader.result as string);
		};
	}, []);

	const handleCoverRemove = React.useCallback(() => {
		setCover(null);
	}, []);

	return (
		<Layout>
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<PortalIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Theme settings"
				/>
				<CardContent>
					<Stack spacing={3}>
						<Stack spacing={2}>
							<Stack spacing={3}>
								{cover ? (
									<Box
										sx={{
											backgroundImage: `url(${cover})`,
											backgroundPosition: "center",
											backgroundSize: "cover",
											borderRadius: 1,
											height: "230px",
										}}
									/>
								) : (
									<Stack
										spacing={1}
										sx={{
											alignItems: "center",
											bgcolor: "var(--mui-palette-background-level1)",
											border: "1px dashed var(--mui-palette-divider)",
											borderRadius: 1,
											height: "230px",
											justifyContent: "center",
											p: 3,
											textAlign: "center",
										}}>
										<Typography
											color="text.secondary"
											variant="h6">
											Upload your logo
										</Typography>
									</Stack>
								)}
								<div>
									<Button
										color="secondary"
										disabled={!cover}
										onClick={handleCoverRemove}
										variant="outlined">
										Remove
									</Button>
								</div>
								<FileDropzone
									accept={{ "image/*": [] }}
									caption="(SVG, JPG, PNG, or gif maximum 900x400)"
									maxFiles={1}
									onDrop={handleCoverDrop}
								/>
							</Stack>
							<ThemeSelect />
							<FormControl>
								<InputLabel>Background color</InputLabel>
								<MuiColorInput
									fullWidth
									format="hex"
									value=""
									// eslint-disable-next-line @typescript-eslint/no-empty-function
									onChange={() => {}}
								/>
							</FormControl>
							<FormControl>
								<InputLabel>Title text color</InputLabel>
								<MuiColorInput
									fullWidth
									format="hex"
									value=""
									// eslint-disable-next-line @typescript-eslint/no-empty-function
									onChange={() => {}}
								/>
							</FormControl>
							<TitleTextSizeSelect />
							<BodyTextColorSelect />
							<FormControl>
								<InputLabel>Accent color</InputLabel>
								<MuiColorInput
									fullWidth
									format="hex"
									value=""
									// eslint-disable-next-line @typescript-eslint/no-empty-function
									onChange={() => {}}
								/>
							</FormControl>
							<FormControl>
								<InputLabel>Feature highlight</InputLabel>
								<OutlinedInput name="portal-name" />
							</FormControl>
							<FeatureHighlightOpacitySelect />
						</Stack>
					</Stack>
				</CardContent>
				<CardActions sx={{ justifyContent: "flex-end" }}>
					<Button color="secondary">Cancel</Button>
					<Button variant="contained">Save changes</Button>
				</CardActions>
			</Card>
		</Layout>
	);
};
