import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DotsThree as DotsThreeIcon } from "@phosphor-icons/react/dist/ssr/DotsThree";
import { Eye as EyeIcon } from "@phosphor-icons/react/dist/ssr/Eye";
import { Lock as LockIcon } from "@phosphor-icons/react/dist/ssr/Lock";
import { paths } from "config/paths";
import Link from "next/link";
import { useRouter } from "next/router";
import * as React from "react";
import { getMapThumbnailUrl } from "utils";

import { usePopover } from "~ui-components/hooks/use-popover";

import { ItemIcon } from "./item-icon";
import { ItemMenu } from "./item-menu";

export interface ItemCardProps {
	item: Record<string, any>;
	onDuplicate?: (itemId: string) => void;
	onDelete?: (itemId: string) => void;
	onLock?: (itemId: string) => void;
	size?: "medium" | "large";
}

export function ItemCard({
	item,
	onDelete,
	onLock,
	size = "medium",
}: ItemCardProps): React.JSX.Element {
	const popover = usePopover<HTMLButtonElement>();
	const router = useRouter();

	const handleDelete = React.useCallback(() => {
		popover.handleClose();
		onDelete?.(item.id);
	}, [item, popover, onDelete]);

	return (
		<React.Fragment>
			<Card
				sx={{
					transition: "box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
					"&:hover": { boxShadow: "var(--mui-shadows-16)" },
				}}>
				<Stack
					direction="row"
					spacing={3}
					sx={{
						alignItems: "center",
						justifyContent: "space-between",
						pt: 2,
						px: 2,
					}}>
					<div>
						<IconButton
							onClick={() => {
								onLock?.(item.id);
							}}>
							{item?.isPrivate ? <LockIcon /> : <EyeIcon />}
						</IconButton>
					</div>
					<IconButton
						onClick={popover.handleOpen}
						ref={popover.anchorRef}>
						<DotsThreeIcon weight="bold" />
					</IconButton>
				</Stack>
				<Stack
					spacing={1}
					padding={2}
					sx={{
						color: "unset",
						textDecoration: "none",
					}}
					component={Link}
					href={paths.accountName.maps.mapId.mapName.root(
						router.query["account-name"],
						item.id,
						item.name,
					)}>
					<Box display="flex">
						<ItemIcon
							size={size}
							src={getMapThumbnailUrl(item.id, item.updatedAt)}
						/>
					</Box>
					<div>
						<Typography variant="subtitle2">{item.name}</Typography>
						<Stack
							direction="row"
							spacing={1}
							sx={{ alignItems: "center", justifyContent: "space-between" }}>
							<Typography
								color="text.secondary"
								variant="caption"
								sx={{
									display: "-webkit-box",
									WebkitLineClamp: 2,
									WebkitBoxOrient: "vertical",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}>
								{item.description}
							</Typography>
						</Stack>
					</div>
				</Stack>
			</Card>
			<ItemMenu
				anchorEl={popover.anchorRef.current}
				onClose={popover.handleClose}
				onDelete={handleDelete}
				open={popover.open}
			/>
		</React.Fragment>
	);
}
