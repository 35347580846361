import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

export interface SummaryItem {
	title: React.ReactNode;
	value: React.ReactNode;
	footer: React.ReactNode;
}

export interface SummaryProps {
	items: SummaryItem[];
}

export const Summary: React.FC<SummaryProps> = ({ items }) => {
	return (
		<Card>
			<Box
				sx={{
					display: "grid",
					gap: 2,
					gridTemplateColumns: {
						xs: "1fr",
						md: "repeat(3, 1fr)",
					},
					p: 3,
				}}>
				{items.map((item, index) => (
					<Stack
						key={index}
						spacing={1}
						sx={{
							borderRight:
								index === items.length - 1
									? {}
									: {
											xs: "none",
											md: "1px solid var(--mui-palette-divider)",
										},
							borderBottom:
								index === items.length - 1
									? {}
									: {
											xs: "1px solid var(--mui-palette-divider)",
											md: "none",
										},
							pb: { xs: 2, md: 0 },
							pr: { xs: 0, md: 2 },
						}}>
						<Typography color="text.secondary">{item.title}</Typography>
						<Typography variant="h3">{item.value}</Typography>
						<Box color="text.secondary">{item.footer}</Box>
					</Stack>
				))}
			</Box>
		</Card>
	);
};
