import { Button, ButtonProps, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { ArrowLeft } from "@phosphor-icons/react/dist/ssr/ArrowLeft";
import { useRouter } from "next/router";
import * as React from "react";

interface DashboardContentLayoutProps {
	pageTitle?: React.ReactNode;
	actionButtonProps?: ButtonProps;
	action?: React.ReactNode;
	disableBackButton?: boolean;
	children: React.ReactNode;
	backButtonProps?: ButtonProps;
}

export const DashboardContentLayout: React.FC<DashboardContentLayoutProps> = ({
	pageTitle,
	actionButtonProps,
	action,
	disableBackButton,
	children,
	backButtonProps,
}) => {
	const router = useRouter();

	return (
		<Box
			sx={{
				maxWidth: "var(--Content-maxWidth)",
				m: "var(--Content-margin)",
				p: "var(--Content-padding)",
				width: "var(--Content-width)",
			}}>
			<Stack spacing={4}>
				<div>
					{!disableBackButton && (
						<Button
							color="inherit"
							onClick={router.back}
							startIcon={<ArrowLeft />}
							{...backButtonProps}>
							Back
						</Button>
					)}
				</div>
				{(!!pageTitle || !!actionButtonProps || !!action) && (
					<Stack
						direction={{ xs: "column", sm: "row" }}
						spacing={3}
						sx={{ alignItems: "flex-start" }}>
						{!!pageTitle && (
							<Box sx={{ flex: "1 1 auto" }}>
								<Typography variant="h4">{pageTitle}</Typography>
							</Box>
						)}
						{!!actionButtonProps && (
							<div style={{ textWrap: "nowrap" }}>
								<Button {...actionButtonProps} />
							</div>
						)}
						{action}
					</Stack>
				)}
				{children}
			</Stack>
		</Box>
	);
};
