/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, Stack, Button, IconButton } from "@mui/material";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { Check } from "@phosphor-icons/react/dist/ssr/Check";

interface Props {
	onSave: () => void;
	onDelete: () => void;
	markerIcon: string; // currently selected marker icon
	onIconClick: (icon: string) => void;
}

const icons = [
	"pin-1.png",
	"pin-2.png",
	"pin-3.png",
	"pin-4.png",
	"pin-5.png",
	"pin-6.png",
	"pin-7.png",
	"pin-8.png",
	"pin-9.png",
];

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
	"& > .MuiBadge-badge": {
		right: 12,
		top: 12,
		width: 12,
		padding: 3,
	},
}));

export const SketchMarkerEditor = ({
	onSave = () => {},
	onDelete = () => {},
	onIconClick = () => {},
	markerIcon = "",
}: Props) => {
	return (
		<Stack
			p={2}
			direction="column"
			sx={{
				height: "100%",
			}}>
			<Box
				sx={{
					flexWrap: "wrap",
					flexGrow: 1,
				}}>
				{icons.map((icon, index) => {
					if (markerIcon === icon) {
						return (
							<StyledBadge
								key={index}
								color="success"
								badgeContent={<Check size="small" />}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}>
								<IconButton
									sx={{ mt: 1 }}
									onClick={() => onIconClick(icon)}
									aria-label="circle"
									size="large">
									<img
										src={`/images/markers/${icon}`}
										alt="icon"
									/>
								</IconButton>
							</StyledBadge>
						);
					}

					return (
						<IconButton
							key={index}
							sx={{ mt: 1 }}
							onClick={() => onIconClick(icon)}
							aria-label="circle"
							size="large">
							<img
								src={`/images/markers/${icon}`}
								alt="icon"
							/>
						</IconButton>
					);
				})}
			</Box>
			<Stack
				direction="row"
				spacing={1}
				justifyContent="end">
				<Button
					onClick={onDelete}
					color="secondary">
					Delete
				</Button>
				<Button
					onClick={onSave}
					variant="contained"
					color="primary">
					Save
				</Button>
			</Stack>
		</Stack>
	);
};
