import { confirmResetPassword, signIn } from "aws-amplify/auth";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import {
	toast,
	UpdatePasswordV2FormValues,
	UpdatePasswordV2Schema,
	UpdatePasswordV2 as View,
} from "ui-components";

import { useAuthContext } from "~auth/contexts/auth";

export const UpdatePasswordV2 = () => {
	const router = useRouter();

	const { refreshSession } = useAuthContext();
	const form = useFormik<UpdatePasswordV2FormValues>({
		validateOnMount: true,
		initialValues: {
			code: "",
			password: "",
			retypedPassword: "",
		},
		validationSchema: UpdatePasswordV2Schema,
		validate(values) {
			return values.password === values.retypedPassword
				? {}
				: { retypedPassword: "Passwords do not match" };
		},
		onSubmit: (values, helpers) => {
			toast.promise(
				async () => {
					await confirmResetPassword({
						username: String(router.query.email),
						confirmationCode: values.code,
						newPassword: values.password,
					});
					await signIn({
						username: String(router.query.email),
						password: values.password,
					});
					await refreshSession();
				},
				{
					loading: "Updating password...",
					success: "Signing you in to your account...",
					error: (e) => {
						helpers.setSubmitting(false);
						return e.message;
					},
				},
			);
		},
	});

	return <View form={form} />;
};
