import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { CursorClick as PopupIcon } from "@phosphor-icons/react/dist/ssr/CursorClick";
import { Question as QuestionIcon } from "@phosphor-icons/react/dist/ssr/Question";
import { useFormik } from "formik";
import Link from "next/link";

interface Slots {
	NoPopup: any;
	DefaultPopup: any;
	CustomPopup: any;
	Calculator: any;
}
const Empty = () => null;

const defaultSlots: Slots = {
	NoPopup: Empty,
	DefaultPopup: Empty,
	CustomPopup: Empty,
	Calculator: Empty,
};

interface SlotProps {
	popupType: string;
}
interface LayerPopupInput {
	popupType: string;
}

interface Props {
	form: ReturnType<typeof useFormik<LayerPopupInput>>;
	slots?: Slots;
	slotProps?: SlotProps;
}

export const LayerPopup = (props: Props) => {
	const { slots = {}, slotProps, form } = props;
	const { values, handleChange } = form;

	const Slots = {
		...defaultSlots,
		...slots,
	};

	return (
		<>
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<PopupIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Feature popup"
					action={
						<Link
							href={
								"https://help.mangomap.com/hc/en-us/articles/360022122592-The-Layers-panel"
							}
							target="_blank">
							<IconButton aria-label="settings">
								<QuestionIcon />
							</IconButton>
						</Link>
					}
				/>
				<CardContent>
					<FormControl>
						<RadioGroup
							row
							aria-labelledby="demo-row-radio-buttons-group-label"
							name="popupType"
							value={values.popupType}
							onChange={handleChange}>
							<FormControlLabel
								value="none"
								control={<Radio />}
								label="None"
							/>
							<FormControlLabel
								value="default"
								control={<Radio />}
								label="Default"
							/>
							<FormControlLabel
								value="custom"
								control={<Radio />}
								label="Custom"
							/>
							<FormControlLabel
								value="calculator"
								control={<Radio />}
								label="Calculator"
							/>
						</RadioGroup>
					</FormControl>
					<Slots.NoPopup popupType={slotProps?.popupType} />
					<Slots.DefaultPopup popupType={slotProps?.popupType} />
					<Slots.CustomPopup popupType={slotProps?.popupType} />
					<Slots.Calculator popupType={slotProps?.popupType} />
				</CardContent>
			</Card>
		</>
	);
};
