import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";
import { ButtonCancel } from "~ui-components/components/atoms/button-cancel";
import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import { DataTable } from "~ui-components/components/atoms/data-table";
import { AccountUser } from "~ui-components/types/__generated/gql/graphql";

export const GroupEditSchema = yup.object({
	name: yup.string().required("Required"),
	memberIds: yup.array().of(yup.string().required()).required("Required"),
});

export type GroupEditFormValues = yup.InferType<typeof GroupEditSchema>;

export function GroupEdit(props: {
	form: FormikProps<GroupEditFormValues>;
	isUsersLoading: boolean;
	users: AccountUser[];
}): React.JSX.Element {
	const { form, users, isUsersLoading } = props;

	return (
		<DashboardContentLayout pageTitle="Edit group">
			<form onSubmit={form.handleSubmit}>
				<Card>
					<CardContent>
						<Stack
							divider={<Divider />}
							spacing={4}>
							<Stack spacing={3}>
								<Typography variant="h6">Group information</Typography>
								<Grid
									container
									spacing={3}>
									<Grid xs={12}>
										<FormControl
											fullWidth
											error={!!form.errors.name}>
											<InputLabel>Name</InputLabel>
											<OutlinedInput {...form.getFieldProps("name")} />
											{form.errors?.name && (
												<FormHelperText>{form.errors.name}</FormHelperText>
											)}
										</FormControl>
									</Grid>
								</Grid>
							</Stack>
							<Stack spacing={3}>
								<Typography variant="h6">Members information</Typography>
								<Grid
									container
									spacing={3}>
									<Grid xs={12}>
										{isUsersLoading ? (
											<CircularProgress />
										) : (
											<Card>
												<DataTable<AccountUser>
													selectable
													uniqueRowId={(row) => row._user.id}
													selected={new Set(Array.from(form.values.memberIds))}
													columns={[
														{
															formatter: (row): React.JSX.Element => (
																<Stack
																	direction="row"
																	spacing={1}
																	sx={{ alignItems: "center" }}>
																	<div>
																		{row._user.isActivated ? (
																			<Typography
																				color="text.primary"
																				sx={{ whiteSpace: "nowrap" }}
																				variant="subtitle2">
																				{row._user.name}
																			</Typography>
																		) : (
																			<Chip
																				label="PENDING ACTIVATION"
																				color="secondary"
																				size="small"
																			/>
																		)}
																		<Typography
																			color="text.secondary"
																			variant="body2">
																			{row._user.email}
																		</Typography>
																	</div>
																</Stack>
															),
															name: "Select all",
														},
														{
															formatter: (row): React.JSX.Element => (
																<Chip
																	label={row.role}
																	color="primary"></Chip>
															),
															name: "Role",
														},
													]}
													rows={users}
													onDeselectAll={() => {
														form.setFieldValue("memberIds", []);
													}}
													onDeselectOne={(_, row) => {
														form.setFieldValue(
															"memberIds",
															form.values.memberIds.filter(
																(id) => id !== row._user.id,
															),
														);
													}}
													onSelectAll={() => {
														form.setFieldValue(
															"memberIds",
															users.map((user) => user._user.id),
														);
													}}
													onSelectOne={(_, row) => {
														form.setFieldValue("memberIds", [
															...form.values.memberIds,
															row._user.id,
														]);
													}}
												/>
												{!users.length ? (
													<Box sx={{ p: 3 }}>
														<Typography
															color="text.secondary"
															sx={{ textAlign: "center" }}
															variant="body2">
															No members found
														</Typography>
													</Box>
												) : null}
											</Card>
										)}
									</Grid>
								</Grid>
							</Stack>
						</Stack>
					</CardContent>
					<CardActions sx={{ justifyContent: "flex-end" }}>
						<ButtonCancel />
						<Button
							type="submit"
							variant="contained"
							disabled={!form.isValid}>
							Save changes
						</Button>
					</CardActions>
				</Card>
			</form>
		</DashboardContentLayout>
	);
}
