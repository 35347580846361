import {
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	Chip,
	FormControl,
	FormControlLabel,
	FormGroup,
	InputLabel,
	Radio,
	RadioGroup,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { ArrowSquareOut } from "@phosphor-icons/react/dist/ssr/ArrowSquareOut";
import { CirclesThreePlus } from "@phosphor-icons/react/dist/ssr/CirclesThreePlus";
import { CreditCard } from "@phosphor-icons/react/dist/ssr/CreditCard";
import { HardDrives } from "@phosphor-icons/react/dist/ssr/HardDrives";
import { MapTrifold } from "@phosphor-icons/react/dist/ssr/MapTrifold";
import { UsersFour } from "@phosphor-icons/react/dist/ssr/UsersFour";
import { railsServerUrls } from "config/rails-server-urls";
import { useFormik } from "formik";
import { FC } from "react";
import * as Yup from "yup";

import { Option } from "~ui-components/components/atoms/option";
import {
	SUBSCRIPTION_CYCLE,
	SUBSCRIPTION_PLAN,
} from "~ui-components/types/__generated/gql/graphql";

const BILLING_CYCLE_NAME: Record<SUBSCRIPTION_CYCLE, string> = {
	MONTHLY: "Monthly",
	ANNUAL: "Annual",
	TWO_YEARS: "2 years",
	THREE_YEARS: "3 years",
	FOUR_YEARS: "4 years",
	FIVE_YEARS: "5 years",
};
const BILLING_CYCLE_DISCOUNT: Record<SUBSCRIPTION_CYCLE, number> = {
	MONTHLY: 0,
	ANNUAL: 20,
	TWO_YEARS: 30,
	THREE_YEARS: 30,
	FOUR_YEARS: 30,
	FIVE_YEARS: 30,
};

const PLAN_NAME: Record<SUBSCRIPTION_PLAN, string> = {
	BUSINESS: "Business",
	ENTERPRISE: "Enterprise",
	AGENCY: "Agency",
};
const PLAN_OFFER: Record<
	SUBSCRIPTION_PLAN,
	{
		maps: number;
		storage: number;
		isViewersUnlimited: boolean;
		isAddOnsAllowed: boolean;
		price: number;
	}
> = {
	BUSINESS: {
		maps: 20,
		storage: 4,
		isViewersUnlimited: false,
		isAddOnsAllowed: false,
		price: 950,
	},
	ENTERPRISE: {
		maps: 40,
		storage: 8,
		isViewersUnlimited: true,
		isAddOnsAllowed: true,
		price: 1910,
	},
	AGENCY: {
		maps: 100,
		storage: 16,
		isViewersUnlimited: true,
		isAddOnsAllowed: true,
		price: 3380,
	},
};

export const ADDONS_ADMINS = [0, 1, 2, 3, 4, 5] as const;
export const ADDONS_EDITORS = [0, 5, 10, 15, 20, 25] as const;
export const ADDONS_MAPS = [0, 5, 10, 15, 20, 25] as const;
export const ADDONS_STORAGE = [0, 50, 100, 150, 200, 250] as const;

export const EditSubscriptionSchema = Yup.object({
	cycle: Yup.string()
		.oneOf(Object.values(SUBSCRIPTION_CYCLE))
		.required("Required"),
	plan: Yup.string()
		.oneOf(Object.values(SUBSCRIPTION_PLAN))
		.required("Required"),
	addons: Yup.object({
		admins: Yup.number()
			.oneOf(ADDONS_ADMINS as unknown as number[])
			.required("Required"),
		editors: Yup.number()
			.oneOf(ADDONS_EDITORS as unknown as number[])
			.required("Required"),
		maps: Yup.number()
			.oneOf(ADDONS_MAPS as unknown as number[])
			.required("Required"),
		storage: Yup.number()
			.oneOf(ADDONS_STORAGE as unknown as number[])
			.required("Required"),
	}),
});

export type EditSubscriptionFormValues = Yup.InferType<
	typeof EditSubscriptionSchema
>;

export interface EditSubscriptionProps {
	form: ReturnType<typeof useFormik<EditSubscriptionFormValues>>;
}

export const AccountEditSubscription: FC<EditSubscriptionProps> = ({
	form,
}) => {
	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<CreditCard fontSize="var(--Icon-fontSize)" />
					</Avatar>
				}
				title="Choose your subscription options"
				action={
					<Button
						endIcon={<ArrowSquareOut />}
						href={railsServerUrls.pricing}
						target="_blank">
						View pricing details
					</Button>
				}
			/>
			<CardContent>
				<Stack
					spacing={3}
					padding={2}>
					<FormControl>
						<InputLabel>Billing cycle</InputLabel>
						<TextField
							select
							{...form.getFieldProps("cycle")}
							error={!!form.errors.cycle}
							helperText={form.errors.cycle}
							sx={{ width: "200px" }}>
							{[
								SUBSCRIPTION_CYCLE.MONTHLY,
								SUBSCRIPTION_CYCLE.ANNUAL,
								SUBSCRIPTION_CYCLE.TWO_YEARS,
								SUBSCRIPTION_CYCLE.THREE_YEARS,
								SUBSCRIPTION_CYCLE.FOUR_YEARS,
								SUBSCRIPTION_CYCLE.FIVE_YEARS,
							].map((cycle) => (
								<Option
									key={cycle}
									value={cycle}>
									{BILLING_CYCLE_NAME[cycle]}
									&nbsp;
									{BILLING_CYCLE_DISCOUNT[cycle] > 0 && (
										<Chip
											color="success"
											size="small"
											label={<>Save {BILLING_CYCLE_DISCOUNT[cycle]}%</>}
										/>
									)}
								</Option>
							))}
						</TextField>
					</FormControl>
					<FormControl>
						<InputLabel>Plan</InputLabel>
						<RadioGroup
							{...form.getFieldProps("plan")}
							sx={{
								"& .MuiFormControlLabel-root": {
									border: "1px solid var(--mui-palette-divider)",
									borderRadius: 1,
									gap: 2,
									p: 2,
								},
							}}>
							{[
								SUBSCRIPTION_PLAN.BUSINESS,
								SUBSCRIPTION_PLAN.ENTERPRISE,
								SUBSCRIPTION_PLAN.AGENCY,
							].map((option) => (
								<FormControlLabel
									key={option}
									control={<Radio />}
									sx={[
										form.values.plan === option && {
											"&.MuiFormControlLabel-root": {
												border: (t) => `1px solid ${t.palette.primary.main}`,
											},
										},
										{ ".MuiFormControlLabel-label": { flex: 1 } },
									]}
									label={
										<Stack
											direction="row"
											alignItems="center"
											justifyContent="space-between">
											<Box flex={1}>
												<Typography variant="inherit">
													{PLAN_NAME[option]}
												</Typography>
												<Stack
													direction="row"
													alignItems="center"
													sx={[
														(t) => t.typography.caption,
														{ color: "text.secondary" },
													]}>
													<MapTrifold />
													&nbsp;{PLAN_OFFER[option].maps} maps&nbsp;&nbsp;
													<HardDrives />
													&nbsp;{PLAN_OFFER[option].storage} GB
													storage&nbsp;&nbsp;
													{PLAN_OFFER[option].isViewersUnlimited && (
														<>
															<UsersFour />
															&nbsp;Unlimited viewers&nbsp;&nbsp;
														</>
													)}
													{PLAN_OFFER[option].isAddOnsAllowed && (
														<>
															<CirclesThreePlus />
															&nbsp;Add ons&nbsp;&nbsp;
														</>
													)}
												</Stack>
											</Box>
											<Typography variant="body2">
												{new Intl.NumberFormat("en-US", {
													style: "currency",
													currency: "USD",
												}).format(PLAN_OFFER[option].price)}
											</Typography>
										</Stack>
									}
									value={option}
								/>
							))}
						</RadioGroup>
					</FormControl>
					<FormControl>
						<InputLabel>Add-ons</InputLabel>
						<FormGroup
							sx={{
								"& .MuiFormControlLabel-root": {
									border: "1px solid var(--mui-palette-divider)",
									borderRadius: 1,
									gap: 2,
									p: 2,
								},
							}}>
							<FormControlLabel
								onChange={(e, checked) =>
									form.setFieldValue(
										"addons.admins",
										checked ? ADDONS_ADMINS[1] : ADDONS_ADMINS[0],
									)
								}
								control={<Checkbox checked={!!form.values.addons.admins} />}
								sx={[
									!!form.values.addons.admins && {
										"&.MuiFormControlLabel-root": {
											border: (t) => `1px solid ${t.palette.primary.main}`,
										},
									},
									{ ".MuiFormControlLabel-label": { flex: 1 } },
								]}
								label={
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between">
										<Box flex={1}>
											<Typography variant="inherit">Admins</Typography>
											<Typography
												variant="caption"
												color="text.secondary">
												Users who can create and manage maps and data
											</Typography>
										</Box>
										<Stack
											direction="row"
											spacing={1}
											alignItems="center">
											<TextField
												select
												{...form.getFieldProps("addons.admins")}>
												{ADDONS_ADMINS.map((option) => (
													<Option
														key={option}
														value={option}>
														{option}
													</Option>
												))}
											</TextField>
											<Typography variant="body2">
												{new Intl.NumberFormat("en-US", {
													style: "currency",
													currency: "USD",
												}).format(470)}
											</Typography>
										</Stack>
									</Stack>
								}
							/>
							<FormControlLabel
								onChange={(e, checked) =>
									form.setFieldValue(
										"addons.editors",
										checked ? ADDONS_EDITORS[1] : ADDONS_EDITORS[0],
									)
								}
								control={<Checkbox checked={!!form.values.addons.editors} />}
								sx={[
									!!form.values.addons.editors && {
										"&.MuiFormControlLabel-root": {
											border: (t) => `1px solid ${t.palette.primary.main}`,
										},
									},
									{ ".MuiFormControlLabel-label": { flex: 1 } },
								]}
								label={
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between">
										<Box flex={1}>
											<Typography variant="inherit">Editors</Typography>
											<Typography
												variant="caption"
												color="text.secondary">
												Users who can edit data in maps
											</Typography>
										</Box>
										<Stack
											direction="row"
											spacing={1}
											alignItems="center">
											<TextField
												select
												{...form.getFieldProps("addons.editors")}>
												{ADDONS_EDITORS.map((option) => (
													<Option
														key={option}
														value={option}>
														{option}
													</Option>
												))}
											</TextField>
											<Typography variant="body2">
												{new Intl.NumberFormat("en-US", {
													style: "currency",
													currency: "USD",
												}).format(470)}
											</Typography>
										</Stack>
									</Stack>
								}
							/>
							<FormControlLabel
								onChange={(e, checked) =>
									form.setFieldValue(
										"addons.maps",
										checked ? ADDONS_MAPS[1] : ADDONS_MAPS[0],
									)
								}
								control={<Checkbox checked={!!form.values.addons.maps} />}
								sx={[
									!!form.values.addons.maps && {
										"&.MuiFormControlLabel-root": {
											border: (t) => `1px solid ${t.palette.primary.main}`,
										},
									},
									{ ".MuiFormControlLabel-label": { flex: 1 } },
								]}
								label={
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between">
										<Box flex={1}>
											<Typography variant="inherit">Maps</Typography>
											<Typography
												variant="caption"
												color="text.secondary">
												Need more maps? Add them to your account
											</Typography>
										</Box>
										<Stack
											direction="row"
											spacing={1}
											alignItems="center">
											<TextField
												select
												{...form.getFieldProps("addons.maps")}>
												{ADDONS_MAPS.map((option) => (
													<Option
														key={option}
														value={option}>
														{option}
													</Option>
												))}
											</TextField>
											<Typography variant="body2">
												{new Intl.NumberFormat("en-US", {
													style: "currency",
													currency: "USD",
												}).format(470)}
											</Typography>
										</Stack>
									</Stack>
								}
							/>
							<FormControlLabel
								onChange={(e, checked) =>
									form.setFieldValue(
										"addons.storage",
										checked ? ADDONS_STORAGE[1] : ADDONS_STORAGE[0],
									)
								}
								control={<Checkbox checked={!!form.values.addons.storage} />}
								sx={[
									!!form.values.addons.storage && {
										"&.MuiFormControlLabel-root": {
											border: (t) => `1px solid ${t.palette.primary.main}`,
										},
									},
									{ ".MuiFormControlLabel-label": { flex: 1 } },
								]}
								label={
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between">
										<Box flex={1}>
											<Typography variant="inherit">Storage</Typography>
											<Typography
												variant="caption"
												color="text.secondary">
												Extend your storage
											</Typography>
										</Box>
										<Stack
											direction="row"
											spacing={1}
											alignItems="center">
											<TextField
												select
												{...form.getFieldProps("addons.storage")}>
												{ADDONS_STORAGE.map((option) => (
													<Option
														key={option}
														value={option}>
														{option} GB
													</Option>
												))}
											</TextField>
											<Typography variant="body2">
												{new Intl.NumberFormat("en-US", {
													style: "currency",
													currency: "USD",
												}).format(470)}
											</Typography>
										</Stack>
									</Stack>
								}
							/>
						</FormGroup>
					</FormControl>
				</Stack>
			</CardContent>
		</Card>
	);
};
