import Badge, { type BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { FC } from "react";

export const StyledBadge: FC<BadgeProps> = styled(Badge)(({ theme }) => ({
	"& .MuiBadge-badge": {
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		right: -8,
		top: "50%",
	},
}));
