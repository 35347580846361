import { AuthError, signIn } from "aws-amplify/auth";
import { useFormik } from "formik";
import {
	AccountSignInFormValues,
	AccountSignInSchema,
	toast,
	AccountSignIn as View,
} from "ui-components";

import { useAuthContext } from "~auth/contexts/auth";

export const AccountSignIn = () => {
	const { refreshSession } = useAuthContext();
	const form = useFormik<AccountSignInFormValues>({
		validateOnMount: true,
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: AccountSignInSchema,
		onSubmit: (values, helpers) => {
			toast.promise(
				async () => {
					const signInRes = await signIn({
						username: values.email,
						password: values.password,
					});

					if (signInRes.nextStep.signInStep === "DONE") {
						toast.success("Redirecting you to portal...");
						await refreshSession();
						return;
					}

					throw new Error("Something went wrong!");
				},
				{
					loading: "Signing you in...",
					error: (e) => {
						helpers.setSubmitting(false);
						if (e instanceof AuthError) return e.message;
						return "Something went wrong!";
					},
				},
			);
		},
	});

	return <View form={form} />;
};
