import {
	Button,
	Chip,
	FormControl,
	FormHelperText,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { paths } from "config/paths";
import { useFormik } from "formik";
import RouterLink from "next/link";
import * as React from "react";
import * as Yup from "yup";

import { MangoLogo } from "~ui-components/components/atoms/mango-logo";
import { SplitLayout } from "~ui-components/components/organisms/split-layout";

export const SignUpVerificationV2Schema = Yup.object({
	code: Yup.string().required("Required"),
});

export type SignUpVerificationV2FormValues = Yup.InferType<
	typeof SignUpVerificationV2Schema
>;

interface SignUpVerificationV2Props {
	email: string;
	onResendEmail: () => void;
	form: ReturnType<typeof useFormik<SignUpVerificationV2FormValues>>;
}

export const SignUpVerificationV2: React.FC<SignUpVerificationV2Props> = ({
	email,
	onResendEmail,
	form,
}) => {
	return (
		<SplitLayout
			title="Get started with Mango"
			subtitle="The accessible and user-friendly alternative to Esri for smart
              spenders."
			caption="Join 6,000+ forward-thinking companies:">
			<Stack spacing={4}>
				<Box
					component={RouterLink}
					href={paths.home}
					sx={{ display: "inline-block", fontSize: 0 }}>
					<MangoLogo
						width={122}
						height={32}
					/>
				</Box>
				<Stack spacing={4}>
					<Stack spacing={1}>
						<Typography variant="h5">
							Verify code&nbsp;
							<Chip
								size="small"
								label="v2"
							/>
						</Typography>
						<Typography
							color="text.secondary"
							variant="body2">
							We&apos;ve just sent a code to {email}. Please check your inbox
							and input the verification code below.
						</Typography>
					</Stack>
					<Stack
						spacing={2}
						component="form"
						onSubmit={form.handleSubmit}>
						<FormControl>
							<InputLabel>Code</InputLabel>
							<OutlinedInput
								autoFocus
								{...form.getFieldProps("code")}
								error={!!form.touched.code && !!form.errors.code}
								autoComplete="off"
							/>
							{!!form.touched.code && !!form.errors.code && (
								<FormHelperText error>{form.errors.code}</FormHelperText>
							)}
						</FormControl>
						<Button
							type="submit"
							variant="contained"
							disabled={form.isSubmitting || !form.isValid}>
							Verify
						</Button>
					</Stack>
					<Stack>
						<Typography
							color="text.secondary"
							variant="body2">
							Didn&apos;t receive the email?
						</Typography>
						<Typography
							color="text.secondary"
							variant="body2">
							Check your spam folder or&nbsp;
							<Link
								href="#"
								variant="subtitle2"
								onClick={onResendEmail}>
								Resend Activation Email
							</Link>
							.
						</Typography>
					</Stack>
				</Stack>
				<Stack spacing={2}></Stack>
			</Stack>
		</SplitLayout>
	);
};
