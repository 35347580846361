import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import { Gear as DetailsIcon } from "@phosphor-icons/react/dist/ssr/Gear";
import { useFormik } from "formik";

import {
	LineQuantityConfigs,
	PointQuantityConfigs,
	PolygonQuantityConfigs,
} from "~ui-components/types/__generated/gql/graphql";

import { QuantityTable } from "./quantity-table";

interface Props {
	form:
		| ReturnType<typeof useFormik<LineQuantityConfigs>>
		| ReturnType<typeof useFormik<PolygonQuantityConfigs>>
		| ReturnType<typeof useFormik<PointQuantityConfigs>>;
}

export const QuantityCustomClassbreak = ({ form }: Props) => {
	return (
		<Card
			sx={{ width: "100%" }}
			component="form"
			onSubmit={form.handleSubmit}>
			<CardHeader
				avatar={
					<Avatar>
						<DetailsIcon fontSize="var(--Icon-fontSize)" />
					</Avatar>
				}
				title="Custom class break"
			/>
			<CardContent>
				<Stack
					direction="row"
					spacing={2}>
					<FormControl>
						<InputLabel sx={{ my: 2 }}>Layout</InputLabel>
						<RadioGroup
							aria-labelledby="map-load-bounds-buttons-group-label"
							value={form.values.legendType}
							name="legendType"
							onChange={form.handleChange}>
							<FormControlLabel
								control={<Radio value="vertical" />}
								label="Vertical"
							/>
							<FormControlLabel
								control={<Radio value="horizontal" />}
								label="Horizontal"
							/>
						</RadioGroup>
					</FormControl>
				</Stack>
				<Stack
					spacing={2}
					sx={{ mt: 2 }}>
					<QuantityTable form={form} />
				</Stack>
			</CardContent>
			<CardActions sx={{ justifyContent: "flex-end" }}>
				<Button color="secondary">Cancel</Button>
				<Button
					variant="contained"
					type="submit">
					Save changes
				</Button>
			</CardActions>
		</Card>
	);
};
