import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import { ChartLine as AnalticsIcon } from "@phosphor-icons/react/dist/ssr/ChartLine";
import { Question as QuestionIcon } from "@phosphor-icons/react/dist/ssr/Question";
import Link from "next/link";

import { BrandLayout as Layout } from "./layout";

export const BrandAnalytics = () => {
	return (
		<Layout>
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<AnalticsIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					action={
						<Link
							href={
								"https://help.mangomap.com/hc/en-us/articles/16476727179289-Google-Analytics-4"
							}
							target="_blank">
							<IconButton aria-label="settings">
								<QuestionIcon />
							</IconButton>
						</Link>
					}
					title="Anaytics"
					subheader="Integrate with your favourite analytics suite in order to get data about map and data views as well as behaviour."
				/>
				<CardContent>
					<Stack spacing={3}>
						<Stack spacing={2}>
							<FormControl>
								<InputLabel>GA4 Measurement ID</InputLabel>
								<OutlinedInput name="portal-name" />
							</FormControl>
						</Stack>
					</Stack>
				</CardContent>
				<CardActions sx={{ justifyContent: "flex-end" }}>
					<Button color="secondary">Cancel</Button>
					<Button variant="contained">Save changes</Button>
				</CardActions>
			</Card>
		</Layout>
	);
};
