import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	Menu,
	MenuItem,
	OutlinedInput,
	Stack,
	Switch,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import { BubbleMenu, Editor } from "@tiptap/react";
import { useFormik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import { BracketsCurly as AttributeIcon } from "@phosphor-icons/react/dist/ssr/BracketsCurly";

import { useDialog } from "~ui-components/hooks/use-dialog";
import { usePopover } from "~ui-components/hooks/use-popover";

export const LinkMenu: React.FC<
	Pick<
		React.ComponentProps<typeof LinkDialog>,
		"hrefAttributes" | "textAttributes"
	> & { editor: Editor }
> = ({ editor, ...props }) => {
	const linkDialog = useDialog<LinkFormValues>();

	return (
		<>
			{linkDialog.open && (
				<LinkDialog
					editor={editor}
					onClose={linkDialog.handleClose}
					initialValues={linkDialog.data}
					{...props}
				/>
			)}
			<BubbleMenu
				editor={editor}
				pluginKey="LinkMenu"
				tippyOptions={{ zIndex: 1 }}
				shouldShow={({ editor }) => editor.isActive("link")}>
				<ToggleButtonGroup
					exclusive
					sx={{ bgcolor: (t) => t.palette.background.paper }}>
					<ToggleButton value="edit">
						<Typography
							variant="caption"
							onClick={() => {
								editor
									.chain()
									.focus()
									.extendMarkRange("link")
									.command(({ tr }) => {
										const linkMark = editor.getAttributes("link");
										const linkText = editor.state.doc.textBetween(
											tr.selection.from,
											tr.selection.to,
										);

										linkDialog.handleOpen({
											text: linkText,
											href: linkMark.href,
											openInNewTab: linkMark.target === "_blank",
										});
										return true;
									});
							}}>
							Edit
						</Typography>
					</ToggleButton>
					<Divider
						flexItem
						orientation="vertical"
						sx={{ my: 1 }}
					/>
					<ToggleButton
						value="remove-link"
						onClick={() => editor.chain().focus().unsetLink().run()}>
						<Typography variant="caption">Remove link</Typography>
					</ToggleButton>
				</ToggleButtonGroup>
			</BubbleMenu>
		</>
	);
};

const linkValidationSchema = Yup.object({
	href: Yup.string().required(),
	text: Yup.string().required(),
	openInNewTab: Yup.boolean().required(),
}).required();

export type LinkFormValues = Yup.InferType<typeof linkValidationSchema>;

export const LinkDialog: React.FC<{
	editor: Editor;
	initialValues?: LinkFormValues;
	onClose: () => void;
	hrefAttributes?: string[];
	textAttributes?: string[];
}> = ({ editor, initialValues, onClose, hrefAttributes, textAttributes }) => {
	const linkForm = useFormik<LinkFormValues>({
		enableReinitialize: true,
		initialValues: initialValues ?? {
			href: "",
			text: "",
			openInNewTab: true,
		},
		validationSchema: linkValidationSchema,
		onSubmit: (values) => {
			const cmds = editor.isActive("link")
				? editor.chain().focus().extendMarkRange("link")
				: editor.chain().focus();

			cmds
				.deleteSelection()
				.setLink({
					href: values.href,
					target: values.openInNewTab ? "_blank" : null,
				})
				.insertContent(values.text)
				.run();

			onClose();
		},
	});
	const hrefPopover = usePopover<HTMLButtonElement>();
	const textPopover = usePopover<HTMLButtonElement>();

	return (
		<Dialog
			open
			fullWidth
			maxWidth="xs"
			onClose={onClose}>
			<form onSubmit={linkForm.handleSubmit}>
				<DialogTitle>Insert link</DialogTitle>
				<DialogContent>
					<Stack gap={3}>
						<FormControl fullWidth>
							<InputLabel>Link URL</InputLabel>
							<OutlinedInput
								{...linkForm.getFieldProps("href")}
								error={!!linkForm.errors.href}
								endAdornment={
									hrefAttributes?.length ? (
										<InputAdornment position="end">
											<IconButton
												sx={{ width: 100 }}
												ref={hrefPopover.anchorRef}
												onClick={hrefPopover.handleOpen}>
												<Box
													display="flex"
													alignItems="center">
													<AttributeIcon />
													<Typography
														variant="body2"
														ml={1}>
														Attribute
													</Typography>
												</Box>
											</IconButton>
											<Menu
												disablePortal
												anchorEl={hrefPopover.anchorRef.current}
												open={hrefPopover.open}
												onClose={hrefPopover.handleClose}
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "left",
												}}
												transformOrigin={{
													vertical: "top",
													horizontal: "left",
												}}>
												{hrefAttributes.map((attribute) => (
													<MenuItem
														key={attribute}
														onClick={() => {
															void linkForm.setFieldValue(
																"href",
																linkForm.values.href.concat(`{${attribute}}`),
															);
															hrefPopover.handleClose();
														}}>
														{attribute}
													</MenuItem>
												))}
											</Menu>
										</InputAdornment>
									) : null
								}
							/>
							{!!linkForm.errors.href && (
								<FormHelperText error>{linkForm.errors.href}</FormHelperText>
							)}
						</FormControl>
						<FormControl fullWidth>
							<InputLabel>Link text</InputLabel>
							<OutlinedInput
								{...linkForm.getFieldProps("text")}
								error={!!linkForm.errors.text}
								endAdornment={
									textAttributes?.length ? (
										<InputAdornment position="end">
											<IconButton
												sx={{ width: 100 }}
												ref={textPopover.anchorRef}
												onClick={textPopover.handleOpen}>
												<Box
													display="flex"
													alignItems="center">
													<AttributeIcon />
													<Typography
														variant="body2"
														ml={1}>
														Attribute
													</Typography>
												</Box>
											</IconButton>
											<Menu
												disablePortal
												anchorEl={textPopover.anchorRef.current}
												open={textPopover.open}
												onClose={textPopover.handleClose}
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "left",
												}}
												transformOrigin={{
													vertical: "top",
													horizontal: "left",
												}}>
												{textAttributes.map((attribute) => (
													<MenuItem
														key={attribute}
														onClick={() => {
															void linkForm.setFieldValue(
																"text",
																linkForm.values.text.concat(`{${attribute}}`),
															);
															textPopover.handleClose();
														}}>
														{attribute}
													</MenuItem>
												))}
											</Menu>
										</InputAdornment>
									) : null
								}
							/>
							{!!linkForm.errors.text && (
								<FormHelperText error>{linkForm.errors.text}</FormHelperText>
							)}
						</FormControl>
						<FormControl fullWidth>
							<InputLabel>Open in new tab</InputLabel>
							<Switch
								checked={linkForm.values.openInNewTab}
								onChange={(_, bool) =>
									linkForm.setFieldValue("openInNewTab", bool)
								}
							/>
						</FormControl>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={onClose}
						color="inherit">
						Cancel
					</Button>
					<Button
						variant="contained"
						color="error"
						type="submit">
						Save
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
