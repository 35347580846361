import { Alert, Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { Polygon } from "@phosphor-icons/react/dist/ssr";

export const FeatureEditGeometry = () => {
	return (
		<>
			<Box
				flex={1}
				p={2}>
				<Stack spacing={2}>
					<Alert severity="info">
						Geometry edits are applied to source data and will be reflected in
						all maps using this data.
					</Alert>
					<Stack
						spacing={2}
						padding={2}
						alignItems={"center"}>
						<Avatar variant="rounded">
							<Polygon
								fontSize="var(--Icon-fontSize)"
								fontWeight="bold"
							/>
						</Avatar>
						<Typography
							variant="body2"
							color={"text.secondary"}>
							Drag handle or marker to edit feature geometry
						</Typography>
					</Stack>
				</Stack>
			</Box>
			<Stack
				direction={"row-reverse"}
				spacing={3}
				p={2}>
				<Button
					color="primary"
					variant="contained">
					Save changes
				</Button>
				<Button color="primary">Cancel</Button>
			</Stack>
		</>
	);
};
