import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";

import { PortalActionCard } from "~ui-components/components/molecules/portal-action-card";

import { ItemCard } from "./items/item-card";

import { MapsViewProps } from ".";

export function MapsPortalLargeView(props: MapsViewProps): React.JSX.Element {
	const {
		isLoading,
		items,
		onItemDuplicate,
		onItemLock,
		onItemDelete,
		onCreateMap,
	} = props;

	return (
		<Box
			sx={{
				display: "grid",
				gap: 4,
				gridTemplateColumns: {
					xs: "1fr",
					sm: "repeat(1, 1fr)",
					md: "repeat(2, 1fr)",
				},
			}}>
			<PortalActionCard
				title="Create Map"
				onClick={() => onCreateMap?.()}
			/>
			{isLoading && (
				<Box sx={{ p: 2 }}>
					<CircularProgress />
				</Box>
			)}

			{Array.from(items.values()).map((item, index) => (
				<ItemCard
					size="large"
					key={index}
					item={item}
					onLock={onItemLock}
					onDuplicate={onItemDuplicate}
					onDelete={onItemDelete}
				/>
			))}
		</Box>
	);
}
