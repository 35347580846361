import { Box } from "@mui/material";
import { FC, ReactNode } from "react";

import { MapToolDrawer } from "~ui-components/components/molecules/map-tool-drawer";

export interface MapViewProps {
	slots: {
		Map: ReactNode;
		MapTools: ReactNode;
		DrawerContent?: ReactNode;
	};
}

export const MapView: FC<MapViewProps> = ({
	slots: { Map, MapTools, DrawerContent },
}) => {
	return (
		<Box
			height="100dvh"
			minHeight="600px"
			position="relative"
			sx={{ overflowY: "hidden" }}>
			{MapTools}
			<Box
				position="absolute"
				zIndex={3}
				sx={{
					left: DrawerContent ? 0 : -400,
					transition: "all .25s",
				}}>
				{DrawerContent ?? (
					<MapToolDrawer
						title="Placeholder"
						Content={null}
						onClose={console.log}
					/>
				)}
			</Box>
			{Map}
		</Box>
	);
};
