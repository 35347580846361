import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export interface StepIconProps {
	number: number;
}

export function StepIcon({ number }: StepIconProps): React.JSX.Element {
	return (
		<Box
			sx={{
				alignItems: "center",
				border: "1px solid var(--mui-palette-divider)",
				borderRadius: "50%",
				display: "flex",
				height: "40px",
				justifyContent: "center",
				width: "40px",
			}}>
			<Typography variant="h6">{number}</Typography>
		</Box>
	);
}
