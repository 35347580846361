import Box from "@mui/material/Box";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

interface Slots {
	MapSettings: any;
	Layers: any;
	LayerSettings: any;
}

const Empty = () => null;

const defaultSlots: Slots = {
	MapSettings: Empty,
	Layers: Empty,
	LayerSettings: Empty,
};

interface Props {
	slots?: Slots;
}

function MapEditor(props: Props) {
	return (
		<DashboardContentLayout disableBackButton={true}>
			<Root {...props} />
		</DashboardContentLayout>
	);
}

function Root(props: Props) {
	const { slots = {} } = props;

	const Slots = {
		...defaultSlots,
		...slots,
	};

	return (
		<Box>
			<Slots.MapSettings />
			<Slots.Layers />
			<Slots.LayerSettings />
		</Box>
	);
}

export { MapEditor };
