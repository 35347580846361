import { Box, Button, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { Globe as WhiteLabelIcon } from "@phosphor-icons/react/dist/ssr/Globe";
import { Plugs as PlugsIcon } from "@phosphor-icons/react/dist/ssr/Plugs";
import { PlugsConnected as PlugsConnectedIcon } from "@phosphor-icons/react/dist/ssr/PlugsConnected";
import { useState } from "react";

import { ConfirmationModal } from "~ui-components/index";

import { BrandLayout as Layout } from "./layout";

export const BrandDomainAndWhiteLabel = () => {
	const [removeMangoBrandingChecked, setRemoveMangoBrandingChecked] =
		useState(false);
	const [useDomainChecked, SetUseDomainChecked] = useState(false);
	const [customDomain, setCustomDomain] = useState("");
	const [openModal, setOpenModal] = useState(false);

	const handleRemoveMangoBrandhingChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setRemoveMangoBrandingChecked(event.target.checked);
	};

	const handleUseDomainChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		SetUseDomainChecked(event.target.checked);
	};

	const isCustomDomainConnected = customDomain.length > 0;

	const handleCustomDomainConnect = () => {
		if (isCustomDomainConnected) {
			setCustomDomain("");
		} else {
			setOpenModal(true);
		}
	};

	return (
		<Layout pageTitle="Domain & whitelabel">
			<ConfirmationModal
				open={openModal}
				title="Error connection domain"
				content="We couldn’t find an A Record. Please check your DNS settings and try again."
				actionText="Permanently delete"
				onClose={() => setOpenModal(false)}
				onConfirm={() => setCustomDomain("subdomain.example.com")}
			/>
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<WhiteLabelIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Enhance trust and elevate your brand by displaying maps under your own web address with full white-labeling"
				/>
				<CardContent>
					<Stack spacing={3}>
						<FormControl>
							<FormControlLabel
								control={
									<Switch
										checked={removeMangoBrandingChecked}
										onChange={handleRemoveMangoBrandhingChange}
									/>
								}
								label="Remove Mango branding"
							/>
						</FormControl>
						<FormControl>
							<FormControlLabel
								control={
									<Switch
										checked={useDomainChecked}
										onChange={handleUseDomainChange}
									/>
								}
								label="Use your own domain"
							/>
						</FormControl>
						{useDomainChecked && (
							<>
								<Divider />
								{!isCustomDomainConnected && (
									<Typography
										variant="body2"
										color="text.primary">
										In your domain’s DNS settings, add a new A-Record with the
										value 174.129.28.51
									</Typography>
								)}
								<Card>
									<CardContent
										component={Box}
										alignItems={"center"}
										alignContent={"center"}>
										<Stack
											direction="row"
											justifyContent={"space-between"}
											marginTop={1}>
											<Stack
												spacing={2}
												direction="row">
												{isCustomDomainConnected ? (
													<Button
														startIcon={<PlugsConnectedIcon />}
														variant="text"
														color="success">
														Domain connected
													</Button>
												) : (
													<Stack>
														<Typography
															variant="subtitle1"
															color="text.primary">
															Custom domain
														</Typography>
														<Typography
															variant="caption"
															color="text.secondary">
															Don&apos;t include https:// or www
														</Typography>
													</Stack>
												)}
												<FormControl variant="outlined">
													<OutlinedInput
														name="custom-domain"
														placeholder="example.com"
														label="Custom domain"
														value={customDomain}
													/>
												</FormControl>
											</Stack>
											<Button
												startIcon={<PlugsIcon />}
												variant="outlined"
												onClick={handleCustomDomainConnect}>
												{isCustomDomainConnected
													? "Disconnect"
													: "Connect to Mango"}
											</Button>
										</Stack>
									</CardContent>
								</Card>
							</>
						)}
					</Stack>
				</CardContent>
			</Card>
		</Layout>
	);
};
