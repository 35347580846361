import { resetPassword } from "aws-amplify/auth";
import { paths } from "config/paths";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import {
	ForgotPasswordV2FormValues,
	ForgotPasswordV2Schema,
	toast,
	ForgotPasswordV2 as View,
} from "ui-components";

export const ForgotPasswordV2 = () => {
	const router = useRouter();

	const form = useFormik<ForgotPasswordV2FormValues>({
		validateOnMount: true,
		initialValues: {
			email: "",
		},
		validationSchema: ForgotPasswordV2Schema,
		onSubmit: (values, helpers) => {
			toast.promise(
				async () => {
					await resetPassword({ username: values.email });
					void router.push(paths.updatePasswordV2(values.email));
				},
				{
					loading: "Sending code...",
					success:
						"If the email is linked to an account, reset instructions will be sent shortly.",
					error: (e) => {
						helpers.setSubmitting(false);
						return e.message;
					},
				},
			);
		},
	});

	return <View form={form} />;
};
