import { FeatureCollection } from "geojson";
import _ from "lodash";

import {
	getAllGeojsonFeatureProperties,
	getTypeOfEachProperties,
} from "~utils/functions/geojson";

const getProperties = (geojson: FeatureCollection) => {
	// INFO: Map geojson features' properties to structured map properties
	const arrayOfProperties = getAllGeojsonFeatureProperties(geojson);
	const geojsonProperties = _.values(
		getTypeOfEachProperties(arrayOfProperties),
	);
	// const properties = getPropertiesConfigs(geojsonProperties);

	return geojsonProperties;
};

export { getProperties };
