import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { ArrowLeft as ArrowLeftIcon } from "@phosphor-icons/react/dist/ssr/ArrowLeft";
import { useFormik } from "formik";
import * as React from "react";

import { Option } from "~ui-components/components/atoms/option";
import { TextEditor } from "~ui-components/components/atoms/text-editor/text-editor";

import { CalculatorPopupInput } from "./index";

export interface JobDescriptionStepProps {
	form: ReturnType<typeof useFormik<CalculatorPopupInput>>;
	onBack?: () => void;
	onNext?: () => void;
	attributes: string[];
}

export function Caculation({
	form,
	onBack,
	onNext,
	attributes,
}: JobDescriptionStepProps): React.JSX.Element {
	const { handleChange } = form;
	const [content, setContent] = React.useState<string>("");

	return (
		<Stack spacing={4}>
			<Stack
				spacing={2}
				direction="row">
				<Stack
					spacing={2}
					sx={{ width: "100%" }}>
					<Stack spacing={3}>
						<InputLabel>Popup content</InputLabel>
						<TextEditor
							placeholder="Write something"
							toolsProps={{
								LinkInput: {
									hrefAttributes: attributes,
									textAttributes: attributes,
								},
								AttributeSelect: { attributes },
								Equation: { attributes },
								UserInputSelect: { options: form.values.formFields },
								Table: true,
								TextAlign: true,
								HorizontalRule: true,
							}}
							content={content}
							onUpdate={({ editor }) => {
								setContent(editor.getHTML());
								form.handleChange({
									target: { name: "output", value: editor.getHTML() },
								});
							}}
						/>
						<FormControl fullWidth>
							<InputLabel>Equation formatting</InputLabel>
							<Select
								onChange={handleChange}
								value={form.values.calculatorFormatting}
								name="calculatorFormatting">
								<Option
									key="none"
									value={0}>
									None
								</Option>
								<Option
									key="one-decimal-place"
									value={1}>
									One decimal place
								</Option>
								<Option
									key="two-decimal-place"
									value={2}>
									Tow decimal place
								</Option>
							</Select>
						</FormControl>
					</Stack>
				</Stack>
				<Stack sx={{ ml: 3, width: "100%" }}>
					<InputLabel>Input preview</InputLabel>
					<Card
						sx={{
							display: "flex",
							flexDirection: "column",
							mt: 1,
							borderRadius: 1,
							flexGrow: 1,
						}}>
						<Stack
							sx={{
								p: 1,
								alignItems: "center",
								backgroundColor: "var(--mui-palette-background-dark)",
							}}
							direction="row"
							spacing={2}>
							{form.values.title || "Title"}
						</Stack>
						<Divider />
						<CardContent sx={{ flexGrow: 1 }}>
							<Stack
								sx={{ height: "100%" }}
								direction="column"
								spacing={2}>
								<Box sx={{ flexGrow: 1 }}>
									{form.values.description}
									<Box>{content}</Box>
								</Box>
								{form.values.buttonText && (
									<Button
										sx={{ width: "200px", alignSelf: "flex-end" }}
										variant="contained"
										color="secondary">
										{form.values.buttonText}
									</Button>
								)}
							</Stack>
						</CardContent>
					</Card>
				</Stack>
			</Stack>
			<Stack
				direction="row"
				spacing={2}
				sx={{ alignItems: "center", justifyContent: "flex-end" }}>
				<Button
					color="secondary"
					onClick={onBack}
					startIcon={<ArrowLeftIcon />}>
					Back
				</Button>
				<Button
					onClick={onNext}
					variant="contained">
					Save Changes
				</Button>
			</Stack>
		</Stack>
	);
}
