import { createServerRunner } from "@aws-amplify/adapter-nextjs";

export const serverRunner = createServerRunner({
	config: {
		API: {
			GraphQL: {
				endpoint: process.env.API_URL!,
				defaultAuthMode: "apiKey",
				apiKey: process.env.API_KEY,
			},
		},
		Auth: {
			Cognito: {
				userPoolId: process.env.AUTH_USER_POOL_ID!,
				userPoolClientId: process.env.AUTH_USER_POOL_CLIENT_WEB_ID!,
				signUpVerificationMethod: "code",
			},
		},
	},
});
