import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { DotsThree as MenuIcon } from "@phosphor-icons/react/dist/ssr/DotsThree";
import { Info as InfoIcon } from "@phosphor-icons/react/dist/ssr/Info";
import { FC } from "react";

import { usePopover } from "~ui-components/hooks/use-popover";
import { LayerGroup } from "~ui-components/types/__generated/gql/graphql";

import { LayerGroupContextMenu } from "./layer-group-context-menu";
import { LayersComponent } from "./layers";
import { StyledBadge } from "./styled-badge";

interface LayerGroupProps {
	layerGroup: LayerGroup;
	onAddLayer: (id: string, layerId: string) => void;
	onDragEnd: (result: any) => void;
	onLayerGroupVisibilityChange: (id: string, visible: boolean) => void;
	onAddLayerGroup: (id: string, position: "above" | "below") => void;
	onDeleteLayerGroup: (id: string) => void;
	onSelectLayer: (layerId: string) => void;
	onRemoveLayer: (layerId: string) => void;
	disableDelete: boolean;
}

export const LayerGroupComponent: FC<LayerGroupProps> = ({
	layerGroup,
	onAddLayer,
	onSelectLayer,
	onLayerGroupVisibilityChange,
	onDragEnd,
	onRemoveLayer,
	onDeleteLayerGroup,
	disableDelete,
	onAddLayerGroup,
}) => {
	const popover = usePopover<HTMLButtonElement>();

	return (
		<Card sx={{ mb: 1 }}>
			<CardHeader
				title={
					<Stack
						direction="row"
						spacing={2}
						alignItems="center">
						<Stack>
							<Tooltip
								title={
									layerGroup.visible
										? "This group will be shown when map loads"
										: "This group will be hidden when map loads"
								}
								placement="top-start">
								<StyledBadge
									overlap="circular"
									variant="dot"
									color={layerGroup.visible ? "success" : "secondary"}>
									Layer Group
								</StyledBadge>
							</Tooltip>
						</Stack>
						<IconButton>
							<InfoIcon size={24} />
						</IconButton>
					</Stack>
				}
				action={
					<IconButton
						onClick={popover.handleOpen}
						ref={popover.anchorRef}>
						<MenuIcon size={24} />
					</IconButton>
				}
			/>
			<CardContent>
				<LayersComponent
					layerGroup={layerGroup}
					onDragEnd={onDragEnd}
					onSelectLayer={onSelectLayer}
					onRemoveLayer={onRemoveLayer}
				/>
				<LayerGroupContextMenu
					anchorEl={popover.anchorRef.current}
					onClose={popover.handleClose}
					open={popover.open}
					onVisibilityChange={(visible) =>
						onLayerGroupVisibilityChange(layerGroup.id, visible)
					}
					isEmptyLayerGroup={layerGroup.layerCollection.items.length === 0}
					visible={layerGroup.visible}
					onAddLayer={() => onAddLayer(layerGroup.id, "")}
					onDelete={() => onDeleteLayerGroup(layerGroup.id)}
					disableDelete={disableDelete}
					onAddLayerGroup={(position) =>
						onAddLayerGroup(layerGroup.id, position)
					}
				/>
			</CardContent>
		</Card>
	);
};
