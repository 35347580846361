import {
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Stack,
	Typography,
} from "@mui/material";
import { ArrowLeft } from "@phosphor-icons/react/dist/ssr";
import { FC } from "react";

import { MapToolDrawer } from "../map-tool-drawer";
import { Search, SearchProps } from "../map-tools";

export interface SearchDrawerProps {
	onClose: () => void;
	searchProps: SearchProps;
	searchResults: SearchResultsListProps["results"];
}

export const SearchDrawer: FC<SearchDrawerProps> = ({
	onClose,
	searchProps,
	searchResults,
}) => {
	return (
		<MapToolDrawer
			Content={
				<Stack
					spacing={2}
					p={2}>
					<Stack
						direction="row"
						spacing={1}>
						<IconButton onClick={onClose}>
							<ArrowLeft />
						</IconButton>
						<Search {...searchProps} />
					</Stack>
					<SearchResultsList results={searchResults} />
				</Stack>
			}
		/>
	);
};

interface SearchResultsListProps {
	results: { title: string; subtitle: string }[];
}
const SearchResultsList: FC<SearchResultsListProps> = ({ results }) => {
	return (
		<List>
			{results.map((result, index) => (
				<>
					<ListItem
						key={result.title}
						sx={{ px: 0 }}>
						<ListItemText
							primary={
								<Typography
									variant="body2"
									fontWeight={600}>
									{result.title}
								</Typography>
							}
							secondary={
								<Typography
									variant="body2"
									color="text.secondary">
									{result.subtitle}
								</Typography>
							}
						/>
					</ListItem>
					{index !== results.length - 1 && <Divider component="li" />}
				</>
			))}
		</List>
	);
};
