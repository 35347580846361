import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { PencilSimple as PencilSimpleIcon } from "@phosphor-icons/react/dist/ssr/PencilSimple";
import { TrashSimple as TrashSimpleIcon } from "@phosphor-icons/react/dist/ssr/TrashSimple";
import { paths } from "config/paths";
import RouterLink from "next/link";
import { useRouter } from "next/router";
import * as React from "react";
import { useGetCurrentUserAsserted } from "~auth/queries/useGetCurrentUser";
import {
	ColumnDef,
	DataTable,
} from "~ui-components/components/atoms/data-table";
import { ConfirmationModal } from "~ui-components/index";
import {
	ACCOUNT_USER_ROLE,
	AccountUser,
} from "~ui-components/types/__generated/gql/graphql";

export interface UsersTableProps {
	rows: AccountUser[];
	onItemDelete?: (item: AccountUser) => void;
}

export function UsersTable(props: UsersTableProps) {
	const router = useRouter();

	const { rows, onItemDelete } = props;

	const [open, setOpen] = React.useState(false);
	const [item, setItem] = React.useState<AccountUser | null>(null);

	const { data: user } = useGetCurrentUserAsserted();

	const columns = React.useMemo(
		() =>
			[
				{
					formatter: (row): React.JSX.Element => (
						<Stack
							direction="row"
							spacing={1}
							sx={{ alignItems: "center" }}>
							<div>
								{row._user.isActivated ? (
									<Typography
										color="text.primary"
										sx={{ whiteSpace: "nowrap" }}
										variant="subtitle2">
										{row._user.name}
									</Typography>
								) : (
									<Chip
										label="PENDING ACTIVATION"
										color="secondary"
										size="small"
									/>
								)}
								<Typography
									color="text.secondary"
									variant="body2">
									{row._user.email}
								</Typography>
							</div>
						</Stack>
					),
					name: "Name",
					width: "250px",
				},
				{
					formatter: (row): React.JSX.Element => (
						<Chip
							label={row.role}
							color="primary"
						/>
					),
					name: "Role",
					width: "250px",
				},
				{
					formatter: (row): React.JSX.Element => (
						<Typography
							variant="body2"
							color="text.primary">
							{row._user._userGroupMembers
								.map((o) => o._userGroup.name)
								.join(", ")}
						</Typography>
					),
					name: "Groups",
					width: "250px",
				},
				{
					formatter: (row): React.JSX.Element => (
						<Stack
							spacing={1}
							direction="row"
							justifyContent="flex-end">
							<IconButton
								component={RouterLink}
								href={paths.accountName.users.edit(
									router.query["account-name"],
									row.id,
								)}>
								<PencilSimpleIcon />
							</IconButton>
							{row.role !== ACCOUNT_USER_ROLE.OWNER &&
								row._user.id !== user.id && (
									<IconButton onClick={() => handleClick(row)}>
										<TrashSimpleIcon />
									</IconButton>
								)}
						</Stack>
					),
					width: "100px",
					align: "right",
				},
			] as ColumnDef<AccountUser>[],

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[router.query["account-name"]],
	);

	const handleClick = (row) => {
		setOpen(true);
		setItem(row);
	};

	const handleClose = () => {
		setOpen(false);
		setItem(null);
	};

	const handleConfirm = () => {
		if (item) onItemDelete?.(item);
		handleClose();
	};

	return (
		<React.Fragment>
			<ConfirmationModal
				open={open}
				title="Permanently delete this user?"
				content="This user's access will be immediately revoked."
				actionText="Permanently delete"
				onClose={handleClose}
				onConfirm={handleConfirm}
			/>

			<DataTable
				columns={columns}
				rows={rows}
			/>

			{!rows.length ? (
				<Box sx={{ p: 3 }}>
					<Typography
						color="text.secondary"
						sx={{ textAlign: "center" }}
						variant="body2">
						No users found
					</Typography>
				</Box>
			) : null}
		</React.Fragment>
	);
}
