import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";

interface Props {
	popupType?: string;
}

export const NoPoup = (props: Props) => {
	const { popupType } = props;

	if (popupType === "none")
		return (
			<CardActions sx={{ justifyContent: "flex-end", m: 0, p: 0, mt: 2 }}>
				<Button color="secondary">Cancel</Button>
				<Button
					variant="contained"
					onClick={() => console.log("submit no popup")}>
					Save changes
				</Button>
			</CardActions>
		);
	return null;
};
