import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import { FC, useMemo } from "react";
import countries from "i18n-iso-countries";

import { Option } from "~ui-components/components/atoms/option";

import { AccountBillingFormValues } from ".";

interface CountriesSelectFieldProps {
	form: ReturnType<typeof useFormik<AccountBillingFormValues>>;
	name: string;
	label: string;
	md?: number;
}

export const CountriesSelectField: FC<CountriesSelectFieldProps> = ({
	form,
	name,
	label,
	md = 6,
}) => {
	const { errors, getFieldProps } = form;

	const options = useMemo(
		() =>
			Object.entries(countries.getNames("en")).map((entry) => ({
				id: entry[0],
				name: entry[1],
				value: entry[0],
			})),
		[],
	);

	return (
		<Grid
			md={md}
			xs={12}>
			<FormControl
				fullWidth
				error={Boolean(errors[name])}>
				<TextField
					{...getFieldProps(name)}
					select
					label={label}
					helperText={form.errors.country}
					error={!!form.errors.country}>
					{options.map((option) => (
						<Option
							key={option.id}
							value={option.value}>
							{option.name}
						</Option>
					))}
				</TextField>
			</FormControl>
		</Grid>
	);
};
