import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	Menu,
	MenuItem,
	OutlinedInput,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import { BracketsCurly as AttributeIcon } from "@phosphor-icons/react/dist/ssr/BracketsCurly";
import { BubbleMenu, Editor } from "@tiptap/react";
import { useFormik } from "formik";
import * as React from "react";
import * as Yup from "yup";

import { useDialog } from "~ui-components/hooks/use-dialog";
import { usePopover } from "~ui-components/hooks/use-popover";

export const ImageMenu: React.FC<
	Pick<React.ComponentProps<typeof ImageDialog>, "srcAttributes"> & {
		editor: Editor;
	}
> = ({ editor, ...props }) => {
	const imageDialog = useDialog<ImageFormValues>();

	return (
		<>
			{imageDialog.open && (
				<ImageDialog
					editor={editor}
					onClose={imageDialog.handleClose}
					initialValues={imageDialog.data}
					{...props}
				/>
			)}
			<BubbleMenu
				editor={editor}
				pluginKey="ImageMenu"
				tippyOptions={{ zIndex: 1 }}
				shouldShow={({ editor }) =>
					editor.isActive("image", {
						"data-value": new RegExp(`"type":"${IMAGE_DATE_VALUE_TYPE}"`),
					})
				}>
				<ToggleButtonGroup
					exclusive
					sx={{ bgcolor: (t) => t.palette.background.paper }}>
					<ToggleButton value="edit">
						<Typography
							variant="caption"
							onClick={() => {
								const imageNode = editor.getAttributes("image");
								const dataValue = imageNode["data-value"]
									? JSON.parse(imageNode["data-value"])
									: {};
								imageDialog.handleOpen(dataValue);
							}}>
							Edit
						</Typography>
					</ToggleButton>
					<Divider
						flexItem
						orientation="vertical"
						sx={{ my: 1 }}
					/>
					<ToggleButton
						value="remove-image"
						onClick={() => editor.chain().focus().deleteSelection().run()}>
						<Typography variant="caption">Remove image</Typography>
					</ToggleButton>
				</ToggleButtonGroup>
			</BubbleMenu>
		</>
	);
};

const imageValidationSchema = Yup.object({
	src: Yup.string().required(),
}).required();

export type ImageFormValues = Yup.InferType<typeof imageValidationSchema>;

export const IMAGE_DATE_VALUE_TYPE = "image";

export const ImageDialog: React.FC<{
	editor: Editor;
	initialValues?: ImageFormValues;
	onClose: () => void;
	srcAttributes?: string[];
}> = ({ editor, initialValues, onClose, srcAttributes }) => {
	const imageForm = useFormik<ImageFormValues>({
		enableReinitialize: true,
		initialValues: initialValues ?? {
			src: "",
		},
		validationSchema: imageValidationSchema,
		onSubmit: (values) => {
			editor
				.chain()
				.focus()
				.setImage({
					src: "https://placehold.co/1280x720?text=image+placeholder",
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					"data-value": JSON.stringify({
						...values,
						type: IMAGE_DATE_VALUE_TYPE,
					}),
				})
				.run();
			onClose();
		},
	});
	const srcPopover = usePopover<HTMLButtonElement>();

	return (
		<Dialog
			open
			fullWidth
			maxWidth="xs"
			onClose={onClose}>
			<form onSubmit={imageForm.handleSubmit}>
				<DialogTitle>Insert image</DialogTitle>
				<DialogContent>
					<FormControl fullWidth>
						<InputLabel>Image URL</InputLabel>
						<OutlinedInput
							{...imageForm.getFieldProps("src")}
							error={!!imageForm.errors.src}
							endAdornment={
								srcAttributes?.length ? (
									<InputAdornment position="end">
										<IconButton
											sx={{ width: 100 }}
											ref={srcPopover.anchorRef}
											onClick={srcPopover.handleOpen}>
											<Box
												display="flex"
												alignItems="center">
												<AttributeIcon />
												<Typography
													variant="body2"
													ml={1}>
													Attribute
												</Typography>
											</Box>
										</IconButton>
										<Menu
											disablePortal
											anchorEl={srcPopover.anchorRef.current}
											open={srcPopover.open}
											onClose={srcPopover.handleClose}
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "left",
											}}
											transformOrigin={{
												vertical: "top",
												horizontal: "left",
											}}>
											{srcAttributes.map((attribute) => (
												<MenuItem
													key={attribute}
													onClick={() => {
														void imageForm.setFieldValue(
															"src",
															imageForm.values.src.concat(`{${attribute}}`),
														);
														srcPopover.handleClose();
													}}>
													{attribute}
												</MenuItem>
											))}
										</Menu>
									</InputAdornment>
								) : null
							}
						/>
						{!!imageForm.errors.src && (
							<FormHelperText error>{imageForm.errors.src}</FormHelperText>
						)}
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={onClose}
						color="inherit">
						Cancel
					</Button>
					<Button
						variant="contained"
						color="error"
						type="submit">
						Save
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
