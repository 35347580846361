import Stack from "@mui/material/Stack";
import React, { FC } from "react";

export interface PortalLayoutProps {
	slots: {
		BannerHeadline?: React.ElementType;
		RowView?: React.ElementType;
		Filters?: React.ElementType;
		MediumView?: React.ElementType;
		LargeView?: React.ElementType;
	};
}

const Empty = () => <></>;

export const PortalLayout: FC<PortalLayoutProps> = ({
	slots: {
		BannerHeadline = Empty,
		RowView = Empty,
		Filters = Empty,
		MediumView = Empty,
		LargeView = Empty,
	} = {},
}) => {
	return (
		<Stack spacing={4}>
			<BannerHeadline />
			<Filters />
			<RowView />
			<MediumView />
			<LargeView />
		</Stack>
	);
};
