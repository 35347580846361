import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { Rows as RowsIcon } from "@phosphor-icons/react/dist/ssr/Rows";
import * as React from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

import {
	ToolsInformationSidebarForm,
	ToolsInformationSidebarFormProps,
} from "./tools-information-sidebar-form";

interface ToolsInformationSidebarProps {
	form: ToolsInformationSidebarFormProps["form"];
}

export function ToolsInformationSidebar(
	props: ToolsInformationSidebarProps,
): React.JSX.Element {
	const { form } = props;

	return (
		<DashboardContentLayout pageTitle="Sidebar">
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<RowsIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Display a collapsible sidebar with rich content enhancing understanding and usability of your map"
				/>
				<ToolsInformationSidebarForm form={form} />
			</Card>
		</DashboardContentLayout>
	);
}

export * from "./tools-information-sidebar-form";
