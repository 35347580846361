import * as React from "react";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import * as yup from "yup";
import { FormikProps } from "formik";

import { ButtonCancel } from "~ui-components/components/atoms/button-cancel";

export const ToolsMeasureSchema = yup.object({
	measureType: yup
		.string()
		.notOneOf([""], "Required")
		.oneOf(["metric", "imperial"])
		.required("Required"),
});

export type ToolsMeasureFormValues = yup.InferType<typeof ToolsMeasureSchema>;

export interface ToolsMeasureFormProps {
	form: FormikProps<ToolsMeasureFormValues>;
}

export function ToolsMeasureForm(
	props: ToolsMeasureFormProps,
): React.JSX.Element {
	const { form } = props;
	return (
		<form onSubmit={form.handleSubmit}>
			<CardContent>
				<Stack spacing={4}>
					<Stack spacing={3}>
						<Grid
							container
							spacing={3}>
							<Grid xs={12}>
								<FormControl fullWidth>
									<Stack
										component={RadioGroup}
										spacing={3}
										defaultValue=""
										{...form.getFieldProps("measureType")}>
										<FormControlLabel
											control={<Radio />}
											label=""
											value=""
											sx={{ display: "none" }}
										/>
										<FormControlLabel
											control={<Radio />}
											label="Metric"
											value="metric"
										/>
										<FormControlLabel
											control={<Radio size="medium" />}
											label="Imperial"
											value="imperial"
										/>
									</Stack>
								</FormControl>
							</Grid>
						</Grid>
					</Stack>
				</Stack>
			</CardContent>
			<CardActions sx={{ justifyContent: "flex-end" }}>
				<ButtonCancel />
				<Button
					type="submit"
					variant="contained"
					disabled={!form.isValid}>
					Save changes
				</Button>
			</CardActions>
		</form>
	);
}
