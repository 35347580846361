import { FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { paths } from "config/paths";
import { useFormik } from "formik";
import RouterLink from "next/link";
import { FC } from "react";
import * as Yup from "yup";

import { MangoLogo } from "~ui-components/components/atoms/mango-logo";
import { SplitLayout } from "~ui-components/components/organisms/split-layout";

export const SignInSchema = Yup.object({
	email: Yup.string().email("Invalid email").required("Required"),
	password: Yup.string().required("Required"),
});

export type SignInFormValues = Yup.InferType<typeof SignInSchema>;

export interface SignInProps {
	form: ReturnType<typeof useFormik<SignInFormValues>>;
}

export const SignIn: FC<SignInProps> = ({ form }) => (
	<SplitLayout
		title="Welcome to Mango"
		subtitle="The accessible and user-friendly alternative to Esri for smart
          spenders.">
		<Stack
			spacing={4}
			component="form"
			onSubmit={form.handleSubmit}>
			<div>
				<Box
					component={RouterLink}
					href={paths.home}
					sx={{ display: "inline-block", fontSize: 0 }}>
					<MangoLogo
						width={122}
						height={32}
					/>
				</Box>
			</div>
			<Stack spacing={1}>
				<Typography variant="h5">Sign in</Typography>
				<Typography
					color="text.secondary"
					variant="body2">
					Don&apos;t have an account?&nbsp;
					<Link
						href={paths.signUp}
						variant="subtitle2">
						Sign up
					</Link>
				</Typography>
			</Stack>
			<Stack spacing={2}>
				<Stack spacing={2}>
					<FormControl>
						<InputLabel>Email address</InputLabel>
						<OutlinedInput
							autoFocus
							type="email"
							{...form.getFieldProps("email")}
							error={!!form.touched.email && !!form.errors.email}
						/>
						{!!form.touched.email && !!form.errors.email && (
							<FormHelperText error>{form.errors.email}</FormHelperText>
						)}
					</FormControl>
					<FormControl>
						<InputLabel>Password</InputLabel>
						<OutlinedInput
							type="password"
							{...form.getFieldProps("password")}
							error={!!form.touched.password && !!form.errors.password}
						/>
						{!!form.touched.password && !!form.errors.password && (
							<FormHelperText error>{form.errors.password}</FormHelperText>
						)}
					</FormControl>
					<Button
						variant="contained"
						disabled={form.isSubmitting || !form.isValid}
						type="submit">
						Sign in
					</Button>
				</Stack>
				<div>
					<Link
						href={paths.forgotPassword}
						variant="subtitle2">
						Forgot password?
					</Link>
				</div>
			</Stack>
		</Stack>
	</SplitLayout>
);
