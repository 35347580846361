import { useState, useEffect } from "react";
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
} from "react-beautiful-dnd";
import { DotsSixVertical as DragIcon } from "@phosphor-icons/react/dist/ssr/DotsSixVertical";
import { Trash as DeleteIcon } from "@phosphor-icons/react/dist/ssr/Trash";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import { PencilSimple as EditIcon } from "@phosphor-icons/react/dist/ssr/PencilSimple";

interface Navigator {
	active: boolean;
	content: string;
	index: number;
	key: string;
	label: string;
	width: number;
	id: string;
}

interface Props {
	navigators: Navigator[];
	onChange: (navigators: Navigator[]) => void;
}

export const SortableMenu = (props: Props) => {
	const { navigators, onChange } = props;
	const [items, setItems] = useState<Navigator[]>([]);

	useEffect(() => {
		setItems(navigators);
	}, [navigators]);

	const onDragEnd = (result: DropResult) => {
		const { destination, source } = result;

		if (!destination) return;

		const reorderedItems = Array.from(items);
		const [removed] = reorderedItems.splice(source.index, 1);
		if (removed) reorderedItems.splice(destination.index, 0, removed);

		setItems(reorderedItems);
		onChange(reorderedItems);
	};

	const handleRemove = (id: string) => {
		const filteredItems = items.filter((item) => item.id !== id);
		setItems(filteredItems);
		onChange(filteredItems);
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable-column">
				{(provided) => (
					<div
						{...provided.droppableProps}
						ref={provided.innerRef}>
						{items.map((item, index) => (
							<Draggable
								key={item.id}
								draggableId={item.id}
								index={index}>
								{(provided) => (
									<Stack
										spacing={2}
										direction="row"
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										sx={{
											alignItems: "center",
											flexWrap: "wrap",
											justifyContent: "space-between",
											px: 3,
											py: 5,
										}}
										style={{
											userSelect: "none",
											padding: "16px",
											margin: "0 0 8px 0",
											backgroundColor: "var(--mui-palette-grey-100)",
											borderRadius: "8px",
											...provided.draggableProps.style,
										}}>
										<Stack
											direction="row"
											spacing={2}
											sx={{ alignItems: "center" }}>
											<IconButton>
												<DragIcon size={24} />
											</IconButton>
											<Typography variant="subtitle1">{item.label}</Typography>
										</Stack>
										<Stack
											direction="row"
											spacing={2}
											sx={{ alignItems: "center" }}>
											<IconButton>
												<EditIcon size={24} />
											</IconButton>
											<IconButton
												onClick={() => {
													handleRemove(item.id);
												}}>
												<DeleteIcon size={24} />
											</IconButton>
										</Stack>
									</Stack>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};
