import {
	Box,
	Button,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import { BoundingBox, LineSegment } from "@phosphor-icons/react/dist/ssr";
import { useState } from "react";

import { PropertyItem } from "~ui-components/components/atoms/property-item";
import { PropertyList } from "~ui-components/components/atoms/property-list";
import { MapToolDrawer } from "../map-tool-drawer";

export type MeasureView = "distance" | "area";

export const MeasureDrawer = ({ onClose }) => {
	const [view, setView] = useState<MeasureView>("distance");

	return (
		<MapToolDrawer
			title="Measure tool"
			onClose={onClose}
			Content={
				<Stack
					p={2}
					spacing={2}>
					<div>
						<ToggleButtonGroup
							color="primary"
							exclusive
							onChange={(_, value) => {
								setView(value);
							}}
							value={view}>
							<ToggleButton value="distance">
								<LineSegment />
							</ToggleButton>
							<ToggleButton value="area">
								<BoundingBox />
							</ToggleButton>
						</ToggleButtonGroup>
						{view === "distance" && (
							<Typography
								component="div"
								variant="caption"
								color="text.secondary">
								{"Click on the map to start measuring a distance"}
							</Typography>
						)}
						{view === "area" && (
							<Typography
								component="div"
								variant="caption"
								color="text.secondary">
								{"Click on the map to start measuring an area"}
							</Typography>
						)}
					</div>
					{view === "distance" && (
						<PropertyList sx={{ px: 0 }}>
							<PropertyItem
								name="Distance"
								value="1605.03 miles"
								boxProps={{ sx: { px: 0 } }}
							/>
						</PropertyList>
					)}
					{view === "area" && (
						<PropertyList>
							<PropertyItem
								name="Area"
								value="1605.03 mi2"
								boxProps={{ sx: { px: 0 } }}
							/>
						</PropertyList>
					)}
				</Stack>
			}
			Action={
				<Box textAlign="right">
					<Button color="inherit">Reset</Button>
				</Box>
			}
		/>
	);
};
