import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { Receipt } from "@phosphor-icons/react/dist/ssr/Receipt";
import { FC } from "react";
import { Box, CardContent, Divider } from "@mui/material";
import dayjs from "dayjs";

import { InvoicesTable, InvoicesTableProps } from "./invoices-table";
import {
	InvoicesPagination,
	InvoicesPaginationProps,
} from "./invoices-pagination";

export const INVOICES: InvoicesTableProps["rows"] = [
	{
		id: "1",
		date: dayjs().subtract(1, "day").subtract(1, "month").toDate(),
		isPayNowAllowed: true,
		number: "128001",
		status: "PAST_DUE",
		amount: 297.5,
	},
	{
		id: "2",
		date: dayjs().subtract(1, "day").subtract(2, "month").toDate(),
		isPayNowAllowed: false,
		number: "127001",
		status: "PAID",
		amount: 297.5,
	},
	{
		id: "3",
		date: dayjs().subtract(1, "day").subtract(3, "month").toDate(),
		isPayNowAllowed: false,
		number: "126001",
		status: "PAID",
		amount: 297.5,
	},
	{
		id: "4",
		date: dayjs().subtract(1, "day").subtract(4, "month").toDate(),
		isPayNowAllowed: false,
		number: "125001",
		status: "PAID",
		amount: 297.5,
	},
	{
		id: "5",
		date: dayjs().subtract(1, "day").subtract(5, "month").toDate(),
		isPayNowAllowed: false,
		number: "124001",
		status: "PAID",
		amount: 297.5,
	},
];

export interface InvoiceHistoryProps {
	tableProps: InvoicesTableProps;
	paginationProps: InvoicesPaginationProps;
}

export const InvoiceHistory: FC<InvoiceHistoryProps> = ({
	tableProps,
	paginationProps,
}) => {
	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<Receipt fontSize="var(--Icon-fontSize)" />
					</Avatar>
				}
				title="Invoice history"
			/>
			<CardContent>
				<Card>
					<Box sx={{ overflowX: "auto" }}>
						<InvoicesTable {...tableProps} />
					</Box>
					<Divider />
					<InvoicesPagination {...paginationProps} />
				</Card>
			</CardContent>
		</Card>
	);
};
