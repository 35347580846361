import { useCallback, useState } from "react";

/**
 * Hook that abstract copy to clipboard logic
 * @see Documentation {@link https://usehooks-ts.com/react-hook/use-copy-to-clipboard}
 */
const useClipboard = (): [string, (value: string) => Promise<boolean>] => {
	const [copiedText, setCopiedText] = useState("");

	const copy = useCallback(async (text: string) => {
		// Clipboard not supported 😢
		if (!navigator?.clipboard) {
			return false;
		}
		let isSuccessful = false;
		let toCopyText = "";
		// Try to save to clipboard then save it in the state if worked
		try {
			await navigator.clipboard.writeText(text);
			toCopyText = text;
			isSuccessful = true;
		} catch (error) {
			console.warn("Copy failed", error);
			// Copy failed 🤨
			toCopyText = "";
			isSuccessful = false;
		}
		setCopiedText(toCopyText);
		return isSuccessful;
	}, []);

	return [copiedText, copy];
};

export { useClipboard };
