import { Image } from "@tiptap/extension-image";
import { Link } from "@tiptap/extension-link";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Table } from "@tiptap/extension-table";
import { TableCell } from "@tiptap/extension-table-cell";
import { TableHeader } from "@tiptap/extension-table-header";
import { TableRow } from "@tiptap/extension-table-row";
import { TextAlign } from "@tiptap/extension-text-align";
import { EditorOptions, useEditor as useTiptapEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";

import { IFrame } from "./extension-iframe";

export type UseEditorOptions = Partial<
	Pick<EditorOptions, "content" | "editable" | "onUpdate" | "autofocus">
> & {
	placeholder?: string;
};

export const useEditor = ({
	autofocus,
	content,
	editable = true,
	onUpdate,
	placeholder,
}: UseEditorOptions) => {
	return useTiptapEditor({
		autofocus,
		immediatelyRender: false,
		extensions: [
			StarterKit,
			Placeholder.configure({
				emptyEditorClass: "is-editor-empty",
				placeholder,
			}),
			Link.configure({ openOnClick: false, autolink: true }).extend({
				inclusive: false,
			}),
			Image
				// .configure({
				// 	HTMLAttributes: { class: 'custom-image' },
				// })
				.extend({
					addAttributes() {
						return {
							...this.parent?.(),
							"data-value": {
								default: null,
								parseHTML: (element) => element.getAttribute("data-value"),
								renderHTML: (attributes) => {
									return attributes["data-value"]
										? { "data-value": attributes["data-value"] }
										: {};
								},
							},
						};
					},
				}),
			TextAlign.configure({
				types: ["heading", "paragraph"],
			}),
			IFrame as any,
			Table.configure({
				resizable: true,
			}),
			TableRow,
			TableHeader,
			TableCell,
		],
		content,
		editable,
		onUpdate,
	});
};
