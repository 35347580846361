import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { DownloadSimple as DownloadSimpleIcon } from "@phosphor-icons/react/dist/ssr/DownloadSimple";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import { paths } from "config/paths";
import Link from "next/link";
import { useRouter } from "next/router";
import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import {
	AccessGroupsTable,
	AccessGroupsTableProps,
} from "./access-groups-table";
import { UsersTable, UsersTableProps } from "./users-table";
import { UsersTabs } from "./users-tabs";
import { CircularProgress } from "@mui/material";

interface UsersProps {
	tab: "users" | "access-groups";
	isUsersLoading: boolean;
	users: UsersTableProps["rows"];
	onUserDelete: UsersTableProps["onItemDelete"];
	isGroupsLoading: boolean;
	accessGroups: AccessGroupsTableProps["rows"];
	onAccessGroupDelete: AccessGroupsTableProps["onItemDelete"];
}

export function Users(props: UsersProps) {
	const router = useRouter();

	const {
		tab = "users",
		users,
		accessGroups,
		onUserDelete,
		onAccessGroupDelete,
		isUsersLoading,
		isGroupsLoading,
	} = props;

	return (
		<DashboardContentLayout
			pageTitle="Users"
			action={
				<Stack
					direction="row"
					justifyContent="flex-end"
					spacing={2}>
					<Button
						href={paths.accountName.users.invite(router.query["account-name"])}
						component={Link}
						startIcon={<PlusIcon />}
						variant="contained">
						Invite user
					</Button>
					<Button
						href={paths.accountName.groups.create(router.query["account-name"])}
						component={Link}
						startIcon={<PlusIcon />}
						variant="contained">
						Add group
					</Button>
					<Button
						startIcon={<DownloadSimpleIcon />}
						variant="outlined">
						Export user log
					</Button>
				</Stack>
			}>
			<Card>
				<UsersTabs
					value={tab}
					usersCount={users.length}
					accessGroupsCount={accessGroups.length}
				/>
				<Divider />
				{tab === "users" &&
					(isUsersLoading ? (
						<Box
							my={2}
							width="100%"
							textAlign="center">
							<CircularProgress />
						</Box>
					) : (
						<Box sx={{ overflowX: "auto" }}>
							<UsersTable
								rows={users}
								onItemDelete={onUserDelete}
							/>
						</Box>
					))}

				{tab === "access-groups" &&
					(isGroupsLoading ? (
						<Box
							my={2}
							width="100%"
							textAlign="center">
							<CircularProgress />
						</Box>
					) : (
						<Box sx={{ overflowX: "auto" }}>
							<AccessGroupsTable
								rows={accessGroups}
								onItemDelete={onAccessGroupDelete}
							/>
						</Box>
					))}
			</Card>
		</DashboardContentLayout>
	);
}
