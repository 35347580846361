import { useMutation } from "@tanstack/react-query";
import { SignInFormValues } from "ui-components";

export const useSignIn = () => {
	return useMutation({
		mutationKey: ["signIn"],
		mutationFn: async (values: SignInFormValues) => {
			const response = await fetch(
				process.env.NODE_ENV === "development" ? "/api/session" : "/session",
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
					method: "POST",
					body: new URLSearchParams({
						"session[email]": values.email,
						"session[password]": values.password,
					}),
				},
			);

			return response;
		},
	});
};
