import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { MagnifyingGlass as MagnifyingGlassIcon } from "@phosphor-icons/react/dist/ssr/MagnifyingGlass";
import { Question as QuestionIcon } from "@phosphor-icons/react/dist/ssr/Question";
import RouterLink from "next/link";
import * as React from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

import { ToolsSearchForm, ToolsSearchFormProps } from "./tools-search-form";

interface ToolsSearchProps {
	form: ToolsSearchFormProps["form"];
}

const href = "https://help.mangomap.com/hc/en-us/articles/360022375371-Search";

export function ToolsSearch(props: ToolsSearchProps): React.JSX.Element {
	const { form } = props;
	return (
		<DashboardContentLayout pageTitle="Search">
			<Card>
				<CardHeader
					action={
						<Button
							href={href}
							component={RouterLink}
							color="secondary"
							endIcon={<QuestionIcon />}>
							Learn more
						</Button>
					}
					avatar={
						<Avatar>
							<MagnifyingGlassIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Powerful combo search box with full text address search, data attribute, and coordinate search options"
				/>
				<ToolsSearchForm form={form} />
			</Card>
		</DashboardContentLayout>
	);
}

export * from "./tools-search-form";
