import { Monitor as PortalIcon } from "@phosphor-icons/react/dist/ssr/Monitor";
import { Palette as ThemeIcon } from "@phosphor-icons/react/dist/ssr/Palette";
import { Translate as LocalisationIcon } from "@phosphor-icons/react/dist/ssr/Translate";
import { Globe as WhiteLabelIcon } from "@phosphor-icons/react/dist/ssr/Globe";
import { ChartLine as AnalticsIcon } from "@phosphor-icons/react/dist/ssr/ChartLine";
import { Article as LayerDetialIcon } from "@phosphor-icons/react/dist/ssr/Article";
import { PaintBrush as StyleIcon } from "@phosphor-icons/react/dist/ssr/PaintBrush";
import { TextAa as LabelsIcon } from "@phosphor-icons/react/dist/ssr/TextAa";
import { CursorClick as PopupIcon } from "@phosphor-icons/react/dist/ssr/CursorClick";
import { Hash as AttributeFormatIcon } from "@phosphor-icons/react/dist/ssr/Hash";
import { AddressBook as AddressBookIcon } from "@phosphor-icons/react/dist/ssr/AddressBook";
import { AlignLeft as AlignLeftIcon } from "@phosphor-icons/react/dist/ssr/AlignLeft";
import { CalendarCheck as CalendarCheckIcon } from "@phosphor-icons/react/dist/ssr/CalendarCheck";
import { ChartPie as ChartPieIcon } from "@phosphor-icons/react/dist/ssr/ChartPie";
import { ChatsCircle as ChatsCircleIcon } from "@phosphor-icons/react/dist/ssr/ChatsCircle";
import { CreditCard as CreditCardIcon } from "@phosphor-icons/react/dist/ssr/CreditCard";
import { Cube as CubeIcon } from "@phosphor-icons/react/dist/ssr/Cube";
import { CurrencyEth as CurrencyEthIcon } from "@phosphor-icons/react/dist/ssr/CurrencyEth";
import { EnvelopeSimple as EnvelopeSimpleIcon } from "@phosphor-icons/react/dist/ssr/EnvelopeSimple";
import { File as FileIcon } from "@phosphor-icons/react/dist/ssr/File";
import { FileDashed as FileDashedIcon } from "@phosphor-icons/react/dist/ssr/FileDashed";
import { FileX as FileXIcon } from "@phosphor-icons/react/dist/ssr/FileX";
import { Gear as GearIcon } from "@phosphor-icons/react/dist/ssr/Gear";
import { GraduationCap as GraduationCapIcon } from "@phosphor-icons/react/dist/ssr/GraduationCap";
import { House as HouseIcon } from "@phosphor-icons/react/dist/ssr/House";
import { Kanban as KanbanIcon } from "@phosphor-icons/react/dist/ssr/Kanban";
import { Link as LinkIcon } from "@phosphor-icons/react/dist/ssr/Link";
import { Lock as LockIcon } from "@phosphor-icons/react/dist/ssr/Lock";
import { ReadCvLogo as ReadCvLogoIcon } from "@phosphor-icons/react/dist/ssr/ReadCvLogo";
import { Receipt as ReceiptIcon } from "@phosphor-icons/react/dist/ssr/Receipt";
import { ShareNetwork as ShareNetworkIcon } from "@phosphor-icons/react/dist/ssr/ShareNetwork";
import { ShoppingBagOpen as ShoppingBagOpenIcon } from "@phosphor-icons/react/dist/ssr/ShoppingBagOpen";
import { ShoppingCartSimple as ShoppingCartSimpleIcon } from "@phosphor-icons/react/dist/ssr/ShoppingCartSimple";
import { SignOut as SignOutIcon } from "@phosphor-icons/react/dist/ssr/SignOut";
import { TextAlignLeft as TextAlignLeftIcon } from "@phosphor-icons/react/dist/ssr/TextAlignLeft";
import { Truck as TruckIcon } from "@phosphor-icons/react/dist/ssr/Truck";
import { Upload as UploadIcon } from "@phosphor-icons/react/dist/ssr/Upload";
import { Users as UsersIcon } from "@phosphor-icons/react/dist/ssr/Users";
import { WarningDiamond as WarningDiamondIcon } from "@phosphor-icons/react/dist/ssr/WarningDiamond";
import { Gear as DetailsIcon } from "@phosphor-icons/react/dist/ssr/Gear";
import { List as NavIcon } from "@phosphor-icons/react/dist/ssr/List";

import type { Icon } from "@phosphor-icons/react/dist/lib/types";

export const icons = {
	"address-book": AddressBookIcon,
	"align-left": AlignLeftIcon,
	"calendar-check": CalendarCheckIcon,
	"chart-pie": ChartPieIcon,
	"chats-circle": ChatsCircleIcon,
	"credit-card": CreditCardIcon,
	"currency-eth": CurrencyEthIcon,
	"envelope-simple": EnvelopeSimpleIcon,
	"file-dashed": FileDashedIcon,
	"file-x": FileXIcon,
	"graduation-cap": GraduationCapIcon,
	"read-cv-logo": ReadCvLogoIcon,
	"share-network": ShareNetworkIcon,
	"shopping-bag-open": ShoppingBagOpenIcon,
	"shopping-cart-simple": ShoppingCartSimpleIcon,
	"sign-out": SignOutIcon,
	"text-align-left": TextAlignLeftIcon,
	"warning-diamond": WarningDiamondIcon,
	cube: CubeIcon,
	file: FileIcon,
	house: HouseIcon,
	kanban: KanbanIcon,
	link: LinkIcon,
	lock: LockIcon,
	receipt: ReceiptIcon,
	truck: TruckIcon,
	upload: UploadIcon,
	gear: GearIcon,
	users: UsersIcon,
	"details-icon": DetailsIcon,
	"nav-icon": NavIcon,
	"layer-details": LayerDetialIcon,
	style: StyleIcon,
	labels: LabelsIcon,
	popup: PopupIcon,
	"attribute-format": AttributeFormatIcon,
	"localisation-icon": LocalisationIcon,
	"theme-icon": ThemeIcon,
	"portal-icon": PortalIcon,
	"white-label-icon": WhiteLabelIcon,
	"analytics-icon": AnalticsIcon,
} as Record<string, Icon>;
