import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { MathOperations as EquationIcon } from "@phosphor-icons/react/dist/ssr/MathOperations";
import { FC, useState } from "react";
import { Editor } from "@tiptap/react";

import { EquationDrawer } from "./equation-drawer";

export interface EquationProps {
	editor: Editor;
	attributes?: string[];
}

export const Equation: FC<EquationProps> = ({ editor, attributes }) => {
	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(true);
	};

	return (
		<>
			<IconButton
				sx={{ width: 100 }}
				onClick={handleClick}>
				<Box
					display="flex"
					alignItems="center">
					<EquationIcon />
					<Typography
						variant="body2"
						ml={1}>
						Equation
					</Typography>
				</Box>
			</IconButton>
			{open && (
				<EquationDrawer
					editor={editor}
					attributes={attributes}
					onClose={() => setOpen(false)}
				/>
			)}
		</>
	);
};
