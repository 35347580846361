import {
	Avatar,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	FormControl,
	IconButton,
	InputLabel,
	Link,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { DotsNine, Plus, Question } from "@phosphor-icons/react/dist/ssr";
import { useFormik } from "formik";
import { MuiColorInput } from "mui-color-input";
import { FC } from "react";
import * as Yup from "yup";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import { ToolsQueryLayerConfigsSchema } from "../layer-configs-form";

export const ToolsQueryDetailsSchema = Yup.object({
	name: Yup.string().required("Required"),
	description: Yup.string().required("Required"),
	highlightColour: Yup.string().required("Required"),
	addedLayers: Yup.array().of(ToolsQueryLayerConfigsSchema).required(),
});

export type ToolsQueryDetailsFormValues = Yup.InferType<
	typeof ToolsQueryDetailsSchema
>;

export interface ToolsQueryDetailsFormProps {
	form: ReturnType<typeof useFormik<ToolsQueryDetailsFormValues>>;
	layersOptions: { id: string; name: string }[];
	onSettingsLayer: (layerId: string) => void;
	onRemoveLayer: (layerId: string) => void;
	onAddLayer: (layerId: string) => void;
	onCancel: () => void;
}
export const ToolsQueryDetailsForm: FC<ToolsQueryDetailsFormProps> = ({
	form,
	layersOptions,
	onSettingsLayer,
	onRemoveLayer,
	onAddLayer,
	onCancel,
}) => {
	return (
		<DashboardContentLayout pageTitle="Query">
			<Card
				component="form"
				onSubmit={form.handleSubmit}>
				<CardHeader
					avatar={
						<Avatar>
							<DotsNine fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Query layer features by attribute and location"
					action={
						<Link
							href={
								"https://help.mangomap.com/hc/en-us/articles/360022190192-Query-Tool"
							}
							target="_blank">
							<IconButton aria-label="settings">
								<Question />
							</IconButton>
						</Link>
					}
				/>
				<CardContent>
					<Stack spacing={3}>
						<Typography variant="h5">Details</Typography>
						<FormControl>
							<InputLabel>Name</InputLabel>
							<TextField
								{...form.getFieldProps("name")}
								error={!!form.errors.name}
								helperText={form.errors.name}
							/>
						</FormControl>
						<FormControl fullWidth>
							<InputLabel>Description</InputLabel>
							<TextField
								minRows={3}
								multiline
								{...form.getFieldProps("description")}
								error={!!form.errors.description}
								helperText={form.errors.description}
							/>
						</FormControl>
						<FormControl>
							<InputLabel>Highlight colour</InputLabel>
							<MuiColorInput
								sx={{ width: "140px" }}
								format="hex"
								value={form.values.highlightColour ?? ""}
								onChange={(value) =>
									form.setFieldValue("highlightColour", value)
								}
								error={!!form.errors?.highlightColour}
							/>
						</FormControl>
						<Typography variant="h5">Query layers</Typography>
						<Card
							sx={{ borderRadius: 1 }}
							variant="outlined">
							<Stack divider={<Divider />}>
								{layersOptions.map((layer) => {
									const isLayerAdded = form.values.addedLayers.some(
										(addedLayer) => addedLayer.id === layer.id,
									);

									return (
										<Stack
											direction="row"
											key={layer.id}
											spacing={2}
											sx={{ alignItems: "center", px: 2, py: 1 }}>
											<Box sx={{ flex: "1 1 auto" }}>
												<Typography variant="subtitle2">
													{layer.name}
												</Typography>
											</Box>
											<Stack
												direction="row"
												alignItems="center"
												gap={1}>
												{isLayerAdded ? (
													<>
														<Button
															color="inherit"
															size="small"
															variant="outlined"
															onClick={() => onSettingsLayer(layer.id)}>
															Settings
														</Button>
														<Button
															color="error"
															size="small"
															variant="outlined"
															onClick={() => onRemoveLayer(layer.id)}>
															Remove
														</Button>
													</>
												) : (
													<Button
														color="inherit"
														startIcon={<Plus />}
														size="small"
														variant="outlined"
														onClick={() => onAddLayer(layer.id)}>
														Add
													</Button>
												)}
											</Stack>
										</Stack>
									);
								})}
							</Stack>
						</Card>
					</Stack>
				</CardContent>
				<CardActions sx={{ justifyContent: "flex-end", alignItems: "center" }}>
					<Button
						color="inherit"
						onClick={onCancel}>
						Cancel
					</Button>
					<Button
						variant="contained"
						type="submit"
						disabled={form.isSubmitting || !form.isValid}>
						Save changes
					</Button>
				</CardActions>
			</Card>
		</DashboardContentLayout>
	);
};
