import { IconButton, Stack, Typography } from "@mui/material";
import {
	ArrowSquareLeft,
	ArrowSquareRight,
} from "@phosphor-icons/react/dist/ssr";

export const FeatureChange = ({
	handleFeatureChange,
	currentIndex,
	featuresCount,
}) => {
	const isMultipleFeatures = featuresCount > 1;
	if (!isMultipleFeatures) return null;
	return (
		<Stack
			p={2}
			direction="row"
			alignItems="center"
			justifyContent={"space-between"}>
			<IconButton
				disabled={currentIndex === 0}
				onClick={() => {
					handleFeatureChange(-1);
				}}>
				<ArrowSquareLeft />
			</IconButton>
			<Typography
				color="text.secondary"
				variant="subtitle2">
				{currentIndex + 1}/{featuresCount} MULTIPLE FEATURES SELECTED
			</Typography>
			<IconButton
				disabled={currentIndex === featuresCount - 1}
				onClick={() => {
					handleFeatureChange(1);
				}}>
				<ArrowSquareRight />
			</IconButton>
		</Stack>
	);
};
