import { Divider, StackProps } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { AlignCenterHorizontal as AlignCenterHorizontalIcon } from "@phosphor-icons/react/dist/ssr/AlignCenterHorizontal";
import { AlignLeft as AlignLeftIcon } from "@phosphor-icons/react/dist/ssr/AlignLeft";
import { AlignRight as AlignRightIcon } from "@phosphor-icons/react/dist/ssr/AlignRight";
import { GridFour as GridFourIcon } from "@phosphor-icons/react/dist/ssr/GridFour";
import { GridNine as GridNineIcon } from "@phosphor-icons/react/dist/ssr/GridNine";
import { Monitor as PortalIcon } from "@phosphor-icons/react/dist/ssr/Monitor";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import { Rows as RowsIcon } from "@phosphor-icons/react/dist/ssr/Rows";
import { useFormik } from "formik";

import { TextEditor } from "~ui-components/components/atoms/text-editor/text-editor";
import { SortableMenu } from "~ui-components/components/molecules/map-menu/sortable-menu";

import type { EditorEvents } from "@tiptap/react";

interface Navigator {
	active: boolean;
	content: string;
	index: number;
	key: string;
	label: string;
	width: number;
	id: string;
}

interface MapDetailsInput {
	portalName: string;
	defaultLayout: string;
	bannerTitle: string;
	bannerTitleAlignment: string;
	bannerContent: string;
	footer: string;
	enableNavigation: boolean;
	navigators: Navigator[];
}

export type PortalSettingsProps = StackProps & {
	form: ReturnType<typeof useFormik<MapDetailsInput>>;
	navigatorSettings: any;
	onCreateTask?: () => void | undefined;
};

export const PortalSettings = (props: PortalSettingsProps) => {
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const { form, onCreateTask = () => {} } = props;
	const { values, handleChange, handleSubmit } = form;

	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<PortalIcon fontSize="var(--Icon-fontSize)" />
					</Avatar>
				}
				title="Portal settings"
			/>
			<CardContent>
				<Stack spacing={3}>
					<Stack spacing={2}>
						<FormControl>
							<InputLabel>Portal name</InputLabel>
							<OutlinedInput
								name="portalName"
								value={values.portalName}
								onChange={handleChange}
							/>
						</FormControl>

						<Stack
							direction="row"
							spacing={2}>
							<FormControl>
								<InputLabel>Default layout</InputLabel>
								<ToggleButtonGroup
									sx={{ mt: 1 }}
									color="primary"
									exclusive
									onChange={(_, value) => {
										handleChange({
											target: {
												name: "defaultLayout",
												value,
											},
										});
									}}
									tabIndex={0}
									value={values.defaultLayout}>
									<ToggleButton value="row">
										<RowsIcon />
									</ToggleButton>
									<ToggleButton value="grid-9">
										<GridNineIcon />
									</ToggleButton>
									<ToggleButton value="grid-4">
										<GridFourIcon />
									</ToggleButton>
								</ToggleButtonGroup>
							</FormControl>
						</Stack>
						<Stack
							direction="row"
							spacing={2}>
							<Stack
								direction="row"
								spacing={2}
								sx={{ flexGrow: 1 }}>
								<FormControl sx={{ flex: "1 1 auto" }}>
									<InputLabel>Banner title</InputLabel>
									<OutlinedInput
										name="bannerTitle"
										value={values.bannerTitle}
										onChange={handleChange}
									/>
								</FormControl>
							</Stack>
							<Stack
								direction="row"
								spacing={2}>
								<FormControl>
									<ToggleButtonGroup
										sx={{ mt: "26px" }}
										color="primary"
										exclusive
										onChange={(_, value) => {
											handleChange({
												target: {
													name: "bannerTitleAlignment",
													value,
												},
											});
										}}
										tabIndex={0}
										value={values.bannerTitleAlignment}>
										<ToggleButton value="align-left">
											<AlignLeftIcon />
										</ToggleButton>
										<ToggleButton value="align-center">
											<AlignCenterHorizontalIcon />
										</ToggleButton>
										<ToggleButton value="align-right">
											<AlignRightIcon />
										</ToggleButton>
									</ToggleButtonGroup>
								</FormControl>
							</Stack>
						</Stack>
						<Stack
							direction="row"
							spacing={2}>
							<FormControl sx={{ flex: "1 1 auto" }}>
								<InputLabel>Banner content</InputLabel>
								<Box
									sx={{
										mt: "8px",
										"& .tiptap-container": { height: "400px" },
									}}>
									<TextEditor
										placeholder="Write something"
										onUpdate={({ editor }: EditorEvents["update"]) => {
											handleChange({
												target: {
													name: "bannerContent",
													value: editor.getHTML(),
												},
											});
										}}
										toolsProps={{
											LinkInput: {},
											ImageInput: {},
											TextAlign: true,
											HorizontalRule: true,
										}}
									/>
								</Box>
							</FormControl>
						</Stack>
						<FormControl>
							<InputLabel>Footer</InputLabel>
							<OutlinedInput
								name="footer"
								value={values.footer}
								onChange={handleChange}
							/>
						</FormControl>
						<Divider sx={{ my: 2 }} />
						<FormControl>
							<FormControlLabel
								control={
									<Switch
										checked={values.enableNavigation}
										name="enableNavigation"
										onChange={handleChange}
									/>
								}
								label="Portal navigation"
							/>
						</FormControl>
						{values.enableNavigation && (
							<Stack spacing={2}>
								<Button
									color="secondary"
									onClick={() => onCreateTask()}
									startIcon={<PlusIcon />}>
									Add task
								</Button>
								<SortableMenu
									navigators={form.values.navigators}
									onChange={(navigators) =>
										handleChange({
											target: {
												name: "navigators",
												value: navigators,
											},
										})
									}
								/>
							</Stack>
						)}
					</Stack>
				</Stack>
			</CardContent>
			<CardActions sx={{ justifyContent: "flex-end" }}>
				<Button color="secondary">Cancel</Button>
				<Button
					variant="contained"
					onClick={() => handleSubmit()}>
					Save changes
				</Button>
			</CardActions>
		</Card>
	);
};
