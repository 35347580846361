import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";

export interface LayerContextMenuProps {
	anchorEl?: HTMLElement | null;
	onClose?: () => void;
	open?: boolean;
	onDelete: () => void;
	onMoveAbove: () => void;
	onMoveBelow: () => void;
}

export function LayerContextMenu({
	anchorEl,
	onClose,
	open = false,
	onDelete,
	onMoveAbove,
	onMoveBelow,
}: LayerContextMenuProps): React.JSX.Element {
	return (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			onClose={onClose}
			open={open}
			transformOrigin={{ horizontal: "right", vertical: "top" }}>
			<MenuItem onClick={onMoveAbove}>Move layer above</MenuItem>
			<MenuItem onClick={onMoveBelow}>Move layer below</MenuItem>
			<MenuItem
				onClick={onDelete}
				sx={{ color: "var(--mui-palette-error-main)" }}>
				Delete layer
			</MenuItem>
		</Menu>
	);
}
