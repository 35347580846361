import {
	Stack,
	FormControl,
	InputLabel,
	OutlinedInput,
	Button,
	Select,
} from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import { MuiColorInput } from "mui-color-input";

import { Option } from "~ui-components/components/atoms/option";

export interface LineProps {
	colour: string;
	width: number;
	opacity: number;
	id: string;
}

interface Props {
	onDelete: () => void;
	form: ReturnType<typeof useFormik<LineProps>>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const SketchLineEditor = ({ onDelete = () => {}, form }: Props) => {
	const { values, handleChange, handleSubmit, handleBlur, setFieldValue } =
		form;
	return (
		<Stack
			p={2}
			sx={{
				height: "100%",
				flexDirection: "column",
			}}>
			<Stack
				spacing={1}
				sx={{ flexGrow: 1 }}>
				<FormControl>
					<InputLabel>Line colour</InputLabel>
					<MuiColorInput
						sx={{ width: 200 }}
						format="hex"
						value={values.colour ?? ""}
						onChange={(value) => setFieldValue("colour", value)}
					/>
				</FormControl>
				<FormControl>
					<InputLabel>Line width</InputLabel>
					<OutlinedInput
						sx={{ width: 200 }}
						name="width"
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.width}
					/>
				</FormControl>
				<FormControl sx={{ width: 200 }}>
					<InputLabel>Line opacity</InputLabel>
					<Select
						name="opacity"
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.opacity}>
						{_.range(0, 11).map((value) => (
							<Option
								key={value}
								value={value / 10}>
								{value * 10}%
							</Option>
						))}
					</Select>
				</FormControl>
			</Stack>
			<Stack
				direction="row"
				spacing={1}
				justifyContent="end">
				<Button
					onClick={onDelete}
					color="secondary">
					Delete
				</Button>
				<Button
					onClick={() => handleSubmit()}
					variant="contained"
					color="primary">
					Save
				</Button>
			</Stack>
		</Stack>
	);
};
