import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { X as XIcon } from "@phosphor-icons/react/dist/ssr/X";
import _ from "lodash";
import { MuiColorInput } from "mui-color-input";
import * as React from "react";

import { Option } from "~ui-components/components/atoms/option";
import {
	MarkerSettingsDrawer,
	MarkerValues,
	PREDEFINED_MARKERS,
} from "~ui-components/components/molecules/layer-style/marker-setting-drawer";
import { SymbolValue } from "~ui-components/types/__generated/gql/graphql";

export interface SettingsDrawerProps {
	onClose?: () => void;
	open?: boolean;
	onChange: (value: SymbolValue) => void;
	value: SymbolValue;
}

export function CustomiseMarkerSettingsDrawer({
	onClose,
	open,
	onChange,
	value: symbolValue,
}: SettingsDrawerProps): React.JSX.Element {
	const [value, setSymbol] = React.useState<SymbolValue>(symbolValue);
	const [openMarkerDrawer, setOpenMarkerDrawer] = React.useState(false);

	React.useEffect(() => {
		setSymbol(symbolValue);
	}, [symbolValue]);

	const handleInputChange = (key: string, newValue: any) => {
		setSymbol((prev) => ({
			...prev,
			symbol: {
				...prev.symbol,
				basic: {
					...prev.symbol?.basic,
					[key]: newValue,
					symbol: prev.symbol?.basic?.symbol ?? "",
				},
			},
		}));
	};

	const handleFillOpacityChange = (value: number) => {
		setSymbol((prev) => ({
			...prev,
			symbol: {
				...prev.symbol,
				fill: {
					...prev.symbol?.fill,
					opacity: value,
				},
			},
		}));
	};

	const handleFillChange = (key: string, newValue: any) => {
		setSymbol((prev) => ({
			...prev,
			symbol: {
				...prev.symbol,
				fill: {
					...prev.symbol?.fill,
					[key]: newValue,
				},
			},
		}));
	};

	const handleStrokeChange = (key: string, newValue: any) => {
		setSymbol((prev) => ({
			...prev,
			symbol: {
				...prev.symbol,
				stroke: {
					...prev.symbol?.stroke,
					[key]: newValue,
				},
			},
		}));
	};

	if (_.isEmpty(value)) return <div></div>;

	const markerValue = _.get(value?.symbol, "basic.symbol", "circle");
	const { Icon, ...iconProps } =
		PREDEFINED_MARKERS[value?.symbol?.basic?.symbol ?? ""];

	const symbol = value.symbol;

	return (
		<>
			<Drawer
				ModalProps={{
					BackdropProps: { invisible: false },
					sx: { zIndex: 100 },
				}}
				PaperProps={{
					elevation: 24,
					sx: {
						display: "flex",
						flexDirection: "column",
						maxWidth: "100%",
						width: "340px",
					},
				}}
				anchor="right"
				disableScrollLock
				onClose={onClose}
				open={open}>
				<Stack
					direction="row"
					spacing={3}
					sx={{
						alignItems: "center",
						justifyContent: "space-between",
						px: 3,
						pt: 2,
					}}>
					<Typography variant="h6">Customise marker</Typography>
					<Stack
						direction="row"
						spacing={0.5}
						sx={{ alignItems: "center" }}>
						<IconButton onClick={onClose}>
							<XIcon />
						</IconButton>
					</Stack>
				</Stack>
				<Stack
					spacing={3}
					sx={{ p: 3 }}>
					<Typography variant="subtitle1">Marker</Typography>
					<FormControl>
						<InputLabel sx={{ pb: 1 }}>Symbol</InputLabel>
						<Stack
							alignItems="center"
							direction="row"
							spacing={2}>
							<Box
								sx={{
									border: "1px solid #ccc",
									alignItems: "center",
									borderRadius: "8px",
									display: "flex",
									height: "100px",
									justifyContent: "center",
									overflow: "hidden",
									width: "100px",
									backgroundImage: 'url("/images/marker-bg.png")',
									backgroundSize: "cover", // Ensures the image covers the entire box
									backgroundPosition: "center", // Centers the image within the box
									position: "relative", // Enables absolute positioning for children
								}}>
								{markerValue === "circle" && (
									<Icon
										fontSize={Number(
											(symbol?.basic?.size ?? 0) + (symbol?.stroke?.width ?? 0),
										)}
										{...iconProps}
										color={symbol?.stroke?.color}
										style={{
											position: "absolute", // Overlaps with the other icon
											top: "50%",
											left: "50%",
											transform: "translate(-50%, -50%)", // Centers the icon
											opacity: symbol?.stroke?.opacity ?? 1,
										}}
									/>
								)}
								<Icon
									fontSize={symbol?.basic?.size}
									{...iconProps}
									color={symbol?.fill?.color}
									style={{
										position: "absolute", // Overlaps with the other icon
										top: "50%",
										left: "50%",
										transform: `translate(-50%, -50%) rotate(${symbol?.basic?.rotation}deg)`,
										opacity: symbol?.fill?.opacity ?? 1,
									}}
								/>
							</Box>

							<Button
								color="secondary"
								variant="outlined"
								onClick={() => {
									setOpenMarkerDrawer(true);
								}}>
								Change marker
							</Button>
						</Stack>
					</FormControl>
				</Stack>
				<Stack
					spacing={3}
					sx={{ p: 3 }}>
					<FormControl>
						<InputLabel>Size</InputLabel>
						<OutlinedInput
							sx={{ width: 200 }}
							onChange={(event) =>
								handleInputChange("size", Number(event.target.value))
							}
							value={value.symbol?.basic?.size ?? ""}
						/>
					</FormControl>

					<FormControl>
						<InputLabel>Rotation</InputLabel>
						<OutlinedInput
							sx={{ width: 200 }}
							onChange={(event) =>
								handleInputChange("rotation", Number(event.target.value))
							}
							value={value.symbol?.basic?.rotation ?? ""}
						/>
					</FormControl>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Fill Opacity</InputLabel>
						<Select
							onChange={(e) => {
								handleFillOpacityChange(Number(e.target.value));
							}}
							value={value.symbol?.fill?.opacity ?? 1}>
							{_.range(0, 11).map((val) => (
								<Option
									key={val}
									value={val / 10}>
									{val * 10}%
								</Option>
							))}
						</Select>
					</FormControl>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Fill colour</InputLabel>
						<MuiColorInput
							sx={{ width: 200 }}
							format="hex"
							value={value.symbol?.fill?.color ?? "#000000"}
							onChange={(color) => handleFillChange("color", color)}
						/>
					</FormControl>
					{markerValue === "circle" && (
						<>
							<FormControl sx={{ width: 200 }}>
								<InputLabel>Stroke opacity</InputLabel>
								<Select
									onChange={(event) =>
										handleStrokeChange("opacity", event.target.value)
									}
									value={value.symbol?.stroke?.opacity ?? 1}>
									{_.range(0, 11).map((val) => (
										<Option
											key={val}
											value={val / 10}>
											{val * 10}%
										</Option>
									))}
								</Select>
							</FormControl>
							<FormControl sx={{ width: 200 }}>
								<InputLabel>Stroke style</InputLabel>
								<Select
									onChange={(event) =>
										handleStrokeChange("style", event.target.value)
									}
									value={value.symbol?.stroke?.style ?? ""}>
									<Option
										key="none"
										value="none">
										None
									</Option>
									<Option
										key="solid"
										value="solid">
										Solid
									</Option>
									<Option
										key="dash"
										value="dash">
										Dash
									</Option>
									<Option
										key="dot"
										value="dot">
										Dot
									</Option>
								</Select>
							</FormControl>
							<FormControl sx={{ width: 200 }}>
								<InputLabel>Stroke width</InputLabel>
								<OutlinedInput
									sx={{ width: 200 }}
									onChange={(event) =>
										handleStrokeChange("width", Number(event.target.value))
									}
									value={value.symbol?.stroke?.width ?? ""}
								/>
							</FormControl>
						</>
					)}
					<CardActions sx={{ justifyContent: "flex-end", p: 0, mt: 2 }}>
						<Button color="secondary">Cancel</Button>
						<Button
							variant="contained"
							onClick={() => {
								onClose?.();
								onChange(value);
							}}>
							Save changes
						</Button>
					</CardActions>
				</Stack>
			</Drawer>
			<MarkerSettingsDrawer
				onClose={() => {
					setOpenMarkerDrawer(false);
				}}
				onChange={(val) => {
					setOpenMarkerDrawer(false);
					setSymbol((prev) => ({
						...prev,
						symbol: {
							...prev.symbol,
							basic: {
								...prev.symbol?.basic,
								symbol: val,
							},
						},
					}));
				}}
				value={value.symbol?.basic?.symbol as MarkerValues}
				open={openMarkerDrawer}
			/>
		</>
	);
}
