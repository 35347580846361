import { Box, Stack } from "@mui/material";
import { PREDEFINED_MARKERS } from "ui-components";

import { PointCategoryConfigs } from "~ui-components/types/__generated/gql/graphql";

interface Props {
	style: PointCategoryConfigs;
}
export const PointSimple = ({ style }: Props) => {
	const Icon =
		PREDEFINED_MARKERS[style.symbolStyle?.basic?.symbol ?? "defaultSymbol"]
			.Icon;

	return (
		<Box>
			<Stack
				direction="row"
				display="flex"
				alignItems="center">
				<Icon
					fontSize={style.symbolStyle?.basic?.size}
					weight="fill"
					color={style.symbolStyle?.fill?.color}
				/>
			</Stack>
		</Box>
	);
};
