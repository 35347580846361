import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import {
	ArrowsOutLineVertical,
	CodeBlock,
	Image,
	Quotes,
	TextAlignCenter,
	TextAlignJustify,
	TextAlignLeft,
	TextAlignRight,
	Code,
	Scan,
	Table,
	ChartBar,
	Link,
	LinkBreak,
	ListDashes,
	ListNumbers,
	TextB,
	TextItalic,
	TextStrikethrough,
	Equals,
	BracketsRound,
	Plus,
	Minus,
	X,
	Divide,
} from "@phosphor-icons/react/dist/ssr";
import { Level } from "@tiptap/extension-heading";
import { type Editor } from "@tiptap/react";
import * as React from "react";
import { IconButton, Menu } from "@mui/material";

import { Option } from "~ui-components/components/atoms/option";
import { useDialog } from "~ui-components/hooks/use-dialog";
import { usePopover } from "~ui-components/hooks/use-popover";

import { IFrameDialog, IFrameFormValues } from "./iframe-menu";
import { ImageDialog, ImageFormValues } from "./image-menu";
import { ChartDialog, ChartFormValues } from "./chart-menu";
import { LinkDialog, LinkFormValues } from "./link-menu";
import {
	AttributeSelect,
	AttributeSelectProps,
} from "./custom-tools/attribute-select";
import {
	AggregateAttributeSelect,
	AggregateAttributeSelectProps,
} from "./custom-tools/aggregate-attribute-select";
import { Equation, EquationProps } from "./custom-tools/equation";
import {
	UserInputSelect,
	UserInputSelectProps,
} from "./custom-tools/user-input-select";

export interface TextEditorToolbarProps {
	editor: Editor;
	toolsProps?: {
		Typography?: boolean;
		Bold?: boolean;
		Italic?: boolean;
		Strike?: boolean;
		BulletList?: boolean;
		OrderedList?: boolean;
		TextAlign?: boolean;
		HorizontalRule?: boolean;
		Blockquote?: boolean;
		CodeBlock?: boolean;
		Code?: boolean;
		Table?: boolean;
		AttributeSelect?: false | Omit<AttributeSelectProps, "editor">;
		Equation?: false | Omit<EquationProps, "editor">;
		UserInputSelect?: false | Omit<UserInputSelectProps, "editor">;
		LinkInput?:
			| false
			| {
					hrefAttributes?: string[];
					textAttributes?: string[];
			  };
		ImageInput?:
			| false
			| {
					srcAttributes?: string[];
			  };
		IFrameInput?:
			| false
			| {
					srcAttributes?: string[];
			  };
		Chart?:
			| false
			| {
					attributes?: string[];
			  };
		MathOperators?: boolean;
		AggregateAttributeSelect?:
			| false
			| Omit<AggregateAttributeSelectProps, "editor">;
	};
}

export const TextEditorToolbar: React.FC<TextEditorToolbarProps> = ({
	editor,
	toolsProps,
}) => {
	const linkDialog = useDialog<LinkFormValues>();
	const imageDialog = useDialog<ImageFormValues>();
	const iframeDialog = useDialog<IFrameFormValues>();
	const chartDialog = useDialog<ChartFormValues>();
	const alignPopover = usePopover<HTMLButtonElement>();

	const isAlignActive = [
		editor.isActive({ textAlign: "left" }),
		editor.isActive({ textAlign: "center" }),
		editor.isActive({ textAlign: "right" }),
		editor.isActive({ textAlign: "justify" }),
	].some(Boolean);

	return (
		<Stack
			className="tiptap-toolbar"
			spacing={1}
			sx={{
				borderBottom: "1px solid var(--mui-palette-divider)",
				p: "8px",
				minHeight: "57px",
			}}>
			{linkDialog.open && !!toolsProps?.LinkInput && (
				<LinkDialog
					{...toolsProps?.LinkInput}
					editor={editor}
					onClose={linkDialog.handleClose}
					initialValues={linkDialog.data}
				/>
			)}
			{imageDialog.open && !!toolsProps?.ImageInput && (
				<ImageDialog
					{...toolsProps?.ImageInput}
					editor={editor}
					onClose={imageDialog.handleClose}
					initialValues={imageDialog.data}
				/>
			)}
			{iframeDialog.open && !!toolsProps?.IFrameInput && (
				<IFrameDialog
					{...toolsProps?.IFrameInput}
					editor={editor}
					onClose={iframeDialog.handleClose}
					initialValues={iframeDialog.data}
				/>
			)}
			{chartDialog.open && !!toolsProps?.Chart && (
				<ChartDialog
					{...toolsProps?.Chart}
					editor={editor}
					onClose={chartDialog.handleClose}
					initialValues={chartDialog.data}
				/>
			)}
			<Stack
				direction="row"
				spacing={0.5}
				sx={{ alignItems: "center", flexWrap: "wrap" }}>
				{!!toolsProps?.MathOperators && (
					<>
						<ToolbarButton
							onClick={() => editor.chain().focus().insertContent(`=`).run()}>
							<Equals />
						</ToolbarButton>
						<ToolbarButton
							onClick={() => editor.chain().focus().insertContent(`()`).run()}>
							<BracketsRound />
						</ToolbarButton>
						<ToolbarButton
							onClick={() => editor.chain().focus().insertContent(`+`).run()}>
							<Plus />
						</ToolbarButton>
						<ToolbarButton
							onClick={() => editor.chain().focus().insertContent(`-`).run()}>
							<Minus />
						</ToolbarButton>
						<ToolbarButton
							onClick={() => editor.chain().focus().insertContent(`x`).run()}>
							<X />
						</ToolbarButton>
						<ToolbarButton
							onClick={() => editor.chain().focus().insertContent(`÷`).run()}>
							<Divide />
						</ToolbarButton>
					</>
				)}
				{!!toolsProps?.Typography && (
					<Select
						onChange={(event: SelectChangeEvent) => {
							const value = event.target.value;

							if (!value) {
								return;
							}

							if (value === "p") {
								editor.chain().focus().setParagraph().run();
								return;
							}

							if (value.startsWith("h")) {
								const level = parseInt(value.replace("h", ""));

								if (!isNaN(level) && level >= 1 && level <= 6) {
									editor
										.chain()
										.focus()
										.setHeading({ level: level as Level })
										.run();
								}
							}
						}}
						value={getFontValue(editor)}>
						<Option
							disabled={!editor.can().chain().focus().setParagraph().run()}
							value="p">
							Paragrah
						</Option>
						{([1, 2, 3, 4, 5, 6] as const).map((level) => (
							<Option
								disabled={
									!editor.can().chain().focus().setHeading({ level }).run()
								}
								key={level}
								value={`h${level}`}>
								Heading {level}
							</Option>
						))}
					</Select>
				)}
				{!!toolsProps?.Bold && (
					<ToolbarButton
						active={editor.isActive("bold")}
						disabled={!editor.can().chain().focus().toggleBold().run()}
						onClick={() => {
							editor.chain().focus().toggleBold().run();
						}}>
						<TextB />
					</ToolbarButton>
				)}
				{!!toolsProps?.Italic && (
					<ToolbarButton
						active={editor.isActive("italic")}
						disabled={!editor.can().chain().focus().toggleItalic().run()}
						onClick={() => {
							editor.chain().focus().toggleItalic().run();
						}}>
						<TextItalic />
					</ToolbarButton>
				)}
				{!!toolsProps?.Strike && (
					<ToolbarButton
						active={editor.isActive("strike")}
						disabled={!editor.can().chain().focus().toggleStrike().run()}
						onClick={() => {
							editor.chain().focus().toggleStrike().run();
						}}>
						<TextStrikethrough />
					</ToolbarButton>
				)}
				{!!toolsProps?.BulletList && (
					<ToolbarButton
						active={editor.isActive("bulletList")}
						disabled={!editor.can().chain().focus().toggleBulletList().run()}
						onClick={() => {
							editor.chain().focus().toggleBulletList().run();
						}}>
						<ListDashes />
					</ToolbarButton>
				)}
				{!!toolsProps?.OrderedList && (
					<ToolbarButton
						active={editor.isActive("orderedList")}
						disabled={!editor.can().chain().focus().toggleOrderedList().run()}
						onClick={() => {
							editor.chain().focus().toggleOrderedList().run();
						}}>
						<ListNumbers />
					</ToolbarButton>
				)}
				{!!toolsProps?.LinkInput && (
					<>
						<ToolbarButton
							onClick={() => {
								const selectedText = editor.state.doc.textBetween(
									editor.state.selection.from,
									editor.state.selection.to,
								);
								linkDialog.handleOpen({
									text: selectedText,
									href: "",
									openInNewTab: true,
								});
							}}>
							<Link />
						</ToolbarButton>
						<ToolbarButton
							active={editor.isActive("link")}
							disabled={!editor.can().chain().focus().unsetLink().run()}
							onClick={() => {
								editor.chain().focus().unsetLink().run();
							}}>
							<LinkBreak />
						</ToolbarButton>
					</>
				)}
				{!!toolsProps?.AttributeSelect && (
					<AttributeSelect
						{...toolsProps.AttributeSelect}
						editor={editor}
					/>
				)}
				{!!toolsProps?.AggregateAttributeSelect && (
					<AggregateAttributeSelect
						{...toolsProps.AggregateAttributeSelect}
						editor={editor}
					/>
				)}
				{!!toolsProps?.Equation && (
					<Equation
						{...toolsProps.Equation}
						editor={editor}
					/>
				)}
				{!!toolsProps?.UserInputSelect && (
					<UserInputSelect
						{...toolsProps.UserInputSelect}
						editor={editor}
					/>
				)}
				{!!toolsProps?.ImageInput && (
					<ToolbarButton onClick={() => imageDialog.handleOpen()}>
						<Image />
					</ToolbarButton>
				)}
				{!!toolsProps?.TextAlign && (
					<>
						<ToolbarButton
							ref={alignPopover.anchorRef}
							onClick={alignPopover.handleOpen}
							disabled={!isAlignActive}
							active={isAlignActive}>
							{(editor.isActive({ textAlign: "left" }) || !isAlignActive) && (
								<TextAlignLeft />
							)}
							{editor.isActive({ textAlign: "center" }) && <TextAlignCenter />}
							{editor.isActive({ textAlign: "right" }) && <TextAlignRight />}
							{editor.isActive({ textAlign: "justify" }) && (
								<TextAlignJustify />
							)}
						</ToolbarButton>
						<Menu
							anchorEl={alignPopover.anchorRef.current}
							open={alignPopover.open}
							onClose={alignPopover.handleClose}>
							<Stack direction="row">
								<ToolbarButton
									onClick={() =>
										editor.chain().focus().setTextAlign("left").run()
									}
									active={editor.isActive({ textAlign: "left" })}>
									<TextAlignLeft />
								</ToolbarButton>
								<ToolbarButton
									onClick={() =>
										editor.chain().focus().setTextAlign("center").run()
									}
									active={editor.isActive({ textAlign: "center" })}>
									<TextAlignCenter />
								</ToolbarButton>
								<ToolbarButton
									onClick={() =>
										editor.chain().focus().setTextAlign("right").run()
									}
									active={editor.isActive({ textAlign: "right" })}>
									<TextAlignRight />
								</ToolbarButton>
								<ToolbarButton
									onClick={() =>
										editor.chain().focus().setTextAlign("justify").run()
									}
									active={editor.isActive({ textAlign: "justify" })}>
									<TextAlignJustify />
								</ToolbarButton>
							</Stack>
						</Menu>
					</>
				)}
				{!!toolsProps?.HorizontalRule && (
					<ToolbarButton
						onClick={() => editor.chain().focus().setHorizontalRule().run()}>
						<ArrowsOutLineVertical />
					</ToolbarButton>
				)}
				{!!toolsProps?.Blockquote && (
					<ToolbarButton
						onClick={() => editor.chain().focus().toggleBlockquote().run()}
						active={editor.isActive("blockquote")}>
						<Quotes />
					</ToolbarButton>
				)}
				{!!toolsProps?.CodeBlock && (
					<ToolbarButton
						onClick={() => editor.chain().focus().toggleCodeBlock().run()}
						active={editor.isActive("codeBlock")}>
						<CodeBlock />
					</ToolbarButton>
				)}
				{!!toolsProps?.Code && (
					<ToolbarButton
						onClick={() => editor.chain().focus().toggleCode().run()}
						active={editor.isActive("code")}>
						<Code />
					</ToolbarButton>
				)}
				{!!toolsProps?.IFrameInput && (
					<ToolbarButton onClick={() => iframeDialog.handleOpen()}>
						<Scan />
					</ToolbarButton>
				)}
				{!!toolsProps?.Table && (
					<ToolbarButton
						onClick={() =>
							editor
								.chain()
								.focus()
								.insertTable({ rows: 3, cols: 3, withHeaderRow: true })
								.run()
						}>
						<Table />
					</ToolbarButton>
				)}
				{!!toolsProps?.Chart && (
					<ToolbarButton onClick={() => chartDialog.handleOpen()}>
						<ChartBar />
					</ToolbarButton>
				)}
			</Stack>
		</Stack>
	);
};

function getFontValue(editor: Editor): string {
	return editor.isActive("paragraph")
		? "p"
		: editor.isActive("heading", { level: 1 })
			? "h1"
			: editor.isActive("heading", { level: 2 })
				? "h2"
				: editor.isActive("heading", { level: 3 })
					? "h3"
					: editor.isActive("heading", { level: 4 })
						? "h4"
						: editor.isActive("heading", { level: 5 })
							? "h5"
							: editor.isActive("heading", { level: 6 })
								? "h6"
								: "p";
}

interface ToolbarButtonProps {
	active?: boolean;
	children: React.ReactNode;
	disabled?: boolean;
	onClick: () => void;
}

const ToolbarButton = React.forwardRef<HTMLButtonElement, ToolbarButtonProps>(
	function ToolbarButton(
		{ active, children, disabled, onClick },
		ref,
	): React.JSX.Element {
		return (
			<IconButton
				color={active ? "primary" : "secondary"}
				disabled={disabled}
				onClick={onClick}
				ref={ref}>
				{children}
			</IconButton>
		);
	},
);
