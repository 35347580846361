import {
	Collapse,
	Stack,
	Box,
	Typography,
	FormControl,
	FormLabel,
	Select,
	Paper,
	OutlinedInput,
	Button,
} from "@mui/material";
import { useState } from "react";
import {
	Funnel,
	CaretUp,
	CaretDown,
	ArrowsHorizontal,
} from "@phosphor-icons/react/dist/ssr";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Option } from "~ui-components/components/atoms/option";

const FILTERS = [
	{
		id: "1",
		name: "Filter Text Field",
		type: "text",
		value: "",
		options: ["Option 1", "Option 2", "Option 3"],
	},
	{
		id: "2",
		name: "Filter Number field",
		type: "number",
		value: [20, 60],
		min: 0,
		max: 100,
	},
	{
		id: "3",
		name: "Filter Date Field",
		type: "date",
	},
];

const Filter = ({ filter }) => {
	switch (filter.type) {
		case "text":
			return (
				<FormControl>
					<FormLabel>{filter.name}</FormLabel>
					<Select>
						{filter.options.map((option) => (
							<Option
								key={option}
								value={option}>
								{option}
							</Option>
						))}
					</Select>
				</FormControl>
			);
		case "number":
			return (
				<FormControl>
					<FormLabel>{filter.name}</FormLabel>
					<Stack
						direction="row"
						spacing={1}
						pt={1}
						alignItems="center">
						<OutlinedInput
							type="number"
							defaultValue={filter.value[0]}
						/>
						<ArrowsHorizontal size={32} />
						<OutlinedInput
							type="number"
							defaultValue={filter.value[1]}
						/>
					</Stack>
				</FormControl>
			);

		case "date":
			return (
				<FormControl>
					<FormLabel>{filter.name}</FormLabel>
					<Stack
						direction="row"
						spacing={1}
						pt={1}
						alignItems="center">
						<DatePicker
							format="MMM D, YYYY"
							sx={{ maxWidth: "250px" }}
						/>
						<ArrowsHorizontal size={32} />
						<DatePicker
							format="MMM D, YYYY"
							sx={{ maxWidth: "250px" }}
						/>
					</Stack>
				</FormControl>
			);

		default:
			return null;
	}
};

export const AttributeFilterInputCollapse = () => {
	const [open, setOpen] = useState(false);

	const handleToggle = () => {
		setOpen(!open);
	};

	return (
		<Paper
			square
			elevation={1}
			sx={{
				backgroundColor: "var(--mui-palette-grey-100)",
			}}>
			<Stack
				direction="row"
				onClick={handleToggle}
				role="button"
				spacing={2}
				sx={{
					justifyContent: "space-between",
					alignItems: "center",
					cursor: "pointer",
					dispaly: "flex",
					p: 2,
					textAlign: "left",
					width: "100%",
				}}
				tabIndex={0}>
				<Box
					display="flex"
					alignItems="center">
					<Funnel
						color={open ? "inherit" : "var(--mui-palette-primary-main)"}
					/>
					<Typography
						variant="subtitle1"
						color="text.secondary"
						ml={1}>
						Attribute filters
					</Typography>
				</Box>
				{open ? <CaretUp /> : <CaretDown />}
			</Stack>
			<Collapse in={open}>
				<Stack
					spacing={2}
					sx={{ p: 2 }}>
					{FILTERS.map((filter) => (
						<Filter
							key={filter.id}
							filter={filter}
						/>
					))}
				</Stack>
				<Stack
					direction={"row-reverse"}
					spacing={2}
					padding={2}>
					<Button variant="contained">Apply filters</Button>
					<Button color="secondary">Reset filters</Button>
				</Stack>
			</Collapse>
		</Paper>
	);
};
