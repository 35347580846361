import { StackProps } from "@mui/material";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import _ from "lodash";
import { MuiColorInput } from "mui-color-input";

import { Option } from "~ui-components/components/atoms/option";
import { PolygonCategoryConfigs } from "~ui-components/types/__generated/gql/graphql";

type Props = StackProps & {
	form: ReturnType<typeof useFormik<PolygonCategoryConfigs>>;
	visualisation?: string;
	onColorRampChange: (colorRamp: string) => void;
	onFieldChange: (field: string) => void;
	attributes?: {
		name: string;
		type: string;
	}[];
	ramps: {
		key: string;
		name: string;
		colors: string[];
	}[];
	onOpenCustomLegend: () => void;
};

export const PolygonCategoryStyle = (props: Props) => {
	const {
		visualisation,
		onFieldChange,
		onOpenCustomLegend,
		onColorRampChange,
		attributes,
		form,
		ramps,
	} = props;
	const { values, handleChange, handleBlur, handleSubmit } = form;

	if (visualisation === "category") {
		return (
			<>
				<Stack spacing={3}>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Colour by</InputLabel>
						<Select
							name="valueField"
							onChange={(e) => {
								onFieldChange(e.target.value ?? "");
								handleChange(e);
							}}
							value={values.valueField}>
							{attributes?.map((value) => (
								<Option
									key={value.name}
									value={value.name}>
									{value.name}
								</Option>
							))}
						</Select>
					</FormControl>

					<FormControl sx={{ width: 200 }}>
						<InputLabel>Color Ramp</InputLabel>
						<Select
							name="colorRamp"
							onChange={(e) => {
								onColorRampChange(e.target.value ?? "");
								handleChange(e);
							}}
							value={values.colorRamp}>
							{ramps?.map((value, key) => (
								<Option
									key={key}
									value={value.key}
									sx={{ width: 250, pr: 2, overflow: "hidden" }}>
									<Stack direction="row">
										<svg
											width={`${value.colors.length * 22}`}
											height="22"
											viewBox={`0 0 ${value.colors.length * 22} 22`}
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											{value?.colors?.map((color, key) => (
												<rect
													key={key}
													x={key * 22}
													width="22"
													height="22"
													fill={color}
												/>
											))}
										</svg>
									</Stack>
								</Option>
							))}
						</Select>
					</FormControl>

					<Typography variant="subtitle1">Fill and Stroke</Typography>

					<FormControl sx={{ width: 200 }}>
						<InputLabel>Fill style</InputLabel>
						<Select
							name="symbolStyle.fill.fillType"
							onChange={handleChange}
							value={values?.symbolStyle?.fill?.fillType}>
							<Option
								key="none"
								value="none">
								None
							</Option>
							<Option
								key="solid"
								value="solid">
								Solid
							</Option>
							<Option
								key="dash"
								value="dash">
								Dash
							</Option>
							<Option
								key="dot"
								value="dot">
								Dot
							</Option>
						</Select>
					</FormControl>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Fill Opacity</InputLabel>
						<Select
							name="symbolStyle.fill.opacity"
							onChange={handleChange}
							value={values.symbolStyle?.fill?.opacity}>
							{_.range(0, 11).map((value) => (
								<Option
									key={value}
									value={value / 10}>
									{value * 10}%
								</Option>
							))}
						</Select>
					</FormControl>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Stroke colour</InputLabel>
						<MuiColorInput
							sx={{ width: 200 }}
							format="hex"
							value={values.symbolStyle?.stroke?.color ?? "#fff"}
							onChange={(value) =>
								form.setFieldValue("symbolStyle.stroke.color", value)
							}
						/>
					</FormControl>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Stroke opacity</InputLabel>
						<Select
							name="symbolStyle.stroke.opacity"
							onChange={handleChange}
							value={values.symbolStyle?.stroke?.opacity}>
							{_.range(0, 11).map((value) => (
								<Option
									key={value}
									value={value / 10}>
									{value * 10}%
								</Option>
							))}
						</Select>
					</FormControl>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Stroke style</InputLabel>
						<Select
							name="symbolStyle.stroke.style"
							onChange={handleChange}
							value={values.symbolStyle?.stroke?.style}>
							<Option
								key="none"
								value="none">
								None
							</Option>
							<Option
								key="solid"
								value="solid">
								Solid
							</Option>
							<Option
								key="dash"
								value="dash">
								Dash
							</Option>
							<Option
								key="dot"
								value="dot">
								Dot
							</Option>
						</Select>
					</FormControl>

					<FormControl sx={{ width: 200 }}>
						<InputLabel>Stroke width</InputLabel>
						<OutlinedInput
							sx={{ width: 200 }}
							name="symbolStyle.stroke.width"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.symbolStyle?.stroke?.width}
						/>
					</FormControl>
					<Button
						sx={{ width: 200 }}
						variant="outlined"
						onClick={() => onOpenCustomLegend()}>
						Customise legend
					</Button>
				</Stack>
				<CardActions sx={{ justifyContent: "flex-end", p: 0, mt: 2 }}>
					<Button color="secondary">Cancel</Button>
					<Button
						variant="contained"
						onClick={() => handleSubmit()}>
						Save changes
					</Button>
				</CardActions>
			</>
		);
	}
	return null;
};
