import { alpha, Box } from "@mui/material";
import { FC, ReactNode } from "react";
import Markdown, { Options } from "react-markdown";
import rehypeRaw from "rehype-raw";

import { IMAGE_DATE_VALUE_TYPE } from "../text-editor/image-menu";
import { CHART_DATE_VALUE_TYPE, CHART_TYPE } from "../text-editor/chart-menu";

import { BarChart } from "./custom-components/bar-chart";
import { Image } from "./custom-components/image";
import { PieChart } from "./custom-components/pie-chart";

export const RichTextView: FC<{
	content: Options["children"];
	valuesToUsed?: Record<string, any>;
}> = ({ content, valuesToUsed }) => {
	return (
		<RichTextStyleWrapper>
			<Markdown
				rehypePlugins={[rehypeRaw]}
				components={{
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					img: ({ node: _, "data-value": dataValueProps, ...restProps }) => {
						if (dataValueProps) {
							const dataValue = JSON.parse(dataValueProps);

							if (dataValue.type === IMAGE_DATE_VALUE_TYPE) {
								return (
									<Image
										dataValue={dataValue}
										valuesToUsed={valuesToUsed}
										imgProps={restProps}
									/>
								);
							}

							if (dataValue.type === CHART_DATE_VALUE_TYPE && valuesToUsed) {
								if (dataValue.chartType === CHART_TYPE.BAR) {
									return (
										<BarChart
											dataValue={dataValue}
											valuesToUsed={valuesToUsed}
										/>
									);
								}
								if (dataValue.chartType === CHART_TYPE.PIE) {
									return (
										<PieChart
											dataValue={dataValue}
											valuesToUsed={valuesToUsed}
										/>
									);
								}
							}
						}

						return <img {...restProps} />;
					},
				}}>
				{content}
			</Markdown>
		</RichTextStyleWrapper>
	);
};

export const RichTextStyleWrapper: FC<{ children: ReactNode }> = ({
	children,
}) => (
	<Box
		sx={(t) => ({
			"& h1": t.typography.h1,
			"& h2": t.typography.h2,
			"& h3": t.typography.h3,
			"& h4": t.typography.h4,
			"& h5": t.typography.h5,
			"& h6": t.typography.h6,
			"& p": t.typography.body1,
			"& iframe, & img": {
				width: "calc(100% - 32px)",
				maxWidth: t.breakpoints.values.sm,
				aspectRatio: "16 / 9",
			},
			"& blockquote": {
				borderLeft: `${t.spacing(0.5)} solid ${t.palette.primary.main}`,
				margin: t.spacing(1.5, 0),
				paddingLeft: t.spacing(1),
			},
			"& code": {
				backgroundColor: t.palette.neutral[900],
				borderRadius: t.spacing(0.5),
				color: t.palette.neutral[100],
				fontSize: t.typography.body2.fontSize,
				padding: t.spacing(0.25, 0.5),
			},
			"& pre": {
				backgroundColor: t.palette.neutral[900],
				borderRadius: t.spacing(0.5),
				color: t.palette.neutral[100],
				margin: t.spacing(1.5, 0),
				padding: t.spacing(0.75, 1),
				"& code": {
					backgroundColor: "transparent",
				},
			},
			"& .tableWrapper": {
				margin: t.spacing(1.5, 0),
				overflowX: "auto",
			},
			"& .resize-cursor": {
				cursor: "col-resize",
			},
			"& table": {
				borderCollapse: "collapse",
				margin: 0,
				overflow: "hidden",
				tableLayout: "fixed",
				width: "100%",
				"& td, & th": {
					border: `${t.spacing(0.125)} solid ${t.palette.divider}`,
					boxSizing: "border-box",
					minWidth: "1em",
					padding: t.spacing(0.75, 1),
					position: "relative",
					verticalAlign: "top",
					"& p": {
						marginTop: 0,
					},
					"& > *": {
						marginBottom: 0,
					},
				},
				"& th": {
					backgroundColor: t.palette.neutral[100],
					textAlign: "left",
					fontWeight: "initial",
				},
				"& .selectedCell:after": {
					background: alpha(t.palette.primary.main, 0.25),
					content: '""',
					left: 0,
					right: 0,
					top: 0,
					bottom: 0,
					pointerEvents: "none",
					position: "absolute",
					zIndex: 2,
				},
				"& .column-resize-handle": {
					backgroundColor: t.palette.primary.main,
					bottom: "-2px",
					pointerEvents: "none",
					position: "absolute",
					right: "-2px",
					top: 0,
					width: "4px",
				},
			},
		})}>
		{children}
	</Box>
);
