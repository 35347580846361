import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect } from "react";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			networkMode: "offlineFirst",
			gcTime: Infinity,
			retry: false,
		},
		mutations: {
			networkMode: "offlineFirst",
			gcTime: Infinity,
			retry: false,
		},
	},
});

export const QueryProvider = ({ children }) => {
	useEffect(() => {
		window.$queryClient = queryClient;
	}, []);

	return (
		<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
	);
};
