import * as React from "react";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import * as yup from "yup";
import { FormikProps } from "formik";
import FormHelperText from "@mui/material/FormHelperText";

import { ButtonCancel } from "~ui-components/components/atoms/button-cancel";

export const ToolsCopyrightSchema = yup.object({
	attribution: yup.string().notOneOf(["", "Required"]).required("Required"),
});

export type ToolsCopyrightFormValues = yup.InferType<
	typeof ToolsCopyrightSchema
>;

export interface ToolsCopyrightFormProps {
	form: FormikProps<ToolsCopyrightFormValues>;
}

export function ToolsCopyrightForm(
	props: ToolsCopyrightFormProps,
): React.JSX.Element {
	const { form } = props;

	return (
		<form onSubmit={form.handleSubmit}>
			<CardContent>
				<Stack spacing={4}>
					<Stack spacing={3}>
						<Grid
							container
							spacing={3}>
							<Grid
								xs={12}
								md={4}>
								<FormControl
									fullWidth
									error={!!form.errors.attribution}>
									<InputLabel>Attribution text</InputLabel>
									<OutlinedInput {...form.getFieldProps("attribution")} />
									{form.errors.attribution && (
										<FormHelperText>{form.errors.attribution}</FormHelperText>
									)}
								</FormControl>
							</Grid>
						</Grid>
					</Stack>
				</Stack>
			</CardContent>
			<CardActions sx={{ justifyContent: "flex-end" }}>
				<ButtonCancel />
				<Button
					type="submit"
					variant="contained"
					disabled={!form.isValid}>
					Save changes
				</Button>
			</CardActions>
		</form>
	);
}
