import { useQuery } from "@tanstack/react-query";
import { graphierQLNextClient } from "utils";

import {
	GetCurrentAccountQuery,
	GetCurrentAccountQueryVariables,
} from "~auth/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetCurrentAccount {
		getCurrentAccount {
			id
			createdAt
			updatedAt
			companyName
			slug
			billingAddress {
				companyName
				vatNumber
				firstName
				lastName
				streetLine1
				streetLine2
				city
				province
				postalCode
				country
			}
			subscription {
				type
				trialExpiresAt
				paidConfig {
					cycle
					plan
					addons {
						admins
						editors
						maps
						storage
					}
				}
			}
			paymentMethod {
				cardName
				cardNumber
				cardExpiresAt
				cardCvv
			}
			_myUserAccountRole
		}
	}
`;

export type GetCurrentAccountVariables = GetCurrentAccountQueryVariables;
export type GetCurrentAccountResults =
	GetCurrentAccountQuery["getCurrentAccount"];

export const getCurrentAccountQueryKey = () => ["getCurrentAccount"];

const getCurrentAccountQueryFn = async () => {
	return graphierQLNextClient<
		GetCurrentAccountQuery,
		GetCurrentAccountVariables
	>({
		query,
	}).then((res) => res.getCurrentAccount);
};

export const useGetCurrentAccount = (enabled?: boolean) => {
	return useQuery({
		enabled,
		queryKey: getCurrentAccountQueryKey(),
		queryFn: () => getCurrentAccountQueryFn(),
	});
};

export const useGetCurrentAccountCache = () => {
	return useGetCurrentAccount(false);
};

export const useGetCurrentAccountAsserted = () => {
	const { data, ...rest } = useGetCurrentAccountCache();
	if (!data) throw new Error("Data not available yet");

	return { ...rest, data };
};
