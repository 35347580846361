import { Box, StackProps } from "@mui/material";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import _ from "lodash";
import { MuiColorInput } from "mui-color-input";

import { Option } from "~ui-components/components/atoms/option";
import { VISUALISATION_COLORS } from "~ui-components/constants/colors";
import { LineQuantityConfigs } from "~ui-components/types/__generated/gql/graphql";

interface Slots {
	MarkerSettingsDrawer: any;
}

interface SlotProps {
	MarkerSettingsDrawer: any;
}

const Empty = () => null;

const defaultSlots: Slots = {
	MarkerSettingsDrawer: Empty,
};

type Props = StackProps & {
	form: ReturnType<typeof useFormik<LineQuantityConfigs>>;
	attributes: string[];
	slots?: Slots;
	slotProps?: SlotProps;
	onOpenCustomLegend: () => void;
	onMarkerChange: () => void;
	onMarkerSettingClose: () => void;
};

export const LineQuantityStyle = (props: Props) => {
	const {
		slots = {},
		slotProps = {},
		onMarkerSettingClose,
		onOpenCustomLegend,
		attributes,
		form,
	} = props;
	const { values, handleChange, handleBlur, handleSubmit } = form;

	const Slots = {
		...defaultSlots,
		...slots,
	};

	return (
		<>
			<Stack spacing={3}>
				<FormControl sx={{ width: 200 }}>
					<InputLabel>Colour by</InputLabel>
					<Select
						name="valueField"
						onChange={handleChange}
						value={values?.valueField}>
						{attributes?.map((value) => (
							<Option
								key={value}
								value={value}>
								{value}
							</Option>
						))}
					</Select>
				</FormControl>

				<FormControl sx={{ width: 200 }}>
					<InputLabel>Steps</InputLabel>
					<Select
						name="classNumber"
						onChange={handleChange}
						value={values?.classNumber}>
						{_.range(3, 10).map((value) => (
							<Option
								key={value}
								value={value}>
								{value}
							</Option>
						))}
					</Select>
				</FormControl>
				<FormControl sx={{ width: 200 }}>
					<InputLabel>Mode</InputLabel>
					<Select
						name="classification"
						onChange={handleChange}
						value={values?.classification}>
						<Option
							key="manual"
							value="manual">
							Manual
						</Option>
						<Option
							key="equal-interval"
							value="equal-interval">
							Equal Interval
						</Option>
						<Option
							key="jenks"
							value="jenksnatural">
							Jenks
						</Option>
						<Option
							key="quantile"
							value="quantile">
							Quantile
						</Option>
						<Option
							key="heads-tails"
							value="heat-tails">
							Head/Tails
						</Option>
					</Select>
				</FormControl>

				<FormControl sx={{ width: 200 }}>
					<InputLabel>Fill </InputLabel>
					<Select
						name="colorRamp"
						value={values?.colorRamp}
						onChange={handleChange}
						renderValue={(value) =>
							value && VISUALISATION_COLORS[value] ? (
								<Stack direction="row">
									{VISUALISATION_COLORS[value].slice(2).map((color) => (
										<Box
											key={color}
											sx={{ width: 20, height: 20, backgroundColor: color }}
										/>
									))}
								</Stack>
							) : null
						}>
						{Object.keys(VISUALISATION_COLORS).map((key) => (
							<Option
								key={key}
								value={key}>
								<Stack direction="row">
									{VISUALISATION_COLORS[key].slice(2).map((color) => (
										<Box
											key={color}
											sx={{ width: 20, height: 20, backgroundColor: color }}
										/>
									))}
								</Stack>
							</Option>
						))}
					</Select>
				</FormControl>

				<Typography variant="subtitle1">Line and Casing</Typography>

				<FormControl>
					<InputLabel>Line width</InputLabel>
					<OutlinedInput
						type="number"
						sx={{ width: 200 }}
						name="symbolStyle.fill.width"
						onChange={handleChange}
						onBlur={handleBlur}
						value={values?.symbolStyle?.fill?.width}
					/>
				</FormControl>
				<FormControl sx={{ width: 200 }}>
					<InputLabel>Line style</InputLabel>
					<Select
						name="symbolStyle.fill.fillType"
						onChange={handleChange}
						value={values?.symbolStyle?.fill?.fillType}>
						<Option
							key="none"
							value="none">
							None
						</Option>
						<Option
							key="solid"
							value="solid">
							Solid
						</Option>
						<Option
							key="dash"
							value="dash">
							Dash
						</Option>
						<Option
							key="dot"
							value="dot">
							Dot
						</Option>
					</Select>
				</FormControl>
				<FormControl sx={{ width: 200 }}>
					<InputLabel>Line Opacity</InputLabel>
					<Select
						name="symbolStyle.fill.opacity"
						onChange={handleChange}
						value={values?.symbolStyle?.fill?.opacity}>
						{_.range(0, 11).map((value) => (
							<Option
								key={value}
								value={value / 10}>
								{value * 10}%
							</Option>
						))}
					</Select>
				</FormControl>
				<FormControl sx={{ width: 200 }}>
					<InputLabel>Casing colour</InputLabel>
					<MuiColorInput
						sx={{ width: 200 }}
						format="hex"
						value={values?.symbolStyle?.stroke?.color ?? "#000000"}
						onChange={(value) =>
							form.setFieldValue("symbolStyle.stroke.color", value)
						}
					/>
				</FormControl>
				<FormControl sx={{ width: 200 }}>
					<InputLabel>Casing opacity</InputLabel>
					<Select
						name="symbolStyle.stroke.opacity"
						onChange={handleChange}
						value={values?.symbolStyle?.stroke?.opacity}>
						{_.range(0, 11).map((value) => (
							<Option
								key={value}
								value={value / 10}>
								{value * 10}%
							</Option>
						))}
					</Select>
				</FormControl>
				<FormControl sx={{ width: 200 }}>
					<InputLabel>Casing style</InputLabel>
					<Select
						name="symbolStyle.stroke.style"
						onChange={handleChange}
						value={values?.symbolStyle?.stroke?.style}>
						<Option
							key="none"
							value="none">
							None
						</Option>
						<Option
							key="solid"
							value="solid">
							Solid
						</Option>
						<Option
							key="dash"
							value="dash">
							Dash
						</Option>
						<Option
							key="dot"
							value="dot">
							Dot
						</Option>
					</Select>
				</FormControl>

				<FormControl sx={{ width: 200 }}>
					<InputLabel>Casing width</InputLabel>
					<OutlinedInput
						type="number"
						sx={{ width: 200 }}
						name="symbolStyle.stroke.width"
						value={values?.symbolStyle?.stroke?.width}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
				</FormControl>
				<Button
					sx={{ width: 200 }}
					variant="outlined"
					onClick={() => onOpenCustomLegend()}>
					Customise legend
				</Button>
			</Stack>
			<CardActions sx={{ justifyContent: "flex-end", p: 0, mt: 2 }}>
				<Button color="secondary">Cancel</Button>
				<Button
					variant="contained"
					onClick={() => handleSubmit()}>
					Save changes
				</Button>
			</CardActions>
			<Slots.MarkerSettingsDrawer
				onClose={onMarkerSettingClose}
				open={
					(slotProps as { MarkerSettingsDrawer: { open: boolean } })
						.MarkerSettingsDrawer?.open
				}
			/>
		</>
	);
};
