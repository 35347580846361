/**
 * Generates an array of colors ranging between two hex colors.
 * @param startColor - The starting hex color code.
 * @param endColor - The ending hex color code.
 * @param steps - The number of colors to generate between the two colors.
 * @returns An array of hex color codes.
 */
export function generateColorRange(
	startColor: string,
	endColor: string,
	steps: number,
): string[] {
	const startRGB = hexToRGB(startColor);
	const endRGB = hexToRGB(endColor);
	const colors: string[] = [];

	for (let i = 0; i <= steps; i++) {
		const ratio = i / steps;
		const interpolatedColor = interpolateColor(startRGB, endRGB, ratio);
		colors.push(rgbToHex(interpolatedColor));
	}

	return colors;
}

/**
 * Converts a hex color code to RGB format.
 * @param hex - The hex color code.
 * @returns An array representing RGB values.
 */
function hexToRGB(hex: string): number[] {
	// Remove '#' if present
	hex = hex.replace("#", "");

	// Parse the hex into RGB components
	const bigint = parseInt(hex, 16);
	return [
		(bigint >> 16) & 255, // Red
		(bigint >> 8) & 255, // Green
		bigint & 255, // Blue
	];
}

/**
 * Interpolates between two RGB colors based on a ratio.
 * @param startRGB - The starting RGB color.
 * @param endRGB - The ending RGB color.
 * @param ratio - The interpolation ratio (0 to 1).
 * @returns An array representing the interpolated RGB values.
 */
function interpolateColor(
	startRGB: number[],
	endRGB: number[],
	ratio: number,
): number[] {
	return startRGB.map((start, index) =>
		Math.round(start + (endRGB[index]! - start) * ratio),
	);
}

/**
 * Converts an RGB array to a hex color code.
 * @param rgb - The RGB array.
 * @returns A hex color code.
 */
function rgbToHex(rgb: number[]): string {
	return `#${rgb.map((x) => x.toString(16).padStart(2, "0")).join("")}`;
}
