import { GraphQLOptionsV6, GraphQLResult } from "@aws-amplify/api-graphql";
import { fetchAuthSession } from "aws-amplify/auth";
import { client } from "../graphql-client";

export type GraphierQLOpts<TArgs = object> = Pick<GraphQLOptionsV6, "query"> & {
	variables?: TArgs;
	useKey?: boolean;
	headers?: Record<string, string>;
};

/** A simple typesafe helper to make your life easier when using Amplify `graphql` */
export const graphierQL = async <TResults = unknown, TArgs = object>(
	_opts: GraphierQLOpts<TArgs>,
): Promise<TResults> => {
	const opts: GraphQLOptionsV6 = {
		query: _opts.query,
	};

	if (_opts.variables) opts.variables = _opts.variables;

	if (_opts.useKey) {
		opts.authMode = "apiKey";
		if (!process.env.API_KEY) throw new Error("No API key found");

		opts.apiKey = process.env.API_KEY;
	} else {
		const session = await fetchAuthSession();
		const token = session.tokens?.idToken?.toString();
		if (!token) throw new Error("Failed to get token");

		opts.authMode = "userPool";
		opts.authToken = token;
	}

	const request = client.graphql(opts, _opts.headers) as Promise<
		GraphQLResult<TResults>
	>;
	return request
		.then((res) => {
			// eslint-disable-next-line @typescript-eslint/only-throw-error
			if (res.errors) throw res.errors;
			return res.data;
		})
		.catch((res) => {
			if (Array.isArray(res.errors) && res.errors.length)
				throw new Error(res.errors[0].message);

			throw new Error(res.message ?? "Something went wrong!");
		});
};
