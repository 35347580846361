import {
	Box,
	Button,
	FormControl,
	InputLabel,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { useState } from "react";

import { MapToolDrawer } from "../map-tool-drawer";

export const CustomSearchDrawer = ({ onClose }) => {
	const [isSearched, setIsSearched] = useState(false);

	return (
		<MapToolDrawer
			title="Custom search"
			onClose={onClose}
			Content={
				<Stack
					p={2}
					spacing={2}>
					<Typography
						variant="body2"
						color="text.secondary">
						Search the Counties layer for counties matching the following.
					</Typography>
					<FormControl>
						<InputLabel>Number of colleges is less than</InputLabel>
						<TextField />
					</FormControl>
					<InputLabel>AND</InputLabel>
					<FormControl>
						<InputLabel>State name is equal to</InputLabel>
						<TextField />
					</FormControl>
					{isSearched && (
						<Stack
							direction="row"
							spacing={1}
							justifyContent="flex-end"
							alignItems="center">
							<Typography variant="body2">Download</Typography>
							<Button
								href="#"
								target="_blank">
								SHP
							</Button>
							<Button
								href="#"
								target="_blank">
								CSV
							</Button>
							<Button
								href="#"
								target="_blank">
								KML
							</Button>
						</Stack>
					)}
				</Stack>
			}
			Action={
				<Box textAlign="right">
					<Button
						sx={{ mr: 1 }}
						color="inherit"
						onClick={() => setIsSearched(false)}>
						Reset
					</Button>
					<Button
						variant="contained"
						onClick={() => setIsSearched(true)}>
						Search
					</Button>
				</Box>
			}
		/>
	);
};
