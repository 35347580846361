import axios, { AxiosRequestConfig } from "axios";
import { GraphQLError } from "graphql";

import {
	IsEmailTakenMutation,
	IsEmailTakenMutationVariables,
} from "~auth/types/__generated/gql/graphql";

export const isEmailTaken = (
	variables: IsEmailTakenMutationVariables,
	configs?: AxiosRequestConfig,
) => {
	return axios
		.post<{ data: IsEmailTakenMutation; errors?: GraphQLError[] }>(
			process.env.API_URL!,
			{
				query: /* GraphQL */ `
					mutation IsEmailTaken($email: String!) {
						isEmailTaken(email: $email)
					}
				`,
				variables,
			},
			{
				...configs,
				headers: {
					"Content-Type": "application/json",
					"x-api-key": process.env.API_KEY!,
				},
			},
		)
		.then(({ data: { data, errors } }) => {
			if (errors?.length) throw new Error(errors[0]!.message);
			return data.isEmailTaken;
		});
};
