import { Box } from "@mui/material";

import { LineCategory } from "~ui-components/components/molecules/legend/shapes/category/line";
import { PointCategory } from "~ui-components/components/molecules/legend/shapes/category/point";
import { PolygonCategory } from "~ui-components/components/molecules/legend/shapes/category/polygon";
import { LAYER_STYLE_TYPE } from "~ui-components/types/__generated/gql/graphql";
export const Category = ({ style }) => {
	return (
		<Box>
			{style.type === LAYER_STYLE_TYPE.POLYGON_CATEGORY && (
				<PolygonCategory style={style} />
			)}
			{style.type === LAYER_STYLE_TYPE.LINE_CATEGORY && (
				<LineCategory style={style} />
			)}
			{style.type === LAYER_STYLE_TYPE.POINT_CATEGORY && (
				<PointCategory style={style} />
			)}
		</Box>
	);
};
