import {
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Stack,
	Typography,
} from "@mui/material";
import { DEFAULT_ATTRIBUTES_VALUES, RICH_TEXT_CONTENT } from "config/constants";

import { RichTextView } from "~ui-components/components/atoms/rich-text-view";
import { MapToolDrawer } from "../map-tool-drawer";

interface MapMenuDrawerProps {
	onClose: () => void;
	title: string;
	description: string;
}

export const MapMenuDrawer = ({
	onClose,
	title = "",
	description = "",
}: MapMenuDrawerProps) => {
	return (
		<MapToolDrawer
			title=""
			onClose={onClose}
			Content={
				<MapContent
					title={title}
					description={description}
				/>
			}
			Action={null}
		/>
	);
};

const MapContent = (props) => {
	return (
		<Stack
			spacing={2}
			p={2}>
			<img
				src="/new-mangomap-assets/logo.svg"
				width={120}
			/>
			<Typography variant="body1">{props.title}</Typography>
			<Typography
				variant="body2"
				color="text.secondary">
				{props.description}
			</Typography>
			<Stack>
				<Divider />
				<List disablePadding>
					<ListItem disablePadding>
						<ListItemButton>
							<ListItemText
								color="text.primary"
								primary="Maps"
							/>
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton>
							<ListItemText
								color="text.primary"
								primary="Data"
							/>
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton>
							<ListItemText
								color="text.primary"
								primary="About us"
							/>
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton>
							<ListItemText
								color="text.primary"
								primary="Contacts"
							/>
						</ListItemButton>
					</ListItem>
				</List>
				<Divider />
			</Stack>
			<RichTextView
				content={RICH_TEXT_CONTENT}
				valuesToUsed={DEFAULT_ATTRIBUTES_VALUES[0]}
			/>
		</Stack>
	);
};
