import { FC } from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import { PortalHeaderActionButtons } from "~ui-components/components/molecules/portal-header-action-buttons";
import {
	PortalLayout,
	PortalLayoutProps,
} from "~ui-components/components/molecules/portal-layout";

export type DataPortalProps = PortalLayoutProps;

export const DataPortal: FC<DataPortalProps> = (props) => {
	return (
		<DashboardContentLayout
			pageTitle="Data"
			action={<PortalHeaderActionButtons />}
			disableBackButton>
			<PortalLayout {...props} />
		</DashboardContentLayout>
	);
};
