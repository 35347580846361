import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";

interface Slots {
	LayerGroups: any;
	BaseMap: any;
}

interface SlotProps {
	layerGroups: {
		variant: string;
	};
}

const Empty = () => null;

const defaultSlots: Slots = {
	LayerGroups: Empty,
	BaseMap: Empty,
};

interface Props {
	active?: string;
	slots?: Slots;
	slotProps?: SlotProps;
}

export const StandLegend = (props: Props) => {
	const { slots = {}, slotProps = {} } = props;

	const Slots = {
		...defaultSlots,
		...slots,
	};

	return (
		<Card>
			{/* <CardHeader
				avatar={
					<Avatar>
						<LayersIcon fontSize="var(--Icon-fontSize)" />
					</Avatar>
				}
				title="Standard legend"
				subheader="Layer groups can be turned on or off independently."
				action={
					<Link
						href={
							'https://help.mangomap.com/hc/en-us/articles/360022122592-The-Layers-panel'
						}
						target="_blank"
					>
						<IconButton aria-label="settings">
							<QuestionIcon />
						</IconButton>
					</Link>
				}
			/> */}
			<CardContent>
				<Stack spacing={3}>
					<Slots.LayerGroups {...slotProps} />
					<Slots.BaseMap {...slotProps} />
				</Stack>
			</CardContent>
			<CardActions sx={{ justifyContent: "flex-end" }}>
				<Button color="secondary">Cancel</Button>
				<Button variant="contained">Save changes</Button>
			</CardActions>
		</Card>
	);
};
