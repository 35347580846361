import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import MuiCheckbox from "@mui/material/Checkbox";
import * as yup from "yup";
import { FormikProps, Field, useFormikContext } from "formik";

import {
	ColumnDef,
	DataTable,
} from "~ui-components/components/atoms/data-table";
import { ButtonCancel } from "~ui-components/components/atoms/button-cancel";

const AccessLevelSchema = yup.object({
	value: yup.boolean(),
	disabled: yup.boolean().default(false),
	hidden: yup.boolean().default(false),
});

const AccessItemSchema = yup.object({
	id: yup.string().required(),
	name: yup.string().required(),
	view: AccessLevelSchema,
	edit: AccessLevelSchema,
});

const AccessItemsSchema = yup.array().of(AccessItemSchema);

export const AccessSchema = yup.object({
	access: AccessItemsSchema,
});

export type AccessFormValues = yup.InferType<typeof AccessSchema>;
export type AccessItemType = yup.InferType<typeof AccessItemSchema>;

export interface DatasetAccessFormProps {
	form: FormikProps<AccessFormValues>;
}

const columns = [
	{
		formatter: (row): React.JSX.Element => (
			<div>
				<Typography
					color="text.primary"
					sx={{ whiteSpace: "nowrap" }}
					variant="subtitle2">
					{row.name}
				</Typography>
			</div>
		),
		name: "Access groups",
	},
	{
		formatter: (row, index): React.JSX.Element => {
			return (
				<Checkbox
					id={row.id}
					index={index}
					column="view"
					checked={row.view.value}
					disabled={row.view.disabled}
					hidden={row.view.hidden}
				/>
			);
		},
		name: "View",
	},
	{
		formatter: (row, index): React.JSX.Element => {
			return (
				<Checkbox
					id={row.id}
					index={index}
					column="edit"
					checked={row.edit.value}
					disabled={row.edit.disabled}
					hidden={row.edit.hidden}
				/>
			);
		},
		name: "Edit",
	},
] as ColumnDef<AccessItemType>[];

function Checkbox(props) {
	const { id, index, column, checked, disabled, hidden } = props;

	const form = useFormikContext();

	React.useEffect(() => {
		if (["public", "account-owner"].includes(id)) return;
		if (column === "view") return;
		form.setFieldValue(`access.${index}.view.value`, checked);
		form.setFieldValue(`access.${index}.view.disabled`, checked);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, column, checked]);

	if (hidden) return <></>;

	return (
		<Field name={`access.${index}.${column}.value`}>
			{({ field }) => {
				return (
					<MuiCheckbox
						{...field}
						checked={checked}
						disabled={disabled}
					/>
				);
			}}
		</Field>
	);
}

export function DataAccessForm(
	props: DatasetAccessFormProps,
): React.JSX.Element {
	const { form } = props;

	const showDivider = (form.values.access?.length ?? 0) > 2;

	return (
		<form onSubmit={form.handleSubmit}>
			<CardContent>
				<Stack
					divider={<Divider />}
					spacing={4}>
					<Stack spacing={3}>
						<Grid
							container
							spacing={3}>
							<Grid xs={12}>
								<Card>
									<DataTable<AccessItemType>
										sx={[
											showDivider && {
												["tr:nth-of-type(2) > td"]: {
													borderBottom: (theme) =>
														`solid 1px ${theme.palette.grey[400]}`,
												},
											},
										]}
										uniqueRowId={(row) => row.id}
										columns={columns}
										rows={form.values.access ?? []}></DataTable>
								</Card>
							</Grid>
						</Grid>
					</Stack>
				</Stack>
			</CardContent>
			<CardActions sx={{ justifyContent: "flex-end" }}>
				<ButtonCancel />
				<Button
					type="submit"
					variant="contained">
					Save changes
				</Button>
			</CardActions>
		</form>
	);
}
