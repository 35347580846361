import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { Printer as PrinterIcon } from "@phosphor-icons/react/dist/ssr/Printer";
import { Question as QuestionIcon } from "@phosphor-icons/react/dist/ssr/Question";
import RouterLink from "next/link";
import * as React from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

import { ToolsPrintForm, ToolsPrintFormProps } from "./tools-print-form";

const href =
	"https://help.mangomap.com/hc/en-us/articles/360022190072-Printing-maps";

interface ToolsPrintProps {
	form: ToolsPrintFormProps["form"];
}

export function ToolsPrint(props: ToolsPrintProps): React.JSX.Element {
	const { form } = props;

	return (
		<DashboardContentLayout pageTitle="Print">
			<Card>
				<CardHeader
					action={
						<IconButton
							sx={{ borderRadius: 99 }}
							component={RouterLink}
							href={href}>
							<QuestionIcon fontSize="inherit" />
						</IconButton>
					}
					avatar={
						<Avatar>
							<PrinterIcon fontSize="var(--Icon-fontSize)" />
						</Avatar>
					}
					title="Print to PDF with a print composer"
				/>
				<ToolsPrintForm form={form} />
			</Card>
		</DashboardContentLayout>
	);
}

export * from "./tools-print-form";
