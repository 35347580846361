import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { ChangeEvent, useState } from "react";
import Button from "@mui/material/Button";

import { MapToolDrawer } from "~ui-components/components/molecules/map-tool-drawer";

export const MapPrintPreviewDrawer = () => {
	const [titleChecked, setTitleChecked] = useState(true);

	const handleTitleSwitch = (event: ChangeEvent<HTMLInputElement>) => {
		setTitleChecked(event.target.checked);
	};

	const [descriptionChecked, setDescriptionChecked] = useState(true);

	const handleDescriptionSwitch = (event: ChangeEvent<HTMLInputElement>) => {
		setDescriptionChecked(event.target.checked);
	};

	return (
		<MapToolDrawer
			title="Print map"
			Content={
				<Stack spacing={2}>
					<Stack>
						<FormControl>
							<FormControlLabel
								control={
									<Switch
										checked={titleChecked}
										onChange={handleTitleSwitch}
									/>
								}
								label="Title"
							/>
						</FormControl>

						{titleChecked && (
							<FormControl>
								<TextField variant="outlined" />
							</FormControl>
						)}
					</Stack>
					<Stack>
						<FormControl>
							<FormControlLabel
								control={
									<Switch
										checked={descriptionChecked}
										onChange={handleDescriptionSwitch}
									/>
								}
								label="Description"
							/>
						</FormControl>

						{descriptionChecked && (
							<FormControl>
								<TextField variant="outlined" />
							</FormControl>
						)}
					</Stack>
					<FormControl>
						<FormControlLabel
							control={<Switch />}
							label="Popup"
						/>
					</FormControl>
					<FormControl>
						<FormControlLabel
							control={<Switch />}
							label="Legend"
						/>
					</FormControl>
					<FormControl>
						<FormControlLabel
							control={<Switch />}
							label="Feature highlight"
						/>
					</FormControl>
					<FormControl>
						<FormControlLabel
							control={<Switch />}
							label="Basemap"
						/>
					</FormControl>
					<FormControl>
						<FormControlLabel
							control={<Switch />}
							label="Scale bar"
						/>
					</FormControl>
					<FormControl>
						<FormControlLabel
							control={<Switch />}
							label="Northing"
						/>
					</FormControl>
				</Stack>
			}
			Action={
				<Stack
					direction={"row-reverse"}
					spacing={3}>
					<Button variant="contained">Print</Button>
					<Button color="inherit">Refresh</Button>
				</Stack>
			}
		/>
	);
};
