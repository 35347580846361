export const getExtensionFromFileName = (fileName: string) => {
	const arr = fileName.split(".");
	const lastExtension = arr.at(arr.length - 1);

	if (!lastExtension) throw new Error("Invalid file extension");

	if (lastExtension !== "zip") return lastExtension;

	const beforeLastExt = arr.at(arr.length - 2);
	return beforeLastExt === "gdb" ? "gdb.zip" : "zip";
};
