import dayjs from "dayjs";

export const DEFAULT_USER_ID = "00d6b79e-48d0-43da-a838-443e4cab2da8";
export const DEFAULT_USER = {
	id: DEFAULT_USER_ID,
	name: "John Doe",
	email: "john-doe@mangomap.com",
	companyName: "Mangomap V4",
	slug: "mangomap-v4",
	subscription: {
		type: "TRIAL",
		trialExpiresAt: dayjs().add(30, "day").toISOString(),
	},
};

export const DEFAULT_ATTRIBUTES = [
	"comment",
	"gps_date",
	"gps_time",
	"northing",
	"easting",
	"point_id",
	"location",
	"pics",
	"x",
	"y",
];

export const DEFAULT_ATTRIBUTES_VALUES = [
	{
		comment: "Sample comment 1",
		gps_date: "2023-10-01",
		gps_time: "12:34:56",
		northing: 123.456,
		easting: 321.654,
		point_id: "point1234",
		location: "Sample location 1",
		pics: "640/360",
		x: 80.95,
		y: 240.69,
	},
	{
		comment: "Sample comment 2",
		gps_date: "2023-10-02",
		gps_time: "13:45:67",
		northing: 332.123,
		easting: 213.123,
		point_id: "point5678",
		location: "Sample location 2",
		pics: "1280/720",
		x: 45.67,
		y: 89.87,
	},
];
export const RICH_TEXT_CONTENT = `<ol><li><p>Text:</p></li></ol><h1>This is heading 1</h1><h2>This is heading 2</h2><h3>This is heading 3</h3><h4>This is heading 4</h4><h5>This is heading 5</h5><h6>This is heading 6</h6><p>This is paragraph</p><p></p><ol start="2"><li><p>Text styling:</p></li></ol><p><strong>Lorem Ipsum</strong> is <em>simply dummy text</em> of the <s>printing</s> and <strong><em><s>typesetting industry</s></em></strong>.</p><p></p><ol start="3"><li><p>Text alignment:</p></li></ol><p>Left align (default)</p><p style="text-align: center">Center align</p><p style="text-align: right">Right align</p><p style="text-align: justify">Justify content. Justify content. Justify content. Justify content. Justify content. Justify content. Justify content. Justify content. Justify content. Justify content. Justify content. Justify content. Justify content.</p><p style="text-align: justify"></p><ol start="4"><li><p style="text-align: justify">Link:</p></li></ol><p style="text-align: justify"><a target="_blank" rel="noopener noreferrer nofollow" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&amp;t=43s">Check me out!</a><br>You can also click on the link to edit or remove link</p><ol start="5"><li><p style="text-align: justify">Image:</p></li></ol><img src="https://placehold.co/1280x720?text=image+placeholder" data-value="{&quot;type&quot;:&quot;image&quot;,&quot;src&quot;:&quot;https://picsum.photos/{pics}&quot;}"><p>You can also click on image to edit or remove</p><ol start="6"><li><p>Horizontal ruler</p></li></ol><hr><ol start="7"><li><p>Blockquote</p></li></ol><blockquote><p>Some wisest of wise words here, by a wiseass</p></blockquote><ol start="8"><li><p>Codeblock:</p></li></ol><pre><code class="language-typescriptreact">for (var i=1; i &lt;= 20; i++) {
  if (i % 15 == 0)
    console.log("FizzBuzz");
  else if (i % 3 == 0)
    console.log("Fizz");
  else if (i % 5 == 0)
    console.log("Buzz");
  else
    console.log(i);
}</code></pre><ol start="9"><li><p>Code inline</p></li></ol><p><code>This is a code</code> but this is not</p><ol start="10"><li><p>IFrame</p></li></ol><div class="iframe-wrapper"><iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ?si=nVmfbsi1Xz54jZJf" frameborder="0" allowfullscreen></iframe></div><ol start="11"><li><p>Table:</p></li></ol><table style="min-width: 100px"><colgroup><col><col><col><col></colgroup><tbody><tr><th colspan="1" rowspan="1"><p>Name</p></th><th colspan="3" rowspan="1"><p>Description</p></th></tr><tr><td colspan="1" rowspan="1"><p>Cyndi Lauper</p></td><td colspan="1" rowspan="1"><p>Singer</p></td><td colspan="1" rowspan="1"><p>Songwriter</p></td><td colspan="1" rowspan="1"><p>Actress</p></td></tr><tr><td colspan="1" rowspan="1"><p>Cyndi Lauper</p></td><td colspan="1" rowspan="1"><p>Singer</p></td><td colspan="1" rowspan="1"><p>Songwriter</p></td><td colspan="1" rowspan="1"><p>Actress</p></td></tr></tbody></table><ol start="12"><li><p>Charts</p></li></ol><pre><code>// CHART DATA
{
  comment: 'Sample comment 1',
  gps_date: '2023-10-01',
  gps_time: '12:34:56',
  northing: 123.456,
  easting: 321.654,
  point_id: 'point1234',
  location: 'Sample location 1',
  pics: '640/360',
  x: 80.95,
  y: 240.69,
}</code></pre><img src="https://placehold.co/1280x720?text=bar+chart+placeholder" data-value="{&quot;chartType&quot;:&quot;BAR&quot;,&quot;attributes&quot;:[{&quot;key&quot;:&quot;northing&quot;,&quot;label&quot;:&quot;Northing&quot;},{&quot;key&quot;:&quot;easting&quot;,&quot;label&quot;:&quot;Easting&quot;},{&quot;key&quot;:&quot;x&quot;,&quot;label&quot;:&quot;X Value&quot;},{&quot;key&quot;:&quot;y&quot;,&quot;label&quot;:&quot;Y Value&quot;}],&quot;type&quot;:&quot;chart&quot;}"><img src="https://placehold.co/1280x720?text=pie+chart+placeholder" data-value="{&quot;chartType&quot;:&quot;PIE&quot;,&quot;attributes&quot;:[{&quot;key&quot;:&quot;northing&quot;,&quot;label&quot;:&quot;Northing&quot;},{&quot;key&quot;:&quot;easting&quot;,&quot;label&quot;:&quot;Easting&quot;},{&quot;key&quot;:&quot;x&quot;,&quot;label&quot;:&quot;X Value&quot;},{&quot;key&quot;:&quot;y&quot;,&quot;label&quot;:&quot;Y Value&quot;}],&quot;startColour&quot;:&quot;#ff8a00&quot;,&quot;endColour&quot;:&quot;#299f00&quot;,&quot;type&quot;:&quot;chart&quot;}">`;

export const DEFAULT_DATA = [
	{
		type: "point",
		id: "beyond-angkor-points",
		name: "Beyond angkor (points)",
		fileName: "beyond_angkor_points.geojson",
		properties: [
			"name",
			"title",
			"lat",
			"lon",
			"otherValues/native name lang",
			"alternate name",
			"field_7",
			"ba no",
			"condition of site",
			"size of site",
			"type of site",
			"category",
			"field_13",
			"icon",
		],
	},
	{
		type: "line",
		id: "cambodia-roads-lines",
		name: "Cambodia roads (lines)",
		fileName: "cambodia_roads_lines.geojson",
		properties: ["osm_id", "highway", "bridge", "name"],
	},
	{
		type: "polygon",
		id: "khm-admin-3-polygons",
		name: "Khmer admin 3 (polygons)",
		fileName: "khm_admin_3_polygons.geojson",
		properties: [
			"Shape_Leng",
			"Shape_Area",
			"ADM3_EN",
			"ADM3_PCODE",
			"ADM3_REF",
			"ADM3ALT1EN",
			"ADM3ALT2EN",
			"ADM2_EN",
			"ADM2_PCODE",
			"ADM1_EN",
			"ADM1_PCODE",
			"ADM0_EN",
			"ADM0_PCODE",
			"date",
			"validOn",
			"validTo",
		],
	},
];
