import Router from "next/router";
import { graphierQL, GraphierQLOpts } from "../graphierQL";

/**
 * `graphierQL` wrapped with some custom headers available in NextJS client side
 * for server side, use `graphierQL` directly
 * */
export const graphierQLNextClient = async <TResults = unknown, TArgs = object>(
	opts: GraphierQLOpts<TArgs>,
): Promise<TResults> => {
	return graphierQL<TResults, TArgs>({
		...opts,
		headers: {
			"x-mangomap-account-slug": Router.query["account-name"]
				? String(Router.query["account-name"])
				: "",
			...(opts.headers ?? {}),
		},
	});
};
