import { Stack } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { GridRowModel } from "@mui/x-data-grid-pro";
import { Gear as DetailsIcon } from "@phosphor-icons/react/dist/ssr/Gear";
import { useFormik } from "formik";

import { LineSortable } from "~ui-components/components/molecules/custom-classbreak/sortable/line";
import { PointSortable } from "~ui-components/components/molecules/custom-classbreak/sortable/point";
import { PolygonSortable } from "~ui-components/components/molecules/custom-classbreak/sortable/polygon";
import {
	LAYER_STYLE_TYPE,
	SymbolValue,
} from "~ui-components/types/__generated/gql/graphql";
interface Props {
	form: ReturnType<
		typeof useFormik<{ symbolValues: SymbolValue[]; type: string }>
	>;
	onRowsChange: (rows: GridRowModel[]) => void;
}

export const CustomClassbreak = (props: Props) => {
	const { form, onRowsChange } = props;
	return (
		<Card sx={{ width: "100%" }}>
			<CardHeader
				avatar={
					<Avatar>
						<DetailsIcon fontSize="var(--Icon-fontSize)" />
					</Avatar>
				}
				title="Map details"
			/>
			<CardContent>
				<Stack spacing={3}>
					<Stack spacing={2}>
						{form.values.type === LAYER_STYLE_TYPE.LINE_CATEGORY && (
							<LineSortable
								form={form}
								onRowsChange={onRowsChange}
							/>
						)}
						{form.values.type === LAYER_STYLE_TYPE.POLYGON_CATEGORY && (
							<PolygonSortable
								form={form}
								onRowsChange={onRowsChange}
							/>
						)}
						{form.values.type === LAYER_STYLE_TYPE.POINT_CATEGORY && (
							<PointSortable
								form={form}
								onRowsChange={onRowsChange}
							/>
						)}
					</Stack>
				</Stack>
			</CardContent>
			<CardActions sx={{ justifyContent: "flex-end" }}>
				<Button color="secondary">Cancel</Button>
				<Button
					variant="contained"
					onClick={() => {
						form.handleSubmit();
					}}>
					Save changes
				</Button>
			</CardActions>
		</Card>
	);
};
