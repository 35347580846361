import axios from "axios";
import { useRouter } from "next/router";
import { SignUpVerification as View, toast } from "ui-components";

import { useResendEmail } from "~auth/queries/useResendEmail";

export const SignUpVerification = () => {
	const {
		query: { email },
	} = useRouter();

	const resendEmail = useResendEmail();

	const onResendEmail = () => {
		toast.promise(resendEmail.mutateAsync({ email: email as string }), {
			loading: "Sending email...",
			success: "Email sent!",
			error: (e) =>
				axios.isAxiosError(e) ? "Something went wrong!" : e.message,
		});
	};

	return (
		<View
			email={email as string}
			onResendEmail={onResendEmail}
		/>
	);
};
