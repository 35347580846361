import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

import { PortalCreateMap } from "~ui-components/components/molecules/portal-create-map";

import { ItemRow } from "./items/item-row";

import { MapsViewProps } from ".";

export function MapsPortalRowView(props: MapsViewProps): React.JSX.Element {
	const {
		isLoading,
		items,
		onItemDuplicate,
		onItemLock,
		onItemClick,
		onItemDelete,
		onCreateMap,
	} = props;

	return (
		<React.Fragment>
			<PortalCreateMap
				title="Create Map"
				onCreate={() => onCreateMap?.()}
			/>
			{isLoading && <CircularProgress sx={{ mx: "auto" }} />}
			<Box sx={{ mx: -3, my: -6 }}>
				<Box sx={{ overflowX: "auto", px: 3 }}>
					<Table sx={{ borderCollapse: "separate", borderSpacing: "0 24px" }}>
						<TableHead sx={{ visibility: "collapse" }}>
							<TableRow>
								<TableCell
									sx={{ width: "250px", minWidth: "250px", maxWidth: "250px" }}
								/>
								<TableCell
									sx={{ width: "25px", minWidth: "25px", maxWidth: "25px" }}
								/>
								<TableCell
									sx={{ width: "25px", minWidth: "25px", maxWidth: "25px" }}
								/>
							</TableRow>
						</TableHead>
						<TableBody>
							{Array.from(items.values()).map((item, index) => (
								<ItemRow
									key={index}
									item={item}
									onLock={onItemLock}
									onDuplicate={onItemDuplicate}
									onDelete={onItemDelete}
									onMapClick={onItemClick}
								/>
							))}
						</TableBody>
					</Table>
				</Box>
			</Box>
		</React.Fragment>
	);
}
