import createCache from "@emotion/cache";
import { EmotionCache } from "@emotion/react";
import { AuthProvider } from "auth";
import { Amplify } from "aws-amplify";
import maplibregl from "maplibre-gl";
import { NextPage } from "next";
import { AppProps } from "next/app";
import NProgress from "nprogress";
import { useEffect } from "react";
import { DefaultSeo, Toaster, UiProvider } from "ui-components";
import {
	AnalyticsProvider,
	I18nProvider,
	QueryProvider,
	ReactDevTools,
} from "utils";

NProgress.configure({ showSpinner: false });

import "aos/dist/aos.css";
import "material-symbols/rounded.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "~ui-components/global.css";

Amplify.configure(
	{
		API: {
			GraphQL: {
				endpoint: process.env.API_URL!,
				defaultAuthMode: "apiKey",
			},
		},
		Auth: {
			Cognito: {
				userPoolId: process.env.AUTH_USER_POOL_ID!,
				userPoolClientId: process.env.AUTH_USER_POOL_CLIENT_WEB_ID!,
				signUpVerificationMethod: "code",
			},
		},
	},
	{
		ssr: true,
	},
);

ReactDevTools.init();

const clientSideEmotionCache = createCache({ key: "css" });

export interface CustomAppProps extends AppProps {
	Component: NextPage;
	emotionCache?: EmotionCache;
}

const loadCogProtocol = async () => {
	const { cogProtocol } = await import("@geomatico/maplibre-cog-protocol");
	maplibregl.addProtocol("cog", cogProtocol);
};
if (typeof window !== "undefined") void loadCogProtocol();

const App = ({
	Component,
	pageProps,
	emotionCache = clientSideEmotionCache,
	router,
}: CustomAppProps) => {
	const isFranchise = router.pathname === "/franchise";

	useNprogress(router);

	return (
		<>
			<DefaultSeo />
			<AnalyticsProvider
				pixelId={process.env.FACEBOOK_PIXEL_ID}
				insightId={process.env.LINKEDIN_INSIGHT_TAG_ID}
				clarityId={process.env.MS_CLARITY_ID}
				gaId={
					isFranchise
						? process.env.FRANCHISE_GA_MEASUREMENT_ID
						: process.env.GIS_GA_MEASUREMENT_ID
				}
				gtmId={
					isFranchise
						? process.env.FRANCHISE_GOOGLE_TAG_MANAGER_ID
						: process.env.GIS_GOOGLE_TAG_MANAGER_ID
				}>
				<I18nProvider language="en">
					<QueryProvider>
						<AuthProvider>
							<UiProvider emotionCache={emotionCache}>
								<Component {...pageProps} />
								<Toaster position="bottom-right" />
							</UiProvider>
						</AuthProvider>
					</QueryProvider>
				</I18nProvider>
			</AnalyticsProvider>
		</>
	);
};

export default App;

const useNprogress = (router) => {
	useEffect(() => {
		const handleRouteStart = () => NProgress.start();
		const handleRouteDone = () => NProgress.done();

		router.events.on("routeChangeStart", handleRouteStart);
		router.events.on("routeChangeComplete", handleRouteDone);
		router.events.on("routeChangeError", handleRouteDone);

		return () => {
			// Make sure to remove the event handler on unmount!
			router.events.off("routeChangeStart", handleRouteStart);
			router.events.off("routeChangeComplete", handleRouteDone);
			router.events.off("routeChangeError", handleRouteDone);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
