import {
	Alert,
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Stack,
	Switch,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";

import { MapToolDrawer } from "../map-tool-drawer";
import { PropertyList } from "~ui-components/components/atoms/property-list";
import { PropertyItem } from "~ui-components/components/atoms/property-item";

let timer: NodeJS.Timeout | null = null;

export const GeolocationDrawer = ({ onClose }) => {
	const [position, setPosition] = useState<{ lat: ReactNode; lng: ReactNode }>({
		lat: 0,
		lng: 0,
	});
	const [isActivated, setIsActivated] = useState(false);
	const [isFollowing, setIsFollowing] = useState(false);

	const getPosition = async () => {
		if (!("geolocation" in navigator))
			throw new Error("Geolocation not supported");

		await new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					setPosition({
						lat: position.coords.latitude.toFixed(6),
						lng: position.coords.longitude.toFixed(6),
					});
					resolve(position);
				},
				(e) => {
					setIsActivated(false);
					reject(new Error(e.message));
				},
			);
		});
	};

	useEffect(() => {
		if (isFollowing) {
			timer = setInterval(getPosition, 2000);
		}

		return () => {
			if (timer) clearInterval(timer);
		};
	}, [isFollowing]);

	return (
		<MapToolDrawer
			title="Geolocation"
			onClose={onClose}
			Content={
				isActivated ? (
					<Stack
						p={2}
						spacing={2}>
						<PropertyList sx={{ px: 0 }}>
							<PropertyItem
								name="Location"
								value={`${position.lat}, ${position.lng}`}
								boxProps={{ sx: { px: 0 } }}
							/>
						</PropertyList>
						<FormControl>
							<FormControlLabel
								control={
									<Switch
										checked={isFollowing}
										onChange={(_, bool) => setIsFollowing(bool)}
									/>
								}
								label="Follow mode"
							/>
						</FormControl>
					</Stack>
				) : (
					<Stack
						p={2}
						spacing={2}>
						<Alert severity="error">
							{
								"We couldn't locate you. Ensure you have allowed your browser to know your location and try again"
							}
						</Alert>
						<Box
							component="img"
							src="/new-mangomap-assets/geolocation_permission.svg"
							alt="permissions"
							width="100%"
							height="auto"
						/>
					</Stack>
				)
			}
			Action={
				<Box textAlign="right">
					<Button
						variant="contained"
						onClick={async () => {
							if (!isActivated) await getPosition();
							setIsActivated((bool) => !bool);
						}}>
						{isActivated ? "Deactivate location" : "Activate location"}
					</Button>
				</Box>
			}
		/>
	);
};
